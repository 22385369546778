import React from 'react';
import styles from './CatDescriptions.module.css';
import WhatHelp from '../../../assets/images/what_help.svg';
import WhatMake from '../../../assets/images/what_make.svg';
import WhatTeach from '../../../assets/images/what_teach.svg';
import WhatRent from '../../../assets/images/what_rent.svg';

const catDescriptions = () => {
    return(
    <div className={styles.WhatDescription}>
        <div className={styles.Description}>
            <div className={styles.DescHeader}>
                <div className={`icon-help ${styles.HelpCat} ${styles.CatIcon}`} />
                <h5>「助けて」・「助けます」をつなぐ</h5>
            </div>
            <div className={styles.WhatImage}>
                <img className={styles.MainContentImage} src={WhatHelp}　alt="助けて・助けます" />
            </div>
            <p>日常の家事・育児・介護など「助け」が必要な人と助けたい人をつなげます。</p>
        </div>
        <div className={styles.Description}>
            <div className={styles.DescHeader}>
                <div className={`icon-teach ${styles.TeachCat} ${styles.CatIcon}`} />
                <h5>「教えて」・「教えます」をつなぐ</h5>
            </div>
            <div className={styles.WhatImage}>
                <img className={styles.MainContentImage} src={WhatTeach}　alt="教えて・教えます" />
            </div>
            <p>家庭教師、パン教室、ヨガ教室など教えて欲しい人と教えたい人をつなげます。</p>
        </div>
        <div className={styles.Description}>
            <div className={styles.DescHeader}>
                <div className={`icon-rent ${styles.RentCat} ${styles.CatIcon}`} />
                <h5>「貸して」・「貸します」をつなぐ</h5>
            </div>
            <div className={styles.WhatImage}>
                <img className={styles.MainContentImage} src={WhatRent} alt="貸して・貸します" 　/>
            </div>
            <p>スーツケース・美容家電・礼服など貸して欲しい人と貸したい人をつなげます。</p>
        </div>
        <div className={styles.Description}>
            <div className={styles.DescHeader}>
                <div className={`icon-make ${styles.MakeCat} ${styles.CatIcon}`}　 />
                <h5>「作って」・「作ります」をつなぐ</h5>
            </div>
            <div className={styles.WhatImage}>
                <img className={styles.MainContentImage} src={WhatMake}　alt="作って・作ります" />
            </div>
            <p>通園の巾着、体操服のゼッケン、など作って欲しい人と作ってあげたい人をつなげます。</p>
        </div>
    </div>
    );
}

export default catDescriptions;