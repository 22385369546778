const numberWithCommasWithDec = (num, localCode, LocalStrings, mark) => {
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return mark===false ? parts.join(".") : LocalStrings[localCode].CURRENCY_MARK + parts.join(".");
}

const numberWithCommas = (num, localCode, LocalStrings, mark) => {
    return mark===false ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : LocalStrings[localCode].CURRENCY_MARK + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const priceFormat = ( number, localCode, LocalStrings, mark ) => {
    //let formatType = {};
    let formatedString;

    switch (localCode) {
        case 'ja_jp':
            //formatType = { loc: 'ja-JP', currency: 'JPY'};
            formatedString = numberWithCommas(number, localCode, LocalStrings, mark);
            break;
        case 'en_us':
            //formatType = { loc: 'en-IN', currency: 'USD'};
            formatedString = numberWithCommasWithDec(number, localCode, LocalStrings, mark);
            break;
        default:
            //formatType = { loc: 'ja-JP', currency: 'JPY' }
            formatedString = numberWithCommas(number, localCode, LocalStrings, mark)
    }
    // toLocalseString currency format is not supported for android, we can't use this yet
    //let formatedString = number.toLocaleString(formatType.loc, { style: 'currency', currency: formatType.currency })
    return formatedString;
}

export default priceFormat;
