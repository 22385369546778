import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class CertChart extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>支払い審査とは</span>
            </h3>
            <p className={styles.ArticleDesc}>
                支払い審査とは、タグジャムで買い手がサービスを購入申請し、売り手に受付された後、お支払い情報（クレジットカード）を登録して頂きます。
                その後、タグジャムはそのカードでお支払いが可能かどうか（利用限度額超過等のお支払いできないなどの支払い不可の状態でないか）の審査を行います。
                この審査の際に実際にお支払いいただく金額より余裕を持った金額を審査いたします。お支払い審査額はそれぞれの項目で算出方法が異なります。
            </p>
            <div className="Item">
                <dl className="Table">
                    <dt>
                        <strong>助けます</strong>
                    </dt>
                    <dd>
                        時間延長、オプション追加及び実費精算額を考慮した金額    
                    </dd>
                    <dt>
                        <strong>教えます</strong>
                    </dt>
                    <dd>
                        実費精算額を考慮した金額    
                    </dd>
                    <dt>
                        <strong>貸します</strong>
                    </dt>
                    <dd>
                        レンタル料及び保証金を考慮した金額    
                    </dd>
                    <dt>
                        <strong>作ります</strong>
                    </dt>
                    <dd>
                        件数追加及び実費精算額を考慮した金額    
                    </dd>
                </dl>
            </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(CertChart);