import React, { Fragment }  from 'react';
import { NavLink } from 'react-router-dom';
import {isIOS, isAndroid} from 'react-device-detect';
import styles from './Footer.module.css';
import AppStore from '../../assets/images/Download_App_Store.svg';
import GooglePlayStore from '../../assets/images/Download_Badge_JP.svg';
import LINE_ICON from '../../assets/images/LINE.svg';

const footer = (props) =>{
    const year = new Date().getFullYear();
    return(
        <footer className={styles.Footer}>
            <div className={styles.FooterContent}>
                <div className={styles.Section}>
                    <h5>ダウンロード</h5>
                    { ((!isAndroid && !isIOS) || isIOS) &&
                    <Fragment>
                    <a href="https://apps.apple.com/jp/app/%E3%82%BF%E3%82%B0%E3%82%B8%E3%83%A3%E3%83%A0-tagujam/id1479792883?mt=8" target="blank">
                        <img src={AppStore} alt="iPhone ダウンロード" />
                    </a>
                    <br />
                    </Fragment> 
                    }
                    { ((!isAndroid && !isIOS) || isAndroid) && 
                    <a href="https://play.google.com/store/apps/details?id=com.tagujammobile" target="blank">
                    <img src={GooglePlayStore} alt="Android ダウンロード" />
                    </a>
                    } 
                </div>
                <div className={styles.Section}>
                    <h5>ヘルプ＆ガイド</h5>
                    <p className={styles.Item}>
                        <NavLink to="/help" exact={true}>
                            使い方ガイド
                        </NavLink>
                    </p>
                    <p className={styles.Item}>
                        <NavLink to="/dealflow" exact={true}>
                            ご利用の流れ
                        </NavLink>
                    </p>
                </div>
                <div className={styles.Section}>
                    <h5>各種規約</h5>
                    <p className={styles.Item}>
                        <NavLink to="/privacy" exact={true}>
                            プライバシーポリシー
                        </NavLink>
                    </p>
                    <p className={styles.Item}>
                        <NavLink to="/terms" exact={true}>
                            タグジャム利用規約
                        </NavLink>
                    </p>
                    <p className={styles.Item}>
                        <NavLink to="/cancelpolicy" exact={true}>
                            キャンセルポリシー
                        </NavLink>
                    </p>
                    <p className={styles.Item}>
                        <NavLink to="/sctl" exact={true}>
                            特定商取引に関する表記
                        </NavLink>
                    </p>  
                </div>
                <div className={styles.Section}>
                    <h5>読み物</h5>
                    <p className={styles.Item}>
                    <NavLink to="/blog/" exact={true}>
                      タグジャム注目の記事
                    </NavLink>
                    </p>
                    <p className={styles.Item}>
                    <NavLink to="blog/HowToStart" exact={true}>
                      タグジャム始めの一歩
                    </NavLink>
                    </p>
                    {/* <p className={styles.Item}>
                    <NavLink to="/blog/Examples" exact={true}>
                      タグジャム活用例
                    </NavLink>
                    </p> */}
                    {/* <p className={styles.Item}>
                    <NavLink to="/blog/Features" exact={true}>
                      タグジャム便利機能
                    </NavLink>
                    </p> */}
                    <p className={styles.Item}>
                    <NavLink to="/blog/HowToVideos" exact={true}>
                      タグジャム使い方動画
                    </NavLink>
                    </p>
                </div>
                <div className={styles.Section}>
                    <h5>運営会社</h5>
                    <p className={styles.Item}>
                    <NavLink to="/about" exact={true}>
                        会社概要
                    </NavLink>
                    </p>
                    <p className={styles.Item}>
                    <NavLink to="/about/mission" exact={true}>
                        私たちのミッション
                    </NavLink>
                    </p>
                    <p className={styles.Item}>
                    <NavLink to="/about/team" exact={true}>
                        運営チーム
                    </NavLink>
                    </p>
                </div>
                <div className={styles.Section}>
                    <h5>ソーシャルメディア</h5>
                    <p className={styles.SnsSection}>
                        <a href="https://www.facebook.com/TaguJam" target="blank">
                        <i className={`fa fa-facebook-square ${styles.Icon}`} style={{marginRight:10}} />
                        </a>
                        <a href="https://twitter.com/TaguJam" target="blank">
                          <i className={`fa fa-twitter ${styles.Icon}`} style={{marginRight:10}} />
                        </a>
                        <a href="https://lin.ee/ZZ3I16P" target="blank">
                          <img src={LINE_ICON} style={{width:'22px', marginRight:'10px', marginTop:'1px'}} alt="ラインロゴ"　/>
                        </a>
                        <a href="https://www.instagram.com/tagujam_official/" target="blank">
                          <i className={`fa fa-instagram ${styles.Icon}`} style={{marginRight:10}} />
                        </a>
                        {/* 
                        <a href="/terms" target="blank">
                          <i className={`fa fa-youtube-play ${styles.Icon}`} style={{marginRight:10}} />
                        </a>
                         */}
                    </p>
                </div>
                <div className={`${styles.Section} ${styles.space}`}/>
            </div>
            <p className={styles.CopyRight}>©{year} タグジャム　All Rights Reserved.</p>
        </footer>
    );
} 

export default footer;