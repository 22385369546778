import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styles from './About.module.scss';
import Aux from '../../hoc/Aux/Aux';
import TaguJamLogo_white from '../../assets/images/tagujam_logo_white.svg';
import TaguJamTypeface_white from '../../assets/images/tagujam_typeface_white.svg';
import Menu from './Menu/Menu'
import CompanyInfo from './CompanyInfo/CompanyInfo';
import Mission from './Mission/Mission';
import Team from './Team/Team';


class About extends Component {
    render(){
      const { appData } = this.props;
      const { LocalStrings } = appData;
      let content = null;
      if(LocalStrings){
          content = <Switch>
          <Route path="/about/mission" exact component={Mission} />
          <Route path="/about/team" exact component={Team} />
          <Route path="/about" exact component={CompanyInfo} />
          <Redirect to="/about" />
      </Switch>;
      }
        return(
          <Aux>
            <div className={styles.AboutHeader}>
              <div className={styles.HeroImage}>
                  <div className={styles.HeroText}>
                    <div className={styles.BrandBox}>
                      <div className={styles.LogoWithTypeFace}>
                          <img className={styles.Logo} src={TaguJamLogo_white} alt="TaguJam タグジャム　ロゴ" />
                          <img className={styles.LogoTypeFace} src={TaguJamTypeface_white} alt="TaguJam タグジャム　タイプファエイス" />
                      </div>
                    </div>
                    <h1 className={styles.TagLine}>
                      あなたの「一歩踏み出す」<br />を応援します
                    </h1>
                  </div>
              </div>
              <Menu 
                sectionPath={this.props.history.location.pathname}
              />
            </div>  
            {content}
            <div className={styles.Banner}>
              <h1 className={styles.BannerText}>地域の「ありがとう」<br />を繋ぐコミュニティー</h1>
            </div>
          </Aux>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError, 
});

export default connect(mapStateToProps, null)(About);