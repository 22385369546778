import React from 'react';
import styles from './Remark.module.scss';
import TextStyle from '../../../constants/TextStyle.module.scss';

const Remark = (props) => {
    const { localCode, appData:{LocalStrings}, theGig } = props;
    return(
    <div className={`${styles.Remark}`} style={{marginBottom:'30px'}}>
        <p className={TextStyle.BodyLargeText} style={{marginBottom:'10px'}}>
            {LocalStrings[localCode].REMARK}
        </p>
        <div>
            <p className={TextStyle.BodyMediumText}>
                {theGig.remark}
            </p>
        </div>
    </div>
    )
}

export default Remark