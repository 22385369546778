import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './FeatureRegular.module.scss';
import {trunicateString} from '../../../utils';
import Aux from '../../../hoc/Aux/Aux';

const FeatureRegular = (props) => {
  const {item, index, clicked} = props;
  const {id, imagePath, title, shortDescription} = item;
  let content = <Aux>
      <div className={styles.PodImageSmallRegular}>
        <img className={styles.PodImgSmallRegular} src={imagePath} alt={`始め方の記事${index+1}`} />
      </div>
      <div className={styles.PodSmallTextAreaRegular}>
        <h4 className={styles.PodSmallTextHeadRegular}>
          {title}
        </h4>
        <p className={styles.PodSmallTextRegular}>
          {trunicateString(42, shortDescription)}
          <span className={styles.Details}>
            詳しく
          </span>
        </p>
      </div>
    </Aux>
  
  if(clicked){
    return(
      <div onClick={()=>clicked(id)} className={styles.PodSmallRegular}>
        {content}  
      </div>
    );
  } else {
    return(
      <NavLink className={styles.PodSmallRegular} to={`/blog/Article/${id}`}>
        {content}  
      </NavLink>
    );
  }
}

export default FeatureRegular