import {
    FETCH_THE_SALES,
} from '../actions/actionTypes';


const initState = {
    theSale: null,
    theSaleIsLoading: false,
    theSaleError: false
};

const salesReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${FETCH_THE_SALES}_PENDING`:
            return {
                ...state,
                theSaleIsLoading: true,
                theSaleError: false,
            }
        case `${FETCH_THE_SALES}_CUSTOMER_FULFILLED`:
            return {
                ...state,
                theSale: {
                    ...state.theSale,
                    customer: payload
                },
                theSaleIsLoading: false,
                theSaleError: false,
            }
        case `${FETCH_THE_SALES}_PROVIDER_FULFILLED`:
            return {
                ...state,
                theSale: {
                    ...state.theSale,
                    provider: payload
                },
                theSaleIsLoading: false,
                theSaleError: false,
            }
        case `${FETCH_THE_SALES}_REJECTED`:
            return {
                ...state,
                theSale: null,
                theSaleIsLoading: false,
                theSaleError: true,
            }
        default:
            return state;
    }
};

export default salesReducer;