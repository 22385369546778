import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import SellersMannger from './Manner/SellersManner';
import BuyersMannger from './Manner/BuyersManner';

class BasicManner extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>タグジャムのマナー</span>
            </h3>
            <p className={styles.ArticleDesc}>以下に示すマナーを守り正しく使いましょう。</p>
            
            <h4 className={styles.HeaderMargin20}>基本マナー</h4>
            <h5 className={styles.HeaderMargin10}>あいさつをしましょう</h5>
            <p>タグジャムで、質問、その返信、購入申請、購入受付などやりとりが始まったら、まず相手にあいさつをしましょう。誠実な態度を心がけると、お互い気持ちよくやり取りを進められます。アカウント登録の際、自己紹介文を入力するなどやりとりをする相手になるべく安心してもらうように心がけましょう。</p>
            <h5 className={styles.HeaderMargin10}>丁寧な言葉遣いを心がけましょう</h5>
            <p>タグジャムは多くのお客様にご利用いただき、年齢層も幅広いです。このため、やり取りは丁寧な言葉・敬語で行なうなど、相手に配慮した失礼のない言葉遣いを心がけましょう。相手の状況の理解など思いやりを持って接する事でお互いに取引がスムーズに進みます。</p>
            <h5 className={styles.HeaderMargin10}>感情的にならないように心がけましょう</h5>
            <p>取引始めの際、文字だけのやり取りは、対面でのコミュニケーションより誤解が起こりやすくなります。一時的な感情でメッセージを送信せず、少し時間をおいて冷静になった状態で送信するなどあくまでもビジネスライクのやり取りを心がけましょう。</p>
            <h5 className={styles.HeaderMargin10}>返信は速やかにしましょう</h5>
            <p>メッセージを送信後に返事がないと、相手は不安になってしまうものです。受信後は速やかな返信を心がけ、またすぐに返事ができない場合は「ご連絡ありがとうございます。内容を確認後またご連絡します。」といった一時返信をすることで、相手に安心してもらえます。特に当日のサービスの申し込みの受付の際には迅速な返信を心がけましょう。</p>
            
            <h4 className={styles.HeaderMargin20}>購入に関するマナー</h4>
            <BuyersMannger />
            <h4 className={styles.HeaderMargin20}>出品に関するマナー</h4>
            <SellersMannger />
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BasicManner);