import {
    FETCH_USER_ADDRESS,
    SET_USER_ADDRESS,
    SET_THE_USER_ADDRESS,
    DELETE_THE_USER_ADDRESS
} from '../actions/actionTypes';

const initState = {
    userAddress: null,
    userAddressIsLoading: false,
    userAddressError: false
};

const removeAddress = (userAddress, addressType) =>{
    let newUserAddress = {...userAddress};
    delete newUserAddress[addressType];
    return newUserAddress;
}

const userAddressReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_USER_ADDRESS:
            return {
                ...state,
                userAddress: payload
            }
        case SET_THE_USER_ADDRESS:
            return {
                ...state,
                userAddress: {
                    ...state.userAddress,
                    [payload.type]:payload.userAddress
                }
            }
        case DELETE_THE_USER_ADDRESS:
            return {
                ...state,
                userAddress: removeAddress(state.userAddress, payload)
            }
        case `${FETCH_USER_ADDRESS}_PENDING`:
            return {
                ...state,
                userAddressIsLoading: true,
                userAddressError: false,
            }
        case `${FETCH_USER_ADDRESS}_FULFILLED`:
            return {
                ...state,
                userAddress: payload,
                userAddressIsLoading: false,
                userAddressError: false,
            }
        case `${FETCH_USER_ADDRESS}_REJECTED`:
            return {
                ...state,
                userAddress: null,
                userAddressIsLoading: false,
                userAddressError: true,
            }
        default:
            return state;
    }
};

export default userAddressReducer;
