import {
    FETCH_USERS_LOCATION    
} from '../actions/actionTypes';

const initState = {
    isLocationLoading: false,
    locationAddress: null,
    locationError: false,
}

const userLocationAddressReducer = (state=initState, action) => {
    switch(action.type) {
        case `${FETCH_USERS_LOCATION}_PENDING`:{
            return{
                ...state,
                isLocationLoading:true,
                locationError:false
            }
        }
        case `${FETCH_USERS_LOCATION}_FULFILLED`:{
            return{
                ...state,
                locationAddress: action.payload,
                isLocationLoading:false,
                locationError:false
            }
        }
        case `${FETCH_USERS_LOCATION}_REJECTED`:{
            return{
                locationAddress: null,
                isLocationLoading:false,
                locationError:true
            }
        }
        default:
            return state;
    }
}

export default userLocationAddressReducer;