import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import UserInfo0 from '../../../assets/images/help/account/user_info.png'
import UserInfo1 from '../../../assets/images/help/account/user_info/user_info_1.png'
import UserInfo2 from '../../../assets/images/help/account/user_info/user_info_2.png'
import UserInfo3 from '../../../assets/images/help/account/user_info/user_info_3.png'
import UserInfo4 from '../../../assets/images/help/account/user_info/user_info_4.png'
import UserInfo5 from '../../../assets/images/help/account/user_info/user_info_5.png'

class UserInfo extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>ユーザー情報</span>
            </h3>
            <p className={styles.ArticleDesc}>ここでは、本人確認に使用される情報を入力していきます。姓・名や生年月日は本人確認に使用する書類と一致するものを正確に入力してください。</p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「ユーザー情報」を選びます。<br />
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo0} alt="ユーザー情報登録ステップ1画像" />
                    <li>
                        姓・名、生年月日を入力し、「保存」をタップします。<br />
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo1} alt="ユーザー情報登録ステップ2画像" />
                    <li>
                        次に、Eメールを確認します。「認証コード送信」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo2} alt="ユーザー情報登録ステップ3画像" />
                    <li>
                        指定のEメールをチェックして認証コードをコピーします。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo3} alt="ユーザー情報登録ステップ4画像" />
                    <li>
                        認証コードを貼り付けまたは入力し、「認証」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo4} alt="ユーザー情報登録ステップ5画像" />
                    <li>
                    「確認済み」のサインが出たら完了です。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo5} alt="ユーザー情報登録ステップ6画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(UserInfo);