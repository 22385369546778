import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux';
import AboutStyles from '../About.module.scss';
import styles from './Team.module.scss';
import Blue_line from '../../../assets/images/blue_line.svg';
import WakiyamaTop from '../../../assets/images/terumi_wakiyama_portray.jpg';
import Wakiyama from '../../../assets/images/terumi_wakiyama_600x600.jpg';
import Minoda from '../../../assets/images/yukari_minoda_600x600.jpg';
import Otsuki from '../../../assets/images/kazue_otsuki_600x600.jpg';
import Matsumiya from '../../../assets/images/hiroshi_matsumiya_600x600.jpg';



class Team extends Component {
    render(){
        const {location} = this.props;
        let pathname = location.pathname;
        let title = '主なチーム紹介';
        let description = 'タグジャムは「好きな時間に、好きな分だけ働く」をサポートするプラットフォームです。地位域の「お手伝い」のニーズと、それを提供できる人を繋げる事を目的とし日々頑張るチームメンバーの紹介です。';
        return(
          <>
          <Helmet>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content={`タグジャム-${title}`} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={`タグジャム-${title}`} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={description}
              />
              <meta content="タグジャム運営チーム,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className={AboutStyles.AboutPageContent}>
            <h2 className={AboutStyles.SectionHead}>運営チーム</h2>
            <div className={styles.Greeting}>
              <h4 className={styles.GreetingTitle}>代表者あいさつ</h4>
              <div className={styles.CEOImageBox}>
                <img className={styles.CEOImage} src={WakiyamaTop} alt={"CEO:脇山輝美"} />
              </div>
              <p className={styles.GreetingText}>タグジャムは「好きな時間に、好きな分だけ働く」をサポートするプラットフォームです。ちょっとした手助けが欲しい、何かを教えて欲しい、何かを借りたい、何かを作って欲しいなど、小さな「お手伝い」のニーズと、それを提供できる人を繋げる事を目的としています。</p>
              <p className={styles.GreetingText}>家事や育児、仕事に追われた日々の中、「こんな私でも役に立つのかな？」っと思いがちですが、例えば一人暮らしのお年寄りなど、天井の電球を交換したい、家具を少し動かしたい等、ちょっとしたお手伝いを必要としている方がたくさん居られます。</p>
              <p className={styles.GreetingText}>また保育士、看護師、理・美容師の免許保持者で、結婚や育児などでキャリアを諦めている方にも、もう一つの働き方のオプションになれれば良いと考えています。</p>
              <p className={styles.GreetingText}>タグジャムによって、地域の方々が、もう少し便利に、そしてサービスを提供する側も「ありがとう」を体験する事でお互いにポジティブな気持ちになれる、そんな「お手伝い」コミュニティーが広がる事を願っています。お手伝いで繋がりたいみなさま、どうぞ気軽に参加または応援よろしくお願いします。</p>
            </div>
            <div className={styles.Section}>
              <div className={`${styles.SectionTitleBG} ${styles.TagBG}`} />
              <div className={styles.SectionContent}>
                <div className={styles.ResumeArea}>
                  <div className={styles.ResumeTitleArea}>
                    <div className={styles.LeftBox}>
                      <p className={styles.ResumeTitle}>
                        経歴 
                      </p>
                      <img className={styles.ResumeBorder} src={Blue_line} alt="menu border" />
                    </div>
                  </div>
                  <div className={styles.ResumeRow}>
                    <ul className={styles.ResumeTextArea}>
                      <li>1988 准看護師免許取得</li>
                      <li>1988 総合病院勤務</li>
                      <li>1993 看護専攻科に入学</li>
                      <li>1995 正看護師免許取得</li>
                      <li>1995 総合病院勤務</li>
                      <li>1996 長女誕生で育児を開始</li>
                      <li>1998 次女誕生、育児と仕事両立を頑張る</li>
                    </ul>
                    <ul className={styles.ResumeTextArea}>
                      <li>1998 医療・福祉療養施設勤務</li>
                      <li>2000 長男誕生で仕事との両立が困難に</li>
                      <li>2000 子育てに専念する</li>
                      <li>2008 介護施設勤務</li>
                      <li>2009 病児保育施設勤務</li>
                      <li>2019 障害児支援施設勤務</li>
                      <li>2019 タグジャム設立</li>　
                    </ul>
                  </div>
                  <div className={styles.PortrayBox}>
                    <img className={styles.Portray} src={Wakiyama} alt="脇山 輝美" />
                    <div className={styles.RoleBox}>
                      <div className={styles.Role}>
                        <p className={styles.RoleTitle}>代表者</p>
                        <p className={styles.Name}>脇山 輝美</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.FavoriteArea}>
                  <p className={styles.FavoriteTitle}>
                    好きな言葉 
                  </p>
                  <img className={styles.FavoriteBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                  変えるにはリスクが伴う。<br />
                  変えなければもっと大きなリスクが伴う。 
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.Section}>
              <div className={`${styles.SectionTitleBG} ${styles.CloverBG}`} />
              <div className={styles.SectionContent}>
                <div className={`${styles.ResumeArea} ${styles.PaddingLeft}`}>
                  <div className={styles.ResumeTitleArea}>
                    <div className={styles.LeftBox}>
                      <p className={styles.ResumeTitle}>
                        経歴 
                      </p>
                      <img className={styles.ResumeBorder} src={Blue_line} alt="menu border" />
                    </div>
                  </div>
                  <div className={styles.ResumeRow}>
                    <ul className={styles.ResumeTextArea}>
                      <li>1991 OSエンジニアリング開発企業勤務</li>
                      <li>1992 家電メーカー勤務</li>
                      <li>1994 会計WANシステム開発企業勤務</li>
                      <li>1996 渡米する</li>
                      <li>1997 USA・WA州 大学入学(IT専攻)</li>
                      <li>1999 USA・WA州 Webアプリ開発企業勤務</li>
                    </ul>
                    <ul className={styles.ResumeTextArea}>
                      <li>2001 USA・WA州 検索ｴﾝｼﾞﾝ開発企業勤務</li>
                      <li>2001 シリコンバレーに移住</li>
                      <li>2001 USA・CA州 CGソフト開発企業勤務</li>
                      <li>2015 ご近所コミュニティーアプリ開発</li>
                      <li>2017 お弁当配達・キッチンカー設立</li>
                      <li>2019 タグジャム設立メンバーになる</li>
                    </ul>
                  </div>
                  <div className={`${styles.PortrayBox} ${styles.PortrayBoxLeft}`}>
                    <img className={styles.Portray} src={Minoda} alt={"IT:蓑田ゆかり"} />
                    <div className={styles.RoleBox}>
                      <div className={styles.Role}>
                        <p className={styles.RoleTitle}>ITエンジニア</p>
                        <p className={styles.Name}>蓑田 ゆかり</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.FavoriteArea} ${styles.FavoriteAreaLeft}`}>
                  <p className={styles.FavoriteTitle}>
                    好きな言葉 
                  </p>
                  <img className={styles.FavoriteBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                  出来ない言い訳を考えるより<br />
                  どうやったら出来るかを考える 
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.Section}>
              <div className={`${styles.SectionTitleBG} ${styles.FlowerBG}`} />
              <div className={styles.SectionContent}>
                <div className={styles.ResumeArea}>
                  <div className={styles.ResumeTitleArea}>
                    <div className={styles.LeftBox}>
                      <p className={styles.ResumeTitle}>
                        経歴 
                      </p>
                      <img className={styles.ResumeBorder} src={Blue_line} alt="menu border" />
                    </div>
                  </div>
                  <div className={styles.ResumeRow}>
                    <ul className={styles.ResumeTextArea}>
                      <li>1992 商社 総務部勤務</li>
                      <li>1993 工業写真企業 DX事業部勤務</li>
                      <li>1996 渡米する</li>
                      <li>1997 USA・WA州 大学入学(CG専攻)</li>
                      <li>1999 USA・WA州 ECアプリ開発企業勤務</li>
                      <li>2000 USA・WA州 SEOデザイン企業勤務</li>
                    </ul>
                    <ul className={styles.ResumeTextArea}>
                      <li>2002 日本一時帰国</li>
                      <li>2002 大阪 Webマーケティング会社勤務</li>
                      <li>2003 渡米する</li>
                      <li>2014 USA・WA州 百貨店ECデザイン部勤務</li>
                      <li>2015 USA・WA州 EC企業UX部門勤務</li>
                      <li>2019 ダグジャム設立メンバーになる</li>
                    </ul>
                  </div>
                  <div className={styles.PortrayBox}>
                    <img className={styles.Portray} src={Otsuki} alt={"デザイン:大槻和枝"} />
                    <div className={styles.RoleBox}>
                      <div className={styles.Role}>
                        <p className={styles.RoleTitle}>UI・UX設計</p>
                        <p className={styles.Name}>大槻 和枝</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.FavoriteArea}>
                  <p className={styles.FavoriteTitle}>
                    好きな言葉 
                  </p>
                  <img className={styles.FavoriteBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                  世界の不幸に対して<br />
                  抗議するために幸福を創造すべきである。 
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.Section}>
              <div className={`${styles.SectionTitleBG} ${styles.HeartBG}`} />
              <div className={styles.SectionContent}>
                <div className={`${styles.ResumeArea} ${styles.PaddingLeft}`}>
                  <div className={styles.ResumeTitleArea}>
                    <div className={styles.LeftBox}>
                      <p className={styles.ResumeTitle}>
                        経歴 
                      </p>
                      <img className={styles.ResumeBorder} src={Blue_line} alt="menu border" />
                    </div>
                  </div>
                  <div className={styles.ResumeRow} style={{marginBottom:'50px'}}>
                    <ul className={styles.ResumeTextArea}>
                      <li>1990 大学(工学部）卒業</li>
                      <li>1990 半導体機器 SE部門勤務</li>
                      <li>2000 シリコンバレーに移住</li>
                      <li>2000 USA・CA州 半導体機器 開発部門勤務</li>
                    </ul>
                    <ul className={styles.ResumeTextArea}>
                      <li>2005 USA・CA州 半導体機器 AE部門勤務</li>
                      <li>2007 USA 測定技術関連の特許取得</li>
                      <li>2019 タグジャム設立メンバーになる</li>
                    </ul>
                  </div>
                  <div className={`${styles.PortrayBox} ${styles.PortrayBoxLeft}`}>
                    <img className={styles.Portray} src={Matsumiya} alt={"カスタマーサポート:松宮博"} />
                    <div className={styles.RoleBox}>
                      <div className={styles.Role}>
                        <p className={styles.RoleTitle}>お客様サポート</p>
                        <p className={styles.Name}>松宮 博</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.FavoriteArea} ${styles.FavoriteAreaLeft}`}>
                  <p className={styles.FavoriteTitle}>
                    好きな言葉 
                  </p>
                  <img className={styles.FavoriteBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                  人生はクローズアップなら悲劇だ<br />
                  ロングショットならコメディだ 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError, 
});

export default connect(mapStateToProps, null)(Team);