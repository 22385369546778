import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import AcceptOrder1 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_1.jpg';
import AcceptOrder1Teach from '../../../assets/images/help/selling/sell_take_order/sell_take_order_teach/sell_take_order_teach_1.jpg';
import AcceptOrder1Make from '../../../assets/images/help/selling/sell_take_order/sell_take_order_make/sell_take_order_make_1.jpg';
import AcceptOrder1Rent from '../../../assets/images/help/selling/sell_take_order/sell_take_order_rent/sell_take_order_rent_1.jpg';

import AcceptOrder2 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_2.jpg';
import AcceptOrder2Teach from '../../../assets/images/help/selling/sell_take_order/sell_take_order_teach/sell_take_order_teach_2.jpg';
import AcceptOrder2Rent from '../../../assets/images/help/selling/sell_take_order/sell_take_order_rent/sell_take_order_rent_2.jpg';
import AcceptOrder2Make from '../../../assets/images/help/selling/sell_take_order/sell_take_order_make/sell_take_order_make_2.jpg';

import AcceptOrder3 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_3.jpg';
import AcceptOrder3Teach from '../../../assets/images/help/selling/sell_take_order/sell_take_order_teach/sell_take_order_teach_3.jpg';
import AcceptOrder3Rent from '../../../assets/images/help/selling/sell_take_order/sell_take_order_rent/sell_take_order_rent_3.jpg';
import AcceptOrder3Make from '../../../assets/images/help/selling/sell_take_order/sell_take_order_make/sell_take_order_make_3.jpg';

import AcceptOrder4 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_4.png';
import AcceptOrder4Teach from '../../../assets/images/help/selling/sell_take_order/sell_take_order_teach/sell_take_order_teach_4.jpg';
import AcceptOrder4Rent from '../../../assets/images/help/selling/sell_take_order/sell_take_order_rent/sell_take_order_rent_4.jpg';
import AcceptOrder4Make from '../../../assets/images/help/selling/sell_take_order/sell_take_order_make/sell_take_order_make_4.jpg';

import AcceptOrder5 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_5.png';
import AcceptOrder5Teach from '../../../assets/images/help/selling/sell_take_order/sell_take_order_teach/sell_take_order_teach_5.jpg';
import AcceptOrder5Rent from '../../../assets/images/help/selling/sell_take_order/sell_take_order_rent/sell_take_order_rent_5.jpg';
import AcceptOrder5Make from '../../../assets/images/help/selling/sell_take_order/sell_take_order_make/sell_take_order_make_5.jpg';

import AcceptOrder6 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_6.png';
// import AcceptOrder6Teach from '../../../assets/images/help/selling/sell_take_order/sell_take_order_teach/sell_take_order_teach_6.jpg';
import AcceptOrder6RentMake from '../../../assets/images/help/selling/sell_take_order/sell_take_order_rent/sell_take_order_rent_make_6.jpg';


import AcceptOrder7 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_7.png';

import AcceptOrder8 from '../../../assets/images/help/selling/sell_take_order/sell_take_order_help/sell_take_order_help_8.jpg';
import AcceptOrder8Teach from '../../../assets/images/help/selling/sell_take_order/sell_take_order_teach/sell_take_order_teach_7.jpg';
import AcceptOrder8Rent from '../../../assets/images/help/selling/sell_take_order/sell_take_order_rent/sell_take_order_rent_7.jpg';
import AcceptOrder8Make from '../../../assets/images/help/selling/sell_take_order/sell_take_order_make/sell_take_order_make_7.jpg';

class AcceptOrder extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    getPendingLabel = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'TEACH':
                return '受講';
            case 'RENT':
                return 'レンタル';
            default:
                return '購入';
        }  
    }
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>申請を受け付ける（{this.getCategory()}）</span>
            </h3>
            <p className={styles.ArticleDesc}>
            サービス購入者（買い手）の購入申請を受け付けるには、以下の手順で行ってください。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                    メニューより「取引」＞「売り」＞「取引中」を選びます。
                    </li>
                    { currentTab==='HELP' && 
                        <img className={styles.ScreenShot} src={AcceptOrder1} alt="申請を受け付けるステップ1画像" />
                    }
                    { currentTab==='TEACH' && 
                        <img className={styles.ScreenShot} src={AcceptOrder1Teach} alt="申請を受け付けるステップ1画像" />
                    }
                    { currentTab==='RENT' && 
                        <img className={styles.ScreenShot} src={AcceptOrder1Rent} alt="申請を受け付けるステップ1画像" />
                    }
                    { currentTab==='MAKE' && 
                        <img className={styles.ScreenShot} src={AcceptOrder1Make} alt="申請を受け付けるステップ1画像" />
                    }

                    <li>
                    {this.getPendingLabel()}申請が来ている場合は以下のように表示されます。内容をみる場合は「{this.getPendingLabel()}申請あり」と表示された取引をタップします。        
                    </li>
                    { currentTab==='HELP' && 
                        <img className={styles.ScreenShot} src={AcceptOrder2} alt="申請を受け付けるステップ2画像" />
                    }
                    { currentTab==='TEACH' && 
                        <img className={styles.ScreenShot} src={AcceptOrder2Teach} alt="申請を受け付けるステップ2画像" />
                    }
                    { currentTab==='RENT' && 
                        <img className={styles.ScreenShot} src={AcceptOrder2Rent} alt="申請を受け付けるステップ2画像" />
                    }
                    { currentTab==='MAKE' && 
                        <img className={styles.ScreenShot} src={AcceptOrder2Make} alt="申請を受け付けるステップ2画像" />
                    }
                    
                    <li>
                    「注文・見積もりを見る」ボタンをタップします。   
                    </li>
                    { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={AcceptOrder3} alt="申請を受け付けるステップ3画像" />
                    }
                    { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={AcceptOrder3Teach} alt="申請を受け付けるステップ3画像" />
                    }
                    { currentTab==='MAKE' &&
                        <img className={styles.ScreenShot} src={AcceptOrder3Make} alt="申請を受け付けるステップ3画像" />
                    }
                    { currentTab==='RENT' &&
                        <img className={styles.ScreenShot} src={AcceptOrder3Rent} alt="申請を受け付けるステップ3画像" />
                    }

                    <li>
                    注文内容を確認し「予定表を確認」をタップします。        
                    </li>
                    { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={AcceptOrder4} alt="申請を受け付けるステップ4画像" />
                    }
                    { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={AcceptOrder4Teach} alt="申請を受け付けるステップ4画像" />
                    }
                    { currentTab==='MAKE' &&
                        <img className={styles.ScreenShot} src={AcceptOrder4Make} alt="申請を受け付けるステップ4画像" />
                    }
                    { currentTab==='RENT' &&
                        <img className={styles.ScreenShot} src={AcceptOrder4Rent} alt="申請を受け付けるステップ4画像" />
                    }
                    
                    <li>
                    空き時間を確認し「申請受付する」ボタンをタップします。        
                    </li>
                    { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={AcceptOrder5} alt="申請を受け付けるステップ5画像" />
                    }
                    { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={AcceptOrder5Teach} alt="申請を受け付けるステップ5画像" />
                    }
                    { currentTab==='MAKE' &&
                        <img className={styles.ScreenShot} src={AcceptOrder5Make} alt="申請を受け付けるステップ5画像" />
                    }
                    { currentTab==='RENT' &&
                        <img className={styles.ScreenShot} src={AcceptOrder5Rent} alt="申請を受け付けるステップ5画像" />
                    }

                    { (currentTab==='HELP' || currentTab==='TEACH') &&
                    <li>
                    「移動時間片道」を「＋」、「ー」にて調節します。次に「予定追加」ボタンをタップします。
                    <p className="Caption">＊「訪問型」でない場合は「移動時間片道」は画面に出ません。</p>           
                    </li>
                    }
                    { (currentTab==='MAKE' || currentTab==='RENT') &&
                    <li>
                    「予定追加」ボタンをタップします。          
                    </li>
                    }
                    { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={AcceptOrder6} alt="申請を受け付けるステップ6画像" />
                    }
                    { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={AcceptOrder6} alt="申請を受け付けるステップ6画像" />
                    }
                    { (currentTab==='MAKE' || currentTab==='RENT') &&
                        <img className={styles.ScreenShot} src={AcceptOrder6RentMake} alt="申請を受け付けるステップ5画像" />
                    }
                    
                    <li>
                    「会話に戻る」ボタンをタップします。           
                    </li>
                    <img className={styles.ScreenShot} src={AcceptOrder7} alt="申請を受け付けるステップ7画像" />
                    
                    <li>
                    「{this.getPendingLabel()}受付済み」のメッセージが送られているのを確認します。           
                    </li>
                    { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={AcceptOrder8} alt="申請を受け付けるステップ8画像" />
                    }
                    { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={AcceptOrder8Teach} alt="申請を受け付けるステップ8画像" />
                    }
                    { currentTab==='RENT' &&
                        <img className={styles.ScreenShot} src={AcceptOrder8Rent} alt="申請を受け付けるステップ8画像" />
                    }
                    { currentTab==='MAKE' &&
                        <img className={styles.ScreenShot} src={AcceptOrder8Make} alt="申請を受け付けるステップ8画像" />
                    }
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(AcceptOrder);