import priceFormat from './priceFormat';
import moment from 'moment';

export const getDepoit = (sectionData) => {
    let deposit = 0;
    sectionData.map( section => {
        if(section.subTotal && section.title ==="DEPOSIT")
        {
            deposit = parseFloat(section.subTotal);
        }
        return true;    
    });
    return deposit;    
}

export const getTotal = (sectionData) => {
    let total = 0;
    sectionData.map( section => {
        if(section.subTotal && section.title !=="DEPOSIT")
        {
            total = total + parseFloat(section.subTotal)
        }
        return true;    
    });
    return total;
}

export const getLocalFormatPrice = (price, localCode, LocalStrings) => {
    switch (localCode) {
        case 'ja_jp':{
            let mark = false;
            return priceFormat(price, localCode, LocalStrings, mark);
        }
        case 'en_us':{
            return priceFormat(price, localCode, LocalStrings);
        }
        default: 
            return priceFormat(price, localCode, LocalStrings);
    }   
}

export const getCurreny = (localCode, LocalStrings) => {
    
    switch (localCode) {
        case 'ja_jp':{
            return LocalStrings[localCode].YEN
        }
        case 'en_us':{
            return "";
        }
        default: 
            return LocalStrings[localCode].YEN
    }
}

export const getRoundHours = (duration) => {
    let roundMinutes = Math.round(duration/15) * 15 //round by 15 minutes and nearest number
    let hours = roundMinutes / 60; // converted to hours. 0.25, 0.5, 0.75, 1,...
    return hours;
}

export const getCalcHours = (start, end) => {
    let start_hr = moment(start);
    let end_hr = moment(end);
    const duration = end_hr.diff(start_hr, 'minutes');
    const result = getRoundHours(duration);
    return result;
}

export const getCalcAfter6 = (start, end) => {
    let start_hr = moment(start);
    let end_hr = moment(end);
    const startDateStr = start_hr.format("YYYY-MM-DD");
    const sixStart = moment( `${startDateStr} 18:00:00` );
    const duration = end_hr.diff(sixStart, 'minutes');
    const result = getRoundHours(duration) <= 0 ? 0 : getRoundHours(duration);
    return result;    
}

export const getMultipleStr = (MULTIPLE) => {
    let str = 'PERSON';
    if(MULTIPLE.includes('PET')){
        str = 'PET';
    }
    return str;
}

export const calcEstimateTotal = (estimate, theGig, ServiceData) => {
    const {MainCat, SubCat, indicator:{VISITOR_TYPE}, customBase, optionableService, optionService, feeInfo, actualCostService} = theGig;
    const {requestDateTime} = estimate;
    const serviceItemData = ServiceData[MainCat][SubCat]; 
    let allTotal = 0;

    if(MainCat==='TEACH'){
        allTotal += parseFloat(feeInfo.price);
    }

    if(MainCat==='RENT'){
        allTotal += parseFloat(feeInfo.price);// + parseFloat(feeInfo.DEPOSIT);
    }
    
    if(estimate.baseService){
        let unitPrice = parseFloat(feeInfo.price);
        let hours = getCalcHours(requestDateTime.start, requestDateTime.end);
        allTotal += Math.round(unitPrice * hours);
    }

    if(customBase){
        let unitPrice = parseFloat(feeInfo.price);
        let quantity = parseInt(estimate.numbers.quantity);
        allTotal += Math.round(unitPrice * quantity);
    }
    
    if(estimate.optionableService){
        const title = 'OPTIONABLE';
        let item;
        for(let key in estimate.optionableService){
            if(serviceItemData.optionable){
                if(serviceItemData.optionable[key].MINUTES_COVERT) {
                    item = {label: key, unitPrice: parseFloat(feeInfo.price), serveMin: parseFloat(optionableService[key]) };
                } else {
                    item = {label: key, unitPrice: parseFloat(optionableService[key]) };    
                } 
            }
            let subTotal = getOptionSubTotalAmount(estimate, theGig, title, item, ServiceData);
            subTotal = subTotal ? subTotal : 0;
            allTotal += parseInt(subTotal);
        }
    }
    
    if(estimate.optionService){
        const title = "OPTIONAL_FEE";
        let optionItemIndex, item;
        for(let key in estimate.optionService){
            if(serviceItemData.options){
                optionItemIndex = serviceItemData.options.list.findIndex( option => {
                    return option.TYPE === key;
                }); 
                if(optionItemIndex!==-1 && serviceItemData.options.list[optionItemIndex].MINUTES_COVERT) {
                    item = {label: key, unitPrice: parseFloat(feeInfo.price), serveMin: parseFloat(optionService[key]) };
                } else {
                    item = {label: key, unitPrice: parseFloat(optionService[key]) };    
                } 
            }
            let subTotal = getOptionSubTotalAmount(estimate, theGig, title, item, ServiceData);
            subTotal = subTotal ? subTotal : 0;
            allTotal += parseInt(subTotal); 
        }
    }

    if(actualCostService){
        if(MainCat==='TEACH' && VISITOR_TYPE){
            for(let key in actualCostService){
                if(actualCostService[key]){
                    let subTotal = actualCostService[key];
                    allTotal += parseInt(subTotal);
                }
            } 
        }
    }
    return allTotal;
}

const getOptionSubTotalAmount = (estimate, theGig, title, item, ServiceData) => {
    const { MainCat, SubCat } = theGig;
    const { numbers, requestDateTime } = estimate;
    const serviceItemData = ServiceData[MainCat][SubCat];
    let calcHours, after6;
    let subTotal = 0;
    if(MainCat !== 'MAKE'){
        calcHours = getCalcHours(requestDateTime.start, requestDateTime.end);
        after6 = getCalcAfter6(requestDateTime.start, requestDateTime.end);
    }
    let serviceObj;
    if(title==='OPTIONABLE'){
        serviceObj = serviceItemData.optionable[item.label];
    } else {
        const serviceIndex = serviceItemData.options.list.findIndex(optionItem => {
            return optionItem.TYPE === item.label;
        });
        serviceObj = serviceItemData.options.list[serviceIndex];
    }

    if(serviceObj){
        const {CHARGE, MULTIPLE, MINUTES_COVERT} = serviceObj;
        if(CHARGE === 'ADD_TO_TOTAL') {
            subTotal = item.unitPrice;
        } else if(MINUTES_COVERT) {
            let serveHr = getRoundHours(item.serveMin);
            subTotal = item.unitPrice * serveHr * parseFloat(numbers[item.label]);
        } else if(CHARGE === "HOURLY_PLUS") {
            let hourObj = item.label.includes('AFTER_6') ?  after6 : calcHours;     
            if(MULTIPLE){
                subTotal = Math.round(item.unitPrice * hourObj * parseInt(numbers[item.label]));
            } else {
                subTotal = Math.round(item.unitPrice * hourObj);
            } 
        } else if(CHARGE === 'PLACE' || CHARGE ==="QUANTITY_NUMBER" || CHARGE === 'LOAD') {
            subTotal = Math.round(item.unitPrice * parseInt(numbers[item.label]) );
        }
    } 
    return subTotal;
}

export const isExpiredRequest = (status, theGig, estimate) => {
    if(status==='PURCHASE_PENDING' && estimate){
        const { 
            MainCat,
            indicator:{VISIT_TYPE, AVAILABLE_NOW}
        } = theGig;
        let isExpired = false;
        const { requestDateTime, optionService } = estimate; 
        if(requestDateTime && requestDateTime.start){
            // MainCat==='MAKE' doesn't have requestDateTime, this logic for Not MAKE 
            let now = moment();
            if(AVAILABLE_NOW){ 
                if( (optionService && optionService["DELIVERY_FEE"] ) || VISIT_TYPE ){
                    now.add(30, 'minutes'); // delivery_time or transportation time 30 minuts
                }
                if(MainCat==='RENT'){
                    const rentDateStr = moment(requestDateTime.start).format("YYYY-MM-DD");
                    if(now.isSameOrAfter(`${rentDateStr} 14:00:00`)){
                        isExpired = true;
                    }
                } else {
                    if(now.isSameOrAfter(requestDateTime.start, 'minute') ){
                        //past time case
                        isExpired = true;
                    } 
                }    
            } else {
                if(now.isSameOrAfter(requestDateTime.start, 'day') ){
                    //past day case
                    isExpired = true;
                }
            }  
        }
        if(isExpired){
            return "REQUEST_EXPIRED";
        } else {
            return status;
        }
    } else {
        return status;
    }
}
