import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import SellerChangeRequest1 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_1.png';
import SellerChangeRequest2 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_2.png';
import SellerChangeRequest3_1 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_3-1.png';
import SellerChangeRequest3_2 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_3-2.png';
import SellerChangeRequest4 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_4.png';
import SellerChangeRequest5_1 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_5-1.png';
import SellerChangeRequest5_2 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_5-2.png';
import SellerChangeRequest6 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_6.png';
import SellerChangeRequest7 from '../../../assets/images/help/selling/sell_change_request/sell_change_request_7.png';

class SellerChangeRequest extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>依頼内容の変更（{this.getCategory()}）</span>
            </h3>
            <p className={styles.ArticleDesc}>
              購入申請の受付け後、購入者から、サービス予定日、時間、オプション選択などの依頼内容の変更のリクエストが入る場合があります。その場合は、以下の手順で見積もりを編集する事で、依頼内容を変更出来ます。
              ご自分のスケジュールの空き状況を確認し、なるべく変更に対応してあげましょう。また、依頼内容の詳細聞き取りの結果、オプションの選択の訂正や、サービス時間の変更など、購入者の同意を得た上で以下の手順にて変更できます。<br />
              ＊変更した最新の見積もりに基づきサービス完了後に請求書を作成を行う事が出来ます。
            </p>
            
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「取引中」を選びます。    
                    </li>
                    <img className={styles.ScreenShot} src={SellerChangeRequest1} alt="依頼内容の変更をするステップ1画像" />

                    <li>
                        依頼内容を変更の連絡が入った、または変更が必要な取引をタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={SellerChangeRequest2} alt="依頼内容の変更をするステップ2画像" />
                    
                    <li>
                        取引詳細画面で、変更の内容を確認し、メッセージ入力ボックスの左側の <i className="fa fa-file-text" /> アイコンをタップし、「最新の注文・見積もりを見る」をタップする            
                    </li>
                    <img className={styles.ScreenShot} src={SellerChangeRequest3_1} alt="依頼内容の変更をするステップ3-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={SellerChangeRequest3_2} alt="依頼内容の変更をするステップ3-2画像" />

                    <li>
                      「見積もり編集」ボタンをタップして下さい。            
                    </li>
                    <img className={styles.ScreenShot} src={SellerChangeRequest4} alt="依頼内容の変更をするステップ4画像" />

                    <li>
                        日付、時間、オプション選択など、必要箇所を編集し、内容を確認して下さい。確認が終わったら、「保存ボタン」をタップします。<br />
                        *日付、時間の変更はスケジュールに空きがないと変更出来ません。     
                    </li>
                    <img className={styles.ScreenShot} src={SellerChangeRequest5_1} alt="依頼内容の変更をするステップ5-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={SellerChangeRequest5_2} alt="依頼内容の変更をするステップ5-2画像" />

                    <li>
                      「会話に戻る」をタップして下さい。            
                    </li>
                    <img className={styles.ScreenShot} src={SellerChangeRequest6} alt="依頼内容の変更をするステップ6画像" />

                    <li>
                        見積もり変更が送信されたのが確認できます。            
                    </li>
                    <img className={styles.ScreenShot} src={SellerChangeRequest7} alt="依頼内容の変更をするステップ7画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SellerChangeRequest);