import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import HowToUseHelp1 from '../../../assets/images/help/how_to_use_help/how_to_use_help_1.png';
import HowToUseHelp2 from '../../../assets/images/help/how_to_use_help/how_to_use_help_2.png';

class HelpHome extends Component {
    render() {
        return(
            <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>ヘルプガイドの使い方</span>
            </h3>
            <p className={styles.ArticleDesc}>ヘルプの内容は、売り手・買い手またはカテゴリーによって違います。次の手順でセクションを選択し、次にカテゴリーを選択してください。</p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol>
                    <li>画面左上の「売り手の方へ」または「買い手の方へ」を選択してください。</li>
                    <img className={styles.ImageWide} src={HowToUseHelp1} alt="売り手・買い手を選ぶ" />
                    <li>「助けます」、「教えます」、「貸します」、「作ります」のいずれかのカテゴリーを選択してください</li>
                    <img className={styles.ImageWide} src={HowToUseHelp2} alt="カテゴリーを選ぶ" />
                </ol>
            </div>
            
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab } }
}) => ({
    currentTab    
});

export default connect(mapStateToProps, null)(HelpHome);