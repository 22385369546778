import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

class Sctl extends Component {
    render(){
        const {location} = this.props;
        let pathname = location.pathname;
        let title = '特定商取引に関する表記';
        let description = 'タグジャム特定商取引に関する表記 タグジャムの基本情報が確認できます。';
        return(
        <>
        <Helmet>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`タグジャム-${title}`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:url" content={pathname} />
            <meta property="og:title" content={`タグジャム-${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:type" content="image/png" />
            <title>{title}</title>
            <meta
              name="description"
              content={description}
            />
            <meta content="特定商取引に関する表記,会社概要,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
        </Helmet>
        <div className="MainPadding">
            <h2 className="PageHeader">
                特定商取引に関する表記
            </h2>
            <div className="Content">
                <div className="Item">
                    <h5>事業者名称</h5>
                    <p>タグジャム</p>
                </div>
                <div className="Item">
                    <h5>代表責任者名</h5>
                    <p>脇山　輝美</p>
                </div>
                <div className="Item">
                    <h5>所在地</h5>
                    <p>〒852-8062<br />長崎県長崎市大園町9-11 JGMウィングヒル滑石 1704号</p>
                </div>
                <div className="Item">
                    <h5>電話番号</h5>
                    <p>090-9490-9116</p>
                    <p className="Caption">※お取引やサービスについてのお問い合わせは電話では受け付けておりません。大変申し訳ありませんが、お問い合わせについては下記のメールアドレスによりご連絡をいただき対応させていただいております。ご不便をおかけいたしますが、ご理解ご協力をよろしくお願いいたします。</p>
                </div>
                <div className="Item">
                    <h5>問合せ受付時間</h5>
                    <p>営業時間　平日 10:00～17:00（土・日・祝休）</p>
                </div>
                <div className="Item">
                    <h5>メールアドレス</h5>
                    <p>contact@tagujam.com</p>
                </div>
                <div className="Item">
                    <h5>ホームページURL</h5>
                    <p>https://www.tagujam.com</p>
                </div>
                <div className="Item">
                    <h5>販売価格</h5>
                    <p>各商品の販売価格は各ページに記載しております。</p>
                    <p className="Caption">＊詳しくは、モバイル版の簡易見積もりにより確認ください。</p>
                </div>
                <div className="Item">
                    <h5>商品代金以外の必要料金</h5>
                    <p>商品により配送・配達費用、実費清算費用（交通費、教材代など）支払い方法により所定の手数料、レンタル品の返却状況により、保証金、修理費用・修繕料金・清掃費用、延滞料など。</p>
                    <p className="Caption">＊詳しくは、モバイル版の簡易見積もり機能により確認ください。</p>
                </div>
                <div className="Item">
                    <h5>商品提供時期</h5>
                    <p>支払い方法情報登録後、お客様が購入申請時に選択された日時。</p>
                </div>
                <div className="Item">
                    <h5>支払時期</h5>
                    <p>サービス提供後、請求書が届き金額を確認しだいお支払い願います。</p>
                </div>
                <div className="Item">
                    <h5>支払い方法</h5>
                    <p>クレジットカード決済。</p>
                </div>
                <div className="Item">
                    <h5>返品・交換　キャンセル等</h5>
                    <p>取引確定後は原則として返品・交換は不可能です。止むを得ない理由で取引確定後のキャンセル希望の場合はキャンセル料の規定に基づきキャンセル料をいただきます。料金(キャンセル料含む)決済後の返金はいたしかねます。</p>
                </div>
            </div>
        </div>
        </>
        );
    }
}

export default Sctl;