export default class IpifyApi {
    constructor(){
        this.ipifyApiURL = "https://api.ipify.org";
    }
    
    async getIpAddress () {
        try {
            let res = await fetch(`${this.ipifyApiURL}?format=json`);
            let data = await res.json();
            let ip = data.ip;
            return ip; 
        } catch(err) {
            console.log(err)
            throw err;
        }    
    }
}