import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class WhatTaguJam extends Component {
    render() {
        return(
            <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>タグジャムとは？</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムは誰でも参加できる個人の「手伝う」を売り買いするフリマアプリです。お住まいの地域の「助けて」・「助けます」、「教えて」・「教えます」、「貸して」・「貸します」、「作って」・「作ります」などのお手伝いが必要な方とそのお手伝いを提供できる方をつなげ、自由に売り買いするコミュニティーを提供するプラットフォームです。</p>
            <h4 className={styles.HeaderMargin15}>何ができるの？</h4>
            <p>売り手の方は簡単フォーム入力によりお手伝いサービスの内容、価格、提供できる地域、値段、提供時間や曜日などを自分で決めてシステムに掲載できます。買い手の方は、カテゴリー別に掲載されたお手伝いサービスを検索、お手伝い内容の問合せや、簡易見積りで取引前に内容や料金を確認でき、予算やニーズにあったお手伝いサービスの購入ができます。また取引が成立するとガイド付き取引チャットによりサービスの提供から支払いまでスムーズに行えます。請求書作成、クレジットカードでの支払い・受付、売上管理、売上金額の銀行振込、購入履歴管理など面倒な管理は全てシステムにお任せ！　快適なサービス提供と利用を体験する事ができます。</p>
            <h4 className={styles.HeaderMargin15}>どんな人のためなの？</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <h5>自分のお手伝いサービスを売ってみたい方</h5>
                <ul>
                    <li>フルタイムで働くのは無理、でも空いてる時間を利用して仕事をしてみたい方</li>
                    <li>資格（看護士、保育士、介護士、美容師、理容師等）を活かして顧客に直接サービスを提供したい方</li>
                    <li>スキル（ネイル、エステ等）を活かして顧客に直接サービスを提供したい方</li>
                    <li>動物取扱業者の登録されている方でペットのお世話を提供したい方</li>
                    <li>特技（習い事、語学、料理、パン作り、ヨガ、パソコン、生活の知恵等）を人に教えたい方</li>
                    <li>電化製品、美容器具、旅行用品など普段使わない物を必要な人にレンタルしたい方</li>
                    <li>手作りや、DIYの品物を必要とする方にオーダーメイドで作ってあげたい方</li>
                </ul>
            </div>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <h5>地域のお手伝いサービスを利用してみたい方</h5>
                <ul>
                    <li>日常の家事（お掃除、料理等、庭の手入れ等）の助けが必要な方</li>
                    <li>育児（保育、お迎え等）の助けが必要な方</li>
                    <li>ペットのお世話（お散歩、預かり）の助けが必要な方</li>
                    <li>介護保険適用の介護サービスでは賄いきれない部分の介護サービス（シニア見守り、シニア同行、家事支援等）が必要な方</li>
                    <li>気軽に習い事（語学、料理、パン作り、ヨガ、パソコン等）をやってみたい方</li>
                    <li>電化製品、美容器具、旅行用品など気軽にレンタルしてみたい方</li>
                    <li>オーダーメイドで手作りの作品やDIYを依頼してみたい方</li>
                </ul>
            </div>
            
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab,  currentMode, } }
}) => ({
    currentTab, currentMode 
});

export default connect(mapStateToProps, null)(WhatTaguJam);