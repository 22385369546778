import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import HelpHome from './HelpHome';
import WhatTaguJam from './WhatTaguJam';
import DealFlow from './DealFlow';
import DealConfirm from './DealConfirm';
import Fee from './Fee';
import SafeEffort from './SafeEffort';
import PayFee from './PayFee';
import BasicManner from './BasicManner';
import BecomeMember from './BecomeMember';
import AccountInfo from './AccountInfo';
import UserInfo from './UserInfo';
import EmailConfirmation from './EmailConfirmation';
import ServiceSubject from './ServiceSubject';
import ServiceAddress from './ServiceAddress';
import Payment from './Payment';
import AddressRegistration from './AddressRegistration';
import IdConfirmation from './IdConfirmation';
import AnswerQuestion from './AnswerQuestion';
import AcceptInterview from './AcceptInterview';
import SubmitDocs from './SubmitDocs';
import SellerSchedule from './SellerSchedule';
import BannedBehaivors from './BannedBehaivors';
import BannedItems from './BannedItems';
import MannerForSeller from './MannerForSeller';
import SendInvoice from './SendInvoice';
import ResendInvoice from './ResendInvoice';
import CheckSales from './CheckSales';
import RegisterBank from './RegisterBank';
import SendMoney from './SendMoney';
import EvaluateBuyer from './EvaluateBuyer';
import SellerChangeRequest from './SellerChangeRequest';
import SellerCancel from './SellerCancel';
import MannerForBuyer from './MannerForBuyer';
import SendQuestion from './SendQuestion';
import RequestInterview from './RequestInterview';
import RegisterPayment from './RegisterPayment';
import BuyerSchedule from './BuyerSchedule';
import CheckInvoicePay from './CheckInvoicePay';
import PausePayment from './PausePayment';
import PurchaseHistory from './PurchaseHistory';
import EvaluateSeller from './EvaluateSeller';
import BuyerChangeRequest from './BuyerChangeRequest';
import BuyerCancel from './BuyerCancel';
import TransactionTermsForBuyer from './TransactionTermsForBuyer';
import Sell from './Sell';
import AcceptOrder from './AcceptOrder';
import SellerRequestDetails from './SellerRequestDetails';
import SellerStartService from './SellerStartService';
import SellerFinishService from './SellerFinishService';
import RequestPurchase from './RequestPurchase';
import BuyerStartService from './BuyerStartService';
import BuyerRequestDetails from './BuyerRequestDetails';
import BuyerFinishService from './BuyerFinishService';
import ReportService from './ReportService';
import ReportUser from './ReportUser';
import BlockUser from './BlockUser';
import ReportDeal from './ReportDeal';
import CustomerSupport from './CustomerSupport';
import CertChart from './CertChart';
import WhatCreditAuthorization from './WhatCreditAuthorization';
import WhatDeposit from './WhatDeposit';


class Article extends Component {
    render() {
        return(
            <Switch>
                <Route path="/help/WhatTaguJam" component={WhatTaguJam} />
                <Route path="/help/DealFlow" component={DealFlow} />
                <Route path="/help/Fee" component={Fee} />
                <Route path="/help/SafeEffort" component={SafeEffort} />
                <Route path="/help/DealConfirm" component={DealConfirm} />
                <Route path="/help/PayFee" component={PayFee} />
                <Route path="/help/BasicManner" component={BasicManner} />

                <Route path="/help/BecomeMember" component={BecomeMember} />
                <Route path="/help/AccountInfo" component={AccountInfo} />
                <Route path="/help/UserInfo" component={UserInfo} />
                <Route path="/help/EmailConfirmation1" component={EmailConfirmation} />
                <Route path="/help/ServiceSubject" component={ServiceSubject} />
                <Route path="/help/ServiceAddress" component={ServiceAddress} />
                <Route path="/help/Payment" component={Payment} />
                <Route path="/help/AddressRegistration" component={AddressRegistration} />
                <Route path="/help/IdConfirmation1" component={IdConfirmation} />
                
                <Route path="/help/IdConfirmation2" component={IdConfirmation} />
                <Route path="/help/EmailConfirmation2" component={EmailConfirmation} />
                <Route path="/help/Sell" component={Sell} />
                <Route path="/help/SubmitDocs" component={SubmitDocs} />
                <Route path="/help/BannedBehaivors" component={BannedBehaivors} />
                <Route path="/help/BannedItems" component={BannedItems} />
                <Route path="/help/MannerForSeller" component={MannerForSeller} />
                <Route path="/help/AnswerQuestion" component={AnswerQuestion} />
                <Route path="/help/AcceptInterview" component={AcceptInterview} />
                <Route path="/help/AcceptOrder" component={AcceptOrder} />
                <Route path="/help/SellerSchedule" component={SellerSchedule} />
                <Route path="/help/SellerRequestDetails" component={SellerRequestDetails} />
                <Route path="/help/SellerStartService" component={SellerStartService} />
                <Route path="/help/SellerFinishService" component={SellerFinishService} />
                <Route path="/help/SendInvoice" component={SendInvoice} />
                <Route path="/help/ResendInvoice" component={ResendInvoice} />
                <Route path="/help/CheckSales" component={CheckSales} />
                <Route path="/help/RegisterBank" component={RegisterBank} />
                <Route path="/help/SendMoney" component={SendMoney} />
                <Route path="/help/EvaluateBuyer" component={EvaluateBuyer} />
                <Route path="/help/SellerChangeRequest" component={SellerChangeRequest} />
                <Route path="/help/SellerCancel" component={SellerCancel} />
                
                <Route path="/help/IdConfirmation3" component={IdConfirmation} />
                <Route path="/help/EmailConfirmation3" component={EmailConfirmation} />
                <Route path="/help/MannerForBuyer" component={MannerForBuyer} />
                <Route path="/help/SendQuestion" component={SendQuestion} />
                <Route path="/help/RequestInterview" component={RequestInterview} />
                <Route path="/help/RequestPurchase" component={RequestPurchase} />
                <Route path="/help/RegisterPayment" component={RegisterPayment} />
                <Route path="/help/BuyerSchedule" component={BuyerSchedule} />
                <Route path="/help/BuyerRequestDetails" component={BuyerRequestDetails} />
                <Route path="/help/BuyerStartService" component={BuyerStartService} />
                <Route path="/help/BuyerFinishService" component={BuyerFinishService} />
                <Route path="/help/CheckInvoicePay" component={CheckInvoicePay} />
                <Route path="/help/PausePayment" component={PausePayment} />
                <Route path="/help/PurchaseHistory" component={PurchaseHistory} />
                <Route path="/help/EvaluateSeller" component={EvaluateSeller} />
                <Route path="/help/BuyerChangeRequest" component={BuyerChangeRequest} />
                <Route path="/help/BuyerCancel" component={BuyerCancel} />
                <Route path="/help/TransactionTermsForBuyer" component={TransactionTermsForBuyer} />
                
                <Route path="/help/ReportService" component={ReportService} />
                <Route path="/help/ReportUser" component={ReportUser} />
                <Route path="/help/BlockUser" component={BlockUser} />
                <Route path="/help/ReportDeal" component={ReportDeal} />
                <Route path="/help/CustomerSupport" component={CustomerSupport} />
                
                <Route path="/help/CertChart" component={CertChart} />
                <Route path="/help/WhatCreditAuthorization" component={WhatCreditAuthorization} />
                <Route path="/help/WhatDeposit" component={WhatDeposit} />

                <Route path="/help" exact component={HelpHome} />
            </Switch>
        );
    }
}

export default Article;