import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './FeatureSmall.module.scss';
import {trunicateString} from '../../../utils'

const FeatureSmall = (props) => {
  const {item, index} = props;
  const {id, imagePath, title, shortDescription} = item;
  return(
  <NavLink className={styles.PodSmall} to={`/blog/Article/${id}`}>
    {/* SmallPod */}
    <div className={styles.PodImageSmall}>
      <img className={styles.PodImgSmall} src={imagePath} alt={`注目の記事${index+2}`} />
    </div>
    <div className={styles.PodSmallTextArea}>
      <h4 className={styles.PodSmallTextHead}>{title}</h4>
      <p className={styles.PodSmallText}>
        {trunicateString(42, shortDescription)}
        <span className={styles.Details}>
          詳しく
        </span>
      </p>
    </div>
  </NavLink>
);
}

export default FeatureSmall