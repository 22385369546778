import {
    FETCH_USERS_LOCATION
} from './actionTypes';

import GoogleMapApi from '../../constants/GoogleMapApi';

const googleMapApi = new GoogleMapApi();

export const fetchUserLocationPending = () => ({
    type: `${FETCH_USERS_LOCATION}_PENDING`
});

export const fetchUserLocationRejected = (err) => ({
    type: `${FETCH_USERS_LOCATION}_REJECTED`,
    payload: err
});

export const fetchUserLocationFulFilled = (userLocation) => ({
    type: `${FETCH_USERS_LOCATION}_FULFILLED`,
    payload: userLocation
});

export const getLocationAddress = (coords, localCode) => {
    return async dispatch => {
        dispatch(fetchUserLocationPending());
        try{
            let userLocation = await googleMapApi.getAddress(coords, localCode);
            dispatch(fetchUserLocationFulFilled(userLocation))
            return userLocation;
        } catch(err){
            dispatch(fetchUserLocationRejected(err));
            throw err
        }
    }   
};
