export const round10yen = (val) => {
    let value = parseInt(val)/10;
    return Math.round(value)*10;
}

export const getRandomNum = (min,max) => {
    return Math.floor(Math.random()*(max-min+1)+min);
}

export const roundDecimalDigits1 = (val) => {
    let number = val.toString().split(".");
    let num = parseInt(number[0]);
    let pointNum = parseFloat(`0.${number[1]}`); 
    
    if(pointNum!==0 && pointNum < 0.5){
        pointNum = 0.5
    } else if(pointNum > 0.5) {
        num = num +1;
        pointNum = 0;
    } 

    return (num + pointNum).toString();
}