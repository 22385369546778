import {
    FETCH_ACCOUNT_INFO,
    SET_ACCOUNT_INFO
} from '../actions/actionTypes';

const initState = {
    accountInfo: null,
    accountInfoIsLoading: false,
    accountInfoError: false
};

const accountInfoReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: payload
            }
        case `${FETCH_ACCOUNT_INFO}_PENDING`:
            return {
                ...state,
                accountInfoIsLoading: true,
                accountInfoError: false,
            }
        case `${FETCH_ACCOUNT_INFO}_FULFILLED`:
            return {
                ...state,
                accountInfo: payload,
                accountInfoIsLoading: false,
                accountInfoError: false,
            }
        case `${FETCH_ACCOUNT_INFO}_REJECTED`:
            return {
                ...state,
                accountInfo: null,
                accountInfoIsLoading: false,
                accountInfoError: true,
            }
        default:
            return state;
    }
};

export default accountInfoReducer;
