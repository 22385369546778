export const isCommunicationEnabled = (method, theUserMeta) => {
  let result = false;
  if(method === 'CHAT'){
      result = true;
  } else {
      if(theUserMeta && theUserMeta.communication && theUserMeta.communication[method]){
          result = true;
      }
  }
  return result;
};

export const getCommunication = (method, localCode, appData, theUserMeta) => {
  const {LocalStrings} = appData;
  let result = isCommunicationEnabled(method, theUserMeta);
  let verifyStr = result ? LocalStrings[localCode].ENABLED : LocalStrings[localCode].DISABLED;
  return `${LocalStrings[localCode][method.toUpperCase()]}${verifyStr}`;
}

export const getUserLevelStr = (mode, theUserMeta) => {
  let level = "BEGINNER";
  if(theUserMeta && theUserMeta.activities && theUserMeta.activities){
      const { activities } = theUserMeta;
      let activityNum = parseInt(activities[mode]) - parseInt(activities[`${mode}_cancel`]);
      if(activityNum >= 3 && activityNum < 5){
          level = "INTERMEDIATE";
      } else if(activityNum >= 5 ){
          level = "ADVANCED";    
      }
  }
  return level;    
}

export const getUserLevel = (mode, localCode, appData, theUserMeta) => {
  const {LocalStrings} = appData;
  let level = getUserLevelStr(mode, theUserMeta);
  return LocalStrings[localCode][level];
}

export const isVerified = (method, theUserMeta) => {
  let result = false;
  if(theUserMeta && theUserMeta.verifyMethod && theUserMeta.verifyMethod[method]){
      result = true;
  }
  return result;
}

export const checkVerifiedMethod = (method, localCode, appData, theUserMeta) => {
  const {LocalStrings} = appData;
  let result = isVerified(method, theUserMeta);
  let verifyStr = result ? LocalStrings[localCode].VERIFIED : LocalStrings[localCode].NOT_VERIFIED;
  return `${LocalStrings[localCode][method.toUpperCase()]}${verifyStr}`;
}

export const getAddressLine = (centerAddress) => {
  const { state, city, town} = centerAddress;
  return `${state} ${city} ${town}`;
}
