import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux';
import BlogStyles from '../Blog.module.scss';
import styles from './TaggedArticles.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import { Spinner } from '../../../components/UI';
import BlogSectionTitle from '../../../components/Blog/BlogSectionTitle/BlogSectionTitle';
import FeatureRegular from '../../../components/Blog/FeatureRegular/FeatureRegular';
import SearchTags from '../../../components/Blog/SearchTags/SearchTags';
import FeatureVideo from '../../../components/Blog/FeatureVideo/FeatureVideo';
import Pagenation from '../../../components/Blog/Pagenation/Pagenation';
import { normalizedTaggedData, normalizedVideoList, calcRemainNum } from '../../../utils';

class TaggedArticles extends Component {
    
    constructor(props){
      super(props);
      const {blog} = this.props;
      const {videos} = blog;
      let howToVideos = normalizedVideoList(videos);
      this.state = {
        howToVideos,  
        currentPage : 1,
        itemNumPage: 9
      }
    }

    updateCurrentPage = (currentPage) => {
      this.setState( (prevState) => {
        return {
          ...prevState,
          currentPage
        }
      });
    }

    renderTaggedArticles = (taggedArticles, maxPage) => {
      const {currentPage, itemNumPage} = this.state;
      if(taggedArticles === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        let taggedArticlesRemain = [];
        if(currentPage === maxPage){
          let remain = calcRemainNum(taggedArticles.length, 3);
          if(remain > 0) {
            for(let i=0; i < remain; i++){
              taggedArticlesRemain.push(1);
            }  
          }
        }

        let currectPageData = taggedArticles.filter((article, index)=>{
          let prevPage = currentPage -1;
          let first = prevPage * itemNumPage;
          let last = currentPage * itemNumPage + 1;
          return (first < index+1 && index+1 < last);
        });

        return(
        <Aux>
          {currectPageData.map((subArticle, index) => {
              const {id} = subArticle;
              return(
              <div key={`taggedArticle_${id}`} className={styles.PodSmallRegularBox} >
                <FeatureRegular 
                  item={subArticle} 
                  index={index} />
              </div>
              );
            })
            }
            {currentPage === maxPage && 
            <Aux>
            {taggedArticlesRemain.length > 0 && taggedArticlesRemain.map((remain, index)=>{
              return(<div key={`taggedArticle_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>
            }  
        </Aux>
        );
      }
    }

    renderHowToVideo = () => {
      const {howToVideos} = this.state;
      if(howToVideos === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        let howToMovieRemain = [];
        if(howToVideos.length < 3) {
          for(let i=0; i < 3 - howToVideos.length; i++){
            howToMovieRemain.push(1);
          }
        }
        return(
        <Aux>
          {howToVideos.map((subArticle, index) => {
              const {id} = subArticle;
              if(index < 3){
                return(
                  <div key={`howToMovies_${id}`} className={styles.PodSmallRegularBox}>
                    <FeatureVideo 
                      item={subArticle} />
                  </div>
                  );
              } else {
                return null;
              }
            })
            }
            <Aux>
            {howToMovieRemain.length > 0 && howToMovieRemain.map((remain, index)=>{
              return(<div key={`remainHowToVideo_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>  
        </Aux>
        );
      }
    }

    render(){
        const {location, localCode, appData, match, blog} = this.props;
        const {articles} = blog
        const {currentPage, itemNumPage} = this.state;
        const { LocalStrings } = appData;
        let pathname = location.pathname;
        let title = 'タグジャムでのサービスの始め方';
        let description = 'タグジャムを使って個人サービスの始め方、SNSシェアで拡散機能、スケジュール管理、取引チャット機能、見積書、請求書機能、キャンセル管理など上手く活用してサービスを成功させよう。';
        let tag = match.params && match.params.tag ? match.params.tag : null;
        let taggedArticles = normalizedTaggedData(tag, articles);
        let maxPage = Math.ceil(taggedArticles.length / itemNumPage);
        
        return(
          <>
          <Helmet>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content={`タグジャム-${title}`} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={`タグジャム-${title}`} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" data-react-helmet="true" />
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={description}
              />
              <meta content="タグジャムの活用方法の記事,個人サービスで稼ぐ,始めるを応援,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className={BlogStyles.BlogPageContent}>
            {/* タグ検索結果 */}    
            <BlogSectionTitle
              tagTitle="タグ"
              title="タグ検索結果"
              tag={LocalStrings[localCode][tag]}
              needMargin={false}
            />
            {taggedArticles.length > 0 &&
            <div className={styles.PodSmallRegularLayout}>
              {this.renderTaggedArticles(taggedArticles, maxPage)}
            </div>
            }
            {taggedArticles.length < 1 && 
            <div className={styles.PodSmallRegularLayout}>
              <p style={{marginTop:'20px'}}>
                {`「${LocalStrings[localCode][tag]}」でタグ付けされた記事は現在ありません。`}
              </p>
            </div>
            }
            { taggedArticles.length > itemNumPage &&
            <Pagenation
              currentPage={currentPage}
              maxPage={maxPage}
              setCurrentPage={this.updateCurrentPage} 
            />
            }

            {/* 使い方動画 */}
            <BlogSectionTitle
              tagTitle="動画"
              title="使い方動画"
              linkTo="/blog/HowToVideos"
              linkLabel="もっと見る…"
              needMargin={true}
            />
            <div className={styles.PodSmallRegularLayout}>
              {this.renderHowToVideo()}
            </div>

            {/* タグで記事を探す */}
            <BlogSectionTitle
              tagTitle="タグ"
              title="タグで記事を探す"
              needMargin={true}
            />
            <div className={BlogStyles.SearchTagContainer}>
              <SearchTags
                appData={appData}
                localCode={localCode} 
              />
            </div>
        </div>
        </>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
  blog: { blog, blogIsLoading, blogError}
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError,
  blog, blogIsLoading, blogError
});

export default connect(mapStateToProps, null)(TaggedArticles);