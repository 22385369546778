import {
    DATE_SELECTED,
} from './actionTypes';

export const dateSelected = ( SelDate ) => {
    return {
        type: DATE_SELECTED,
        payload: {
            SelDate
        }
    }
}