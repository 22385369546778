import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import SellerRequestDetails1Help from '../../../assets/images/help/selling/sell_request_details/sell_request_details_help/sell_request_details_help_1.png';
import SellerRequestDetails1Make from '../../../assets/images/help/selling/sell_request_details/sell_request_details_make/sell_request_details_make_1.png';

import SellerRequestDetails2Help from '../../../assets/images/help/selling/sell_request_details/sell_request_details_help/sell_request_details_help_2.png';
import SellerRequestDetails2Make from '../../../assets/images/help/selling/sell_request_details/sell_request_details_make/sell_request_details_make_2.png';

import SellerRequestDetails3_1Help from '../../../assets/images/help/selling/sell_request_details/sell_request_details_help/sell_request_details_help_3-1.png';
import SellerRequestDetails3_2Help from '../../../assets/images/help/selling/sell_request_details/sell_request_details_help/sell_request_details_help_3-2.png';
import SellerRequestDetails3_3Help from '../../../assets/images/help/selling/sell_request_details/sell_request_details_help/sell_request_details_help_3-3.png';

import SellerRequestDetails3_1Make from '../../../assets/images/help/selling/sell_request_details/sell_request_details_make/sell_request_details_make_3-1.png';
import SellerRequestDetails3_2Make from '../../../assets/images/help/selling/sell_request_details/sell_request_details_make/sell_request_details_make_3-2.png';
import SellerRequestDetails3_3Make from '../../../assets/images/help/selling/sell_request_details/sell_request_details_make/sell_request_details_make_3-3.png';

import SellerRequestDetails4Help from '../../../assets/images/help/selling/sell_request_details/sell_request_details_help/sell_request_details_help_4.png';
import SellerRequestDetails4Make from '../../../assets/images/help/selling/sell_request_details/sell_request_details_make/sell_request_details_make_4.png';



class SellerRequestDetails extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>依頼内容詳細の確認（{this.getCategory()}）</span>
            </h3>
            {(currentTab === 'HELP' || currentTab === 'MAKE') &&
            <p className={styles.ArticleDesc}>
            予約が確定されると買い手の方の画面で「詳細聞き取りフォーム」の入力が可能になります。サービス開始までに依頼内容の詳細を入力してもらうよう、お願いしましょう。フォームの入力が終わると、以下の手順で内容を確認できます。
            </p>
            }
            {(currentTab === 'TEACH' || currentTab === 'RENT') &&
            <p className={styles.ArticleDesc}>
            「{this.getCategory()}」の項目には依頼の詳細聞き取りフォームの確認の手順はありません。次に進んで下さい。
            </p>
            }
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「取引中」を選びます。    
                    </li>
                    }
                    { currentTab==='HELP' && 
                    <img className={styles.ScreenShot} src={SellerRequestDetails1Help} alt="依頼内容詳細の確認ステップ1画像" />
                    }
                    { currentTab==='MAKE' && 
                    <img className={styles.ScreenShot} src={SellerRequestDetails1Make} alt="依頼内容詳細の確認ステップ1画像" />
                    }

                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                      「詳細聞き取りの回答受信」の表示されている取引をタップします。    
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={SellerRequestDetails2Help} alt="依頼内容詳細の確認ステップ2画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={SellerRequestDetails2Make} alt="依頼内容詳細の確認ステップ2画像" />
                    }

                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        「詳細聞き取りの回答」を見るをタップする。または、メッセージ入力ボックスの左横の <i className={`fa fa-file-text ${styles.Icon}`} /> アイコンをタップし、「最新の詳細聞き取りの回答」を見るをタップする。            
                    </li>
                    }
                    {currentTab === 'HELP' &&
                    <Aux> 
                    <img className={styles.ScreenShot} src={SellerRequestDetails3_1Help} alt="依頼内容詳細の確認ステップ3-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={SellerRequestDetails3_2Help} alt="依頼内容詳細の確認ステップ3-2画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={SellerRequestDetails3_3Help} alt="依頼内容詳細の確認ステップ3-2画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux> 
                    <img className={styles.ScreenShot} src={SellerRequestDetails3_1Make} alt="依頼内容詳細の確認ステップ3-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={SellerRequestDetails3_2Make} alt="依頼内容詳細の確認ステップ3-2画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={SellerRequestDetails3_3Make} alt="依頼内容詳細の確認ステップ3-2画像" />
                    </Aux>
                    }
                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        依頼内容をよく読んで確認しておきましょう。他に、買い手の方に質問があれば、「戻る」ボタンで会話に戻り、サービスを開始する前までに聞いておきましょう。           
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={SellerRequestDetails4Help} alt="依頼内容詳細の確認ステップ4画像" />
                    }
                    {currentTab === 'MAKE' &&
                    <img className={styles.ScreenShot} src={SellerRequestDetails4Make} alt="依頼内容詳細の確認ステップ4画像" />
                    }
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SellerRequestDetails);