import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import textStyle from '../../constants/TextStyle.module.scss';
import styles from './ReviewItems.module.scss';
import {
    CloseBtn,
    Backdrop,
    Avator,
    Spinner
} from '../UI';
import { UserInformation } from '../Gigs'
import {
    fetchAllUserMeta
} from '../../store/actions';
import {
    trunicateString
} from '../../utils'

class ReviewItems extends Component {

    state = {
        reviwedUserId:null,
        reviewedGigId:null,
        userId:null,
        reviewList:null,
        loading: true,
        error:false
    }

    componentDidMount = async() => {
        const {reviewedGig, userMeta, onLoadUserMeta} = this.props;
        if(!userMeta){
            // eslint-disable-next-line no-unused-vars
            const userMetaData = await onLoadUserMeta(); 
        }
        if(reviewedGig){
            let reviews = reviewedGig.reviews;
            let reviewList = this.getReviewList(reviews);
            this.setState(prevState => {
                return{
                    ...prevState,
                    reviewedGig,
                    userId:reviewedGig.user,
                    reviewList,
                    loading:false
                }
            }); 
        } else {
            this.setState(prevState => {
                return{
                    ...prevState,
                    loading:false,
                    error:true
                }
            });
        }    
    }

    trunicateMessage = (text, maxLen) => { 
        return trunicateString(maxLen, text);
    }

    getReviewList = (reviews) => {
        let list=[];
        for(let key in reviews){
            list.push({id:key, ...reviews[key]});
        }
        return list;
    } 

    renderReviewItem = (item, index) => {
        const {userMeta, localCode, appData } = this.props;
        const { Colors, LocalStrings} = appData;
        const {id, customerId, comment, createdDate, anonymous} = item;
        let reviewer = userMeta[customerId];
        let {accountInfo} = reviewer;
        if(id){
            return(
            <div 
                key={`${item}_${index}`}
                className="BorderBottom" 
                style={{width:"100%", backgroundColor:Colors.white, padding:'15px 8px'}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
                    <div style={{ display:"flex", flexDirection:"column", width:'60px', justifyContent:"center", alignItems:"center", paddingRight:'8px', borderRight:`1px solid ${Colors.contentFontTintColor}` }}>
                        <Avator avator={anonymous? null : accountInfo.avator} size={35} />
                    </div>
                    <div style={{paddingLeft:'15px', flex:3}}>
                        <p className={`${textStyle.BodyTextSmall} ${textStyle.Bold}`} style={{ marginTop:'5px', color:Colors.contentFontColor}}>
                            {anonymous? LocalStrings[localCode].ANONYMOUS : accountInfo.displayName}      
                        </p>
                        <p className={`${textStyle.BodyTextSmall} ${textStyle.Bold}`} style={{ marginTop:'5px', color:Colors.contentFontColor}}>
                            {`${moment(createdDate).format('LL')} ${LocalStrings[localCode].POST}`}      
                        </p>
                    </div>
                </div>
                <div style={{paddingTop:'10px', paddingLeft:'8px'}}>
                    <div className={textStyle.BodyText} style={{ marginTop:'5px', color:Colors.contentFontColor}}>
                        {comment}
                    </div>
                </div>
            </div>   
            );  
        } else {
            return null;
        } 
    }

    renderContent = () => {
        const { 
            localCode,
            userMeta,
            appData,
            onUserInfoPressed  
        } = this.props;
        const { Colors, LocalStrings } = appData;
        const {reviewedGig, userId, reviewList} = this.state;
        const {reviews} = reviewedGig;
        const theUserMeta = userMeta[userId];
        return(
        <div style={{height:"100%", width:"100%", display:"flex", flexDirection: 'column', backgroundColor: Colors.contentBGColor}}>
            {reviewedGig && 
            <UserInformation
                localCode={localCode}
                appData={appData}
                onUserInfoPressed={onUserInfoPressed} 
                accountInfo={theUserMeta.accountInfo}
                theGig={reviewedGig}
                onPressReviews={()=>{console.log("review pressed")}} 
            />
            }
            {reviews && 
            <div style={{flex:3, display:"flex", flexDirection: 'column', width:"100%"}}>
                <div>
                    { reviewList.map((item, index)=>{
                        return this.renderReviewItem(item, index)
                    })}    
                </div>     
            </div>
            }
            {!reviews &&
            <div style={{flex:3, width:'100%', height:'100%', padding:'10px'}}> 
                <div style={{display:"flex", flexDirection: 'row', width:"100%", padding:'20px 0', justifyContent:"flex-start", alignItems:"center"}}>
                    <p style={textStyle.bodyText}>{`${LocalStrings[localCode].REVIEW}${LocalStrings[localCode].NO_ITEM}`}</p>
                </div>
            </div>
            }
        </div>
        );    
    }    

    render(){
        const { 
            onClose, 
            appData, 
            localCode, 
            windowStatus,
            userMetaIsLoading, userMetaError
        } = this.props;
        const { LocalStrings, Colors} = appData;
        const {loading, error} = this.state;
        let content = null;
        
        if(loading || userMetaIsLoading){
            content = <div className="SpinnerBox">
                <Spinner large />
            </div>
        } else if(error || userMetaError){
            content =  <p>{LocalStrings[localCode].ERROR_OCCURED}</p>;
        } else {
            content = this.renderContent();  
        }
        
        return(
        <Fragment>
            <Backdrop 
                show={windowStatus}
                clicked={onClose}/>
            <div className={windowStatus ? `${styles.ReviewItemsContainer} Fade ${styles.Open}` : `${styles.ReviewItemsContainer} Fade ${styles.Close}`}>
                <div className={styles.CloseBtnContainer}>
                    <CloseBtn clicked={onClose} color={Colors.contentFontColor} fontSize={'1.5em'} />
                </div>
                {content}
            </div>              
        </Fragment>
        );
    }
}

const mapStateToProps = ({
    local: { localCode },
    auth: { authInfo },
    userMeta: {userMeta, userMetaIsLoading, userMetaError},
    appData: { appData }
}) => ({
    localCode,
    authInfo,
    userMeta, userMetaIsLoading, userMetaError,
    appData,
});

const mapDispatchToProps = (dispatch)=>({
    onLoadUserMeta: () => { dispatch(fetchAllUserMeta()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewItems);
