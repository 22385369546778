import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import styles from './UserProfile.module.scss';
import {
    CloseBtn,
    Backdrop,
    Spinner
} from '../UI';
import UserLevelNote from '../../components/UserLevelNote/UserLevelNote';
import {
    fetchTheUserMeta
} from '../../store/actions';
import ProfileCard from './ProfileCard/ProfileCard';

class UserProfile extends Component {

    state = {
        userLevelExpWinOpen:false,
        supportWinOpen: false,
        accountDeactivatedWin: false,
        mode:null
    }

    toggleModalWindow = (key, mode) => {
        this.setState(prevState => {
            if(key==='supportWinOpen'){
                return {
                    ...prevState,
                    [key]: !prevState[key]
                };    
            } else {
                return {
                    ...prevState,
                    [key]: !prevState[key],
                    mode   
                };
            }
        });
    }

    componentDidMount(){
        const { getTheMetaData, theUserMeta, userId } = this.props;
        if(!theUserMeta || (theUserMeta && theUserMeta.id !==userId) ){
            console.log("userId found in navigation param");
            getTheMetaData(userId);
        } else {
            console.log("theUserMeta id matches with userId");
        }
    }

    compareOrder = (a , b) => {
        //sorting list by order assending order
        if(a.order < b.order) return -1;
        if(a.order > b.order) return 1;
        return 0;
    }

    getLevelList = () => {
        const { appData } = this.props;
        let list = [];
        for(let key in appData.UserLevels) {
            list.push({key, color:appData.UserLevels[key].color, order:appData.UserLevels[key].order});
        }
        return list.sort(this.compareOrder);   
    }

    toggleModalWindow = (key, mode) => {
        this.setState(prevState => {
            if(key==='supportWinOpen'){
                return {
                    ...prevState,
                    [key]: !prevState[key]
                };    
            } else {
                return {
                    ...prevState,
                    [key]: !prevState[key],
                    mode   
                };
            }
        });
    }

    explainPress = (mode) => {
        console.log("explain user level pressed");
        this.toggleModalWindow("userLevelExpWinOpen", mode);
    }

    render(){
        const { 
            onClose, 
            appData, 
            localCode, 
            windowStatus,
            theUserMeta, theUserMetaIsLoading, theUserMetaError,
            theBuyerMeta, theBuyerMetaIsLoading, theBuyerMetaError,
            centerAddress
        } = this.props;
        const { LocalStrings, Colors} = appData;
        const { mode, userLevelExpWinOpen } = this.state;
        const levelList = this.getLevelList();
        let content = null;
        if(theUserMetaIsLoading || theBuyerMetaIsLoading){
          content = <div className="SpinnerBox">
            <Spinner large />
          </div>
        } else if(theUserMetaError || theBuyerMetaError){
          content =  <p>{LocalStrings[localCode].ERROR_OCCURED}</p>;
        } else if(theUserMeta || theBuyerMeta){
          content = <ProfileCard 
                      appData={appData} 
                      localCode={localCode} 
                      theUserMeta={theUserMeta} 
                      centerAddress={centerAddress}
                      explainPress={this.explainPress} />;
        }
        
        return(
        <Fragment>
          <Backdrop 
            show={windowStatus}
            clicked={onClose}/>
          <div className={windowStatus ? `${styles.UserProfileContainer} Fade ${styles.Open}` : `${styles.UserProfileContainer} Fade ${styles.Close}`}>
            <div className={styles.CloseBtnContainer}>
                <CloseBtn clicked={onClose} color={Colors.white} fontSize={'1.5em'} />
            </div>
            {content}
          </div>
          <UserLevelNote
            localCode={localCode} 
            levelList={levelList}
            mode={mode}
            appData={appData}
            windowStatus={userLevelExpWinOpen}
            onClose={()=> { this.toggleModalWindow("userLevelExpWinOpen", null) }} />
        </Fragment>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  auth: { authInfo },
  userMeta : { 
    theUserMeta, theUserMetaIsLoading, theUserMetaError,
    theBuyerMeta, theBuyerMetaIsLoading, theBuyerMetaError
  },
  appData: { appData }
}) => ({
  localCode,
  authInfo,
  theUserMeta, theUserMetaIsLoading, theUserMetaError,
  theBuyerMeta, theBuyerMetaIsLoading, theBuyerMetaError,
  appData,
});

const mapDispatchToProps = dispatch => ({
  getTheMetaData: (userId, buyerId) => dispatch( fetchTheUserMeta(userId, buyerId) )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
