import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux';
import AboutStyles from '../About.module.scss';
import styles from './CompanyInfo.module.scss';
import Blue_line from '../../../assets/images/blue_line.svg';
import Tagger from '../../../assets/images/tagger.svg';
import Jammer from '../../../assets/images/jammer.svg';

class CompanyInfo extends Component {
    render(){
        const {location} = this.props;
        let pathname = location.pathname;
        let title = '運営会社情報';
        let description = '目に見えないお手伝いも値札である「タグ」をつけて販売できる！ タグジャムは「手伝う」を売り買いするコミュニティーを作りを目的とした会社です。いろんな働き方のオプションを増やし、地域の頑張る人たちを応援する会社です。';
        return(
          <>
          <Helmet>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content={`タグジャム-${title}`} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={`タグジャム-${title}`} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" data-react-helmet="true" />
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={description}
              />
              <meta content="タグジャム運営会社情報,始めるを応援,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className={AboutStyles.AboutPageContent}>
            <h2 className={AboutStyles.SectionHead}>会社概要</h2>
            <div className={styles.Section}>
              <div className={styles.SectionTitleBox}>
                <div className={`${styles.SectionTitleBG} ${styles.TagBG}`} />
                <div className={styles.SectionTitle}>
                  <p className={styles.SectionTitleText}>
                    タグジャム 
                  </p>
                  <img className={styles.SectionBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                    「手伝う」を売り買いするアプリ
                  </p>
                </div>
              </div>
              <p className={styles.Description}>
                私たちは個人のサービスを売り買いするフリマアプリ「<strong>タグジャム</strong>」を運営しています。困っている人を助ける事をサービスとして売り買いできるプラットフォームを開発、運営しています。またタグジャムを通して「お手伝い」のコミュニティー作りを行っています。
              </p>
            </div>
            <div className={styles.Section}>
              <div className={styles.SectionTitleBox}>
                <div className={`${styles.SectionTitleBG} ${styles.CloverBG}`} />
                <div className={styles.SectionTitle}>
                  <p className={styles.SectionTitleText}>
                    設立の動機
                  </p>
                  <img className={styles.SectionBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                  「手伝う」の潤滑で地域を元気に
                  </p>
                </div>
              </div>
              <p className={styles.Description}>
              「夜勤が出来ない看護師は、仕事復帰が出来ない！」3人の子育てに専念するか、看護師を続けるか、の選択を強いられた代表者の苦い経験から、頑張る人たちにいろんな働き方のオプションを増やしたいと考えるようになりました。
              また公共の介護サービスでは、電球の交換や、家具の移動などには対応しておらず、多くの方がちょっとしたお手伝いを求めているのが現状です。
              空いた時間で、できる範囲で「お手伝い」を提供できる場所があれば、いつでも簡単に「お手伝い」が依頼できたら、そういった方々を繋げたい思いが強くなり、2019年についに<strong>タグジャム</strong>を設立いたしたました。
              職場復帰が困難な中で自分の価値を見失いかけている人が「お手伝い」を通じ「ありがとう」を体験する、
              困り事を抱え一人で頑張る方に「お手伝い」で寄り添い、不安を取り除き、ポジティブな気持ちの循環でお互いに元気になる、そんな豊かな社会を支えるサービスを目指しています。
              </p>
            </div>
            <div className={styles.Section}>
              <div className={styles.SectionTitleBox}>
                <div className={`${styles.SectionTitleBG} ${styles.FlowerBG}`} />
                <div className={styles.SectionTitle}>
                  <p className={styles.SectionTitleText}>
                    名称の由来
                  </p>
                  <img className={styles.SectionBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                    「タガー」と「ジャマー」
                  </p>
                </div>
              </div>
              <p className={styles.Description}>
                目に見えないお手伝いも値札である「タグ」をつけて販売できる！　それらのタグを買いにみんなが「ジャム」集まるの2つをくっつけました。
                タグをつける「タガー」とそれを買う「ジャマー」を楽しく繋げ、ワイワイ♪と活気ある市場にしたいという思いで<strong>タグジャム</strong>と名付けました。
              </p>
            </div>
            <div className={styles.TaggerJammer}>
              <img className={styles.Stecker} src={Tagger} alt="タガー" />
              <img className={styles.Stecker} src={Jammer} alt="ジャマー" />
            </div>
            <dl className={styles.Table}>
                <dt className={`${styles.Middle} ${styles.RadiusLeftTop}`}>
                  <h5>事業者名称</h5>
                </dt>
                <dd className={`${styles.Middle} ${styles.RadiusRightTop}`}>
                  <p>タグジャム</p>
                </dd>
                <dt className={styles.Middle}>
                  <h5>代表責任者名</h5>
                </dt>
                <dd className={styles.Middle}>
                  <p>脇山　輝美</p>
                </dd>
                <dt className={styles.Middle}>
                  <h5>所在地</h5>
                </dt>
                <dd className={styles.Middle}>
                  <p>〒852-8062<br />長崎県長崎市大園町9-11 JGMウィングヒル滑石 1704号</p>
                </dd>
                <dt className={styles.Middle}>
                  <h5>メールアドレス</h5>
                </dt>
                <dd className={styles.Middle}>
                  <p>contact@tagujam.com</p>
                </dd>
                <dt className={styles.Middle}>
                  <h5>ホームページURL</h5>
                </dt>
                <dd className={styles.Middle}>
                  <p>https://www.tagujam.com</p>
                </dd>
                <dt className={styles.Middle}>
                  <h5>設立日</h5>
                </dt>
                <dd className={styles.Middle}>
                  <p>2019年9月</p>
                </dd>
                <dt className={`${styles.Middle} ${styles.RadiusLeftBottom}`}>
                  <h5>事業内容</h5>
                </dt>
                <dd className={`${styles.Middle} ${styles.RadiusRightBottom}`}>
                  <p>個人サービスのフリマアプリ運営</p>
                </dd>
            </dl>
          </div>
        </>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError, 
});

export default connect(mapStateToProps, null)(CompanyInfo);