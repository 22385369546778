import React from 'react';
import Aux from '../../../../hoc/Aux/Aux';
import styles from '../Article.module.css';

const SellersManner = (props) => {
    return(
        <Aux>
            <h5 className={styles.HeaderMargin10}>プロフィール内容の入力</h5>
            <p>サービス購入を検討する際、プロフィールは重要な参考事項です。プロフィール写真は本人の画像を推奨いたします。顔が見える事で購入者が安心できます。また、性別の誤解を与えるような写真やユーザー名は避けて下さい。自己紹介で簡単に自分の説明を書いておきましょう。有資格や、特技、今までの経験などを入力することで提供するサービスをアピールする事ができます。虚偽や誤解を招く内容の入力は避けて下さい。</p>
            <h5 className={styles.HeaderMargin10}>サービス内容の選択は慎重に</h5>
            <p>サービスを出店する場合、各カテゴリーにおける自分が提供可能な基本サービス・オプションサービスなどは依頼がきた場合に必ず提供できる項目を選択しましょう。又、サービス購入者に準備してもらいたい物や、注意事項などきちんと自分のサービスにあった内容を記載されているかを確認しましょう。</p>
            <h5 className={styles.HeaderMargin10}>シフト期間・時間は正確に</h5>
            <p>シフト時間・期間は、その時間・期間内なら必ずサービス提供できる時間・期間を設定しましょう。なお、私用などで提供できない時間が発生した場合は「アカウント」＞「スケージュール管理」にて、その時間を予約不可にしておきましょう。</p>
            <h5 className={styles.HeaderMargin10}>必要資格・届出書の提出は速やかに</h5>
            <p>必要資格・届出書が必要なサービスの出品後、書類提出は速やかに行いましょう。必要書類の確認がタグジャム事務局側で完了する前に、質問の返答や、取引を始める事はできませんので提出が遅れると、購入者への返信の遅れにつながりますので気をつけましょう。</p>
            <h5 className={styles.HeaderMargin10}>サービスの提供は予約時間を守りましょう</h5>
            <p>サービス購入者の方は、予約時間にサービスの提供がなされる事を期待して購入します。予約時間にサービス提供が開始されないと不安になります。訪問型・配達をするサービスなどの場合は移動時間などをきちんと考慮して時間を守りましょう。止むを得ず、予約時間にサービス開始ができない場合は購入者に事前連絡をとりましょう。「作ります」の場合は、納品日を目安に作業を進めてください。もし納品日に遅れる場合は事前に購入者に連絡をとりましょう。もし自分の遅延により予約したサービスの提供が短縮になった場合や迷惑をかけた場合には、精算の際、割引などを提供するなどで誠意のある対応を心がけましょう。</p>
            <h5 className={styles.HeaderMargin10}>レンタルサービスの提供について</h5>
            <p>レンタル品の整備点検は、事前に行っておきましょう。貸し出しした後に故障などを報告された場合は速やかに対応してください。対応できない場合は、取引をキャンセルしてください。返却時に故障・損傷・汚れなどを発見した場合は、返却時適用料金を請求書に入力してください。その際に必ず購入者に料金を請求する旨を伝えてください。レンタル品の返却が遅延した場合は、遅延金を適用することができます。その際も必ず購入者に料金を適用する旨を伝えてください。レンタル品が返却されない場合はサービス利用者にまず連絡を取ってください。連絡が取れない場合は保証金の請求が可能ですので、タグジャム事務局に連絡をしてください。</p>
            <h5 className={styles.HeaderMargin10}>キャンセルについて</h5>
            <p>タグジャムでは基本的に取引成立後のキャンセルはできません。購入者はあなたのサービスを予定通りに行ってくれる事を期待して、購入、計画しています。成立後のキャンセルは購入者に迷惑をかけることになります。ただし、止むを得ない場合はキャンセルの申請ができますが、必ず購入者の同意の元に行ってください。その際なぜキャンセルしないといけないのか、止むを得ない事情をきちんと説明するとトラブルを防げます。又、購入申請を受け付ける際に無理な場合は、事情を話し、受付できない事を理解してもらい申請をお断りしましょう。</p>
            <h5 className={styles.HeaderMargin10}>購入者への評価の入力を行いましょう</h5>
            <p>購入者の「良かった点」をコメントにて伝えることは、出品者と購入者の良い関係を作るための大切なものです。5段階評価の一部（総合評価）や、コメントは公開され、他のユーザーも見ることができる状態になります。なお、誹謗中傷や個人情報などを記載することがないようご注意ください。（タグジャムのルールに反する内容の場合は運営で削除し、記載した方のご利用を制限いたします。）</p>
        </Aux>
    )
}

export default SellersManner