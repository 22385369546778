export const getAcceptedMinutes = (theGig, estimate, appData, hasMinuteCon) =>  {
    const { MainCat, feeInfo : {accepted, acceptedUnit, length, lengthUnit} } = theGig;
    const { numbers } = estimate;
    let acceptedMinutes;
    if(MainCat==='TEACH'){
        acceptedMinutes = lengthUnit === 'HOUR' ? parseFloat(length) * 60 : parseFloat(length);  
    } else {
        acceptedMinutes = acceptedUnit === 'HOUR_ACCEPTED' ? parseFloat(accepted) * 60 : parseFloat(accepted); 
    }
    let hasMinConvert = hasMinuteCon ? hasMinuteCon : hasMinuteConvert(theGig, appData); 
    if(hasMinConvert){
        if(numbers && numbers["orderHours"]){
            let orderMins = parseFloat(numbers["orderHours"]) * 60;
            acceptedMinutes = acceptedMinutes > orderMins ? acceptedMinutes : orderMins;
        }
    }
    return acceptedMinutes;
};

export const hasMinuteConvert = (theGig, appData) => {
    const { ServiceData } = appData;
    const { MainCat, SubCat, optionableService, optionService} = theGig;
    const serviceItemData = ServiceData[MainCat][SubCat];
    let hasMinuteConvert = false;
    if(optionService){
        for(let key in optionService){
            if(optionService[key]!==false){
                let optionItem = serviceItemData.options.list.filter(option => {
                    return option.TYPE === key;
                });
                if( optionItem.length > 0 && optionItem[0].MINUTES_COVERT ){
                    hasMinuteConvert = true;
                    break;
                }  
            }  
        }
    }
    if(hasMinuteConvert===false && optionableService){
        for(let key in optionableService){
            if(optionableService[key]!==false){
                if( serviceItemData.optionable[key] && serviceItemData.optionable[key].MINUTES_COVERT ){
                    hasMinuteConvert = true;
                    break;
                }  
            }  
        }    
    }
    return hasMinuteConvert;
}
