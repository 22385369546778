import React from 'react';
import styles from './TagSvg.module.scss';

const TagSvg = props => {
        const { type, active} = props;
        return(
        <div
            className={ active ? `${styles.TagSvg} ${styles[type]}` : styles.TagSvg}
        />
        ); 
    }

export default TagSvg;