import {
    CHANGE_SEARCH_QUERY,
    ADD_ITEM_TO_SEARCH,
    SET_SEARCH_SERVICE_INIT,
    ADD_ADDRESS_TO_SEARCH,
    SET_ADDRESS_BY_KEY,
    SET_PRICE_BY_KEY,
    SET_DATE_TO_SEARCH,
    RESET_SEARCH,
    SET_INDICATOR_TO_SEARCH
} from '../actions/actionTypes'

const initState = {
    query:"",
    SearchMainCat:null,
    SearchSubCat:null,
    serviceItem:null,
    searchArea:null,
    searchPrice:{
        priceMin:null,
        priceMax:null
    },
    searchDate:null,
    indicator:null,
}

const searchReducer = (state=initState, action) => {
    const {type, payload} = action;
    switch(type){
        case CHANGE_SEARCH_QUERY:{
            return {
                ...state,
                query:payload
            }
        }
        case ADD_ITEM_TO_SEARCH:{
            return {
                ...state,
                serviceItem:{
                    ...state.serviceItem,
                    [payload.serviceType]:{
                        ...state.serviceItem[payload.serviceType],
                        [payload.key]: payload.value
                    }
                }
            }
        }
        case SET_SEARCH_SERVICE_INIT:{
            return {
                ...state,
                SearchMainCat:payload.SearchMainCat,
                SearchSubCat:payload.SearchSubCat,
                serviceItem:payload.searchInitData
            }
        }
        case ADD_ADDRESS_TO_SEARCH: {
            return{
                ...state,
                searchArea:payload
            } 
        }
        case SET_ADDRESS_BY_KEY: {
            return{
                ...state,
                searchArea: {
                    ...state.searchArea,
                    [payload.key]: payload.value
                }
            }
        }
        case SET_PRICE_BY_KEY: {
            return{
                ...state,
                searchPrice: {
                    ...state.searchPrice,
                    [payload.key]: payload.value
                }
            }
        }
        case SET_DATE_TO_SEARCH: {
            return{
                ...state,
                searchDate: payload
            }
        }
        case RESET_SEARCH: {
            return{
                ...initState
            }
        }
        case SET_INDICATOR_TO_SEARCH: {
            return{
                ...state,
                indicator: payload
            }
        }
        default:
            return state;
    }
}

export default searchReducer;