import {
    FETCH_TOS_ACCEPTANCE
} from './actionTypes';

import { 
    authGetToken 
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchTosAcceptancePending = () => ({
    type: `${FETCH_TOS_ACCEPTANCE}_PENDING`
});

export const fetchTosAcceptanceRejected = (err) => ({
    type: `${FETCH_TOS_ACCEPTANCE}_REJECTED`,
    payload: err
});

export const fetchTosAcceptanceFulFilled = (userInfo) => ({
    type: `${FETCH_TOS_ACCEPTANCE}_FULFILLED`,
    payload: userInfo
});

export const fetchTosAcceptance = (id) => {
    return async dispatch => {
        dispatch(fetchTosAcceptancePending());
        try{
            let token = await dispatch(authGetToken());
            let userInfo = await taguJamApi.fetchTosAcceptance(id, token);
            dispatch(fetchTosAcceptanceFulFilled(userInfo))
            return userInfo;
        } catch(err){
            dispatch(fetchTosAcceptanceRejected(err));
            throw err
        }
    }   
};