import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import BuyerStartService1 from '../../../assets/images/help/buying/buy_start_service/buy_start_service_help/buy_start_service_help_1.jpg';
import BuyerStartService1Teach from '../../../assets/images/help/buying/buy_start_service/buy_start_service_teach/buy_start_service_teach_1.jpg';
import BuyerStartService1Rent from '../../../assets/images/help/buying/buy_start_service/buy_start_service_rent/buy_start_service_rent_1.jpg';
import BuyerStartService1Make from '../../../assets/images/help/buying/buy_start_service/buy_start_service_make/buy_start_service_make_1.jpg';

import BuyerStartService2 from '../../../assets/images/help/buying/buy_start_service/buy_start_service_help/buy_start_service_help_2.jpg';
import BuyerStartService2Teach from '../../../assets/images/help/buying/buy_start_service/buy_start_service_teach/buy_start_service_teach_2.jpg';
import BuyerStartService2Rent from '../../../assets/images/help/buying/buy_start_service/buy_start_service_rent/buy_start_service_rent_2.jpg';
import BuyerStartService2Make from '../../../assets/images/help/buying/buy_start_service/buy_start_service_make/buy_start_service_make_2.jpg';


import BuyerStartService3Teach from '../../../assets/images/help/buying/buy_start_service/buy_start_service_teach/buy_start_service_teach_3.jpg';
import BuyerStartService3Rent from '../../../assets/images/help/buying/buy_start_service/buy_start_service_rent/buy_start_service_rent_3.jpg';

import BuyerStartService4Rent from '../../../assets/images/help/buying/buy_start_service/buy_start_service_rent/buy_start_service_rent_4.jpg';

import BuyerStartService5 from '../../../assets/images/help/buying/buy_start_service/buy_start_service_help/buy_start_service_help_3.jpg';
import BuyerStartService5Teach from '../../../assets/images/help/buying/buy_start_service/buy_start_service_teach/buy_start_service_teach_4.jpg';
import BuyerStartService5Rent from '../../../assets/images/help/buying/buy_start_service/buy_start_service_rent/buy_start_service_rent_5.jpg';
import BuyerStartService5Make from '../../../assets/images/help/buying/buy_start_service/buy_start_service_make/buy_start_service_make_3.jpg';


class BuyerStartService extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービスを提供開始（{this.getCategory()}）</span>
            </h3>
            {currentTab === 'HELP' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            予約が確定されたサービスの開始日になり実際にサービスの提供が始まるとサービス開始のメッセージがきます。以下の手順でサービス開始メッセージを確認できます。
            </p>
            </Aux>
            }
            {currentTab === 'TEACH' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            予約が確定された取引には以下の手順でクラスに参加することができます。
            </p>
            </Aux>
            }
            {currentTab === 'RENT' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            予約が確定されたら、配達オプションがある場合は配達日時を、配達オプションのない場合は、レンタル品の引き渡し場所と時間を貸し手に確認しましょう。
            </p>
            </Aux>
            }
            {currentTab === 'MAKE' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            作り手の方が作成を開始するとメッセージが入ります。その場合は以下の手順で確認することができます。
            </p>
            </Aux>
            }

            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。    
                    </li>
                    { currentTab==='HELP' && 
                    <img className={styles.ScreenShot} src={BuyerStartService1} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='TEACH' && 
                    <img className={styles.ScreenShot} src={BuyerStartService1Teach} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='RENT' && 
                    <img className={styles.ScreenShot} src={BuyerStartService1Rent} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerStartService1Make} alt="サービスを提供をするステップ1画像" />
                    }

                    {currentTab === 'HELP' && 
                    <li>
                        「サービスが開始されました」の表示されている取引をタップします。    
                    </li>
                    }
                    {　(currentTab === 'TEACH' || currentTab === 'RENT')　 && 
                    <li>
                        「予約が確定されました」の表示されている取引をタップします。    
                    </li>
                    }
                    {currentTab === 'MAKE' && 
                    <li>
                        「作成が開始されました」の表示されている取引をタップします。    
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={BuyerStartService2} alt="サービスを提供をするステップ2画像" />
                    }
                    {currentTab === 'TEACH' && 
                    <img className={styles.ScreenShot} src={BuyerStartService2Teach} alt="サービスを提供をするステップ2画像" />
                    }
                    {currentTab === 'RENT' && 
                    <img className={styles.ScreenShot} src={BuyerStartService2Rent} alt="サービスを提供をするステップ2画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerStartService2Make} alt="サービスを提供をするステップ2画像" />
                    }

                    {currentTab === 'TEACH' &&
                    <Aux>
                    <li>
                        クラスに出向き「出席コードを表示」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={BuyerStartService3Teach} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                        配達日時、引き渡しの場所と日時などを聞いておきましょう。
                    </li>
                    <img className={styles.ScreenShot} src={BuyerStartService3Rent} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }

                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                        レンタル品の引き渡しが終わると「レンタル品が渡されました」のメッセージが表示されます。レンタル品を受け取りの際は、最初からある傷や、損傷などをサービス提供者（貸し手）と一緒に確認しましょう。問題がなければ「レンタル品受け取り完了」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={BuyerStartService4Rent} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                    
                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                        「サービス開始」のメッセージを確認することができます。            
                    </li>
                    <img className={styles.ScreenShot} src={BuyerStartService5} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                    {currentTab === 'TEACH' &&
                    <Aux>
                    <li>
                        QRコードが表示されます。そのQRコードを売り手の方に読み取ってもらいます。
                    </li>
                    <p>＊「来客型」のクラスの場合はクラスの受講申請した代表者の方がQRコードを見せてください。</p>
                    <p>＊「来客型」のクラスの場合は、クラス開催の場所も確認できます。</p>
                    <img className={styles.ScreenShot} src={BuyerStartService5Teach} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                        レンタル品受け取りのメッセージを確認できます。
                    </li>
                    <img className={styles.ScreenShot} src={BuyerStartService5Rent} alt="サービスを提供をするステップ5画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                        「作成が開始されました」のメッセージが確認できます。        
                    </li>
                    <img className={styles.ScreenShot} src={BuyerStartService5Make} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BuyerStartService);