import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import BuyerRequestDetails1Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_1.png';
import BuyerRequestDetails2Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_2.png';
import BuyerChangeRequest1 from '../../../assets/images/help/buying/buy_change_request/buy_change_request_1.png';
import BuyerChangeRequest2 from '../../../assets/images/help/buying/buy_change_request/buy_change_request_2.png';
import BuyerChangeRequest3 from '../../../assets/images/help/buying/buy_change_request/buy_change_request_3.png';

class BuyerChangeRequest extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>依頼内容の変更（{this.getCategory()}）</span>
            </h3>
            <p className={styles.ArticleDesc}>
              購入申請が受付けられた後、サービス予定日、時間、オプション選択などの依頼内容の変更をしたい場合は、まずは取引中の提供者に連絡をして下さい。提供者が同意した場合は、提供者の方が内容を編集する事ができます。
              提供者のスケジュールの空き状況や、諸事情により、変更に対応しかねる場合がありますのでご了承願います。
            </p>
            
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerRequestDetails1Help} alt="依頼内容の変更をするステップ1画像" />

                    <li>
                        依頼内容を変更したい取引をタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerRequestDetails2Help} alt="依頼内容の変更をするステップ2画像" />
                    
                    <li>
                        取引詳細画面で、変更の内容をサービス提供者に送信して下さい。            
                    </li>
                    <img className={styles.ScreenShot} src={BuyerChangeRequest1} alt="依頼内容の変更をするステップ3画像" />

                    <li>
                        変更が承諾され、提供者の方が見積もりの編集を完了した時点で、以下のメッセージが確認できます。「見積もりを見る」をタップして下さい。            
                    </li>
                    <img className={styles.ScreenShot} src={BuyerChangeRequest2} alt="依頼内容の変更をするステップ4画像" />

                    <li>
                        変更内容を確認して下さい。            
                    </li>
                    <img className={styles.ScreenShot} src={BuyerChangeRequest3} alt="依頼内容の変更をするステップ5画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BuyerChangeRequest);