import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import BlockUser1 from '../../../assets/images/help/buying/buy_block_user/buy_block_user_1.png';
import BlockUser2 from '../../../assets/images/help/buying/buy_block_user/buy_block_user_2.png';
import BlockUser3 from '../../../assets/images/help/buying/buy_block_user/buy_block_user_3.png';

class BlockUser extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>特定のユーザーをブロックする</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムでは、特定のユーザーの今後のコミュニケーションをブロックする事ができます。
            </p>
            <p className={styles.ArticleDesc}>
            尚、ブロックされたユーザからは、「取引」画面でのメッセージの送信、サービス詳細画面からの「問合せ」、「購入申請」、「面接の申し込み」など、今後のあなたに対するコミュニケーションが一切出来ませんので慎重に行って下さい。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        「取引」画面で該当する取引をタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={BlockUser1} alt="特定のユーザーをブロックするステップ1画像" />
                    <li>
                        「取引詳細」画面で「このユーザーをブロックする」ボタンをタップします。     
                    </li>
                    <img className={styles.ScreenShot} src={BlockUser2} alt="特定のユーザーをブロックするステップ2画像" />
                    <li>
                        ボタンの表示が「ユーザーをブロック解除する」に変更した事を確認して、ブロックの完了です。<br /> 
                        ＊尚、このボタンにてブロックを解除する事が出来ます。   
                    </li>
                    <img className={styles.ScreenShot} src={BlockUser3} alt="特定のユーザーをブロックするステップ3画像" />
                </ol>
            </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BlockUser);