import React from 'react';
import AppStore from '../../assets/images/Download_App_Store.svg';
import GooglePlayStore from '../../assets/images/Download_Badge_JP.svg';
import {isIOS, isAndroid} from 'react-device-detect';
import styles from './DownloadBtns.module.css';

const downloadBtns = props =>{
    let dynamicClass = styles.Center;
    if(props.mode === 'Hero'){
      dynamicClass = styles.Left;  
    }
    if(props.mode === 'Blog'){
      dynamicClass = styles.BlogAlign; 
    }
    return (
        <div className={`${styles.DownloadBtns} ${dynamicClass}`}>
            { ((!isAndroid && !isIOS) || isIOS) &&
            <a
              style={ {paddingRight:(!isAndroid && !isIOS) ? '7px' : '0px' } }
              href="https://apps.apple.com/jp/app/%E3%82%BF%E3%82%B0%E3%82%B8%E3%83%A3%E3%83%A0-tagujam/id1479792883?mt=8" target="blank" className={styles.BtnStyles}>
                <img src={AppStore} alt="App Store ダウンロード"/>
            </a>
            }
            { ((!isAndroid && !isIOS) || isAndroid) && 
            <a 
              style={ {paddingLeft:(!isAndroid && !isIOS) ? '7px' : '0px' } } 
              href="https://play.google.com/store/apps/details?id=com.tagujammobile" target="blank" className={styles.BtnStyles}>
                <img src={GooglePlayStore} alt="Google Play Store ダウンロード"/>
            </a>
            }
        </div>
    );
}

export default downloadBtns;