import {
    FETCH_USER_INFO,
    SET_USER_INFO,
    SET_EMAIL_CODE,
    SET_VERIFY_STATUS,
    RESET_EMAIL_VERIFICATION
} from '../actions/actionTypes';

const initState = {
    userInfo: null,
    userInfoIsLoading: false,
    userInfoError: false
};

const procsssUserInfo = (prevUserInfo) => {
    if(prevUserInfo){
        return {
            ...prevUserInfo,
            emailValidateCode: null,
            emailVerifyStatus: null 
        }
    } else {
        return null
    }
}

const userInfoReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: payload
            }
        case SET_EMAIL_CODE:
            return {
                ...state,
                userInfo: state.userInfo === null ? {emailValidateCode: payload, emailVerifyStatus: "NOT_VERIFYED" }  : {
                    ...state.userInfo,
                    emailValidateCode: payload,
                    emailVerifyStatus: "NOT_VERIFYED"
                }
            }
        case SET_VERIFY_STATUS:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    emailVerifyStatus: payload
                }
            }
        case RESET_EMAIL_VERIFICATION:{
            return {
                ...state,
                userInfo: procsssUserInfo(state.userInfo)
            }
        }
        case `${FETCH_USER_INFO}_PENDING`:
            return {
                ...state,
                userInfoIsLoading: true,
                userInfoError: false,
            }
        case `${FETCH_USER_INFO}_FULFILLED`:
            return {
                ...state,
                userInfo: payload,
                userInfoIsLoading: false,
                userInfoError: false,
            }
        case `${FETCH_USER_INFO}_REJECTED`:
            return {
                ...state,
                userInfo: null,
                userInfoIsLoading: false,
                userInfoError: true,
            }
        default:
            return state;
    }
};

export default userInfoReducer;
