/* ui */
export const UPDATE_CURRENT_HELP_TAB='UPDATE_CURRENT_HELP_TAB';
export const UPDATE_CURRENT_HELP_MODE='UPDATE_CURRENT_HELP_MODE';
export const UPDATE_CURRENT_HELP_TAB_AND_MODE='UPDATE_CURRENT_HELP_TAB_AND_MODE';
export const TOGGLE_HELP_MENU='TOGGLE_HELP_MENU';
export const SET_MAIN_MENU = 'SET_MAIN_MENU';
export const SET_SUB_MENU = 'SET_SUB_MENU';
export const SET_MAIN_SUB_MENU = 'SET_MAIN_SUB_MENU';
export const TOGGLE_AUTHWIN_OPEN='TOGGLE_AUTHWIN_OPEN';
export const INIT_AUTH_UI_STATE='INIT_AUTH_UI_STATE';
export const UI_START_LOADING='UI_START_LOADING';
export const UI_STOP_LOADING='UI_STOP_LOADING';
export const TOGGLE_ERRORWIN_OPEN='TOGGLE_ERRORWIN_OPEN';
export const INIT_ERRORWIN_STATE='INIT_ERRORWIN_STATE';
export const TOGGLE_SUCCESS_OPEN='TOGGLE_SUCCESS_OPEN';
export const INIT_SUCCESSWIN_STATE='INIT_SUCCESSWIN_STATE';
export const SET_SMART_BANNER_SHOW='SET_SMART_BANNER_SHOW';

/* appData */
export const FETCH_APP_DATA='FETCH_APP_DATA';

/* local */
export const SET_LOCAL='SET_LOCAL';

/* auth */
export const TRY_AUTH_START='TRY_AUTH_START';
export const TRY_AUTH_END='TRY_AUTH_END';
export const SET_AUTH='SET_AUTH';
export const SET_AUTH_ERROR='SET_AUTH_ERROR';
export const SET_AUTH_SUCCESS='SET_AUTH_SUCCESS';

/* userMeta */
export const FETCH_USER_META='FETCH_USER_META';
export const FETCH_THE_USER_META='FETCH_THE_USER_META';
export const FETCH_THE_BUYER_META='FETCH_THE_BUYER_META';
export const ADD_USER_META_TO_LIST='ADD_USER_META_TO_LIST';
export const SET_ACCOUNT_INFO_TO_META='SET_ACCOUNT_INFO_TO_META';
export const SET_THE_PREFERENCE='SET_THE_PREFERENCE';
export const SET_THE_MAIL_SUBSCRIPTION='SET_THE_MAIL_SUBSCRIPTION';
export const SET_EMAIL_VERIFICATION_STATUS='SET_EMAIL_VERIFICATION_STATUS';
export const SET_CERT_STATUS_TO_META='SET_CERT_STATUS_TO_META';
export const CHANGE_EMAIL_AND_RELATED='CHANGE_EMAIL_AND_RELATED';

/* accountInfo */
export const FETCH_ACCOUNT_INFO='FETCH_ACCOUNT_INFO';
export const SET_ACCOUNT_INFO='SET_ACCOUNT_INFO';

/* userInfo */
export const FETCH_USER_INFO='FETCH_USER_INFO';
export const SET_USER_INFO='SET_USER_INFO';
export const SET_EMAIL_CODE='SET_EMAIL_CODE';
export const SET_VERIFY_STATUS='SET_VERIFY_STATUS';
export const RESET_EMAIL_VERIFICATION='RESET_EMAIL_VERIFICATION';

/* bank */
export const FETCH_BANK='FETCH_BANK';
export const SET_BANK='SET_BANK';

/* gigs */
export const FETCH_GIGS='FETCH_GIGS';
export const FETCH_THE_GIG='FETCH_THE_GIG';

/* estimate */
export const DATE_SELECTED='DATE_SELECTED';

/* search */
export const CHANGE_SEARCH_QUERY='CHANGE_SEARCH_QUERY';
export const ADD_ITEM_TO_SEARCH='ADD_ITEM_TO_SEARCH';
export const SET_SEARCH_SERVICE_INIT='SET_SEARCH_SERVICE_INIT';
export const ADD_ADDRESS_TO_SEARCH='ADD_ADDRESS_TO_SEARCH';
export const SET_ADDRESS_BY_KEY='SET_ADDRESS_BY_KEY';
export const SET_PRICE_BY_KEY='SET_PRICE_BY_KEY';
export const SET_DATE_TO_SEARCH='SET_DATE_TO_SEARCH';
export const RESET_SEARCH='RESET_SEARCH';
export const SET_INDICATOR_TO_SEARCH='SET_INDICATOR_TO_SEARCH';

/* userAddress */
export const FETCH_USER_ADDRESS='FETCH_USER_ADDRESS';
export const SET_USER_ADDRESS='SET_USER_ADDRESS';
export const SET_THE_USER_ADDRESS='SET_THE_USER_ADDRESS';
export const DELETE_THE_USER_ADDRESS='DELETE_THE_USER_ADDRESS';

/* certificate */
export const FETCH_CERTIFICATE='FETCH_CERTIFICATE';
export const SET_CERTIFICATE='SET_CERTIFICATE';
export const SET_THE_CERTIFICATE='SET_THE_CERTIFICATE';

/* payment */
export const FETCH_PAYMENT='FETCH_PAYMENT';
export const SET_PAYMENT='SET_PAYMENT';

/* tosAcceptance */
export const FETCH_TOS_ACCEPTANCE='FETCH_TOS_ACCEPTANCE';

/* bankData */
export const FETCH_BANK_DATA='FETCH_BANK_DATA';

/* userLocationAddress */
export const FETCH_USERS_LOCATION='FETCH_USERS_LOCATION';

/* salse */
export const FETCH_THE_SALES='FETCH_THE_SALES';

/*blog */
export const FETCH_BLOG='FETCH_BLOG';
export const SET_BLOG='SET_BLOG';