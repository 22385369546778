import {
    FETCH_APP_DATA
} from './actionTypes';

import AppDataApi from '../../constants/AppDataApi';

const appDataApi = new AppDataApi();

export const fetchAppDataPending = () => ({
    type: `${FETCH_APP_DATA}_PENDING`
});

export const fetchAppDataRejected = (err) => ({
    type: `${FETCH_APP_DATA}_REJECTED`,
    payload: err
});

export const fetchAppDataFulFilled = (AppData) => ({
    type: `${FETCH_APP_DATA}_FULFILLED`,
    payload: AppData
});

export const fetchAppData = () => {
    return async dispatch => {
        dispatch(fetchAppDataPending());
        try{
            let AppData = await appDataApi.fetchAppData();
            dispatch(fetchAppDataFulFilled(AppData))
            return AppData;
        } catch(err){
            dispatch(fetchAppDataRejected(err));
            throw err
        }
    }   
};