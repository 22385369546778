import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class BannedBehaivors extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>禁止行為</span>
            </h3>
            <h4 className={styles.HeaderMargin15}>サービスの出品</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>法律、法令、条例に違反するサービスを出品する行為</li>
                    <li>他の会員に誤解を生じさせる虚偽又は誇張した内容の記載をする行為</li>
                    <li>サービスと適合しないカテゴリに出品する行為</li>
                    <li>サービス提供に必要な資格や届出がない状態でサービス提供する行為</li>
                    <li>完全同一のサービスを複数出品する行為</li>
                    <li>第三者の出品を代行する行為</li>
                    <li>販売する意思がないにも関わらず出品する行為</li>
                    <li>他の会員の写真や文章を無断で使用する行為</li>
                </ul>
            </div>
            <h4 className={styles.HeaderMargin15}>取引</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>タグジャムの取引で取得した他の会員の個人情報や取引・質問チャットの内容を、取引を履行する目的以外のために利用または外部に公開する行為</li>
                    <li>自己紹介欄やサービス内容にタグジャムを介さずに取引することを目的に個人情報を記載する行為</li>
                    <li>タグジャムの取引・質問チャットに、メールアドレスや、その他SNSの直接の連絡先、電話番号などを入力する行為</li>
                    <li>他サイトの登録フォームへ誘導し会員登録、または個人情報を登録させる行為</li>
                    <li>完全同一のサービスを複数出品する行為</li>
                    <li>タグジャム内の決済を利用せずに、タグジャム外で決済を行う行為</li>
                    <li>成果報酬や月額払い等の独自の支払を促す行為</li>
                    <li>次回以降の取引を外部で行うように持ちかける行為</li>
                    <li>決済が可能なサイトを記載し、そちらへ誘導する行為</li>
                    <li>マネーロンダリングを目的とした行為</li>
                    <li>サービスを出品者自らが購入する行為</li>
                    <li>サービスが未提供又は不十分な状態で一方的に取引を中止する行為</li>
                </ul>
            </div>
            <h4 className={styles.HeaderMargin15}>その他禁止行為</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>迷惑行為</li>
                    <ul>
                        <li>誹謗中傷行為</li> 
                        <li>脅迫行為</li>    
                        <li>差別行為</li>    
                        <li>他人に不快感を与える行為</li> 
                        <li>他人に不利益・損害を与える行為</li>   
                    </ul>
                    <li>公序良俗に反する行為</li>
                    <ul>
                        <li>性的発言</li> 
                        <li>露出度の高い画像の利用</li>    
                        <li>青少年に悪影響を与える行為</li>    
                        <li>当社がわいせつであると判断する行為</li> 
                    </ul>
                    <li>タグジャム競合の宣伝又は営業する行為</li>
                    <li>宗教活動にあたる行為</li>
                    <li>選挙活動にあたる行為</li>
                    <li>複数のアカウントを登録・所持する行為</li>
                    <li>利用規約に反する行為</li>
                    <li>ご利用ガイドに反する行為</li>
                    <li>タグジャムの運営を妨害する行為</li>
                    <li>タグジャムの信用を失墜、毀損させる行為</li>
                    <li>青少年に悪影響を与える行為</li>
                </ul>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BannedBehaivors);