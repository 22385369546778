import React from 'react';
import styles from './SideDrawer.module.css';
import Aux from '../../../hoc/Aux/Aux';
import NavigationItems from '../../NavigationItems/NavigationItems';
import LogoWithTypeFace from '../../Brand/LogoWithTypeFace/LogoWithTypeFace';
import CloseBtn from '../../UI/CloseBtn/CloseBtn';
import Backdrop from '../../UI/Backdrop/Backdrop';


const sideDrawer = (props) => {
    let attachStyles = [ styles.SideDrawer, styles.Close];
    
    if(props.showSideDrawer){
        attachStyles = [ styles.SideDrawer, styles.Open ];
    }

    return(
        <Aux>
            <Backdrop 
                show={props.showSideDrawer}
                clicked={props.closed}/>
            <div className={attachStyles.join(' ')}>
                <div style={{textAlign:"right", paddingRight: '15px'}}>
                    <CloseBtn clicked={props.closed} fontSize={20} color="gray"/>
                </div>
                <div className={styles.Brand}>
                    <LogoWithTypeFace />
                </div>
               <NavigationItems
                    onToggleAuthWinOpen={props.onToggleAuthWinOpen}
                    onInitAuthUiState={props.onInitAuthUiState}
                    onLogout={props.onLogout}
                    uiAuth={props.uiAuth}
                    authInfo={props.authInfo}
                    appData={props.appData}
                    localCode={props.localCode}
                    history={props.history}
                    closed={props.closed}
               /> 
            </div>
        </Aux>
    );
}

export default sideDrawer;