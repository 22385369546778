import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SearchTagButton.module.scss';

const SearchTagButton = props => {
  const { 
      SubCat, 
      selectedSubMenu, 
      localCode, 
      appData:{LocalStrings},  
  } = props;
  return(
    <NavLink
      to={`/blog/taggedArticles/${SubCat}`}
      className={selectedSubMenu===SubCat ? `${styles.SubCatButton} ${styles.SubCatSelected}` : styles.SubCatButton} 
    >
      <p className={styles.ButtonText}>
          {LocalStrings[localCode][SubCat]}
      </p>
    </NavLink>
  );
}


export default SearchTagButton;