import React from 'react';
import styles from './Step.module.css';
import PointRight from '../../../../assets/images/pointer_right.svg';
import PointLeft from '../../../../assets/images/pointer_left.svg';

const IconStyle = (role) => {
    const style =  {
        color: role === 'provider' ? '#ffccb4' : '#b7e6f7', 
        fontSize:'15px',
        backgroundColor: '#ffffff',
        width:'26px',
        height:'26px',
        borderRadius: '13px',
        marginRight: '5px',
        display:'inline-block'
    }
    return <div style={style}><i className="fa fa-user" /></div> 
}

const step = props => {

    let color = props.side==='left' ?  '#ffccb4' : '#b7e6f7';
    
    const ContentBorder = {border: `1px solid ${color}`};
    const HeadBgStyle = {backgroundColor: color};
    const ContentStyle = props.special 
            ? `${styles.StepContent} ${styles.BgSpecial}`
            : `${styles.StepContent} ${styles.BgWhite}` 

    const titleIcon = props.side==='left' ? IconStyle('provider') : IconStyle('customer');

    const Content = (
        <div key={`${props.title}-content`} className={ContentStyle} style={ContentBorder}>
            <h4 className={styles.StepTitle}  style={HeadBgStyle}>{titleIcon}{props.title}</h4>
            {props.children}
        </div>
    );
    const PointerRight = (<img key={`${props.title}-PointRight`} className={styles.Pointer} src={PointRight} alt="右を指す"/>);
    const PointerLeft = (<img key={`${props.title}-PointLeft`} className={styles.Pointer} src={PointLeft} alt="左を指す"/>);
   
    let StepContents = props.pointer === 'right' ?  [Content, PointerRight] : [PointerLeft, Content];

    let StepClass = props.side === 'left' 
                    ? styles.StepLeft
                    : styles.Step
    
    if(props.marginTop) {
        StepClass = StepClass +  ` ${styles.MarginTop60}`;
    }

    return(
    <div className={StepClass}>
        {StepContents} 
    </div>
    )
}

export default step;