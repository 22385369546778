import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import BuyerFinishService1 from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_help/buy_finish_service_help_1.jpg';
import BuyerFinishService1Rent from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_rent/buy_finish_service_rent_1.jpg';
import BuyerFinishService1Make from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_make/buy_finish_service_make_1.jpg';

import BuyerFinishService2 from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_help/buy_finish_service_help_2.jpg';
import BuyerFinishService2Rent from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_rent/buy_finish_service_rent_2.jpg';
import BuyerFinishService2Make from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_make/buy_finish_service_make_2.jpg';

import BuyerFinishService3Rent from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_rent/buy_finish_service_rent_3.jpg';
import BuyerFinishService3Make from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_make/buy_finish_service_make_3.jpg';

import BuyerFinishService4Rent from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_rent/buy_finish_service_rent_4.jpg';
import BuyerFinishService4Make from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_make/buy_finish_service_make_4.jpg';

import BuyerFinishService5 from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_help/buy_finish_service_help_3.jpg';
import BuyerFinishService5Rent from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_rent/buy_finish_service_rent_5.jpg';
import BuyerFinishService5Make from '../../../assets/images/help/buying/buy_finish_service/buy_finish_service_make/buy_finish_service_make_5.jpg';


class BuyerFinishService extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービスを提供完了（{this.getCategory()}）</span>
            </h3>
            {currentTab === 'HELP' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            サービスの提供が終わるとサービス完了のメッセージがきます。以下の手順でサービス完了メッセージを確認できます。
            </p>
            </Aux>
            }
            {currentTab === 'TEACH' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            特にクラス完了の手続きはありません。請求書の確認と支払いに進んでください。
            </p>
            </Aux>
            }
            {currentTab === 'RENT' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            以下の手順でレンタル品の返却を行ってください。
            </p>
            </Aux>
            }
            {currentTab === 'MAKE' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            作品が完成するとメッセージがきます。以下の手順で作品の受け取りを行ってください。
            </p>
            </Aux>
            }

            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    { currentTab!=='TEACH' && 
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。    
                    </li>
                    }
                    { currentTab==='HELP' && 
                    <img className={styles.ScreenShot} src={BuyerFinishService1} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='RENT' && 
                    <img className={styles.ScreenShot} src={BuyerFinishService1Rent} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerFinishService1Make} alt="サービスを提供をするステップ1画像" />
                    }

                    {currentTab === 'HELP' && 
                    <li>
                        「サービスが完了されました」と表示された取引をタップします。
                    </li>
                    }
                    {currentTab === 'MAKE' && 
                    <li>
                        「作品が完了されました」と表示された取引をタップします。   
                    </li>
                    }
                    {currentTab === 'RENT' && 
                    <li>
                    「レンタル品を受け取りました」と表示された取引をタップします。
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={BuyerFinishService2} alt="サービスを提供をするステップ2画像" />
                    }
                    {currentTab === 'RENT' && 
                    <img className={styles.ScreenShot} src={BuyerFinishService2Rent} alt="サービスを提供をするステップ2画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerFinishService2Make} alt="サービスを提供をするステップ2画像" />
                    }

                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    サービス提供者（貸し手）にレンタル品を引き渡し「レンタル品返却」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerFinishService3Rent} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    「作品が完成されました」のメッセージが確認できます。配達オプションを依頼した人は配達日時を、配達オプションがない人は引き渡しの場所と日時を、取引チャットで売り手の方と話し合って決めてください。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerFinishService3Make} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }
                    
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    「レンタル品を返却しました」のメッセージが確認できます。
                    </li>
                    <img className={styles.ScreenShot} src={BuyerFinishService4Rent} alt="サービスを提供をするステップ5画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    商品が引き渡されると「作品が引き渡しされました」のメッセージが確認できます。作品に修正などが必要な場合は、サービス提供者に交渉してください。作品に問題がなければ「作品受け取り完了」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={BuyerFinishService4Make} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }

                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                    「サービスの完了」メッセージを確認することができます。                
                    </li>
                    <img className={styles.ScreenShot} src={BuyerFinishService5} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    サービス提供者（貸し手）と一緒にレンタル品のチェックをしましょう。もしクリーニング、損傷、修理などのレンタル時適用料金がかかる場合はサービス提供者からその旨伝えられます。返却が完了すると以下のメッセージが確認できます。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerFinishService5Rent} alt="サービスを提供をするステップ5画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    「作品を受け取りました」のメッセージが確認できます。            
                    </li>
                    <img className={styles.ScreenShot} src={BuyerFinishService5Make} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BuyerFinishService);