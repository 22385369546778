import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import Bank0 from '../../../assets/images/help/account/bank.png'
import Bank1 from '../../../assets/images/help/account/bank/bank_1.png'
import Bank2 from '../../../assets/images/help/account/bank/bank_2.png'
import Bank3 from '../../../assets/images/help/account/bank/bank_3.png'
import Bank4 from '../../../assets/images/help/account/bank/bank_4.png'

class RegisterBank extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>銀行を登録する</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムでサービスを出品するには売上金を入金する銀行の登録が必要です。登録は以下の手順で行います。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「振込銀行」を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={Bank0} alt="銀行を登録するステップ1画像" />
                    <li>
                        銀行名の一部を入力します。銀行リストより、自分の銀行を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={Bank1} alt="銀行を登録するステップ2画像" />
                    <li>
                        支店名の一部を入力します。支店リストより、自分の支店を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={Bank2} alt="銀行を登録するステップ3画像" />
                    <li>
                        普通・当座のいずれかを選び、口座番号、口座名義（姓と名の間はスペースを入れカタカナで入力する）。ひらがなで入力した場合でも「カタカナ変換」ボタンをタップすることでカタカナに変換できます。入力内容を確認して「保存」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={Bank3} alt="銀行を登録するステップ4画像" />
                    <li>
                        「データが保存されました」のメッセージが出たら登録は完了です。「戻る」ボタンで元の画面に戻ることができます。
                    </li>
                    <img className={styles.ScreenShot} src={Bank4} alt="銀行を登録するステップ5画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(RegisterBank);