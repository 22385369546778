import React from 'react';
import styles from './PriceTagSvg.module.scss';
import HELP from '../../../assets/images/priceLeftHELP.svg';
import TEACH from '../../../assets/images/priceLeftTEACH.svg';
import RENT from '../../../assets/images/priceLeftRENT.svg';
import MAKE from '../../../assets/images/priceLeftMAKE.svg';

const PirceTagSvg = (props) => {
    const { height, type, borderRadius} = props;
    const BGimg = {
        'HELP': HELP,
        'TEACH': TEACH,
        'RENT': RENT,
        'MAKE': MAKE
    };
    return(
        <div
            className={`${styles.PriceTagSvg}`}
            style={{
                height: `${height}px`,
                backgroundImage: `url(${BGimg[type]})`,
                borderRadius:borderRadius
            }}
        >
            <div style={{width:`${height}px`}} />
            <div className={styles[type]} style={{flex:3, borderRadius:borderRadius}} />
        </div>
    );
}

export default PirceTagSvg;