import React from 'react';
import styles from './NAMessage.module.scss';
import { TagSvg } from '../../../components/UI'; 

const NAMessage = props => {
    // eslint-disable-next-line no-unused-vars
    const {type, goBackOnPress, goSellOnPress, localCode, mode, appData } = props;
    const { Colors, LocalStrings} = appData;
    return (
        <div className={styles.NAContainer}>
            <div className={styles.NAContentContainer}>
                <div className={styles.TagSvgContainer}>
                    <TagSvg active={false} /> 
                    <i
                        className="fa fa-times-circle-o"
                        style={{position:'absolute', fontSize:'40px', marginLeft:'5px', color:Colors.contentFontTintColor}}/> 
                </div>
                <div className={styles.MessageContainer}>
                    <p className={styles.MessageText}>
                        {LocalStrings[localCode][type]}
                    </p>
                </div>
                <div 
                    className={styles.ButtonContainer}
                    onClick={ goBackOnPress }>
                    <p className={styles.ButtonText}>
                        {LocalStrings[localCode].GO_BACK}
                    </p>
                </div>
                {/* { mode==='Catalog' && 
                    <div
                        className={styles.ButtonContainer} 
                        style={{borderWidth:0, marginTop:0, backgroundColor:Colors.primaryButtonBGColor}}
                        onClick={ goSellOnPress }>
                        <p className={styles.ButtonText}  style={{color:Colors.white}}>
                            {LocalStrings[localCode].TO_SELL_BUTTON}
                        </p>
                    </div> 
                */}
             </div>
        </div>
    );
}

export default NAMessage;