import { createStore, combineReducers, applyMiddleware, compose  } from 'redux';
import thunk from 'redux-thunk';
import uiReducer from './reducers/ui';
import appDataReducer from './reducers/appData';
import localReducer from './reducers/local';
import authReducer from './reducers/auth';
import userMetaReducer from './reducers/userMeta';
import accountInfoReducer from './reducers/accountInfo';
import bankReducer from './reducers/bank';
import userInfoReducer from './reducers/userInfo';
import gigsReducer from './reducers/gigs';
import estimateReducer from './reducers/estimate';
import searchReducer from './reducers/search';
import userAddressReducer from './reducers/userAddress';
import certificateReducer from './reducers/certificate';
import paymentReducer from './reducers/payment';
import tosAcceptanceReducer from './reducers/tosAcceptance';
import bankDataReducer from './reducers/bankData';
import userLocationAddressReducer from './reducers/userLocationAddress';
import salesReducer from './reducers/sales';
import blogReducer from './reducers/blog';

let composeEnhancers = process.env.NODE_ENV === 'development' 
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
    : null;

if(!composeEnhancers){
    composeEnhancers = compose;   
}

const rootReducer = combineReducers({
    ui: uiReducer,
    appData: appDataReducer,
    local: localReducer,
    auth: authReducer,
    userMeta: userMetaReducer,
    accountInfo: accountInfoReducer,
    userinfo: userInfoReducer,
    bank:bankReducer,
    gigs:gigsReducer,
    estimate:estimateReducer,
    search: searchReducer,
    userAddress: userAddressReducer,
    certificate: certificateReducer,
    payment:paymentReducer,
    tosAcceptance: tosAcceptanceReducer,
    bankData: bankDataReducer,
    userLocationAddress: userLocationAddressReducer,
    sales: salesReducer,
    blog: blogReducer
});

const configureStore = () => {
    return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
}

export default configureStore;