import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import Certificate0 from '../../../assets/images/help/account/certificate.png';
import Id0 from '../../../assets/images/help/account/certificate/id.png';
import Id1 from '../../../assets/images/help/account/certificate/id/id_1.jpg';
import Id2 from '../../../assets/images/help/account/certificate/id/id_2.jpg';
import Id3 from '../../../assets/images/help/account/certificate/id/id_3.jpg';
import Id4 from '../../../assets/images/help/account/certificate/id/id_4.jpg';
import Id5 from '../../../assets/images/help/account/certificate/id/id_5.jpg';
import Id6 from '../../../assets/images/help/account/certificate/id/id_6.jpg';
import Id7 from '../../../assets/images/help/account/certificate/id/id_7.jpg';

class IdConfirmation extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>本人確認</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムでは、なりすましなどを防ぐために、全てのユーザーに免許証、保険証、マイナンバーカードなど本人が確認できる書類の画像を提出する事をお願いしています。画像の提出は以下の手順で行ってください。<br/>
            <span className="Caption">＊本人確認が未提出の場合はタグジャムでの取引は開始できません。</span>
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「各種確認書類」を選びます。    
                    </li>
                    <img className={styles.ScreenShot} src={Certificate0} alt="本人確認ステップ1画像" />
                    <li>
                        「本人確認」をタップします。 
                    </li>
                    <img className={styles.ScreenShot} src={Id0} alt="本人確認ステップ1画像" />
                    <li>
                        本人確認画面が表示されます。ユーザー情報を登録済みでない方は「ユーザー情報を設定する＞」をタップして登録してください。登録が済んだら　1.「写真を提出」 をタップしてください。<br/>
                        <span className="Caption">＊ユーザー情報は確認書類と一致する必要があります。正確でない場合は「ユーザー情報を編集する＞」で編集して下さい。</span>   
                    </li>
                    <img className={styles.ScreenShot} src={Id1} alt="本人確認ステップ2画像" />
                    <li>
                        「カメラで撮影する」、「写真を選択」のどちらかを選び、本人確認書類を撮影・選択してください。    
                    </li>
                    <img className={styles.ScreenShot} src={Id2} alt="本人確認ステップ3画像" />
                    <li>
                        選択・撮影されて画像が表示されたら、位置を調節し、「この写真を選択」をタップします。
                        <span className="Caption">＊画像が不鮮明、切れている場合は認証されませんので、撮影の場合は明るい部屋で、鮮明な画像を撮影して下さい。</span>    
                    </li>
                    <img className={styles.ScreenShot} src={Id3} alt="本人確認ステップ4画像" />
                    <li>
                        住所変更などが裏面に記載されている場合は　2.「写真を提出」 をタップして裏面などの画像の撮影・選択もお願いします。
                        免許証、パスポートを使用する場合は、有効期限「あり」を選択肢し、期限を入れて下さい。運転経歴書、健康保険証の場合は、有効期限「なし」を選択して下さい。「提出」をボタンをタップして下さい。    
                    </li>
                    <img className={styles.ScreenShot} src={Id4} alt="本人確認ステップ5画像" />
                    <li>
                        「確認中...」と表示されます。    
                    </li>
                    <img className={styles.ScreenShot} src={Id5} alt="本人確認ステップ6画像" />
                    <li>
                        本人確認の結果は２４時間以内に表示されます。タグジャム当局が書類の確認が終わると「確認済み」と表示されます。これで本人確認は終了です。       
                    </li>
                    <img className={styles.ScreenShot} src={Id6} alt="本人確認ステップ7画像" />
                    <p>＊未確認と表示された場合は、理由が表示されますので訂正の上、書類の再提出を行って下さい。</p>
                    <img className={styles.ScreenShot} src={Id7} alt="本人確認ステップ8画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(IdConfirmation);