import React from 'react';
import Aux from '../../../hoc/Aux/Aux';
import styles from './Header.module.css';
import WhatTaguJamBG from '../../../assets/images/what_bg.svg';

const header = () => {
    return(
        <Aux>
            <div className={styles.MainContent}>
                <h2>タグジャムとは？</h2>
                <h5>タグジャムはだれでも参加できる<br/>個人の「手伝う」にタグを付けて<br/>売り買いするフリマアプリです</h5>
            </div>
            <div style={{width:'320px', margin:'0 auto', padding:'0'}}>
                <img className={styles.MainContentImage} src={WhatTaguJamBG} alt="タグイメージ" />
            </div>
        </Aux>
    );
}

export default header;