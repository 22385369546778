/**
 * Calender Render functions
 */
import React from 'react';
import moment from 'moment';
import {getRoundUpXmin} from '../../utils'

export const renderRow = (calender, renderDayButton) =>{
    return(
        <div>
            {
                calender.map( (week, i) => {
                    return(
                        <div key={`week_${i}`} style={{ display:"flex", paddingBottom:'5px', width:'100%', flexDirection:'row', justifyContent:"space-between", alignItems:"center"}}>
                            { renderDayButton(week)}            
                        </div>
                    );
                })
            }
        </div>
    )
}

export const renderDayHead = (localCode, appData) => { 
    const { Colors,  LocalStrings} = appData;
    const Days = [
        LocalStrings[localCode].SUN,
        LocalStrings[localCode].MON,
        LocalStrings[localCode].TUE,
        LocalStrings[localCode].WED,
        LocalStrings[localCode].THU,
        LocalStrings[localCode].FRI,
        LocalStrings[localCode].SAT
    ];
    return(
        <div style={{ display:"flex", paddingBottom:'5px', width:'100%', flexDirection:'row', justifyContent:"space-between", alignItems:"center"}}>
            {
                Days.map( (day, index) => {
                    let fontColor = Colors.contentFontColor;
                    if(index === 0 ){
                        fontColor = Colors.redColor;    
                    }
                    if(index===6){
                        fontColor = Colors.blue
                    }
                    return (
                        <div key={day} style={{display:"flex",flexDirection:'column', flex:1, justifyContent:'center', alignItems:'center'}}>
                            <div 
                                style={{
                                    display:"flex",
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:"center",
                                    padding:'2px',
                                    marginBottom:'5px', 
                                    backgroundColor:'transparent',
                                    width:'97%'
                                }}
                            >
                                <p style={{fontSize:'12px', fontWeight:"bold", color:fontColor}}>{day}</p>
                            </div>
                        </div>
                    );   
                })
            }      
        </div>
    );
}
 
export const getCalenderData = (calenderDate) => {
    const today = calenderDate || new Date();
    const DateObj = {
        firstDay : new Date(today.getFullYear(), today.getMonth(), 1),
        lastDay : new Date(today.getFullYear(), today.getMonth()+1, 0),
        prevlastDay : new Date(today.getFullYear(), today.getMonth(), 0)
    }

    let monthData = [];
    let week = [];
    let date = 1;
    let nextMonth1stDate = 1;
    let counter = 0;
    let dayofWeekNum = DateObj.firstDay.getDay();
    let prevLastDate = DateObj.prevlastDay.getDate();
    let prevDate = prevLastDate - (dayofWeekNum-1);

    while( date < DateObj.lastDay.getDate()+1 ){
        while(counter < 7){
            if(counter < dayofWeekNum){
                week[counter] = new Date(today.getFullYear(), today.getMonth()-1, prevDate);
                prevDate++;    
            } else {
                dayofWeekNum=0
                if(date > DateObj.lastDay.getDate()){
                    week[counter] = new Date(today.getFullYear(), today.getMonth()+1, nextMonth1stDate);
                    nextMonth1stDate++
                } else {
                    week[counter] = new Date(today.getFullYear(), today.getMonth(), date);
                }
                date++    
            }
            counter++; 
        }
        monthData.push(week);
        counter = 0;
        week=[];    
    }

    return {
        monthData,
        today
    }
}

export const getAvailableTimeList = (Day, theGigWEvents, acceptedMinutes) => {
    const { MainCat, events,
        feeInfo: { MAX_PERSON }, 
        schedule: {weekShift},
        indicator:{VISIT_TYPE, AVAILABLE_NOW}, 
        hoursBeforeOrder,
        user
    } = theGigWEvents;
    const CalenderType = renderCalenderType(theGigWEvents);
    const eventsOftheDay = getEventsOftheDay(Day, events, MainCat);
    const onShift = weekShift[Day.getDay()];
    const shiftList = getShiftList(onShift, Day);

    let availTimeList = [];
    if(CalenderType === 'SEAT') {
        shiftList.map( shift => {
          let eventOfTheShift = getClassEventsOfTheShift(
            eventsOftheDay,
            shift,
            user
          );
          let noneClassEventsOfTheShift = getNoneClassEventsOfTheShift(
            eventsOftheDay,
            shift,
            user
          );
          if (noneClassEventsOfTheShift.length === 0) {
            if (eventOfTheShift.length < MAX_PERSON) {
              let today = moment();
              if (today.isSame(shift.start, 'day')) {
                if (AVAILABLE_NOW) {
                  let minsBeforeOrder = parseFloat(hoursBeforeOrder) * 60;
                  let now = today.add(minsBeforeOrder, 'minutes');
                  if (moment(shift.start).isAfter(now)) {
                    availTimeList.push([shift]);
                  }
                }
              } else {
                availTimeList.push([shift]);
              }
            }
          }
          return true;
        });
    } else {
        shiftList.map( shift => {
            let eventOfTheShift = getEventsOfTheShift(eventsOftheDay, shift);
            let availTime = hasAvailabeTime(eventOfTheShift, shift, acceptedMinutes, VISIT_TYPE, AVAILABLE_NOW, hoursBeforeOrder);
            if(availTime.length > 0){
                availTimeList.push(availTime);
            }
            return true;
        });
    }

    return availTimeList;
}

export const isEnoughTime = (shift, start, end, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder) => {
    
    let minTransportTime = 30;
    const acceptedSec = acceptedMinutes * 60  * 1000;
    let availTime = null;
    let today = moment(); //moment('18/11/2020 17:36:00', "DD/MM/YYYY hh:mm:ss"); //

    if(today.isSame(start, 'day')){
        if(AVAILABLE_NOW){
            let minsBeforeOrder = (parseFloat(hoursBeforeOrder) * 60); 
            let orderCanStart = today.add(minsBeforeOrder, 'minutes').toDate();
            let orderCanStart_hr = getRoundUpXmin(15, orderCanStart); // round up to next 15 minutes; 
            let start_hr = orderCanStart_hr.getTime() > start.getTime() ? orderCanStart_hr : start;
            const isShiftStart = shift.start.getTime() === start_hr.getTime();
            if(isVisitType && isShiftStart===false){
                start_hr = moment(start_hr).add(minTransportTime, 'minutes').toDate();
            }
            const duration = end.getTime() - start_hr.getTime();
            if(acceptedSec <= duration){
                availTime = {start:start_hr, end:end};    
            } 
        } 
    } else {
        let start_hr = start;
        const isShiftStart = shift.start.getTime() === start.getTime() ? true : false;
        if(isVisitType && isShiftStart===false) {
            start_hr = moment(start).add(minTransportTime, 'minutes').toDate();  
        }
        const duration = end.getTime() - start_hr.getTime();
        if(acceptedSec <= duration){
            availTime = {start:start_hr, end:end}
        };   
    }
    
    return availTime;
}

export const compareTime = (a , b) => {
    let a_start = a.start ? a.start : a.bookStart;
    let b_start = b.start ? b.start : b.bookStart;
	if(a_start.getTime() < b_start.getTime()) return -1;
	if(a_start.getTime() > b_start.getTime()) return 1;
	return 0;
}

export const hasAfter6Option = (theGig) => {
    const {optionService, optionableService}  = theGig;
    let hasAfter6 = false;
    let key, serviceType;
    for(let keyName in optionService){
        if(keyName.includes('AFTER_6') && optionService[keyName]!==false){
            hasAfter6 = true;
            key = keyName;
            serviceType = "optionService";
            break;    
        }    
    }
    if(hasAfter6 === false){
        for(let keyName in optionableService){
            if(keyName.includes('AFTER_6') && optionableService[keyName]!==false){
                hasAfter6 = true;
                key = keyName;
                serviceType = "optionableService";
                break;    
            }    
        }
    }
    return { hasAfter6, key, serviceType };
}

export const renderCalenderType = (theGig) => {
    const {
      MainCat,
      indicator: {VISITOR_TYPE},
      feeInfo: {unit},
    } = theGig;
    let type = 'DEFAULT';
    if (MainCat === 'TEACH') {
      if (VISITOR_TYPE) {
        if (unit === 'PERSON') {
          type = 'SEAT';
        }
      }
    }
    if(MainCat === 'RENT'){
        type = 'RENT';
    }
    return type;
}

export const hasAvailabeTime = (eventOfTheShift, shift, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder) => {
    let currentAve = []; 
        currentAve.push({...shift})
    let nextAve = [];
    let i = 0;
    let eventExist = false; 
    
    eventOfTheShift.map( (event, index) => {
        eventExist = true;
        if(index!==0){
            currentAve = [];
            currentAve.push(...nextAve);
            i = 0;
        } 
        while( i < currentAve.length ){
            if(currentAve[i].start.getTime() <= event.start.getTime() 
            &&  event.end.getTime() <=  currentAve[i].end.getTime() )
            {
                if(currentAve[i].start.getTime()===event.start.getTime() && currentAve[i].end.getTime()===event.end.getTime() ){
                    if(nextAve.length >0){
                        // eslint-disable-next-line no-loop-func
                        const idx = nextAve.findIndex( (availTime) => {
                            return ( availTime.start.getTime() === currentAve[i].start.getTime()
                                && availTime.end.getTime() === currentAve[i].end.getTime() );
                        });
                        if(idx!==-1){
                            nextAve.splice(idx, 1);        
                        }
                    }    
                } else if(currentAve[i].start.getTime()===event.start.getTime()){
                    if(nextAve.length >0){
                        const idx = nextAve.findIndex(availTime => availTime.start.getTime()===event.start.getTime());
                        let avTime = {start:event.end, end:currentAve[i].end}; // isEnoughTime(shift, event.end, currentAve[i].end, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder);
                        idx!==-1 ? nextAve[idx]=avTime : nextAve.push(avTime);
                    } else {
                        let avTime = {start:event.end, end:currentAve[i].end}; // isEnoughTime(shift, event.end, currentAve[i].end, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder);
                        nextAve.push(avTime);       
                    }
                } else if (currentAve[i].end.getTime()===event.end.getTime()){
                    if(nextAve.length >0){
                        const idx = nextAve.findIndex(availTime => availTime.end.getTime()===event.end.getTime());
                        let avTime = {start:currentAve[i].start, end:event.start}; // isEnoughTime(shift, currentAve[i].start, event.start, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder);
                        idx!==-1 ? nextAve[idx]=avTime : nextAve.push(avTime);
                    } else {
                        let avTime = {start:currentAve[i].start, end:event.start}; // isEnoughTime(shift, currentAve[i].start, event.start, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder); 
                        nextAve.push(avTime);
                    }
                } else {
                    if(nextAve.length >0){
                        // eslint-disable-next-line no-loop-func
                        const idx = nextAve.findIndex(availTime => {
                            return ( availTime.start.getTime() === currentAve[i].start.getTime()
                            && availTime.end.getTime() === currentAve[i].end.getTime() )
                        } );
                        let avTime1 = {start:currentAve[i].start, end:event.start}; // isEnoughTime(shift, currentAve[i].start, event.start, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder);
                        idx!==-1 ? nextAve[idx]={...avTime1} : nextAve.push(avTime1);
                        let avTime2 = {start:event.end, end:currentAve[i].end}; // isEnoughTime(shift, event.end, currentAve[i].end, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder);
                        nextAve.push(avTime2)
                    } else {
                        let avTime1 = {start:currentAve[i].start, end:event.start}; // isEnoughTime(shift, currentAve[i].start, event.start, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder);
                        nextAve.push(avTime1);
                        let avTime2 = {start:event.end, end:currentAve[i].end}; // isEnoughTime(shift, event.end, currentAve[i].end, acceptedMinutes, isVisitType, AVAILABLE_NOW, hoursBeforeOrder);
                        nextAve.push(avTime2)  
                    }
                }
            }
            i++      
        }
        return true;
    });

    if(eventExist){
        let availTimeList = [];
        nextAve.map((availTime) => {
          let enoughTime = isEnoughTime(
            shift,
            availTime.start,
            availTime.end,
            acceptedMinutes,
            isVisitType,
            AVAILABLE_NOW,
            hoursBeforeOrder
          );
          if (enoughTime) {
            availTimeList.push(enoughTime);
          }
          return true;
        });
        return availTimeList.length > 1
        ? availTimeList.sort(compareTime)
        : availTimeList;
    } else {
        let availTime = [];
        const acceptedSec = acceptedMinutes * 60  * 1000;
        let minTransportTime = 30;
        let today = moment(); //moment('18/11/2020 17:36:00', "DD/MM/YYYY hh:mm:ss");
        if(today.isSame(currentAve[0].start, 'day')){
            if(AVAILABLE_NOW){
                let minsBeforeOrder = (parseFloat(hoursBeforeOrder) * 60); 
                let orderCanStart = today.add(minsBeforeOrder, 'minutes').toDate();
                let orderCanStart_hr = getRoundUpXmin(15, orderCanStart); // round up to next 15 minutes; 
                let start_hr = orderCanStart_hr.getTime() > currentAve[0].start.getTime() ? orderCanStart_hr : currentAve[0].start;
                const isShiftStart = shift.start.getTime() === start_hr.getTime();
                if(isVisitType && isShiftStart===false){
                    start_hr = moment(start_hr).add(minTransportTime, 'minutes').toDate();
                }
                const duration = currentAve[0].end.getTime() - start_hr.getTime();
                if(acceptedSec <= duration){
                    availTime = [{start:start_hr, end:currentAve[0].end}];    
                } 
            } 
        } else {
            let start_hr = currentAve[0].start;
            const isShiftStart = shift.start.getTime() === start_hr.getTime();
            if(isVisitType && isShiftStart===false){
                start_hr = moment(start_hr).add(minTransportTime, 'minutes').toDate();
            }
            const duration = currentAve[0].end.getTime() - start_hr.getTime(); 
            if(acceptedSec <= duration){
                availTime = [{start:start_hr, end:currentAve[0].end}];    
            }    
        }
        
        return availTime;
    }
}

export const getEventsOftheDay = (Day, events, MainCat='ALL', includeTempEvent) => {
    const eventList  = getEventList(events, includeTempEvent, MainCat);
    let event;
    if(MainCat==='RENT'){
        // eslint-disable-next-line array-callback-return
        event = eventList.filter( eventObj => {
            if(eventObj.bookStart && eventObj.bookEnd){
                return eventObj.bookStart.getTime() <= Day.getTime() 
                    && Day.getTime() <= eventObj.bookEnd.getTime(); 
            }
        });
    } else {
        event = eventList.filter( eventObj => {
            const eventDate = moment(eventObj.start);
            const DayDate = moment(Day);
            return eventDate.isSame(DayDate, 'day');
        });    
    }
    return event;
}

export const getClassEventsOfTheShift = (eventsOftheDay, shift, providerId) => {
  const classEventsOfTheShift = [];
  eventsOftheDay.map((event) => {
    if (
      event.start &&
      event.end &&
      event.providerInfo &&
      event.providerInfo.id === providerId &&
      event.calenderType &&
      event.calenderType === 'SEAT'
    ) {
      if (event.start.getTime() <= shift.start.getTime()) {
        let eventData = {
          start: shift.start,
          end:
            shift.end.getTime() <= event.end.getTime() ? shift.end : event.end,
        };
        if (eventData.start.getTime() < eventData.end.getTime()) {
          classEventsOfTheShift.push(eventData);
        }
      } else if (shift.start.getTime() < event.start.getTime()) {
        let eventData = {
          start: event.start,
          end:
            shift.end.getTime() <= event.end.getTime() ? shift.end : event.end,
        };
        if (eventData.start.getTime() < eventData.end.getTime()) {
          classEventsOfTheShift.push(eventData);
        }
      }
    }
  });
  return classEventsOfTheShift;
};

export const getNoneClassEventsOfTheShift = (
  eventsOftheDay,
  shift,
  providerId
) => {
  const noneClassEventsOfTheShift = [];
  eventsOftheDay.map((event) => {
    const {providerInfo, calenderType} = event;
    let shouldInclude = true;
    if (providerInfo && providerInfo.id && providerInfo.id === providerId) {
      if (calenderType === 'SEAT') {
        shouldInclude = false;
      }
    }
    if (!event.start || !event.end) {
      shouldInclude = false;
    }

    if (shouldInclude) {
      if (event.start.getTime() <= shift.start.getTime()) {
        let eventData = {
          start: shift.start,
          end:
            shift.end.getTime() <= event.end.getTime() ? shift.end : event.end,
        };
        if (eventData.start.getTime() < eventData.end.getTime()) {
          noneClassEventsOfTheShift.push(eventData);
        }
      } else if (shift.start.getTime() < event.start.getTime()) {
        let eventData = {
          start: event.start,
          end:
            shift.end.getTime() <= event.end.getTime() ? shift.end : event.end,
        };
        if (eventData.start.getTime() < eventData.end.getTime()) {
          noneClassEventsOfTheShift.push(eventData);
        }
      }
    }
  });
  return noneClassEventsOfTheShift;
};

export const getEventsOfTheShift = (eventsOftheDay, shift) => {
    const eventOfTheShift = [];
    eventsOftheDay.map(event => {
        if(event.start && event.end){
          if (event.start && event.end) {
            if (event.start.getTime() <= shift.start.getTime()) {
              let eventData = {
                start: shift.start,
                end:
                  shift.end.getTime() <= event.end.getTime() ? shift.end : event.end,
              }
              if (eventData.start.getTime() < eventData.end.getTime()) {
                eventOfTheShift.push(eventData);
              }
            } else if (shift.start.getTime() < event.start.getTime()) {
              let eventData = {
                start: event.start,
                end:
                  shift.end.getTime() <= event.end.getTime() ? shift.end : event.end,
              }
              if (eventData.start.getTime() < eventData.end.getTime()) {
                eventOfTheShift.push(eventData);
              }
            }
          }   
        }
        return true;                     
    });
    return eventOfTheShift;   
}

const getShiftBlock = (theEvent) => {
  const {personal, shiftBlock} = theEvent;
  let newShiftBlock = false;
  if (!personal) {
    newShiftBlock = true;
  } else {
    if (shiftBlock) {
      newShiftBlock = shiftBlock;
    }
  }
  return newShiftBlock;
};

const includeEventList = (theEvent, includeTempEvent, MainCat) => {
  const {personal, shiftBlock, Temp} = theEvent;
  let include = true;
  if (!includeTempEvent) {
    if (!Temp) {
      if (personal && !shiftBlock) {
        include = false;
      }
      if (MainCat !== 'RENT' && theEvent.MainCat === 'RENT') {
        include = false; // DO NOT include RENT buyer event
      }
    } else {
      include = false;
    }
  }
  return include;
};

export const getEventList = (events, includeTempEvent, MainCat) => {
  const eventList = [];
  // includeTempEvent:true ACCOUNT/Schedule includes all events
  // includeTempEvent:false other screens
  // exclude following:
  // 1. personal/!shiftBlock
  // 2. RENT and buyer events
  for (let key in events) {
    if (includeEventList(events[key], includeTempEvent, MainCat)) {
      let eventData = {
        id: key,
        title: events[key].title ? events[key].title : null,
        personal: events[key].personal ? events[key].personal : false,
        shiftBlock: getShiftBlock(events[key]),
        start: events[key].blockTime
          ? new Date(events[key].blockTime.start_hr)
          : null,
        end: events[key].blockTime
          ? new Date(events[key].blockTime.end_hr)
          : null,
        bookStart: events[key].bookTime
          ? new Date(events[key].bookTime.start_hr)
          : null,
        bookEnd: events[key].bookTime
          ? new Date(events[key].bookTime.end_hr)
          : null,
        estimateId: events[key].estimateId ? events[key].estimateId : null,
        interviewId: events[key].interviewId ? events[key].interviewId : null,
        dealId: events[key].dealId ? events[key].dealId : null,
        gigId: events[key].gigId ? events[key].gigId : null,
        gigImage: events[key].gigImage ? events[key].gigImage : null,
        price: events[key].price ? events[key].price : null,
        Temp: events[key].Temp ? events[key].Temp : false,
        MainCat: events[key].MainCat ? events[key].MainCat : null,
        SubCat: events[key].SubCat ? events[key].SubCat : null,
        buyerInfo: events[key].buyerInfo ? events[key].buyerInfo : null,
        providerInfo: events[key].providerInfo
          ? events[key].providerInfo
          : null,
        calenderType: events[key].calenderType
          ? events[key].calenderType
          : null,
      };
      if (!eventData.start && eventData.bookStart) {
        eventData = {
          ...eventData,
          start: new Date(events[key].bookTime.start_hr),
        };
      }
      if (!eventData.end && eventData.bookEnd) {
        eventData = {
          ...eventData,
          end: new Date(events[key].bookTime.end_hr),
        };
      }
      eventList.push(eventData);
    }
  }
  return eventList.sort(compareTime);
};

export const getShiftList = (onShift, Day) =>{
    const shiftList= [];
    const theDayStr = moment(Day).format('YYYY-MM-DD');
    for(let key in onShift){
        let shiftStartTimeStr = moment(onShift[key].start_hr).format("HH:mm");
        let shiftEndTimeStr = moment(onShift[key].end_hr).format("HH:mm");
        let start = moment(`${theDayStr} ${shiftStartTimeStr}`).toDate();
        let end = moment(`${theDayStr} ${shiftEndTimeStr}`).toDate();
        shiftList.push({start, end});
    }
    return shiftList;
}
