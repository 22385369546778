import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import ResendInvoice1 from '../../../assets/images/help/selling/sell_resend_invoice/sell_resend_invoice_1.jpg';
import ResendInvoice2 from '../../../assets/images/help/selling/sell_resend_invoice/sell_resend_invoice_2.jpg';
import ResendInvoice3 from '../../../assets/images/help/selling/sell_resend_invoice/sell_resend_invoice_3.jpg';
import ResendInvoice4 from '../../../assets/images/help/selling/sell_resend_invoice/sell_resend_invoice_4.jpg';
import ResendInvoice5 from '../../../assets/images/help/selling/sell_resend_invoice/sell_resend_invoice_5.jpg';
import ResendInvoice6 from '../../../assets/images/help/selling/sell_resend_invoice/sell_resend_invoice_6.jpg';
import ResendInvoice7 from '../../../assets/images/help/selling/sell_resend_invoice/sell_resend_invoice_7.jpg';

class ResendInvoicee extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>支払い保留時の請求書の再送信</span>
            </h3>
            <p className={styles.ArticleDesc}>
            請求書の発信後に、サービス購入者（買い手）が請求金額に疑問などがある場合は「支払い保留」を送信する場合があります。その場合は、以下の手順で請求書を確認・編集をして再送信してください。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「売る」＞「取引中」を選びます。     
                    </li>
                    <img className={styles.ScreenShot} src={ResendInvoice1} alt="支払い保留時の請求書の再送信ステップ1画像" />
                    <li>
                        「支払い保留」と表示されている取引をタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={ResendInvoice2} alt="支払い保留時の請求書の再送信ステップ2画像" />
                    <li>
                        買い手の質問を読んで「請求書を再確認・編集する」をタップします。      
                    </li>
                    <img className={styles.ScreenShot} src={ResendInvoice3} alt="支払い保留時の請求書の再送信ステップ3画像" />
                    <li>
                        請求内容を確認し、問題がある場合は請求書内容を編集してください。「購入者も請求金額に同意してます」にチェックをしてください。最後に「請求書送信へ」をタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={ResendInvoice4} alt="支払い保留時の請求書の再送信ステップ4画像" />
                    <li>
                        「メッセージ」に質問などの返信や説明を入力し、「請求書送信」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={ResendInvoice5} alt="支払い保留時の請求書の再送信ステップ5画像" />
                    <li>
                        「会話に戻る」ボタンをタップします。   
                    </li>
                    <img className={styles.ScreenShot} src={ResendInvoice6} alt="支払い保留時の請求書の再送信ステップ6画像" />
                    <li>
                        「請求書送信済み」のメッセージを確認します。
                    </li>
                    <img className={styles.ScreenShot} src={ResendInvoice7} alt="支払い保留時の請求書の再送信ステップ7画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(ResendInvoicee);