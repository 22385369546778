import React, {Component} from 'react';
import styles from './ImageSlides.module.scss';
import Aux from '../../../hoc/Aux/Aux';

class ImageSlides extends Component {

    state = {
        selectedIndex: 0
    }

    setSelectedIndex = (event, n) => {
        if(event){
            event.preventDefault();
        }
        const {images} = this.props;
        let currentN = n;
        if (n > images.length-1) {
            currentN = 0
        }
        if (n < 0) {
            currentN = images.length-1
        }
        this.setState(prevState => {
            return {
                ...prevState,
                selectedIndex: currentN 
            }
        });
    }

    plusSlides = (event, n) => {
        event.preventDefault();
        const { selectedIndex } = this.state;
        const currentN = selectedIndex + n;
        this.setSelectedIndex(null, currentN);
    }
    
    render () {
        const {images} = this.props;
        const { selectedIndex } = this.state;
        return(
            <div style={{ height:"100%", width:"100%", position:'relative'}}>
                { images.map( (image, index) => {
                    return (
                    <div 
                        key={image.ImagePath} 
                        className={`${styles.GigImage} Fade`} 
                        style={ {backgroundImage: `url('${image.ImagePath}')`, display: index === selectedIndex ? 'block' : 'none'}} />
                    );    
                })}
                {images.length > 1 &&
                    <Aux>
                    <div className={styles.CircleBox}>
                    { images.map( (image, index) => {
                        return (
                        <div 
                            key={`${index}-${image}`}
                            className={styles.WhiteCircle}
                            style={{opacity: index === selectedIndex ? 1 : 0.5}}
                            onClick={(event) => {this.setSelectedIndex(event,index)}}
                        ></div>
                        );    
                    })}
                    </div>
                    <div className={styles.Prev} onClick={(event) => {this.plusSlides(event, -1)} }>&#10094;</div>
                    <div className={styles.Next} onClick={(event) => {this.plusSlides(event, 1)} }>&#10095;</div>
                    </Aux> 
                }
            </div>
        );
    }
}

export default ImageSlides;