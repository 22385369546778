import React from 'react';
import styles from './Review.module.scss';

const Review = props => {

    const { reviews, id, localCode, user, fontSize, appData } = props;
    const {Colors, LocalStrings } = appData;
    
    const getReviewList = (reviews) => {
        let list = [];
        for (let key in reviews){
            list.push( { ...reviews[key], id: key } );    
        }
        return list;
    }

    const getReviewTotalScore = (list) => {
        let score = 0;
        list.map( review => {
            score += parseFloat(review.totalScore);
            return true;    
        });
        return score;
    }

    const renderScore = (reviewData, id) => {
        const {totalReviewer, totalScore} = reviewData;
        let avgScore = 0;
        
        if(totalReviewer !==0){
            avgScore = Math.round( (totalScore / totalReviewer) * 10 ) / 10;    
        }

        let hearts = [];
        for(let i = 1; i <= 5; i++){
            let iconName = "heart";
            let iconColor=Colors.heart;
            if(i > avgScore){
                (avgScore > (i-1)+0.4) ? iconName = "heart-stroke-half" : iconName = "heart-stroke";
                (avgScore > (i-1)+0.4) ? iconColor=Colors.heart : iconColor=Colors.contentFontColor;
            }
            hearts.push((<div key={`${i}-${id}`} className={`icon-${iconName}`} style={{fontSize:heartSize, color:iconColor}} />));
        }

        return(
        <div className={styles.ReviewScoreContainer}>
            { hearts }
            <p className={styles.TextStyle} style={{fontWeight:'bold', fontSize:fontSize, paddingLeft:'5px'}}>{avgScore}</p>
        </div> 
        );
    }

    const renderUserName = () => {
        if(user){
            return (
            <p className={styles.TextStyle} style={{fontWeight:'bold', fontSize:fontSize}}>
                {user.displayName}
            </p>
            )
        } else {
            return null
        }
    }

    const reviewerFontSize = fontSize-1;
    const heartSize = Math.floor(fontSize * 0.63);
    const reviewList = getReviewList(reviews);
    const totalReviewer = reviewList.length;
    const totalScore = reviewList.length === 0 ? 0 : getReviewTotalScore(reviewList);
    let reviewData = {totalReviewer, totalScore};

    return (
        <div className={styles.ReviewDataContainer} style={{padding:'10px'}}>
            { renderUserName() }
            { renderScore(reviewData, id) }
            <p className={styles.TextStyle} style={{alignSelf:"center", fontSize:reviewerFontSize}}>
            { `${reviewData.totalReviewer} ${LocalStrings[localCode].REVIEW}`}
            </p> 
        </div>
    );
}

export default Review;