import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import Address0 from '../../../assets/images/help/account/address.png'
import Address1 from '../../../assets/images/help/account/address/address_1.png'
import Address2 from '../../../assets/images/help/account/address/address_2.png'

class AddressRegistration extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>住所登録</span>
            </h3>
            <p className={styles.ArticleDesc}>
                ここでは、本人確認に使用される住所情報を入力していきます。住所は本人確認に使用する書類と一致するものを正確に入力してください。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより、「アカウント」＞「住所」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={Address0} alt="アドレス登録ステップ1画像" />
                    <li>
                        本人住所をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={Address1} alt="アドレス登録ステップ3画像" />
                    <li>
                        郵便番号を入力すると都道府県、市区郡村、町域が自動入力されます。丁目・番地を入力します。場所を特定するのに、ビル・建物・号も必要であれば入力します。入力内容を確認して「保存」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={Address2} alt="アドレス登録ステップ4画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(AddressRegistration);