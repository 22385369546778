import React, {Component} from 'react';
import styles from './ErrorModal.module.css';
import { connect } from 'react-redux';
import CloseBtn from '../UI/CloseBtn/CloseBtn';
import Button from '../UI/Button/Button';
import LogoWithTypeFace from '../Brand/LogoWithTypeFace/LogoWithTypeFace';
import Aux from '../../hoc/Aux/Aux';
import Backdrop from '../UI/Backdrop/Backdrop';
import {
    initUiErrorState
} from '../../store/actions';

class ErrorModal extends Component {

    getErrorStr = (errorMsg) => {
        const {appData, localCode} = this.props;
        const { LocalStrings } = appData;
        return errorMsg ? LocalStrings[localCode][errorMsg] : null
    }

    closeWin = () => {
        const {onInitUiErrorState} = this.props; 
        const initState = {
            errorWinOpen: false,
            closable: true,
            systemError:null    
        }
        onInitUiErrorState(initState);
    }

    render() {
        const {
            localCode,
            appData, 
            uiError
        } = this.props;
        const { errorWinOpen, closable, systemError } = uiError;
        let attachStyles = [ styles.ErrorModal, styles.Close];

        if(errorWinOpen){
            attachStyles = [ styles.ErrorModal, styles.Open ];
        }
        if(appData){
            const { LocalStrings } = appData;
            return(
            <Aux>
                <Backdrop 
                    show={errorWinOpen}
                    clicked={()=>{console.log('Backdrop clicked')}}/>
                <div className={attachStyles.join(' ')}>
                    { closable &&
                    <div style={{textAlign:"right"}}>
                        <CloseBtn 
                            clicked={this.closeWin} 
                            fontSize={20} 
                            color="gray"/>
                    </div>
                    }
                    <div className={styles.Brand}>
                        <LogoWithTypeFace />
                    </div>
                    {systemError &&
                    <div style={{display:'flex', flexDirection:"column", alignItems:"center"}}>
                        <h5 style={{padding:15}}>{LocalStrings[localCode].ERROR}</h5>
                        <p>{this.getErrorStr(systemError)}</p>
                        <Button 
                            type="button" 
                            onClick={this.closeWin} 
                            style={{ margin:'20px 0 0 0', width: "50%"}} >
                            { LocalStrings[localCode].CLOSE }
                        </Button>
                    </div> 
                    }
                </div>
            </Aux>
            );
        } else {
            return null;
        }     
    }

}

const mapStateToProps = ({
    ui:{ uiError },
    local: { localCode },
    appData: {appData, appDataIsLoading, appDataError}
}) => ({
    uiError,
    localCode,
    appData, appDataIsLoading, appDataError 
});

const mapDispatchToProps = dispatch => ({
    onInitUiErrorState : (initState) => dispatch(initUiErrorState(initState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);