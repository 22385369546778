import {
    FETCH_BANK_DATA
} from './actionTypes';

import AppDataApi from '../../constants/AppDataApi';

const appDataApi = new AppDataApi();

export const fetchBankDataPending = () => ({
    type: `${FETCH_BANK_DATA}_PENDING`
});

export const fetchBankDataRejected = (err) => ({
    type: `${FETCH_BANK_DATA}_REJECTED`,
    payload: err
});

export const fetchBankDataFulFilled = (BankData) => ({
    type: `${FETCH_BANK_DATA}_FULFILLED`,
    payload: BankData
});

export const fetchBankData = () => {
    return async dispatch => {
        dispatch(fetchBankDataPending());
        try{
            let BankData = await appDataApi.fetchBankData();
            dispatch(fetchBankDataFulFilled(BankData))
            return BankData;
        } catch(err){
            dispatch(fetchBankDataRejected(err));
            throw err
        }
    }   
};