import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import ReportUser1 from '../../../assets/images/help/buying/buy_report_user/buy_report_user_1.png';
import ReportUser2 from '../../../assets/images/help/buying/buy_report_user/buy_report_user_2.png';
import ReportUser3 from '../../../assets/images/help/buying/buy_report_user/buy_report_user_3.png';
import ReportUser5 from '../../../assets/images/help/buying/buy_report_user/buy_report_user_5.png';
import ReportUser6 from '../../../assets/images/help/buying/buy_report_user/buy_report_user_6.png';

import ReportService2 from '../../../assets/images/help/buying/buy_report_service/buy_report_service_2.png';
import ReportService4 from '../../../assets/images/help/buying/buy_report_service/buy_report_service_4.png';
import ReportService6 from '../../../assets/images/help/buying/buy_report_service/buy_report_service_6.png';

class ReportUser extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>不適切な行為を報告する</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムでは、禁止されている行為を行うユーザーに関しては、アカウントの一時停止、又は削除を行い、そのようなユーザーの撲滅に努めております。
            </p>
            <p className={styles.ArticleDesc}>
            尚、お客様が、不適切な行為を行うユーザーを発見した場合は、以下の方法でタグジャムカスターマーサービスに報告することが出来ます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        サービスの詳細画面等で出品者の「詳細」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={ReportUser1} alt="不適切な行為を報告するステップ1画像" />
                    <li>
                        ユーザープロフィール画面の「不適切な行為を報告」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={ReportUser2} alt="不適切な行為を報告するステップ2画像" />
                    <li>
                        ログインがお済みでない方はログインをしてください。アカウントをお持ちでない方は「アカウントをお持ちでない方」にて新規無料登録をお願いします。
                    </li>
                    <img className={styles.ScreenShot} src={ReportService2} alt="不適切な行為を報告するステップ3画像" />
                    <li>
                        「報告内容」を入力し、「不適切な行為を報告する」ボタンをタップします。   
                    </li>
                    <img className={styles.ScreenShot} src={ReportUser3} alt="不適切な行為を報告するステップ4画像" />
                    <li>
                        送信済みの報告内容を確認する場合は「カスタマーサポートへ」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={ReportService4} alt="不適切な行為を報告するステップ5画像" />
                    <li>
                        該当するケースをタップして内容、又は返信を確認出来ます。     
                    </li>
                    <img className={styles.ScreenShot} src={ReportUser5} alt="不適切な行為を報告するステップ6-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={ReportUser6} alt="不適切な行為を報告するステップ6-2画像" />
                    <li>
                        今後は、「アカウント」 > 「カスタマーサポート」にてタグジャムカスタマーサービスの返信を確認できます。    
                    </li>
                    <img className={styles.ScreenShot} src={ReportService6} alt="不適切な行為を報告するステップ7画像" />
                </ol>
            </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(ReportUser);