import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import BuyerRequestDetails1Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_1.png';
import BuyerRequestDetails1Make from '../../../assets/images/help/buying/buy_request_details/buy_request_details_make/buy_request_details_make_1.png';

import BuyerRequestDetails2Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_2.png';
import BuyerRequestDetails2Make from '../../../assets/images/help/buying/buy_request_details/buy_request_details_make/buy_request_details_make_2.png';

import BuyerRequestDetails3Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_3.png';
import BuyerRequestDetails3Make from '../../../assets/images/help/buying/buy_request_details/buy_request_details_make/buy_request_details_make_3.png';

import BuyerRequestDetails4_1Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_4-1.png';
import BuyerRequestDetails4_2Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_4-2.png';
import BuyerRequestDetails4_3Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_4-3.png';

import BuyerRequestDetails4_1Make from '../../../assets/images/help/buying/buy_request_details/buy_request_details_make/buy_request_details_make_4-1.png';
import BuyerRequestDetails4_2Make from '../../../assets/images/help/buying/buy_request_details/buy_request_details_make/buy_request_details_make_4-2.png';

import BuyerRequestDetails5_1Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_5-1.png';
import BuyerRequestDetails5_2Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_5-2.png';
import BuyerRequestDetails5_3Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_5-3.png';

import BuyerRequestDetails6Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_6.png';
import BuyerRequestDetails5Make from '../../../assets/images/help/buying/buy_request_details/buy_request_details_make/buy_request_details_make_5.png';

import BuyerRequestDetails7Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_7.png';
import BuyerRequestDetails7Make from '../../../assets/images/help/buying/buy_request_details/buy_request_details_make/buy_request_details_make_7.png';

import BuyerRequestDetails8Help from '../../../assets/images/help/buying/buy_request_details/buy_request_details_help/buy_request_details_help_8.png';

class BuyerRequestDetails extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    
    render() {
        const {currentTab, currentMode} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>依頼内容詳細の入力（{this.getCategory()}）</span>
            </h3>
            {(currentTab === 'HELP' || currentTab === 'MAKE') &&
            <p className={styles.ArticleDesc}>
            予約が確定されると、依頼内容の詳細フォームの入力が可能になります。以下の手順で、詳細を提供者の方に伝えましょう。この入力が、サービスの開始日の開始時刻前までに完了しない場合は、サービスの開始が遅れる場合がありますので、速やかに入力を完了しましょう。
            </p>
            }
            {(currentTab === 'RENT' || currentTab === 'TEACH') &&
            <p className={styles.ArticleDesc}>
            「{this.getCategory()}」の項目には依頼の詳細聞き取りフォームの入力はありません。次に進んで下さい。
            </p>
            }
          
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。    
                    </li>
                    }
                    { currentTab==='HELP' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails1Help} alt="依頼内容詳細の入力をするステップ1画像" />
                    }
                    { currentTab==='MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails1Make} alt="依頼内容詳細の入力をするステップ1画像" />
                    }

                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        「予約が確定されました」の表示されている取引をタップします。    
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails2Help} alt="依頼内容詳細の入力をするステップ2画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails2Make} alt="依頼内容詳細の入力をするステップ2画像" />
                    }

                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        「詳細聞き取りフォームを入力」をタップします。            
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails3Help} alt="依頼内容詳細の入力をするステップ3画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails3Make} alt="依頼内容詳細の入力をするステップ3画像" />
                    }

                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                        依頼したサービスに応じて、「お子様情報を選択」、「シニアの方の情報」、「ヘアケアお客様情報」、「ネイルお客様情報」、「エステお客様情報」、「ﾘﾗｸｾｰｼｮﾝお客様情報」のいずれかのボタンをタップし、事前登録したサービス対象者を選択、または新規で登録します。
                        <br />*サービス対象者の事前登録のやり方は<Link to={`/help/ServiceSubject?mode=${currentMode.toLowerCase()}&tab=${currentTab.toLowerCase()}`}>こちら</Link>を参照下さい。
                        <br />*サービス対象者の情報の入力が必要ないサービスの場合は次のステップに進んで下さい。           
                    </li>
                    <img className={styles.ScreenShot} src={BuyerRequestDetails4_1Help} alt="依頼内容詳細の入力をするステップ4-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={BuyerRequestDetails4_2Help} alt="依頼内容詳細の入力をするステップ4-2画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={BuyerRequestDetails4_3Help} alt="依頼内容詳細の入力をするステップ4-3画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                        質問を回答していきます。理想の出来上がり品の画像など参照にできるものがあればここで指定してください。サイズ、柄、などの希望をできるだけ詳しく説明しましょう。           
                    </li>
                    <img className={styles.ScreenShot} src={BuyerRequestDetails4_1Make} alt="依頼内容詳細の入力をするステップ4-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={BuyerRequestDetails4_2Make} alt="依頼内容詳細の入力をするステップ4-2画像" />
                    </Aux>
                    }

                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                        依頼した内容に、送迎が含まれている場合は、「住所を選択」ボタンをタップし、事前登録した住所を選択、または新規で登録します。送迎して欲しい順番に出発地点から選択・登録してください。備考欄を活用し、入口の入り方などの情報も入力してくださ。
                        <br />*住所の事前登録のやり方は<Link to={`/help/ServiceAddress?mode=${currentMode.toLowerCase()}&tab=${currentTab.toLowerCase()}`}>こちら</Link>を参照下さい。
                        <br />*住所入力が必要ないサービスの場合は次のステップに進んで下さい。           
                    </li>
                    <img className={styles.ScreenShot} src={BuyerRequestDetails5_1Help} alt="依頼内容詳細の入力をするステップ5-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={BuyerRequestDetails5_2Help} alt="依頼内容詳細の入力をするステップ5-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={BuyerRequestDetails5_3Help} alt="依頼内容詳細の入力をするステップ5-1画像" />
                    </Aux>
                    }
                    
                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        質問の回答は全て必須です。該当しない場合や特別な要望がない場合は、“特になし”と入力し全ての入力が完了したら、「詳細聞き取り送信」のボタンをタップします。
                        <br />*要望は詳しく伝える事で提供者の方があなたのニーズにあったお手伝いが出来ます。   
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails6Help} alt="依頼内容詳細の入力をするステップ6画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails5Make} alt="依頼内容詳細の入力をするステップ5画像" />
                    }

                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                        「会話に戻る」のボタンをタップします。    
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails7Help} alt="依頼内容詳細の入力をするステップ7画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails7Help} alt="依頼内容詳細の入力をするステップ6画像" />
                    }

                    {(currentTab === 'HELP' || currentTab === 'MAKE') &&
                    <li>
                      「詳細聞き取りの回答を送信済み」のメッセージが確認出来ます。    
                    </li>
                    }
                    {currentTab === 'HELP' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails8Help} alt="依頼内容詳細の入力をするステップ8画像" />
                    }
                    {currentTab === 'MAKE' && 
                    <img className={styles.ScreenShot} src={BuyerRequestDetails7Make} alt="依頼内容詳細の入力をするステップ7画像" />
                    }
                    
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BuyerRequestDetails);