import React from 'react';
import styles from './StepHead.module.css';

const stepHead = props => {

    const borderClass = props.role === 'provider' 
            ? {
                border: '1px solid #ffccb4' 
            }
            : {
                border: '1px solid #b7e6f7'    
            };


    const HeadStyle = props.side === 'left' 
                        ? styles.StepHeadLeft 
                        : styles.StepHead;

    const IconStyle = {
        color: props.role === 'provider' ? '#ffccb4' : '#b7e6f7', 
        fontSize:'2rem'
    }

    const labelColor = props.role === 'provider' ? '#bf9582' : '#74a1b2';
    const label = props.role === 'provider' ? '( タガー )' : '( ジャマー )';

    const description = props.role === 'provider' 
            ? <span className={styles.Description} style={{color:labelColor}}>助けます・教えます<br/>貸します・作ります</span> 
            : <span className={styles.Description} style={{color:labelColor}}>助けて・教えて<br/>貸して・作って</span>;

    return(
        <div className={HeadStyle} >
            <div className={styles.StepHeadContent} >
                <div className={styles.HeadBox} style={borderClass}>
                    <i className="fa fa-user" style={IconStyle}/><br />
                    <span style={{display:"block", color:labelColor, fontSize:'0.68rem'}}>{label}</span>
                    <strong>{props.label}</strong>
                    {description}
                </div>
            </div>
        </div>
    );
}

export default stepHead;