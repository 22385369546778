import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import CheckInvoicePay1 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_1.jpg';
import CheckInvoicePay2 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_2.jpg';
import CheckInvoicePay3 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_3.jpg';
import CheckInvoicePay4 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_4.png';
import CheckInvoicePay5 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_5.jpg';
import CheckInvoicePay6 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_6.jpg';
import CheckInvoicePay7 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_7.jpg';
import CheckInvoicePay8 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_8.png';
import CheckInvoicePay9 from '../../../assets/images/help/buying/buy_pay_invoice/buy_pay_invoice_9.png';


class CheckInvoicePay extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>請求書の確認と支払い</span>
            </h3>
            <p className={styles.ArticleDesc}>
            サービス提供完了後、サービス提供者の方から請求書が送信されます。以下の手順で請求書を確認し支払いを確定させて下さい。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。      
                    </li>
                    <img className={styles.ScreenShot} src={CheckInvoicePay1} alt="請求書の確認と支払いするステップ1画像" />
                    <li>
                        「請求書が届いています」と表示された取引をタップします。       
                    </li>
                    <img className={styles.ScreenShot} src={CheckInvoicePay2} alt="請求書の確認と支払いするステップ3画像" />
                    <li>
                        「請求書を見る」ボタンをタップします。       
                    </li>
                    <img className={styles.ScreenShot} src={CheckInvoicePay3} alt="請求書の確認と支払いするステップ4画像" />
                    <li>
                        基本料金、オプション料金のサービス項目、実費精算がある場合は金額、請求金額を確認します。      
                    </li>
                    <img className={styles.ScreenShot} src={CheckInvoicePay4} alt="請求書の確認と支払いするステップ5画像" />
                    <li>
                        画面をスクロールして、「料金を支払う」ボタンをタップします。      
                    </li>
                    <img className={styles.ScreenShot} src={CheckInvoicePay5} alt="請求書の確認と支払いするステップ6-1画像" />
                    <li>
                        登録済みの支払い情報（クレジットカード）が画面に表示されます。カード番号、有効期限を確認して、必要であれば、「支払い情報編集」で編集してください。確認が済んだら「請求額を支払う」ボタンをタップすると決済の処理が行われます。
                    </li>
                    <p className={styles.Red}>＊決済処理には少しお時間がかかりますので、アプリを閉じないようにしてください。</p>
                    <img className={styles.ScreenShot} src={CheckInvoicePay6} alt="請求書の確認と支払いするステップ6-2画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={CheckInvoicePay7} alt="請求書の確認と支払いするステップ8画像" />
                    <li>
                        処理が完了すると以下の画面が表示されます。「購入履歴へ」ボタンを押して、購入履歴を確認することができます。       
                    </li>
                    <img className={styles.ScreenShot} src={CheckInvoicePay8} alt="請求書の確認と支払いするステップ9画像" />
                    <li>
                        自動的に「取引」＞「買い」＞「購入履歴」に移動します。確認したい取引が表示されています。タップすると詳しい請求内容を再度確認できます。       
                    </li>
                    <img className={styles.ScreenShot} src={CheckInvoicePay9} alt="請求書の確認と支払いするステップ9画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(CheckInvoicePay);