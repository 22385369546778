import {
    FETCH_BANK_DATA    
} from '../actions/actionTypes';

const initState = {
    bankData: null,
    bankDataIsLoading: false,
    bankDataError: false
};

const bankDataReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${FETCH_BANK_DATA}_PENDING`:
            return {
                ...state,
                bankDataIsLoading: true,
                bankDataError: false,
            }
        case `${FETCH_BANK_DATA}_FULFILLED`:
            return {
                ...state,
                bankData: payload,
                bankDataIsLoading: false,
                bankDataError: false,
            }
        case `${FETCH_BANK_DATA}_REJECTED`:
            return {
                ...state,
                bankData: null,
                bankDataIsLoading: false,
                bankDataError: true,
            }
        default:
            return state;
    }
};

export default bankDataReducer;
