import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import UserInfo0 from '../../../assets/images/help/account/user_info.png'
// import UserInfo1 from '../../../assets/images/help/account/user_info/user_info_1.png'
import UserInfo2 from '../../../assets/images/help/account/user_info/user_info_2.png'
import UserInfo3 from '../../../assets/images/help/account/user_info/user_info_3.png'
import UserInfo4 from '../../../assets/images/help/account/user_info/user_info_4.png'
import UserInfo5 from '../../../assets/images/help/account/user_info/user_info_5.png'

class EmailConfirmation1 extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>Eメールアドレスの確認</span>
            </h3>
            <p className={styles.ArticleDesc}>
                Eメールを確認すると、サービス購入の際、お支払いの領収書などを受け取れます。売り手の方は、プロフィールにメール確認済みマークが表示されます。<br/>
                <span className="Caption">ユーザー情報の登録時にすでにEメールを認証済みの方はここの手順は不要です。</span>
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「ユーザー情報」を選びます。<br />
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo0} alt="Eメール確認ステップ1画像" />
                    <li>
                        「認証コード送信」または「認証コード再送信」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo2} alt="Eメール確認ステップ3画像" />
                    <li>
                        指定のEメールをチェックして認証コードをコピーします。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo3} alt="Eメール確認ステップ4画像" />
                    <li>
                        認証コードを貼り付けまたは入力し、「認証」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo4} alt="Eメール確認ステップ5画像" />
                    <li>
                        「確認済み」のサインが出たら完了です。
                    </li>
                    <img className={styles.ScreenShot} src={UserInfo5} alt="Eメール確認ステップ6画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(EmailConfirmation1);