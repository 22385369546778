import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import AcceptInterview1 from '../../../assets/images/help/selling/sell_interview/sell_interview_1.jpg';
import AcceptInterview2 from '../../../assets/images/help/selling/sell_interview/sell_interview_2.jpg';
import AcceptInterview3 from '../../../assets/images/help/selling/sell_interview/sell_interview_3.jpg';
import AcceptInterview4 from '../../../assets/images/help/selling/sell_interview/sell_interview_4.jpg';
import AcceptInterview5 from '../../../assets/images/help/selling/sell_interview/sell_interview_5.jpg';
import AcceptInterview6 from '../../../assets/images/help/selling/sell_interview/sell_interview_6.jpg';
import AcceptInterview7 from '../../../assets/images/help/selling/sell_interview/sell_interview_7.jpg';
import AcceptInterview8 from '../../../assets/images/help/selling/sell_interview/sell_interview_8.jpg';
import AcceptInterview9 from '../../../assets/images/help/selling/sell_interview/sell_interview_9.jpg';
import AcceptInterview10 from '../../../assets/images/help/selling/sell_interview/sell_interview_10.jpg';


class AcceptInterview extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>面接の申請を受け付ける</span>
            </h3>
            <p className={styles.ArticleDesc}>
                サービス購入者から、面接申請がきた場合は以下の手順で申請を受け付けます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより、「取引」＞「売り」＞「面接」を選びます。      
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview1} alt="面接の申請を受け付けるステップ1画像" />
                    <li>
                        面接申請ありと表示された取引を選びます。    
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview2} alt="面接の申請を受け付けるステップ2画像" />
                    <li>
                        「面接申請をみる」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview3} alt="面接の申請を受け付けるステップ3画像" />
                    <li>
                        「予定表を確認」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview4} alt="面接の申請を受け付けるステップ4画像" />
                    <li>
                        「申請受付する」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview5} alt="面接の申請を受け付けるステップ5-1画像" />
                    <p>次の画面で、「移動時間」を「＋」「ー」ボタンで調節し、「予定追加」ボタンをタップします。</p>
                    <img className={styles.ScreenShot} src={AcceptInterview6} alt="面接の申請を受け付けるステップ5-2画像" />
                    <li>
                        「会話に戻る」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview7} alt="面接の申請を受け付けるステップ7画像" />
                    <li>
                        「面接申請受付済み」のメッセージを確認します。
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview8} alt="面接の申請を受け付けるステップ8画像" />
                    <li>
                        面接が終わったら、「面接完了」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview9} alt="面接の申請を受け付けるステップ9画像" />
                    <li>
                        「面接完了」のメッセージを確認します。
                    </li>
                    <img className={styles.ScreenShot} src={AcceptInterview10} alt="面接の申請を受け付けるステップ2画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(AcceptInterview);