import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class WhatDeposit extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>保証金とは</span>
            </h3>
            <p className={styles.ArticleDesc}>
                保証金とは、借り手のレンタル申請が、貸し手に受付された後、支払い情報の登録の際、レンタル品の保証として信販会社の審査により、タグジャムが支払いの確保をする金額のことです。
            </p>
            <p className={`${styles.ArticleDesc} ${styles.HeaderMargin10}`}>
                保証金のお支払いの審査が完了した時点で、予約が確定され、取引が成立します。お支払いの審査が未完了の場合は（予約は仮予約の状態のまま）仮に他の方が同じ時間に予約を入れ、さらにお支払いの審査が完了された場合は、ご自身の申請は無効になりますので支払い情報の登録は速やかにお願いいたします。
           </p>
            <p className={`${styles.ArticleDesc} ${styles.HeaderMargin10}`}>
                その後、レンタル品を無事返却し、レンタル料を支払いいただく際に、確保された金額は、全額返金されます。ただし、レンタル品の返却が期日の定刻までに行われない場合は、確保された金額の返金処理は行われませんので、レンタル品は必ず返却しましょう。
            </p>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(WhatDeposit);