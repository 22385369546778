import {
    FETCH_BLOG,
    SET_BLOG,
} from '../actions/actionTypes';

const initState = {
    blog: null,
    blogIsLoading: false,
    blogError: false,
};

const blogReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_BLOG:
          return {
              ...state,
              blog: payload
          }
        case `${FETCH_BLOG}_PENDING`:
          return {
              ...state,
              blogIsLoading: true,
              blogError: false,
          }
        case `${FETCH_BLOG}_FULFILLED`:
          return {
              ...state,
              blog: payload,
              blogIsLoading: false,
              blogError: false,
          }
        case `${FETCH_BLOG}_REJECTED`:
          return {
              ...state,
              blog: null,
              blogIsLoading: false,
              blogError: true,
          }
        default:
          return state;
    }
};

export default blogReducer;
