import React from 'react';
import styles from './NavigationItems.module.css';
import Aux from '../../hoc/Aux/Aux';


const openSwitchMode = (event, mode, props) => {
    event.preventDefault();
    const {onToggleAuthWinOpen, onInitAuthUiState} = props;
    const initState = {
        mode: mode, //'SIGNIN' || 'SIGNUP',
        closable: true
    }
    onInitAuthUiState(initState);
    onToggleAuthWinOpen();
}

const logOutHandler = (event, props) => {
    event.preventDefault();
    const {onLogout, authInfo: {userId}, closed} = props;
    onLogout(userId);
    if(closed){
      closed();
    }
}

const linkClicked = (event, link, props) => {
    console.log(props);
    const {history, closed} = props;
    event.preventDefault();
    history.push({
        pathname:link
    });
    if(closed){
        closed();
    }
}

const navigationItems = (props) =>{
    const { authInfo: {token} } = props;
    return (
        <div className={styles.NavigationItems}>
            <p 
                onClick={(event)=> { linkClicked(event, '/dealflow', props) }} 
                className={styles.NavigationItem}>
                ご利用の流れ
            </p>
            <p 
                onClick={(event)=> { linkClicked(event, '/help', props) }} 
                className={styles.NavigationItem}>
                    使い方ガイド
            </p>
            <p 
                onClick={(event)=> { linkClicked(event, '/blog', props) }} 
                className={styles.NavigationItem}>
                    読み物
            </p>
            {!token &&
            <Aux>
                <button onClick={(event) => {openSwitchMode(event, 'SIGNIN', props)}} className={styles.Btn}>ログイン</button>
                <button onClick={(event) => {openSwitchMode(event, 'SIGNUP', props)}} className={styles.Btn}>会員登録</button>
            </Aux> 
            }
            {token &&
            <Aux>
            <p 
                onClick={(event)=> { linkClicked(event, '/account', props) }}
                className={styles.NavigationItem}>
                会員情報
            </p>
            <button onClick={(event) => {logOutHandler(event, props)}} className={styles.Btn}>ログアウト</button>
            </Aux> 
            }
        </div>
    );
}

export default navigationItems;