import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import Certificate0 from '../../../assets/images/help/account/certificate.png';
import SubmitDocs0 from '../../../assets/images/help/account/certificate/cert.png';
import SubmitDocs1 from '../../../assets/images/help/account/certificate/cert/cert_1.png';
import SubmitDocs2 from '../../../assets/images/help/account/certificate/cert/cert_2.png';
import SubmitDocs3 from '../../../assets/images/help/account/certificate/cert/cert_3.jpg';
import SubmitDocs4 from '../../../assets/images/help/account/certificate/cert/cert_4.jpg';
import SubmitDocs5 from '../../../assets/images/help/account/certificate/cert/cert_5.jpg';
import SubmitDocs6 from '../../../assets/images/help/account/certificate/cert/cert_6.jpg';
import SubmitDocs7 from '../../../assets/images/help/account/certificate/cert/cert_7.jpg';


class SubmitDocs extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>必要書類を提出する</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムではサービスを出品の際、出品項目により書類提出が必要になる場合があります。書類提出後タグジャムの確認完了により、取引を始めることができます。書類提出は次の手順で行うことが出来ます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「各種確認書類」を選びます。          
                    </li>
                    <img className={styles.ScreenShot} src={Certificate0} alt="必要書類を提出するステップ1画像" />
                    <li>
                        提出が必要な書類を選択します。例：保育免許    
                    </li>
                    <img className={styles.ScreenShot} src={SubmitDocs0} alt="必要書類を提出するステップ2画像" />
                    <li>
                        1.「写真を提出」 をタップしてください。<br/>
                        <p className="Caption">＊本人確認が済んでない方は先に本人確認を済ませてください。</p>    
                    </li>
                    <img className={styles.ScreenShot} src={SubmitDocs1} alt="必要書類を提出するステップ3画像" />
                    <li>
                        「カメラで撮影する」、「写真を選択」のどちらかを選び、資格証明・届出書等の書類を撮影・選択してください。複数面の記載の書類の場合は　2.「写真を提出」 をタップして他の面の画像の撮影・選択もお願いします。<br/>
                        <p className="Caption">＊届出書などは、受付印が押されている面を提出してください。</p>    
                    </li>
                    <img className={styles.ScreenShot} src={SubmitDocs2} alt="必要書類を提出するステップ4画像" />
                    <li>
                        選択・撮影されて画像が表示されたら、位置を調節して、「この写真を選択」をタップします。横向きで撮影するなどで枠内に画像を収めて下さい。<br/>
                        <p className="Caption">＊画像が不鮮明、切れている場合は認証されませんので、撮影の場合は明るい部屋で、鮮明な画像を撮影して下さい。</p>
                    </li>
                    <img className={styles.ScreenShot} src={SubmitDocs3} alt="必要書類を提出するステップ5画像" />
                    <li>
                        有効期限がある場合は「あり」を選択し、期限を入れて下さい。それ以外は「なし」を選択してください。次に「提出」をタップして下さい。    
                    </li>
                    <img className={styles.ScreenShot} src={SubmitDocs4} alt="必要書類を提出するステップ6画像" />
                    <li>
                        「確認中...」と表示されます。    
                    </li>
                    <img className={styles.ScreenShot} src={SubmitDocs5} alt="必要書類を提出するステップ6画像" />
                    <li>
                        本人確認の結果は２４時間以内に表示されます。タグジャム当局が書類の確認が終わると「確認済み」と表示されます。    
                    </li>
                    <img className={styles.ScreenShot} src={SubmitDocs6} alt="必要書類を提出するステップ7-1画像" />
                    <p>＊未確認と表示された場合は、理由が表示されますので訂正の上、書類の再提出を行って下さい。</p>
                    <img className={styles.ScreenShot} src={SubmitDocs7} alt="必要書類を提出するステップ7-2画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SubmitDocs);