import React from 'react';
import styles from './SubCatButton.module.scss';

const SubCatButton = props => {
    const { 
        SubCat, 
        selectedSubMenu, 
        localCode, 
        round,
        budge, 
        onClick,
        appData:{LocalStrings},  
    } = props;
    return(
        <div
            onClick={onClick}
            className={selectedSubMenu===SubCat ? `${styles.SubCatButton} ${styles.SubCatSelected}` : styles.SubCatButton} 
            style={{borderRadius: round ? '17.5px' : '8px'}}
        >
            <p className={styles.ButtonText}>
                {LocalStrings[localCode][SubCat]}
            </p>
            {budge && 
            <d className={styles.Budge}>
                <p className={styles.BudgeText}>{`${budge}`}</p>
            </d>
            }
        </div>
    );
}


export default SubCatButton;