import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import DealCofirmImage from '../../../assets/images/help/deal_confirm/deal_confirm.svg';


class DealConfirm extends Component {
    articleClickedHandler = (article) => {
        console.log(article);
        if(article === 'cancelpolicy'){
            this.props.history.push({
                pathname: `/${article}`
            });
        } else {
            this.props.history.push({
                pathname: `/help/${article}`
            });
        }
    }
    render() {
        return(
            <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>取引成立について</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムの取引はサービス購入者（買い手）がまず購入の申請をし、サービス提供者（売り手）がスケジュールを確認後に受付をします。その後、買い手の方が支払い方法を登録し、タグジャムがお支払いの審査を行います。審査に通過した時点で取引の成立となります。成立するとサービスの予約が確定されます。</p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>申請受付後支払い登録が完了していない場合は、予約は仮予約になりますので、他に購入者があり支払い登録が完了された場合は、その方の予約が優先されますので登録はお早めにお願いします。</li>
                    <li>取引成立後のキャンセルは基本的には出来ませんのでご注意ください。</li>
                    <li>
                        止むを得ずキャンセルする場合は、キャンセル料金が発生する場合がございます。
                        <p>*キャンセル料の詳しい規定は<a href="/help" onClick={ e => { e.preventDefault(); this.articleClickedHandler('cancelpolicy') } }>こちら</a>を参照して下さい。</p>
                    </li>
                    <img className={`${styles.ImageWide} ${styles.HeaderMargin10}`} src={DealCofirmImage} alt="取引成立図解" />
                </ul>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(DealConfirm);