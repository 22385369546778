import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styles from './DealFlow.module.css';
import Indicator from '../../assets/images/indicator.svg';
import Step from './Step/Step';
import StepHead from './StepHead/StepHead';

class DealFlow extends Component {
    state = {
        currentTab:0,
        serviceType:'VISIT'
    }
    tabClikedHandler = (tabNum) => {
        this.setState({
            currentTab: tabNum
        });
    }
    serviceTypeClikedHandler = (serviceType) => {
        this.setState({
            serviceType: serviceType
        });         
    }

    render(){
        const {location} = this.props;
        const {currentTab, serviceType} = this.state;
        const show = {display: 'block'};
        const hide = {display: 'none'};
        let pathname = location.pathname;
        let title = 'ご利用の流れ';
        let description = 'サービスの買い方,サービスの売り方,タグジャムご利用の流れでは、サービス提供者の（サービスの出品、購入申請受付、サービス提供、売り上げ受け取り）、サービス購入者の（サービスの購入申請、支払審査、サービス料の支払）の大まかな流れを説明します。「助けて」・「助けます」「教えて」・「教えます」「貸して」・「貸します」「作って」・「作ります」の分野で、それぞれのカテゴリーを選択して流れがご確認頂けます。';
        return(
        <>
        <Helmet>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`タグジャム-${title}`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:url" content={pathname} />
            <meta property="og:title" content={`タグジャム-${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:type" content="image/png" />
            <title>{title}</title>
            <meta
              name="description"
              content={description}
            />
            <meta content="タグジャム使い方,タグジャムご利用の流れ,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
        </Helmet>
        <div style={{backgroundColor:'#f5f5f5', paddingBottom: '30px'}}>
            <h2 className={styles.PageHeader}>
                ご利用の流れ
            </h2>
            <div className={styles.DealFlow}>
                <div className={styles.TabContainer}>
                    <div className={currentTab === 0 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel} 
                        onClick={() => this.tabClikedHandler(0)}
                        >
                        <div className={`icon-help ${styles.CatIcon}`} />
                        <div className={styles.TabLabelText}>助けます</div>
                        <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                    </div>
                    <div className={styles.TabGap} />
                    <div className={currentTab === 1 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                        onClick={() => this.tabClikedHandler(1)}
                        >
                        <div className={`icon-teach ${styles.CatIcon}`} />
                        <div>教えます</div>
                        <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                    </div>
                    <div className={styles.TabGap} />
                    <div className={currentTab === 2 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                        onClick={() => this.tabClikedHandler(2)}
                        >
                        <div className={`icon-rent ${styles.CatIcon}`} />
                        <div>貸します</div>
                        <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                    </div>
                    <div className={styles.TabGap} />
                    <div className={currentTab === 3 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                        onClick={() => this.tabClikedHandler(3)}
                        >
                        <div className={`icon-make ${styles.CatIcon}`} />
                        <div>作ります</div>
                        <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                    </div>
                </div> 
            </div>
            <div className={`${styles.DealFlowContents} Fade`} style={currentTab === 0 ? show : hide} >
                <div className={styles.FlowHead}>
                    <div className={styles.FlowColum}>
                        <StepHead side="left" label="サービス提供者" role='provider' />
                    </div>
                    <div className={styles.FlowColum }>
                        <StepHead side="right" label="サービス購入者" role='customer' />
                    </div>
                </div>    
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="① ユーザー登録"
                            pointer="right">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="① ユーザー登録"
                            pointer="left">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="② 本人確認の提出"
                            pointer="right">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="② 本人確認の提出"
                            pointer="left">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="③ サービスの出店"
                            pointer="right">
                                詳細(金額・提供項目地域・シフト)を決めて出店。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}/>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="④ 資格の確認"
                            pointer="right">
                                資格が必要なサービスを出店する場合は、資格・証明の画像提出をします。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="③ サービスを選ぶ"
                            pointer="left">
                                出店リストから選ぶ、または検索機能で希望のサービスを探す。不明な点があれば売り手にサービス内容など質問・相談。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum} >
                        <Step 
                            side="left"
                            title="⑤ 買い手の質問応答"
                            pointer="right">
                                買い手の質問・相談に返信して、見積もりの内容(オプション選択)など決定の手助け。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="④ 簡易見積もり"
                            pointer="left">
                                詳細ページの簡易見積もりで、内容、金額を確認。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum} >
                        <Step 
                            side="left"
                            marginTop="15"
                            title="⑥ 購入申請の受付"
                            pointer="right">
                                注文内容を見る、受付すると仮予約が入る。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑤ 購入申請をする"
                            pointer="left">
                                見積もりページの購入申請ボタンを押す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="予約の確定"
                            pointer="right"
                            special={true}
                            >
                            買い手のお支払いが可能か審査します。<br />審査通過後に、システムにより予約が確定されます。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑥ 支払い方法登録"
                            pointer="left">
                                クレジットカード情報を登録する。
                                <br />
                                <span className={styles.WarningNote}>
                                    *有効期限、利用限度枠、カード名義などの確認審査が行われます。これらをお確かめの上登録願います。
                                </span>
                        </Step>
                    </div>
                </div>
                <div className={`${styles.FlowRow} ${styles.CancelText}`} >
                    <p>ここからキャンセルの場合、<br />回数にカウントされます。</p>
                    <p>*開始時刻の<strong>48時間</strong>以内<br />サービス料合計の<strong>20％</strong> / <strong>250円</strong>のいずれか高い方<br />のキャンセル料金</p>
                    <p>*開始時刻の<strong>24時間</strong>以内<br />サービス料合計の<strong>50％</strong> / <strong>250円</strong>のいずれか高い方<br />のキャンセル料金</p>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑦ サービス開始"
                            pointer="right"
                            >
                                予約時刻の１０分前に開始ボタンが表示されます。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="サービス開始の通知"
                            pointer="left"
                            special={true}
                            >
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑧ サービス完了"
                            pointer="right"
                            >
                                仕事が終わったら完了ボタンを押す。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="サービス完了の通知"
                            pointer="left"
                            special={true}
                            >
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑨ 請求書の作成"
                            pointer="right"
                            >
                                交通費など実費を入力して自動作成、購入者へ送信する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="⑦ 請求額のお支払い"
                            pointer="left"
                            >
                                金額、内容を確認してお支払い。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="お支払いの確定"
                            pointer="right"
                            special={true}
                            >
                            お支払いを決済します。
                        </Step>
                    </div>
                    <div className={styles.FlowColum} />
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑩ 購入者の評価"
                            pointer="right"
                            >
                                購入者を評価して投稿する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑧ 提供者の評価"
                            pointer="left"
                            >
                                提供者を評価して投稿する。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑪ 支払いの受取り"
                            pointer="right"
                            >
                                支払金を受取る。決済後14日で口座振込申請ができます。<br />
                                <span className={styles.PaymentAvlText}>*JCB, Diners Club, Discoverカードでの支払いの場合は30日後に振込可能になります。</span>
                        </Step>
                    </div>
                    <div className={styles.FlowColum} />
                </div>
            </div>
            <div className={`${styles.DealFlowContents} Fade`} style={currentTab === 1 ? show : hide} >
                <p　style={{paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                    *訪問型と、来客型では流れが多少違います。どちらかを選択して流れを見てください。
                </p>
                <div style={{display:'flex', justifyContent:'center', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}}>
                    <div className={serviceType === 'VISIT' ? `${styles.ServiceTypeLabel} ${styles.ServiceTypeActive}` : styles.ServiceTypeLabel}
                        onClick={() => this.serviceTypeClikedHandler('VISIT')}>
                        <i className={`fa fa-caret-right ${styles.faicon}`}></i>
                        <div className={styles.ServiceTypeLabelText}>訪問型</div>
                    </div>
                    <div className={serviceType === 'VISITOR' ? `${styles.ServiceTypeLabel} ${styles.ServiceTypeActive}` : styles.ServiceTypeLabel}
                        onClick={() => this.serviceTypeClikedHandler('VISITOR')}>
                        <i className={`fa fa-caret-right ${styles.faicon}`} style={{paddingLeft:'15px'}}></i>
                        <div className={styles.ServiceTypeLabelText}>来客型</div>
                    </div>
                </div> 
                <div className={styles.FlowHead}>
                    <div className={styles.FlowColum}>
                        <StepHead side="left" label="クラス提供者" role='provider' />
                    </div>
                    <div className={styles.FlowColum }>
                        <StepHead side="right" label="クラス購入者" role='customer' />
                    </div>
                </div>   
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="① ユーザー登録"
                            pointer="right">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="① ユーザー登録"
                            pointer="left">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="② 本人確認の提出"
                            pointer="right">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="② 本人確認の提出"
                            pointer="left">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="③ クラスの出店"
                            pointer="right">
                                詳細(料金・クラス内容・時間など)を決めて出店。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="③ クラスを選ぶ"
                            pointer="left">
                                出店リストから選ぶ、または検索機能で希望のクラスを探す。不明な点があれば売り手に質問・相談。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="④ 買い手の質問応答"
                            pointer="right">
                                買い手の質問に返信して、クラス受講決定の手助け。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="④ 簡易見積もり"
                            pointer="left">
                                詳細ページの簡易見積もりで、料金、準備する物などを確認。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="⑤ 受講申請の受付"
                            pointer="right">
                                空席を確認し、受付すると仮予約が入る。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑤ 受講申請をする"
                            pointer="left">
                                見積もりページの受講申請ボタンを押す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="予約の確定"
                            pointer="right"
                            special={true}
                            >
                            買い手のお支払いが可能か審査します。<br />審査通過後に、システムにより予約が確定されます。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑥ 支払い方法登録"
                            pointer="left">
                                クレジットカード情報を登録する。
                                <br />
                                <span className={styles.WarningNote}>
                                    *有効期限、利用限度枠、カード名義などの確認審査が行われます。これらをお確かめの上登録願います。
                                </span>
                        </Step>
                    </div>
                </div>
                <div className={`${styles.FlowRow} ${styles.CancelText}`} >
                    <p>ここからキャンセルの場合、<br />回数にカウントされます。</p>
                    <p>*開始時刻の<strong>48時間</strong>以内<br />受講料合計の<strong>20％</strong> / <strong>250円</strong>のいずれか高い方<br />のキャンセル料金</p>
                    <p>*開始時刻の<strong>24時間</strong>以内<br />受講料合計の<strong>50％</strong> / <strong>250円</strong>のいずれか高い方<br />のキャンセル料金</p>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑥ クラスの受付"
                            pointer="right"
                            marginTop="15"
                            >
                                受講者の出席コードを読み取り受付する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="出席コードが表示"
                            pointer="left"
                            special={true}
                            >
                                クラス受付の際にこのコードを提示する。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        {serviceType === 'VISITOR' &&
                        <Step 
                            side="left"
                            title="請求書の自動送信"
                            pointer="right"
                            special={true}
                            >
                                教材費などを加えた授業料の請求書が自動作成・送信される。
                        </Step>
                        }
                        {serviceType === 'VISIT' &&
                        <Step 
                            side="left"
                            title="⑦ 請求書の作成"
                            pointer="right"
                            >
                                交通費など実費を入力して自動作成、購入者へ送信する。
                        </Step>
                        }
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="⑦ 請求額のお支払い"
                            pointer="left"
                            >
                                金額、内容を確認してお支払い。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="お支払いの確定"
                            pointer="right"
                            special={true}
                            >
                            お支払いを決済します。
                        </Step>
                    </div>
                    <div className={styles.FlowColum} />
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title={`${serviceType === 'VISITOR' ? '⑦' : '⑧' } 受講者の評価`}
                            pointer="right"
                            >
                                受講者を評価して投稿する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑧ クラスの評価"
                            pointer="left"
                            >
                                クラスを評価し、投稿する。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title={`${serviceType === 'VISITOR' ? '⑧' : '⑨' } 支払いの受取り`}
                            pointer="right"
                            >
                                支払金を受取る。決済後14日で口座振込申請ができます。<br />
                                <span className={styles.PaymentAvlText}>*JCB, Diners Club, Discoverカードでの支払いの場合は30日後に振込可能になります。</span>
                        </Step>
                    </div>
                    <div className={styles.FlowColum} />
                </div>
            </div>
            <div className={`${styles.DealFlowContents} Fade`} style={currentTab === 2 ? show : hide} >
                <div className={styles.FlowHead}>
                    <div className={styles.FlowColum}>
                        <StepHead side="left" label="レンタル提供者" role='provider' />
                    </div>
                    <div className={styles.FlowColum }>
                        <StepHead side="right" label="レンタル購入者" role='customer' />
                    </div>
                </div>    
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="① ユーザー登録"
                            pointer="right">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="① ユーザー登録"
                            pointer="left">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="② 本人確認の提出"
                            pointer="right">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="② 本人確認の提出"
                            pointer="left">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="③ レンタル品出店"
                            pointer="right">
                                詳細(料金・保証金、返却時の料金など)を決めて出店。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="③ レンタル品を選ぶ"
                            pointer="left">
                                出店リストから選ぶ、または検索機能で希望のレンタル品を探す。不明な点があれば売り手に質問・相談。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="④ 買い手の質問応答"
                            pointer="right">
                                買い手の質問に返信して、レンタル利用決定の手助け。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="④ 簡易見積もり"
                            pointer="left">
                                詳細ページの簡易見積もりで、内容、金額、保証金などを確認。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="⑤ 申請の受付"
                            pointer="right">
                                注文内容を見る、受付すると仮予約が入る。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑤ レンタル申請"
                            pointer="left">
                                見積もりページのレンタル申請ボタンを押す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="予約の確定"
                            pointer="right"
                            special={true}
                            >
                            買い手のお支払いが可能か審査します。<br />審査通過後に、システムにより予約が確定されます。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑥ 支払い方法登録"
                            pointer="left">
                                クレジットカード情報を登録する。
                                <br/>
                                <span className={styles.WarningNote}>
                                    *有効期限、利用限度枠、カード名義などの確認審査が行われます。これらをお確かめの上登録願います。
                                </span>
                        </Step>
                    </div>
                </div>
                <div className={`${styles.FlowRow} ${styles.CancelText}`} >
                    <p>ここからキャンセルの場合、<br />回数にカウントされます。</p>
                    <p>*レンタル開始日の<strong>2日</strong>以内<br />レンタル料合計の<strong>20％</strong> / <strong>250円</strong>のいずれか高い方<br />のキャンセル料金</p>
                    <p>*レンタル開始日の<strong>1日</strong>以内<br />レンタル料合計の<strong>50％</strong> / <strong>250円</strong>のいずれか高い方<br />のキャンセル料金</p>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑥ 品物の受け渡し"
                            pointer="right"
                            >
                                レンタル品を受け渡し、受け渡しボタンを押す。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="⑦ 品物の受け取り"
                            pointer="left"
                            >
                                レンタル品を受け取り、受け取りボタンを押す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="left"
                            title="⑦ 品物の受け取り"
                            pointer="right"
                            >
                                レンタル品の返却を受け取り、返却受け取りボタンを押す。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑧ 品物の返却"
                            pointer="left"
                            >
                                レンタル品を返却し、返却完了ボタンを押す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑧ 請求書の作成"
                            pointer="right"
                            >
                                クリーニング代など返却時の料金を入力して自動作成、購入者へ送信する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="⑨ 請求額のお支払い"
                            pointer="left"
                            >
                                金額、内容を確認してお支払い。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="お支払いの確定"
                            pointer="right"
                            special={true}
                            >
                            お支払いを決済します。
                        </Step>
                    </div>
                    <div className={styles.FlowColum} />
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑨ 購入者の評価"
                            pointer="right"
                            >
                                レンタル利用者を評価して投稿する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑩ 提供者の評価"
                            pointer="left"
                            >
                                レンタル提供者を評価して投稿する。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑩ 支払いの受取り"
                            pointer="right"
                            >
                                支払金を受取る。決済後14日で口座振込申請ができます。<br />
                                <span className={styles.PaymentAvlText}>*JCB, Diners Club, Discoverカードでの支払いの場合は30日後に振込可能になります。</span>
                        </Step>
                    </div>
                    <div className={styles.FlowColum} />
                </div>
            </div>
            <div className={`${styles.DealFlowContents} Fade`} style={currentTab === 3 ? show : hide} >
                <div className={styles.FlowHead}>
                    <div className={styles.FlowColum}>
                        <StepHead side="left" label="サービス提供者" role='provider' />
                    </div>
                    <div className={styles.FlowColum }>
                        <StepHead side="right" label="サービス購入者" role='customer' />
                    </div>
                </div>    
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="① ユーザー登録"
                            pointer="right">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="① ユーザー登録"
                            pointer="left">
                                メールアドレスのみでOK。<br />(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="② 本人確認の提出"
                            pointer="right">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="② 本人確認の提出"
                            pointer="left">
                                免許証などの画像を提出、24時間以内に承認されます。(初回のみ)
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="③ サービス出店"
                            pointer="right">
                                詳細(料金、作成日数など)を決めて出店。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="③ サービスを選ぶ"
                            pointer="left">
                                出店リストから選ぶ、または検索機能で希望のサービスを探す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="④ 買い手の質問応答"
                            pointer="right">
                                買い手の質問に返信して、サービス利用決定の手助け。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            marginTop="15"
                            title="④ 簡易見積もり"
                            pointer="left">
                                詳細ページで、希望の物を相談、簡易見積もりで金額確認。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="⑤ 購入申請の受付"
                            pointer="right">
                                受付すると仮納品日がスケジュールに追加されます。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑤ 購入申請する"
                            pointer="left">
                                見積もりページの購入申請ボタンを押す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            marginTop="15"
                            title="予約の確定"
                            pointer="right"
                            special={true}
                            >
                            買い手のお支払いが可能か審査します。<br />審査通過後に、システムにより、納品日が確定されます。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑥ 支払い方法登録"
                            pointer="left">
                                クレジットカード情報を登録する。
                                <br />
                                <span className={styles.WarningNote}>
                                    *有効期限、利用限度枠、カード名義などの確認審査が行われます。これらをお確かめの上登録願います。
                                </span>
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑥ 作成を開始する"
                            pointer="right"
                            >
                                作成開始ボタンを押し、作成を開始する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="作成開始の通知"
                            marginTop="15"
                            pointer="left"
                            special={true}>  
                        </Step>
                    </div>
                </div>
                <div className={`${styles.FlowRow} ${styles.CancelText}`} >
                    <p>ここからキャンセルの場合、<br />回数にカウントされます。</p>
                    <p>*<strong>作成通知後</strong>のキャンセル<br />サービス料合計の<strong>50％</strong> / <strong>250円</strong>のいずれか高い方<br />のキャンセル料金</p>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑦ 作品の完成"
                            pointer="right"
                            >
                                作品が出来上がったら、作品の完成ボタンを押す。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="作品完成の通知"
                            pointer="left"
                            special={true}
                            >
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑧ 納品する"
                            pointer="right"
                            >
                                作品を購入者に渡し、納品完了ボタンを押す。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="⑦ 作品の受け取り"
                            pointer="left"
                            >
                                作品を受け取り、受け取りボタンを押す。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑨ 請求書の作成"
                            pointer="right"
                            >
                                追加料金があれば入力して自動作成、購入者へ送信する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            marginTop="15"
                            side="right"
                            title="⑧ 請求額のお支払い"
                            pointer="left"
                            >
                                金額、内容を確認してお支払い。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑩ 購入者の評価"
                            pointer="right"
                            >
                                購入者を評価して投稿する。
                        </Step>
                    </div>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="right"
                            title="⑨ 提供者の評価"
                            pointer="left"
                            >
                                提供者を評価して投稿する。
                        </Step>
                    </div>
                </div>
                <div className={styles.FlowRow}>
                    <div className={styles.FlowColum}>
                        <Step 
                            side="left"
                            title="⑪ 支払いの受取り"
                            pointer="right"
                            >
                                支払金を受取る。決済後14日で口座振込申請ができます。<br />
                                <span className={styles.PaymentAvlText}>*JCB, Diners Club, Discoverカードでの支払いの場合は30日後に振込可能になります。</span>
                        </Step>
                    </div>
                    <div className={styles.FlowColum} />
                </div>
            </div>
        </div>
        </>
        );
    }
}

export default DealFlow;