import React from 'react';
import styles from './SNSButton.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import FaceBookShare from '../../../assets//images/snsbuttons/FBShare.png';
import FaceBookLike from '../../../assets//images/snsbuttons/FBLike.png';
import LineSend from '../../../assets//images/snsbuttons/LINESend.png';
import LineLike from '../../../assets//images/snsbuttons/LINELike.png';
import TwitterTweet from '../../../assets//images/snsbuttons/TWTweet.png';
import TwitterFllow from '../../../assets//images/snsbuttons/TWFollow.png';



const SNSButton = (props) => {
  const {type} = props;
  let buttonImage = null;
  switch( type ) {
    case 'facebook-share':{
        buttonImage = FaceBookShare;
        break;
    }
    case 'facebook-like':{
        buttonImage = FaceBookLike;
        break;
    }
    case 'line-send':{
        buttonImage = LineSend;
        break;
    }
    case 'line-like':{
        buttonImage = LineLike;
        break;
    }
    case 'twitter-tweet':{
      buttonImage = TwitterTweet;
      break;
    }
    case 'twitter-follow':{
      buttonImage = TwitterFllow;
      break;
    }
    default:{
        buttonImage = null;
    }
  }
  return(
    <Aux>
    {buttonImage && 
      <div className={styles.SNSButton} >
        <img src={buttonImage} className={styles.SNSBtnImage} alt="SNS button" />
      </div>
    }
    </Aux>
  );
}

export default SNSButton;