import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styles from './Terms.module.css';

class Terms extends Component {
    render(){
        const {location} = this.props;
        let pathname = location.pathname;
        let title = '利用規約';
        let description = 'タグジャム利用規約はタグジャムを利用する上での大切な規約です。禁止事項など、ご利用の際には必ず確認・同意願います。';
        return(
        <>
        <Helmet>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`タグジャム-${title}`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:url" content={pathname} />
            <meta property="og:title" content={`タグジャム-${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:type" content="image/png" />
            <title>{title}</title>
            <meta
              name="description"
              content={description}
            />
            <meta content="タグジャム利用規約,規定,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
        </Helmet>
        <div className="MainPadding">
            <h2 className="PageHeader">
                利用規約
            </h2>
            <div className="Content">
                <div className="Item">
                    <h5>第１条（本規約の目的及び適用）</h5>
                    <ol>
                        <li>本利用規約（以下、「本規約」と言います。）は、タグジャム（以下、「当社」と言います。）が本ウェブサイト等（第２条において定義します。）で提供するサービス（以下、「本サービス」と言います。）に関して、本サービスを提供・運営する当社とサービス利用会員（第２条において定義します。）、及びサービス利用会員への商品（第２条において定義します。）の提供を行う者（第２条において「出品者」として定義します。）との間の権利義務関係を定めることを目的とし、本サービスの利用に係る一切の取引・関係に適用されます。</li>
                        <li>当社が本ウェブサイト等にて随時掲載する本サービスの利用等に関するルール、諸規定、お知らせ等は本規約の一部を構成するものとし、以下本規約内において本規約という場合には、特段の表示のない限り、これらを含むものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２条（定義）</h5>
                    <ol>
                        <li>本規約において使用する主要な用語の定義は、次のとおりとします。</li>
                    </ol>
                    <div className={styles.Word}>
                    <p><strong>本ウェブサイト等 : </strong> <br/>当社が「タグジャム」、「tagujam」、「TaguJam」、「tagu jam」又は「Tagu Jam」の名称で運営するウェブサイト及びモバイル版アプリケーションを意味します。</p>
                    <p><strong>サービス利用会員 : </strong> <br/>第４条に基づき会員登録を行った個人、法人及びその団体を意味します。</p>
                    <p><strong>出品者 : </strong> <br/>本サービスを通じて商品を出品するサービス利用会員を意味します。</p>
                    <p><strong>出品 : </strong> <br/>本サービスを利用するために、商品の内容となる商品の詳細情報等を出品者が本ウェブサイト等において公開し、本サービスを利用することを意味します。</p>
                    <p><strong>商品 : </strong> <br/>本サービスにおいて、出品者がサービス利用会員に対して提供する役務、出品者が物品の貸し出しを行うレンタルサービス、出品者がサービス利用会員に対して知識の講義・教授、または出品者がサービス利用会員に対して提供するものでこれらに類するものを意味します。</p>
                    <p><strong>サービス利用希望者 : </strong> <br/>本サービスにおけるサービス利用会員としての利用を希望する者。</p>
                    <p><strong>パスワード : </strong> <br/>サービス利用会員が会員登録申請時に任意に登録するパスワードを意味し、ログイン時の認証に必要となるものをいいます。</p>
                    <p><strong>ユーザーID : </strong> <br/>サービス利用希望者が会員登録申請時に登録情報として当社に提供したメールアドレスのアカウントを意味し、ログイン時の認証に必要となるものをいいます。</p>
                    <p><strong>情報登録画面 : </strong> <br/>サービス利用会員が会員情報、お支払い情報等又は、出品者が商品を販売する際の情報、又は各種確認書類の提出・承認状況の確認等を行う本ウェブサイト等における情報を登録する画面を意味します。</p>
                    <p><strong>購入申請画面 : </strong> <br/>サービス利用会員が商品購入を希望した場合に本ウェブサイト等における購入の情報を入力する画面を意味します。</p>
                    <p><strong>取引画面 : </strong> <br/>サービス利用会員が商品購入の際、取引の開始から決済までの一連のやり取りを出品者と行う本ウェブサイト等における画面を意味します。</p>
                    <p><strong>チャット画面 : </strong> <br/>サービス利用会員が商品購入の取引を出品者と行う場合、取引についてのメッセージを互いに送信し合う本ウェブサイト等における画面を意味します。</p>
                    <p><strong>提携広告メディア : </strong> <br/>当社と提携又は協力関係にある第三者が運営するウェブサイト及び情報誌等を含む任意の媒体を意味します。</p>
                    </div>
                </div>
                <div className="Item">
                    <h5>第３条（本サービスの内容）</h5>
                    <p>本サービスにおいて、サービス利用会員が利用できるサービスは次の通りです。</p>
                    <ul>
                        <li>出品及び商品の販売</li>
                        <li>出品者が提供する商品の利用及び購入</li>
                        <li>出品者への質問</li>
                        <li>その他当社が提供するサービス</li>
                    </ul>
                </div>
                <div className="Item">
                    <h5>第４条（会員登録）</h5>
                    <ol>
                        <li>本サービスにおけるサービスの利用は、サービス利用希望者が本規約に同意の上、当社の定める一定の情報（サービス利用会員又は出品に関する情報）を当社が定める方法（本ウェブサイト等の入力フォーム）によって利用・出品登録を申請し承認を待ちます。</li>
                        <li>当社が会員登録を承認するには本人確認書類の提出が必要です。当社の定める本人確認書類の画像を速やかに当社が定める方法（本ウェブサイト等の情報登録画面）によって提出し、当社がこれを承認することによって、利用登録が完了するものとします。</li>
                        <li>登録申請は、本サービスを利用する個人、法人又はその他の団体自身が行い、真実、正確な情報を当社に提供するものとします。</li>
                        <li>当社は、会員登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。</li>
                       <ol>
                           <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                           <li>本規約に違反したことがある者から、又は違反する恐れがある者からの申請である場合</li>
                           <li>未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合</li>
                           <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味する。以下同様とします。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合</li>
                           <li>その他、当社が会員登録を相当でないと判断した場合</li>
                        </ol> 
                    </ol>
                </div>
                <div className="Item">
                    <h5>第５条（登録情報の変更）</h5>
                    <p>サービス利用会員は、登録情報に変更があった場合は、速やかに、本ウェブサイト等の情報登録画面にて変更するものとする。変更できない項目は当社に変更の連絡をするものとします。</p>
                </div>
                <div className="Item">
                    <h5>第６条（ユーザーID及びパスワードの管理）</h5>
                    <ol>
                        <li>サービス利用会員は、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。</li>
                        <li>サービス利用会員は、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているサービス利用会員自身による利用とみなします。</li>
                        <li>ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第７条（本サービスの利用）</h5>
                    <ol>
                        <li>サービス利用会員は、本利用規約に従い、本サービスを利用するものとします。</li>
                        <li>本サービスの利用は本人が行うものとします。</li>
                        <li>サービス利用会員は本サービスにて公開された情報、又はチャット画面により発信された情報につき、一切の責任を追うものとし、当社に迷惑、損害を与えないものとします。</li>
                        <li>出品者が資格を必要とする商品を本サービスにて販売する場合は、当社の定める各種証明書類・資格書類の画像を速やかに当社が定める方法（本ウェブサイト等の情報登録画面）によって提出し、当社がこれを承認することによって、出品の登録が完了するものとします。</li>
                        <li>サービス利用会員が未成年、成年被後見人、被保佐人又は被補助人のいずれかである場合、本サービスの利用につき、個別の取引に係る申込を行う際は、必ず事前に親権者その他の法定代理人、後見人、保佐人または補助人の同意等を得るものとします。また、商品の提供業務を履行する出品者より当該同意等につき改めて確認が入る場合がございます。予めご了承ください。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第８条（利用停止）</h5>
                    <ol>
                        <li>サービス利用会員は、本利用規約に従い、本サービスを利用するものとします。当社がサービス利用会員が本利用規約に違反すると判断した場合は、当該サービス利用会員に事前に通知することなく、本サービスの利用停止、取引の中止措置などを講じることができるものとします。</li>
                        <li>当社は、理由の如何を問わず、前項に定める利用停止、取引の中止措置などによりサービス利用会員に生じた損害から、一切免責されるものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第９条（個人情報の取り扱い）</h5>
                    <p>当社は、本サービスの利用によって取得する個人情報については、当社の「プライバシーポリシー」に従い適切に取り扱うものとします。</p>
                </div>
                <div className="Item">
                    <h5>第１０条（購入の申請及び売買契約の成立）</h5>
                    <ol>
                        <li>サービス利用会員は、本サービスにおいて商品の購入を希望する場合、本サービス所定の申請方法（本ウェブサイト等で簡易見積もり計算後、購入申請画面）により購入を行うものとします。</li>
                        <li>商品の購入を希望するサービス利用会員（以下、「購入希望者」と言います。）より購入申請がなされると出品者に購入申請があった旨の通知が行われ、取引画面にて申請の内容が表示されます。</li>
                        <li>出品者はスケジュールや他の受注状況を確認して購入申請を受けるか否かの判断を行います。購入申請を拒否する事も可能です。</li>
                        <li>出品者が購入を受付けると取引画面にて購入希望者に購入申請が受け付けられた旨の表示が行われ、支払い情報の選択・登録へと進むことができます。</li>
                        <li>購入希望者が支払い情報の選択・登録を完了した時点で売買契約は成立します。</li>
                        <li>売買契約は出品者とサービス利用会員との間で成立します。なお、当社は、その売買契約の当事者となるものではなく、売買契約につき、売主又は買主のいずれの立場に関する責任も負いません。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１１条（キャンセル及び返品）</h5>
                    <ol>
                        <li>サービス利用会員は、原則として、前条による契約成立後、商品購入のキャンセル及び返品はできないものとします。ただし、サービス利用会員が成果に疑義を生じた場合で、当社が特に認めた場合には、返品を受け付けるものとします。</li>
                        <li>サービス利用会員は、前条による契約成立後、止むを得ない理由の場合のみ出品者の合意の上で、商品購入のキャンセルができます。ただし、当社のキャンセル料金の規定に基づきキャンセル料が発生し、その支払いが必要です。</li>
                        <li>前条による契約成立後、商品購入のキャンセルが発生した場合、出品者はキャンセル料の合計金額に当社の定める手数料率を乗じた額を差し引いた残金を受け取れる事とします。</li>
                        <li>出品者はサービス利用会員の購入申請時に希望した日時にサービスを提供できなかった場合はサービス利用会員の当該商品の購入の申込みは取り消されるものとし、これに異議を唱えないものとします。</li>
                        <li>サービス利用会員は、出品者に対して、商品の詳細に関する連絡を本ウェブサイト等のチャット画面により出品者に直接とり、商品の提供を求めることができるものとします。また、出品者からの請求金額に疑問がある場合は取引画面により出品者に請求金額の疑問点の説明を求め、支払いを保留する事が出来ます。</li>
                        <li>サービス利用会員が出品者の請求金額を取引画面により支払いを行い決済が完了した場合は請求金額に合意したものとみなし、その後の返金には応じてかねますので十分お気をつけ下さい。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１２条（利用料金）</h5>
                    <ol>
                        <li>サービス利用会員は、商品購入の際、当該商品に予め定められた料金（出品者が請求する金額）をサービスの対価として当社が指定する方法（取引画面）により支払うものとします。</li>
                        <li>出品者は当社が定める手数料（取引金額の合計に手数料の割合を乗じた金額）とその手数料に対する消費税を当社に支払うものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１３条 （支払方法と支払時期）</h5>
                    <ol>
                        <li>サービス利用会員の商品購入の際の商品代金の支払方法は、クレジットカード、その他の当社が本サービスにおいて利用可能なものとして指定するものとし、クレジットカードを使用する際には、サービス利用会員は、必ず本人名義のクレジットカードを使用するものとします。</li>
                        <li>クレジットカード等の決済手段の利用については、サービス利用会員と当該カード会社等との契約条件や利用規約に従うものとします。</li>
                        <li>サービス利用会員は、商品購入の際の支払いは、原則として出品者より請求書が取引画面を通じ表示された時点で速やかに行う事とします。出品者からの請求金額に疑問がある場合は取引画面で出品者に請求金額の疑問点の説明を求め、支払いを保留するものとします。また、請求金額の疑問点の解決がなされ、再度請求書が取引画面を通じ表示された場合はその時点で速やかに当社が指定する方法（取引画面）により支払うものとします。</li>
                        <li>出品者の手数料の支払いは、サービス利用会員の該当商品購入の際に取引画面での決済時に自動的に行い、手数料と係る消費税を控除した残金額を出品者に本ウェブサイト等の売り上げ画面に表示してお知らせします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１４条（サービス利用時の禁止事項）</h5>
                    <p>サービス利用会員は、本サービスの利用にあたり、以下の行為を禁止します。</p>
                    <ol>
                        <li>法令または公序良俗に違反する行為</li>
                        <li>所属する企業又は業界団体の内部規則等に違反する行為</li>
                        <li>犯罪に関連する行為</li>
                        <li>当社、サービス利用会員、他の出品者及びその他第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害又は侵害を助長する行為</li>
                        <li>政治的又は宗教的思想を含む情報を提供する行為及び政治的又は宗教的な勧誘を行う行為</li>
                        <li>ネットワークビジネス、マルチ商法、MLMのメンバーを募集、勧誘する行為</li>
                        <li>医師、看護師、弁護士、司法書士、行政書士、社会保険労務士、税理士などの資格を有しない出品者が、または資格はあるが登録がない出品者が、それらの相談を行う又はサービスを提供する行為</li>
                        <li>介護士または看護師の資格を有しない出品者が、介護サービス、又はこれに類するサービスを提供する行為</li>
                        <li>保育士または看護師の資格を有しない出品者、又は地方自治体に届出がない出品者が保育サービス、又はこれに類するサービスを提供する行為</li>
                        <li>美容師または理容師の資格を有しない出品者が、理・美容サービス、又はこれに類するサービスを提供する行為、又は、資格を有するが保健所に届出をしていない出品者が「訪問理・美容」サービス、又はこれに類するサービスを提供する行為</li>
                        <li>あん摩マツサージ指圧師、はり師、きゅう師の資格を有しない出品者が、特定の疾病、症状が治癒する等、医学的有効性をうたうサービス、又はマッサージを提供する行為</li>
                        <li>動物取扱業者の登録のない出品者が、ペットシッター（預かり・散歩等）又はこれに類するサービスを提供する行為</li>
                        <li>特定の法人、団体又は個人を非難又は誹謗中傷する行為</li>
                        <li>本サービスの他のサービス利用会員またはその他の第三者に不利益、損害、不快感を与える行為</li>
                        <li>サービス利用会員の目的と合致しない一般広告、情報を提供する行為及びサービス利用会員に誤解又は損害等を与えるおそれのある行為</li>
                        <li>サービス利用会員の目的と合致しない外部ウェブサイトへのハイパーリンクを設置する行為及び第三者のために有償で外部ウェブサイトへのハイパーリンクを設置する行為並びにこれらに類する行為</li>
                        <li>当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</li>
                        <li>当社が特別に許可をする本ウェブサイト等における所定のスペースを除き、出品者並びにそれらの親会社、子会社及び取引先等の第三者のメールアドレス又は電話番号等の連絡先を掲載し又は宣伝する行為及びこれらに類する行為</li>
                        <li>本サービスと競合するサービス等を宣伝する行為及びこれに類する行為</li>
                        <li>本サービスを通じて、第三者の運営するアフィリエイトプログラムに参加する行為及びこれに類する行為</li>
                        <li>本サービスにより知り得た情報（公知の情報を除きます。）を本サービスの目的の範囲外で利用する行為</li>
                        <li>本サービスによって得られた情報を商業的に利用する行為</li>
                        <li>検索エンジンスパム行為及び第三者の検索妨害エンジンスパム行為を直接又は間接的に助長する行為並びにこれらに類する行為</li>
                        <li>当社、ほかのサービス利用会員、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</li>
                        <li>不正アクセス、改ざん及びコンピューター・ウィルスや有害なコンピューター・プログラム等により本ウェブサイト等を攻撃する行為、またはこれを試みる行為</li>
                        <li>他のサービス利用会員に関する個人情報等を本サービス上での取引目的以外での取得、収集または蓄積する行為</li>
                        <li>サービス利用会員に関する個人情報をサービス利用会員から承諾を得た範囲を超えて、利用、提供又は開示等をする行為</li>
                        <li>当社ウェブサイト上に掲載する情報の全部又は一部に虚偽の表示を行う行為</li>
                        <li>不正な目的を持って本サービスを利用する行為</li>
                        <li>不当な要求を執拗に繰り返すなどして、出品者による応答や対応を強要する行為</li>
                        <li>一般的な社会常識に照らして重大な瑕疵のない商品等について、商品代金の支払い忌避を目的に商品の受け取りを拒否、または繰り返し支払いの保留を行う行為</li>
                        <li>他のサービス利用会員に成りすます行為</li>
                        <li>当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</li>
                        <li>本サービスの運営及び当社の業務を妨害するまたは妨害するおそれのある行為</li>
                        <li>猥褻な情報又は青少年に有害な情報の送信、その他猥褻又は青少年に有害な言動を発する行為</li>
                        <li>異性との出会いを目的とした行為</li>
                        <li>その他、当社が不適切と判断する行為</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１５条（禁止事項防止・対処方法）</h5>
                    <ol>
                        <li>当社は、サービス利用会員の本サービスの利用時の行為が前条各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、サービス利用会員に事前に通知することなく、当該行為の全部又は一部を停止させ、又は当該行為により当社ウェブサイト等に掲載された情報（本ウェブサイト等のチャット画面によるメッセージを含みますが、これに限られません。）の全部又は一部を削除する等係る違反行為を排除するあらゆる措置を講じることができるものとします。当社は、係る停止、削除等の措置を講じたことによりサービス利用会員に生じた損害から、一切免責されるものとします。</li>
                        <li>当社は、サービス利用会員の本サービスの利用時の行為が前条各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合、その他当社が必要と判断した場合には、本ウェブサイト等のチャット画面によるメッセージ内容を閲覧又は確認することができるものとし、サービス利用会員は予めこれに同意するものとします。</li>
                        <li>サービス利用会員は、取引画面の「このユーザーをブロックする」にて特定のサービス利用会員からのその後のコミュニケーションをブロックする事が出来ます。尚、ブロックされたサービス利用会員からは、「取引」画面でのメッセージの送信、サービス詳細画面からの「問合せ」、「購入申請」、「面接の申し込み」など、ブロックを講じたサービス利用会員に対するコミュニケーションが一切出来なくなる事に同意するものとします。</li>
                        <li>サービス利用会員は、取引画面の「取引の問題の報告」にて特定の取引についての問題を当社に報告する事が出来ます。通報を受けた当社の事務局は２４時間以内に調査を開始し、違反、又は違反する恐れがあると判断した場合は、該当サービス利用会員に事前に通知することなく、当該行為の全部又は一部を停止させ、又は当該行為により当社ウェブサイト等に掲載された情報（本ウェブサイト等のチャット画面によるメッセージを含みますが、これに限られません。）の全部又は一部を削除する等係る違反行為を排除するあらゆる措置を講じることができるものとします。当社は、係る停止、削除等の措置を講じたことによりサービス利用会員に生じた損害から、一切免責されるものとします。</li>
                        <li>サービス利用会員は、第１７条（出品者に関する規約）４号各項のいずれかに該当する違反した出品を発見した場合は、本ウェブサイト等の「不適切なサービスの報告」により通報する事ができます。通報を受けた当社の事務局は２４時間以内に調査を開始し、違反、又は違反する恐れがあると判断した場合は、出品者に事前に通知することなく、当社ウェブサイト等の掲載を削除出来る事とします。</li>
                        <li>サービス利用会員は、サービス利用会員の本サービスの利用時の行為が前条各号のいずれかに該当する違反行為を発見した場合は本ウェブサイト等の「不適切な行為の報告」により通報する事ができます。通報を受けた当社の事務局は２４時間以内に調査を開始し、違反、又は違反する恐れがあると判断した場合はサービス利用者に事前に通知することなく、該当サービス利用会員に対して、本サービスの全部もしくは一部の利用を制限し、またはサービス利用会員としての登録を抹消することができるものとします。</li>
                        <li>当社は、本サービスの運営及び保守管理並びに当社の事業上で必要と判断した場合、サービス利用会員の本サービスの利用履歴その他お問い合わせ内容、質問、サービス利用会員と出品者の本ウェブサイト等のチャット画面によるメッセージ内容を閲覧又は確認することができるものとし、サービス利用会員は予めこれに同意するものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１６条（利用制限及び登録取り消し）</h5>
                    <ol>
                        <li>当社は、サービス利用会員が以下のいずれかに該当する場合には、事前の通知なく、サービス利用会員に対して、本サービスの全部もしくは一部の利用を制限し、またはサービス利用会員としての登録を抹消することができるものとします。</li>
                        <ol>
                            <li>本規約のいずれかの条項に違反した場合</li>
                            <li>登録事項に虚偽の事実があることが判明した場合</li>
                            <li>過去にサービス利用会員の登録取消処分を受けたことがあることが判明した場合</li>
                            <li>サービス利用会員が本サービスの運営を妨害した場合</li>
                            <li>料金等の支払債務の不履行があった場合</li>
                            <li>当社からの連絡に対し、一定期間返答がない場合</li>
                            <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
                            <li>支払停止若しくは支払不能となり、又は破産、特別清算開始、会社更生手続開始、民事再生手続開始及びその他これらに準じる手続の開始申立てがあった又は係る手続の申立てがなされていることが判明した場合</li>
                            <li>自ら振出し、若しくは引受けた手形又は小切手につき、不渡りの処分を受けた場合</li>
                            <li>差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合</li>
                            <li>租税公課の滞納処分を受けた場合</li>
                            <li>死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合</li>
                            <li>第４条第４項各号に該当する場合</li>
                            <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
                        </ol>
                        <li>サービス利用会員は、原則として、いつでも当社の定める方法及び手順により当社に通知することで、会員登録の取消を申し出ることができるものとします。但し、サービス利用会員による商品提供を求める意思表示により、サービス利用会員に対する出品者の商品提供義務が発生し、係る関係に基づく債権債務関係が発生している場合、会員登録取消は、当該債権債務関係につき何らの影響も及ぼさないものとします。</li>
                        <li>当社は、本条に基づき当社が行った行為によりサービス利用会員に生じた損害について、一切の責任を負いません。</li>
                    </ol>
                </div>
                
                <div className="Item">
                    <h5>第１７条（出品者に関する規約）</h5>
                    <ol>
                        <li>出品者は、商品の代金を原則として一般的な社会常識に照らして妥当だと思われる金額を自由に決めることが出来ます。</li>
                        <li>出品者は、特定のサービスを出品する際に、当社が定める必要資格・証明書等の画像の提出を求められる場合があります。その場合は速やかに必要書類の提出を当社が定める提出方法（本ウェブサイト等の登録機能）により行うものとします。</li>
                        <li>当社は、出品者による出品情報登録後、商品の詳細情報、商品自体の品質および内容（名称、価格、写真等を含みます。）について、随時審査を行うものとします。出品者は審査にあたり、当社が出品者に対し商品に関する情報の提供を求めたときは、これに応じなければなりません。出品者がこれに応じない場合、当社は、出品者に対する事前通知をすることなく、当該審査の対象となった商品の出品を一時停止し、または削除することができるものとします。</li>
                        <li>当社は、出品が次の各号のいずれかに該当すると判断した場合は、出品を取り消すものとします。</li>
                        <ol>
                            <li>法令又は公序良俗に反する場合</li>
                            <li>特定の法人、団体又は個人を非難又は誹謗中傷する内容を含む場合</li>
                            <li>第三者の個人情報の売買または譲受にあたる場合、又はそれらの恐れのある場合</li>
                            <li>当社、サービス利用会員、他の出品者及びその他第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害又は侵害を助長する内容を含む場合</li>
                            <li>政治的又は宗教的思想を含む場合</li>
                            <li>サービス利用会員の目的と合致しない一般広告、情報を提供する行為及びサービス利用会員に誤解又は損害等を与えるおそれのある情報を含む場合</li>
                            <li>本サービスと競合するサービス等の宣伝する情報を含む場合</li>
                            <li>サービス利用会員の目的と合致しない外部ウェブサイトへのハイパーリンクを含む場合</li>
                            <li>コンピューター・ウィルスや有害なコンピューター・プログラム等を含む場合</li>
                            <li>猥褻な情報又は青少年に有害な情報を含む場合</li>
                            <li>異性交際に関する情報を含む場合</li>
                            <li>その他、当社が不適切と判断する場合</li>
                        </ol>
                        <li>第３項及び第４項に定める当社の停止若しくは削除又は前項に定める当社の出品取消により、出品者が損害を被ったとしても、当社は当該損害につき何らの賠償責任も負いません。</li>
                        <li>タグジャムにおける出品者(サービス提供者)向けの支払処理サービスは、Stripeが提供し、<a target="new" href="https://stripe.com/jp/connect-account/legal">Stripe Connectアカウント契約</a>（<a target="new" href="https://stripe.com/jp/legal">Stripe利用規約</a>を含み、総称して「Stripeサービス契約」といいます。）に従うものとします。本規約への同意又はタグジャムにおいて出品者としての取引の継続により、お客様はStripeサービス契約（随時Stripeにより修正されることがあり、その場合には修正されたものを含みます。）に拘束されることに同意するものとします。 Stripeを通じた支払処理サービスをタグジャムができるようにするための条件として、お客様は、タグジャムに対してお客様及びお客様の事業に関する正確かつ完全な情報を提供することに同意し、タグジャムが当該情報及びStripeが提供する支払処理サービスのお客様による使用に関連する取引情報を共有することを認めるものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１８条（振込申請及び手数料）</h5>
                    <ol>
                        <li>出品者が提供するサービスを購入したサービス利用会員（以下、「購入者」といいます。）による出品者に対する商品代金の支払いは、当社が出品者に代わり当該商品代金を受領し、それを当社が出品者に引渡す事とし、当社が商品代金を受領した時点その他当社が指定した時点で、購入者の出品者に対する商品代金の支払いは完了したものとします。</li>
                        <li>出品者は、本条において、本規約に定める条件に従い、当社または当社が指定する決済事業者及び収納代行業者に対し、サービス利用会員から支払われる商品代金を代理受領する権限を付与するものとします。さらに、出品者は、当社が決済事業者又は収納代行業者との間で締結する決済サービスに関する契約により必要となる場合には、商品代金債権を当社へ譲渡することに同意します。</li>
                        <li>出品者登録は無料とします。但し、当社の裁量による有料化への変更等、出品者に対する本サービス利用にかかる課金の可能性を否定するものではありません。但し、有料化への変更等が生じた場合は、当社は、当社が定める方法により出品者に対し事前に通知するものとします。</li>
                        <li>出品者は、本規約に別段の定めがある場合を除き、商品の代金が支払われた時点から120日以内に商品代金相当額の振込申請を行うものとします。当該振込申請がなされた場合には、当社は、当該振込申請の時点における商品代金相当額を、当社が代理受領した金銭を決済精算金の引き渡しとして、出品者が登録した金融機関の口座（以下、「登録銀行口座」といいます。）に振り込む方法により支払います。</li>
                        <li>当社が商品代金相当額の振込申請を求めたにもかかわらず、出品者が商品の代金が支払われた時点から120日を経過しても、当該振込申請を行わなかった場合、当社は登録銀行口座に当該商品代金相当額を振り込む方法により支払います。当社が本項に基づき振込を行ったにもかかわらず、当社の責めに帰すべき事由なく振り込みが正常に完了しない場合には、当社は、当該出品者が当該商品代金相当額の支払請求権を放棄したものとみなすことができるものとします。なお、商品代金相当額の振込申請に当たっては本人確認を求めることがあり、確認が終了するまで振込を留保することがございますが、この場合には、当社が係る留保を行っている間は本項は適用されないものとします。</li>
                        <li>振込金額の確定については、振込申請日時点での商品代金相当額の総額から、当社または当社が指定する振込事業者の規定する銀行振込手数料を差し引いた金額を、振込金額として確定するものとします。</li>
                        <li>振込の時期は、振込申請日から所定の営業日以内に登録銀行口座に振り込むものとします。</li>
                        <li>商品代金相当額、振込金額等の支払いに関する情報は、本ウェブサイト等の売上または、送金機能にて確認することができます。</li>
                        <li>登録銀行口座に誤りがあった場合、当社は、原則として、所定の組戻し手数料を振込金額から減算して振り込みます。</li>
                        <li>振込金額が当社指定の基準金額（以下、「振込可能基準額」といいます。）を下回っている場合、原則として振込申請はできないものとします。ただし、出品者登録の取消や失効の際は、振込金額が振込可能基準額を下回っている場合においても、商品代金相当額から所定の銀行振込手数料を差し引いた振込金額が1円以上となる場合は振込申請を可能とします。</li>
                        <li>出品者登録の取消や失効の時点において、振込申請をすることが可能な代金がある場合には、出品者は、取消や失効の時点から1ヶ月間に限り、振込申請をすることができるものとします　その場合は当社の問い合わせメールにて連絡する事とします。当社が本項に基づき振込を行ったにもかかわらず、当社の責めに帰すべき事由なく振り込みが正常に完了しない場合には、当社は、当該出品者が当該代金の支払請求権を放棄したものとみなすことができるものとします。</li>
                        <li>出品者は、当社に対し、手数料として、商品代金相当額に当社が定める手数料率を乗じた金額を支払うものとします。</li>
                        <li>前項に定める手数料の支払いは、当社が、売買契約成立時に、本条に基づき当社が出品者に支払うべき金額から当該手数料を控除する方法により行うものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第１９条（知的財産権の帰属及び著作物等の利用）</h5>
                    <ol>
                        <li>本ウェブサイト等及び本サービスに係る機能、デザイン、記事等に係る著作権等の知的財産権は、当社と出品者との関係において全て当社又は当社に対して知的財産権の利用を許諾した第三者に帰属するものとします。なお、本規約に基づく本サービスの利用許諾は、本規約において明示的に認められた範囲を超えて、当社ウェブサイト等及び本サービスに係る当社の知的財産権の利用許諾を意味するものではありません。</li>
                        <li>本サービスに関する集計データ並びに出品者及びサービス利用会員に関する集計データの利用等に係る一切の権利は、当社に帰属するものとします。</li>
                        <li>出品者著作物等に係る知的財産権は全て出品者に帰属するものとします。但し、当社及び当社の指定する者は、出品者著作物等について、当社及び当社の指定する者による出品者著作物等の利用に際して、当社は、出品者著作物等をそのまま複製し、公衆送信等を行うだけでなく、掲載又は転載等の利用を行う媒体の仕様、デザイン又はコンセプト等に合わせるために、出品者著作物等を修正又は改変等を行うことができるものとします。また、出品者は、出品者著作物等について、当社及び当社の指定する者に対して上記の利用につき異議を述べないものとします。</li>
                        <li>出品者は、サービス利用会員に対し、出品者が提供した商品に関する出品者著作物等につき、サービス利用会員自らの使用のためにのみ、自由に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することのできる権利を許諾するものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２０条（肖像権等）</h5>
                    <ol>
                        <li>出品者は、当社が当社ウェブサイト及び提携広告メディアをはじめ、オンライン・オフラインの別を問わない任意の媒体において、本サービスに出品者登録された出品者の写真、氏名（ペンネーム等の変名も含みます。以下、本条において同様とします。）、経歴その他登録情報の内容（以下「出品者プロフィール情報」といいます。）を公開することを予め承諾するものとし、当社は期間、態様を問わず、無償にて任意の目的（公衆送信、放送、本サービスの広告・宣伝等を含みますが、これらに限られません。）で、出品者プロフィール情報を利用（第三者への使用許諾を含みます。）できるものとします。</li>
                        <li>前項に定める当社による出品者プロフィール情報の利用につき、出品者に生じた損害について、当社は一切責任を負わないものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２１条（権利等の保証）</h5>
                    <ol>
                        <li>出品者は、出品者による本サービスの利用、本規約第１９条及び第２０条に基づく当社による利用を含む出品者著作物等及び出品者プロフィール情報の利用、その他本規約に基づく出品者の権利の行使又は義務の履行が、法令、出品者の所属する業界団体の規則等又は出品者と第三者との契約に違反せず、また第三者の知的財産権、人格権、名誉権、プライバシー権、パブリシティ権その他一切の権利を侵害しないことを保証する事とします。</li>
                        <li>出品者は、出品者著作物等に関して、次のとおり保証する事とします。</li>
                        <ol>
                            <li>法令、出品者の所属する業界団体の規則又は第三者との契約に違反していないこと</li>
                            <li>公序良俗に違反していないこと</li>
                            <li>猥褻な内容を含まないこと</li>
                            <li>特定の法人、団体及び個人を攻撃し、又は誹謗中傷していないこと</li>
                            <li>他人のプライバシーを侵害していないこと</li>
                            <li>虚偽の内容を含まないこと</li>
                            <li>差別的な表現を含まないこと</li>
                            <li>出品者著作物等の公表等が犯罪行為とならないこと</li>
                        </ol>
                        <li>出品者は、前2項に反する事実を発見した場合には直ちに当社に通知するものとし、万一前2項に関して又は出品者の本サービスの利用に起因して第三者から異議、苦情の申立あるいは実費又は対価の請求、損害賠償請求等があった場合には、当社に直ちに通知するとともに、弁護士費用等を含めて出品者の費用と責任においてこれを処理するものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２２条（設備等）</h5>
                    <ol>
                        <li>サービス利用会員は、本サービスを利用するために必要な備品、設備、通信機器、ソフトウェア、SNSアカウント、その他これらに付随して必要となる一切の機器及び利用環境等を、自己の費用と責任において準備し、本サービスが利用可能な状態を維持するものとします。なお、サービス利用会員が選択した利用環境により本サービスが利用できない場合でも、当社は、何らの責任も負わないものとします。</li>
                        <li>サービス利用会員は、自己の費用と責任において、任意の電気通信サービスを経由して本サービスの利用環境に接続し、本サービスを利用するものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２３条（機密保護）</h5>
                    <ol>
                        <li>本規約において「機密情報」とは、登録完了の先後を問わず、本サービスに関して、サービス利用会員が当社より書面、口頭、電子メール、その他電磁的・光学的記録媒体等の有形な媒体により提供された情報（個人情報を含みます。）、技術データ又はノウハウ（これらには、サービス、ユーザ、市場、デザイン、マーケティング等に関する情報等を含みますが、以上の例示に限りません。）を意味します。</li>
                        <li>サービス利用会員は、当社の事前の書面による承諾なく、機密情報を第三者に一切開示し又は漏洩してはならないものとします。但し、以下の各号の情報は機密情報から除外するものとします。</li>
                        <ol>
                            <li>当社から開示された時点で、既に自ら保有していた情報</li>
                            <li>当社から開示された時点で、公知であった情報その他一般に利用可能となっていた情報</li>
                            <li>当社から開示された後に、サービス利用会員又は出品者の自己の責によらず公知となった情報その他一般に利用可能となった情報</li>
                            <li>当社から開示された後に、第三者から機密保持義務を負うことなく合法的に入手した情報</li>
                            <li>当社から開示された情報に基づかず、独自に開発した情報</li>
                            <li>当社が、機密情報としての扱いから除外することをサービス利用会員へ通知した情報</li>
                        </ol>
                        <li>サービス利用会員は、当社の書面による事前の承諾がない限り、本サービスの利用に係る目的以外に機密情報を使用しないものとします。</li>
                        <li>サービス利用会員は、善良なる管理者の注意をもって機密情報を厳重に機密として管理・利用するものとします。</li>
                        <li>サービス利用会員は、当社から要求があった場合、直ちにすべての機密情報を当社に返却し又は当社の指示に従い、機密漏洩に十分に配慮した方法で廃棄するものとします。</li>
                        <li>サービス利用会員が、法令により開示を求められた場合、または裁判所、警察等の公的機関から開示を求められた場合、係る求めに応じた開示は、本条の機密保持義務の対象外とします。但し、サービス利用会員は、開示を求められた事実を遅滞なく当社に通知するものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２４条（個人情報）</h5>
                    <ol>
                        <li>サービス利用会員は、本サービスを通じて知り得たサービス利用会員の個人情報を厳重に管理し、第三者に開示又は漏洩してはならないものとします。</li>
                        <li>サービス利用会員は、本サービスを通じて知り得たサービス利用会員の個人情報を、サービス利用会員から明示的に依頼された業務を履行する以外の目的及び当社が別途定める目的以外の目的で利用してはならないものとします。但し、当該サービス利用会員による事前の同意がある場合は除きます。</li>
                        <li>サービス利用会員は、当社が提供する情報の範囲を超えて、本サービスを通じてサービス利用会員の個人情報を取得しようとする場合、事前かつ個別に利用目的を明示しなければならないものとします。</li>
                        <li>サービス利用会員は、当社が求めたときは、直ちにサービス利用会員又は出品者の取り扱っているサービス利用会員の個人情報の取得状況、管理状況等について当社に報告するものとします。</li>
                        <li>サービス利用会員は、本条に違反する事態が生じ、又は生じるおそれがあると知ったときは、直ちにその旨を当社に報告するとともに、それに対する当社の指示に従うものとします。</li>
                        <li>サービス利用会員は、万一サービス利用会員の管理するサービス利用会員の個人情報が他に漏洩したことが発覚した場合には、直ちにその内容を当社に通知するものとし、係る事態に起因しサービス利用会員及び第三者から異議、苦情の申立あるいは実費又は対価の請求、損害賠償請求等があった場合には、当社に直ちに通知するとともに、弁護士費用等を含めてサービス利用会員の費用と責任においてこれを処理するものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２５条（表示）</h5>
                    <p>サービス利用会員は、広告、名刺、郵便物その他一切の媒体に関し、当社の名称又は当社との関係を表示する場合には、事前に当社が定めた表示方法及び表示内容による場合を除き、その可否、表示内容及び表示方法等について、事前に当社の承諾を求めるものとし、当該承諾に付帯する当社の指示に従うものとします。</p>
                </div>
                <div className="Item">
                    <h5>第２６条（権利義務の譲渡の禁止）</h5>
                    <p>サービス利用会員は、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡あるいは担保提供、又は引受けさせる等の処分を行ってはならないものとします。</p>
                </div>
                <div className="Item">
                    <h5>第２７条（本サービスの提供の中断、停止又は終了）</h5>
                    <ol>
                        <li>当社は、以下のいずれかの事由があると判断した場合、サービス利用会員に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</li>
                        <ol>
                            <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                            <li>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
                            <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                            <li>その他、当社が本サービスの提供が困難と判断した場合</li>
                        </ol>
                        <li>当社は、本サービスの運営上の理由等により、サービス利用会員に対して事前に通した上で、本サービスの全部を終了することができるものとします。</li>
                        <li>当社は、本サービスの提供の停止または中断または終了により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第２８条（情報の保存についての注意）</h5>
                    <p>商品その他の情報は、当社所定の期間に限り、本サービス上に保存されるものとします。当社は、かかる期間を超えて商品その他の情報を保存する義務を負うものではなく、当社はかかる期間を経過した後はいつでもこれらの情報を削除できるものとします。なお、当社は、本条に基づき当社が行った措置に基づきサービス利用会員に生じた損害について一切の責任を負いません。</p>
                </div>
                <div className="Item">
                    <h5>第２９条（サービスの更新）</h5>
                    <ol>
                        <li>当社は、本ウェブサイト等を構成する枠組み、機能、デザイン、記事等の内容、その他一切の本サービス運営上の仕様について、当社の判断により自由に変更することができるものとします。</li>
                        <li>当社は、理由の如何を問わず、前項に定める変更により、サービス利用会員に生じた損害から一切免責されるものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第３０条（本規約等の変更）</h5>
                    <ol>
                        <li>当社は、必要と判断した場合には、いつでも本規約を変更することができるものとします。なお、当社は、本規約を変更した場合には、サービス利用会員に対して通知を行い、又は当社ウェブサイトに掲載するものとします。変更された本規約の効力は、変更後の本規約が当社ウェブサイトに掲載された時より生ずるものとします。</li>
                        <li>当社は、前項の当該改定又は変更の通知後、サービス利用会員が本サービスを利用した場合又はサービス利用会員が当社の定める期間内（定めがない場合は通知の日から30 日間）に会員登録取消又は出品者登録取消の手続をとらなかった場合には、当該改定又は変更の内容に同意したものとみなします。</li>
                        <li>当社は、理由の如何を問わず、本規約の改定又は変更に起因しサービス利用会員が会員登録取消又は出品者登録取消の手続をとったことによりサービス利用会員に生じた損害から一切免責されるものとします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第３１条（通知または連絡）</h5>
                    <p>サービス利用会員と当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、サービス利用会員から、当社が別途定める方式に従った変更届け出（本ウェブサイト等の登録画面での変更）がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にサービス利用会員へ到達したものとみなします。</p>
                </div>
                <div className="Item">
                    <h5>第３２条（当社の保証の否認及び免責）</h5>
                    <ol>
                        <li>当社は、サービス利用会員間で行われる商品提供、情報の送受信、コミュニケーション等に伴いサービス利用会員が何らかの損害を被ったとしても、当社は生じた損害について一切補償を行いません。</li>
                        <li>出品者は、本サービスの利用が出品者の商品提供等に適用される法令及び出品者の所属する業界団体の規則等に違反するか否かを自己の費用と責任により調査するものとし、当社は、出品者による本サービスの利用が出品者の商品提供等に適用される法令及び出品者の所属する業界団体の規則等に照らし、適法であること又は規則等に違反しないことを保証しません。</li>
                        <li>出品者は、自らの出品及び商品に必要な資格、免許、許可等の有無及び法令その他の規制の有無を自らの責任において調査するものとします。出品者がかかる資格、免許、許可等を取得しなかったことにより出品者に発生した損害及び出品者が法令その他の規制に違反したことにより出品者が損害につき、当社は何らの賠償責任も負いません。</li>
                        <li>当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを合理的な範囲内で確認、システム維持に勤めますが、明示的にも黙示的にもそれらがないことを保証はしておりません。</li>
                        <li>当社は、本サービスに起因してサービス利用会員に生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社とサービス利用会員との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。</li>
                        <li>前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりサービス利用会員に生じた損害のうち特別な事情から生じた損害（当社またはサービス利用会員が損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりサービス利用会員に生じた損害の賠償は、サービス利用会員から当該損害が発生した取引に対し受領した利用料の額を上限とします。</li>
                    </ol>
                </div>
                <div className="Item">
                    <h5>第３３条（損害賠償）</h5>
                    <ul>
                        <li>サービス利用会員は、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対し、その損害を賠償するものとします。また、サービス利用会員が雇用している従業員等が本規約に違反して当社に損害を与えた場合も同様とします。</li>
                        <li>サービス利用会員が、本サービスの利用に関連してサービス利用会員その他の者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、自らの費用と責任において当該クレーム又は紛争を処理し、その結果を当社に報告するものとします。</li>
                        <li>サービス利用会員による本サービスの利用に起因して、当社が、第三者から権利侵害その他の理由により何らかの損害賠償請求を受けた場合は、当該サービス利用会員は当該請求に基づき当社が第三者に支払いを余儀なくされた金額及びかかる事由に起因し当社において生じた又は負担した一切の損害及び費用（弁護士費用等を含みますが、これらに限りません。）を賠償するものとします。</li>
                        <li>消費者契約法の適用その他の理由により、本条その他当社の損害賠償責任を免責する規定にかかわらず当社がサービス利用会員に対し何らかの損害賠償義務を負う場合においても、当社のサービス利用会員に対する損害賠償の金額は、当社がその損害賠償責任の原因事由が生じた時点から遡って１年以内にサービス利用会員より実際に受領した手数料の合計額を上限とします。</li>
                    </ul>
                </div>
                <div className="Item">
                    <h5>第３４条（準拠法・裁判管轄）</h5>
                    <ol>
                        <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
                        <li>本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。</li>
                    </ol>
                </div>
                <p>2020年03月22日　改訂・施行</p>
                <p>2019年09月01日　制訂・施行</p>
            </div>
        </div>
        </>
        );
    }
}

export default Terms;