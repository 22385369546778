import moment from 'moment';

export default class TaguJamApi  {
    constructor(){
        //firebase path set up
        this.googleApiURL = process.env.REACT_APP_GOOGLE_API_URL;
        this.firebaseAPI_KEY = process.env.REACT_APP_FB_API_KEY;
        this.tokenApiURL = process.env.REACT_APP_TOKEN_API_URL;
        this.firebaseRoot = process.env.REACT_APP_FB_DATABASEURL;
        this.firebaseFunctionURL = process.env.REACT_APP_FB_FUNCTION_URL;
    }

    normalizedData = (Data) => {
        let DataList = [];
        for(let key in Data){
            const data = {
                ...Data[key],
                id: key
            }
            DataList.push(data);
        }
        return DataList;
    }
    
    local2ISO3166Code = (localCode) => {
        const local = {
            ja_jp:"jp",
            en_us:"us"    
        }
        return local[localCode];
    }

    localCurrency = (localCode) => {
        const local = {
            ja_jp:"jpy",
            en_us:"usd"    
        }
        return local[localCode];
    }

    getCurrentyUnit = (localCode) => {
        switch (localCode) {
            case 'jp_ja': return 'jpy'
            case 'en_us': return 'usd'
            default: return 'jpy'
        }
    }

    convertToArrayData = (Data) => {
        let DataList = [];
        for(let key in Data){
            DataList.push({id: key});
        }
        return DataList;
    }

    getFBLanguageCode = (localCode) => {
        const local = {
            ja_jp:"ja",
            en_us:"en"    
        }
        return local[localCode];
    }

    async tryAuth(authData, mode){
        const {email, password, platform, today, ipAddress} = authData;
        // mode could be "SIGNIN" ? "SIGNUP"
        const urlString = mode==="SIGNIN" ? "signInWithPassword" : "signUp";
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:${urlString}?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        email,
                        password,
                        returnSecureToken: true
                    }),
                    headers:{
                        "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            let { idToken, localId } = data;
            if(mode === 'SIGNUP'){
                // eslint-disable-next-line no-unused-vars
                let metaResult = await fetch(
                    `${this.firebaseRoot}/userMeta/${localId}.json?auth=${idToken}`,
                    {
                        method:"PUT",
                        body: JSON.stringify({
                            active:true,
                            id: localId,
                            preference: {
                                showDealingOnly:true,
                                showActiveInterviewOnly:true,
                                showActiveQuestionOnly:true     
                            },
                            mailSubscription:{
                                campaignMail:false,
                                dealPending:false,
                                systemNotification:false     
                            },
                            fcmTokens: {
                                [platform]:"weblogin"    
                            }
                        }),
                        headers:{
                            "Content-Type": "application/json"
                        }
                    }
                );
                let userResult = await fetch(
                    `${this.firebaseRoot}/users/${localId}.json?auth=${idToken}`,
                    {
                        method:"PUT",
                        body: JSON.stringify({
                            tos_acceptance: {
                                date: today,
                                ip: ipAddress
                            },
                            email:email
                        }),
                        headers:{
                          "Content-Type": "application/json"
                        }
                    }
                );
                console.log(userResult);
            }
            return data;
        } catch (err){
            throw err;
        }
    }

    async sendResetPasswordMsg(email, localCode) {
        let lang = this.getFBLanguageCode(localCode);
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:sendOobCode?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        requestType:"PASSWORD_RESET",
                        email
                    }),
                    headers:{
                        "Content-Type": "application/json",
                        "X-Firebase-Locale":lang
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("sendResetPasswordMsgResult:", data);
                return data;
            } else {
                console.log("data fetch error");
                throw data.error;    
            }
        } catch (err){
            throw err;
        }   
    }

    async updatePassword(newPassword, token) {
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:update?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        idToken:token,
                        password: newPassword,
                        returnSecureToken: true
                    }),
                    headers:{
                        "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("update password:", data);
                return data;
            } else {
                console.log("update password error");
                throw data.error;    
            }
        } catch (err){
            throw err;
        }   
    }

    async updateEmail(newEmail, userId, token) {
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:update?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        idToken:token,
                        email: newEmail,
                        returnSecureToken: true
                    }),
                    headers:{
                        "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                let theUserInfoRes = await fetch(`${this.firebaseRoot}/users/${userId}/userInfo.json?auth=${token}`);
                let theUserInfo = await theUserInfoRes.json();
                if(theUserInfo.error){
                    throw theUserInfo.error;
                }
                console.log("update Email success:", data);
                let promiseArray = [];
                promiseArray.push(
                    fetch(
                        `${this.firebaseRoot}/users/${userId}.json?auth=${token}`,
                        {
                            method:"PATCH",
                            body: JSON.stringify({
                                email: newEmail,
                                userInfo:{
                                    ...theUserInfo,
                                    emailValidateCode:null,
                                    emailVerifyStatus:null
                                }
                            }),
                            headers:{
                                "Content-Type": "application/json"
                            }
                        }
                    )
                );
                promiseArray.push(
                    fetch(
                        `${this.firebaseRoot}/userMeta/${userId}.json?auth=${token}`,
                        {
                            method:"PATCH",
                            body: JSON.stringify({
                                mailSubscription:{
                                    campaignMail:false,
                                    dealPending:false,
                                    systemNotification:false     
                                },
                                verifyMethod : {email: false}
                            }),
                            headers:{
                                "Content-Type": "application/json"
                            }
                        }
                    )
                );
                let [userRes, userMetaRes] = await Promise.all(promiseArray);
                const data2 = userRes.json();
                const data3 = userMetaRes.json();
                if(data2.error || data3.error){
                    if(data2.error){
                        throw data2.error;
                    }
                    if(data3.error){
                        throw data3.error;
                    }   
                } else {
                    return data;
                }
            } else {
                console.log("update Email error");
                throw data.error;    
            }
        } catch (err){
            throw err;
        }   
    }

    async updateOnlineStatus(userId, value, token){
        try {
            let now = moment().format();
            let params = {
                online:value
            }
            if(value){
                params = {
                    ...params,
                    lastLogInDate:now
                }
            }
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify(params),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async fetchGigs() {
        try {
            let res = await fetch(`${this.firebaseRoot}/gigs.json`);
            let data = await res.json();
            let normalized_data = this.normalizedData(data);
            return normalized_data;
        } catch(err) {
            console.log(err)
            throw err;
        }
    }

    async fetchTheGig(id) {
        try {
            let res = await fetch(`${this.firebaseRoot}/gigs/${id}.json`);
            let data = await res.json();
            return data;
        } catch(err) {
            console.log(err)
            throw err;
        }
    }

    async fetchUserMeta(){
        try {
            let res = await fetch(`${this.firebaseRoot}/userMeta.json`);
            let data = res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            throw err;
        }
    }

    async fetchTheUserMeta(userId){
        try {
            console.log(userId);
            let res = await fetch(`${this.firebaseRoot}/userMeta/${userId}.json`);
            let data = res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return fetchTheUserMeta data");
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            throw err;
        }
    }

    async saveMailSubscriptionByKey (userId, key, value, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}/mailSubscription.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify({
                        [key]:value
                    }),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            throw err;
        }
    }

    async savePreferenceByKey (userId, key, value, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}/preference.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify({
                        [key]:value
                    }),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            throw err;
        }
    }

    async fetchAccountInfo(userId){
        try {
            let res = await fetch(`${this.firebaseRoot}/userMeta/${userId}/accountInfo.json`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async uploadImage( image, destination, imagename, token ){
        try {
            let res = await fetch(
                `${this.firebaseFunctionURL}/storeImage`,
                {
                    method:'POST',
                    body: JSON.stringify({
                        destination: destination,
                        imagename: imagename,
                        image: image.base64
                    }),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            if(!data.imageUrl){
                throw new Error("image upload failed");
            } else {
                return data.imageUrl;    
            }
        } catch (err) {
            throw err;
        }
    }

    async saveAccountInfo(accountInfoData, userId, token){
        try {
            const { displayName, selfPR, avatorImage } = accountInfoData;
            const destination = `/avators/${userId}/`;
            const imagename = 'avator';
            let imageUrl;
            if(avatorImage.base64){
                imageUrl = await this.uploadImage(avatorImage, destination, imagename, token);
            } else {
                imageUrl = avatorImage.avator;
            }
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}/accountInfo.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify({
                        displayName: displayName,
                        selfPR: selfPR,
                        avator: imageUrl
                    }),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            //console.log(err)
            console.log("catch block due to error");
            throw err;    
        }
    }

    async fetchUserInfo(id, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${id}/userInfo.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async fetchUserBankMeta(id, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${id}/bank.json?auth=${token}`);
            let data = await res.json();
            console.log("res", res);
            if(data){
                if(!data.error){
                    return data;
                } else {
                    console.log("fetch User BankMeta error");
                    throw new Error("fetch User BankMeta");    
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async saveRecipient( accountData, userId, recipientId, token ){
        try {
            const saveData = {
                userId: userId,
                recipientId: recipientId,
                accountData
            };
           
            let res = await fetch(
                `${this.firebaseFunctionURL}/storeRecipient`,//`${this.taguJamApiURL}/recipients`,
                {
                    method:'POST',
                    body: JSON.stringify(saveData),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            //console.log(data);
            if(!data.recipientId){
                throw new Error("recipient creation failed");
            } else {
                return data;    
            }
        } catch (err) {
            throw err;
        }
    }

    async saveUserInfo(userInfoData, id, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/userInfo.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(userInfoData),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("storing verification error");
                throw new Error(data.error);
            }   
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async saveVerifyStatus(id, verifyStatus, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/userInfo/emailVerifyStatus.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(verifyStatus),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return verifyStatus;        
            } else {
                console.log("storing verification error");
                throw new Error(data.error);
            }       
        } catch (err) {
            throw err;
        }    
    }

    async sendEmail(email, mode, token) {
        try {
            let functionName = (mode==='verifyEmail') ? 'sendEmailValification' : 'sendOrderComfirmation';
            let res = await fetch(
                `${this.firebaseFunctionURL}/${functionName}`,
                {
                    method:'POST',
                    body: JSON.stringify({
                        email: email
                    }),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            if(!data.emailCode){
                throw new Error("sending verification code failed");
            } else {
                return data.emailCode; 
            }    
        } catch (err) {
            throw err;
        }
    } 

    async sendEmailVerifyCode(id, email, token){
        try {
            let mode = 'verifyEmail';
            let emailCode = await this.sendEmail(email, mode, token);
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/userInfo/emailValidateCode.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(emailCode),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return emailCode;        
            } else {
                console.log("storing verification error");
                throw new Error(data.error);
            }       
        } catch (err) {
            throw err;
        }
    }

    async fetchBank(id, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${id}/bank.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data");
                    let bankInfo = null;
                    if(data.recipientId){
                        let bankRes = await fetch(
                            `${this.firebaseFunctionURL}/getRecipient/?recipientId=${data.recipientId}`,//`${this.taguJamApiURL}/recipients/${data.recipientId}`,
                            {
                                method:'GET',
                                headers: {
                                    "Authorization": `Bearer ${token}`
                                }
                            }
                        )
                        bankInfo = await bankRes.json();
                    }
                    if(data.document){
                        bankInfo = {
                            ...bankInfo,
                            document:data.document
                        }
                    }
                    return bankInfo;       
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async fetchUserAddress(id, token, type){
        try {
            let url = type ? `${this.firebaseRoot}/users/${id}/address/${type.toLowerCase()}.json?auth=${token}` : `${this.firebaseRoot}/users/${id}/address.json?auth=${token}`;
            let res = await fetch(url);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async saveUserAddress(addressData, id, token){
        try {
            let type = addressData.addressType.toLowerCase();
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/address/${type}.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(addressData),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async deleteUserAddress(type, id, token){
        try {
            let result = await fetch(
                `${this.firebaseRoot}/users/${id}/address/${type}.json?auth=${token}`,
                {
                    method:"DELETE"
                }
            );
            //let result = await res.json(); 
            console.log("result", result);
            if(result.ok){
                return result;
            } else {
                console.log("data delete error");
                throw new Error("Data delete error");    
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async fetchCertificate(userId, token, certificateType){
        try {
            let url = certificateType 
                    ? `${this.firebaseRoot}/users/${userId}/certificate/${certificateType.toLowerCase()}.json?auth=${token}`
                    : `${this.firebaseRoot}/users/${userId}/certificate.json?auth=${token}`;
            let res = await fetch(url);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data");
                    return data;       
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async saveCertificate(certificateData, userId, token){
        try {
            const { certificateType, hasExpireDate, verifyStatus, expireDate, certImages } = certificateData;
            let response = await Promise.all(certImages.map(async (image, index) => {
                try{
                    if(image.base64){
                        const destination = `/certificates/${userId}/${certificateType.toLowerCase()}/`;
                        const imagename = `${certificateType.toLowerCase()}_${index}`; 
                        let cert = await this.uploadImage(image, destination, imagename, token);
                        return cert;
                    } else {
                        let Path=null;
                        if(image.cert && image.cert.startsWith("https://firebasestorage.googleapis.com")){
                            Path = image.cert;
                        } 
                        return Path;       
                    }
                } catch (err) {
                    console.log('err', err);
                    throw new Error(err);
                }
            }));

            let newCertImages= {...certImages};
            response.map( (cert, index) => {
                newCertImages = {
                    ...newCertImages,
                    [index]: { cert: cert, base64:null }
                }
                return true;
            });
            let res = await fetch(
                `${this.firebaseRoot}/users/${userId}/certificate/${certificateType.toLowerCase()}.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify({
                        certificateType, 
                        hasExpireDate,
                        verifyStatus, 
                        expireDate: hasExpireDate ? expireDate : null,
                        certImages: newCertImages
                    }),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            //console.log(err)
            console.log("catch block due to error");
            throw err;    
        }
    }

    async fetchPayment(userId, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${userId}/payment.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    let customerCardRes = await fetch(
                        `${this.firebaseFunctionURL}/getCustomerWidthCard/?customerId=${data.customerId}`,// `${this.taguJamApiURL}/customers/${data.customerId}`,
                        {
                            method:'GET',
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );
                    let customerCard = await customerCardRes.json();
                    return customerCard;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data return case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async createCustomerWithCard(paymentData, cardToken, userId, token, localCode){
        try{
            const {cardHolderName, customerId, cardId} = paymentData;
            console.log("card token:", cardToken);
            const customerData = {
                'description': `${cardHolderName} (id: ${userId})`,
                'card': cardToken, //tokenId
                'customerId': customerId,
                'cardId': cardId,
                'preferred_locale': this.local2ISO3166Code(localCode)
            };
            let res = await fetch(
                `${this.firebaseFunctionURL}/storeCustomerWithCard/`,//`${this.taguJamApiURL}/customers`,
                {
                    method:'POST',
                    body: JSON.stringify(customerData),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            console.log(data);
            if(!data.customerId){
                throw new Error("customer creation failed");
            } else {
                return data;    
            }
        } catch(err){
            console.log("customer creating error");
            throw err;
        }
    }

    async savePayment(paymentData, tokenId, userId, token, localCode){
        try {
            let customer = await this.createCustomerWithCard(paymentData, tokenId, userId, token, localCode);
            let res = await fetch(
                `${this.firebaseRoot}/users/${userId}/payment.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify({
                        cardHolderName: paymentData.cardHolderName,
                        customerId: customer.customerId,
                        cardId: customer.cardId
                    }),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return customer; // return full data     
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async saveBank(saveData, userId, recipientId, token){
        try {
            let recipient = await this.saveRecipient(saveData, userId, recipientId, token);
            let savebankData = {
                bankId: recipient.bankId,
                recipientId:recipient.recipientId,
                accountName:recipient.accountName,
                active:recipient.active,
                verified: recipient.verified
            }
            if(saveData.document){
                savebankData = {
                    ...savebankData,
                    document: {
                        ...saveData.document    
                    }
                }    
            }
            let res = await fetch(
                `${this.firebaseRoot}/users/${userId}/bank.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(savebankData),
                    headers:{
                      "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data");
                return recipient; // return full data instead of firebase stored portinal data       
            } else {
                console.log("data save error:", data.error);
                throw new Error(data.error);
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async fetchTosAcceptance(id, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${id}/tos_acceptance.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async fetchTheSale(customerId, providerId, token){
        try {
            let userId = customerId ? customerId : providerId;
            let mode = customerId ? "customer" : "provider";
            let res = await fetch(`${this.firebaseRoot}/sales/${mode}/${userId}.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data");
                    return data;       
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async fetchBlog() {
      try {
          let res = await fetch(`${this.firebaseRoot}/blog.json`);
          let data = await res.json();
          return data;
      } catch(err) {
          console.log(err)
          throw err;
      }
    }
    
};
