import React from 'react';
import ReactPlayer from 'react-player/facebook';
import styles from './FeatureVideo.module.scss';
import {trunicateString} from '../../../utils'

const FeatureVideo = (props) => {
  const {item} = props;
  const {id, imagePath, title, shortDescription} = item;
  return(
  <div key={`howToMoviesArticle_${id}`} className={styles.PodSmallVideo}>
    <div className={styles.PodImageSmallVideo}>
      <div className={styles.PodImgSmallRegular}>
      <ReactPlayer 
        url={imagePath}
        controls
        width='100%'
        height='100%'
        />
      </div>
    </div>
    <div className={styles.PodSmallTextAreaVideo}>
      <h4 className={styles.PodSmallTextHeadVideo}>
        {title}
      </h4>
      <p className={styles.PodSmallTextVideo}>
        {trunicateString(42, shortDescription)}
        <span className={styles.Details}>
          詳しく
        </span>
      </p>
    </div>
  </div>
);
}

export default FeatureVideo