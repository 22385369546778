import {
    FETCH_USER_META,
    FETCH_THE_USER_META,
    FETCH_THE_BUYER_META,
    ADD_USER_META_TO_LIST,
    SET_ACCOUNT_INFO_TO_META,
    SET_THE_PREFERENCE,
    SET_THE_MAIL_SUBSCRIPTION,
    SET_EMAIL_VERIFICATION_STATUS,
    SET_CERT_STATUS_TO_META,
    CHANGE_EMAIL_AND_RELATED
} from '../actions/actionTypes';

const initState = {
    userMetaIsLoading: false,
    userMeta: null,
    userMetaError: false,
    theUserMeta: null,
    theUserMetaIsLoading: false,
    theUserMetaError: false,
    theBuyerMeta: null,
    theBuyerMetaIsLoading: false,
    theBuyerMetaError: false,
};

const processAddUserMeta = (prevUserMetaObj, payload) => {
    const {userMeta, id} = payload;
    let newUserMeta = {...prevUserMetaObj};
    if(newUserMeta.hasOwnProperty(id)){
        newUserMeta[id] =userMeta     
    } else {
        newUserMeta = {
            ...newUserMeta,
            [id]:userMeta
        }
    }
    return newUserMeta;
};

const processPrefForTheUser = (prevTheUserMeta, userId, key, value) => {
    if(prevTheUserMeta){
        if(prevTheUserMeta.id === userId) {
            let newTheUserMeta = {
                ...prevTheUserMeta,
                preference:{
                    ...prevTheUserMeta.preference,
                    [key]: value    
                }
            }
            return newTheUserMeta;
        } else {
            return prevTheUserMeta;
        }
    } else {
        return null;
    }
};

const processMailSubForTheUser = (prevTheUserMeta, userId, key, value) => {
    if(prevTheUserMeta){
        if(prevTheUserMeta.id === userId) {
            let newTheUserMeta = {
                ...prevTheUserMeta,
                mailSubscription:{
                    ...prevTheUserMeta.mailSubscription,
                    [key]: value    
                }
            }
            return newTheUserMeta;
        } else {
            return prevTheUserMeta;
        }
    } else {
        return null;
    }
};

const processVerifyMethodForTheUser = (prevTheUserMeta, userId) => {
    if(prevTheUserMeta){
        if(prevTheUserMeta.id === userId) {
            let newTheUserMeta = {
                ...prevTheUserMeta,
                verifyMethod:{
                    ...prevTheUserMeta.verifyMethod,
                    email: true    
                }
            }
            return newTheUserMeta;
        } else {
            return prevTheUserMeta;
        }
    } else {
        return null;
    }
};

const processCertStatusForTheUser = (prevTheUserMeta, userId, certType, status) => {
    if(prevTheUserMeta){
        if(prevTheUserMeta.id === userId) {
            let newTheUserMeta = {
                ...prevTheUserMeta,
                certificates:{
                    ...prevTheUserMeta.certificates,
                    [certType]: status    
                }
            }
            return newTheUserMeta;
        } else {
            return prevTheUserMeta;
        }
    } else {
        return null;
    }
}

const processChangeEmailAndRelated = (prevTheUserMeta, userId) => {
    if(prevTheUserMeta){
        if(prevTheUserMeta.id === userId) {
            let newTheUserMeta = {
                ...prevTheUserMeta,
                verifyMethod:null,
                mailSubscription:{
                    campaignMail:false,
                    dealPending:false,
                    systemNotification:false     
                }
            }
            return newTheUserMeta;
        } else {
            return prevTheUserMeta;
        }
    } else {
        return null;
    }    
}

const processUserMeta = (prevUserMeta, userId, func) => {
    if(prevUserMeta){
        let newUserMeta = {...prevUserMeta};
        if(newUserMeta[userId]){
            newUserMeta[userId] = func() // theUserMet Update function runs
        }
        return newUserMeta;    
    } else {
        return null;
    }
}

const userMetaReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case ADD_USER_META_TO_LIST: {
            return{
                ...state,
                userMeta:processAddUserMeta(state.userMeta, payload)
            }
        }
        case SET_ACCOUNT_INFO_TO_META: {
            return {
                ...state,
                theUserMeta: {
                    ...state.theUserMeta,
                    accountInfo: payload
                }
            }
        }
        case SET_THE_PREFERENCE:{
            return{
                ...state,
                userMeta:processUserMeta(state.userMeta, payload.userId, () => processPrefForTheUser(state.theUserMeta, payload.userId, payload.key, payload.value)),
                theUserMeta:processPrefForTheUser(state.theUserMeta, payload.userId, payload.key, payload.value)
            }
        }
        case SET_THE_MAIL_SUBSCRIPTION:{
            return{
                ...state,
                userMeta:processUserMeta(state.userMeta, payload.userId, () => processMailSubForTheUser(state.theUserMeta, payload.userId, payload.key, payload.value)),
                theUserMeta:processMailSubForTheUser(state.theUserMeta, payload.userId, payload.key, payload.value)
            }
        }
        case SET_EMAIL_VERIFICATION_STATUS: {
            return{
                ...state,
                userMeta:processUserMeta(state.userMeta, payload.userId, () => processVerifyMethodForTheUser(state.theUserMeta, payload.userId)),
                theUserMeta:processVerifyMethodForTheUser(state.theUserMeta, payload.userId)
            }    
        }
        case SET_CERT_STATUS_TO_META:{
            return{
                ...state,
                userMeta:processUserMeta(state.userMeta, payload.userId, () => processCertStatusForTheUser(state.theUserMeta, payload.userId, payload.certificateType, payload.status)),
                theUserMeta:processCertStatusForTheUser(state.theUserMeta, payload.userId, payload.certificateType, payload.status)
            }
        }
        case CHANGE_EMAIL_AND_RELATED: {
            return {
                ...state,
                userMeta:processUserMeta(state.userMeta, payload.userId, () => processChangeEmailAndRelated(state.theUserMeta, payload.userId )),
                theUserMeta:processChangeEmailAndRelated(state.theUserMeta, payload.userId )
            }    
        }
        case `${FETCH_USER_META}_PENDING`:
            return {
                ...state,
                userMetaIsLoading: true,
                userMetaError: false
            };
        case `${FETCH_USER_META}_FULFILLED`:
            return {
                ...state,
                userMeta: payload,
                userMetaIsLoading: false,
                userMetaError: false
            };
        case `${FETCH_USER_META}_REJECTED`:
            return {
                ...state,
                userMeta: null,
                userMetaIsLoading: false,
                userMetaError: {
                    message: 'Database error',
                    err: payload
                }
            };
        case `${FETCH_THE_USER_META}_PENDING`:
            return {
                ...state,
                theUserMetaIsLoading: true,
                theUserMetaError: false
            };
        case `${FETCH_THE_USER_META}_FULFILLED`:
            return {
                ...state,
                theUserMeta: payload,
                theUserMetaIsLoading: false,
                theUserMetaError: false
            };
        case `${FETCH_THE_USER_META}_REJECTED`:
            return {
                ...state,
                theUserMeta: null,
                theUserMetaIsLoading: false,
                theUserMetaError: {
                    message: 'Database error',
                    err: payload
                }
            };
        case `${FETCH_THE_BUYER_META}_PENDING`:
            return {
                ...state,
                theBuyerMetaIsLoading: true,
                theBuyerMetaError: false
            };
        case `${FETCH_THE_BUYER_META}_FULFILLED`:
            return {
                ...state,
                theBuyerMeta: payload,
                theBuyerMetaIsLoading: false,
                theBuyerMetaError: false
            };
        case `${FETCH_THE_BUYER_META}_REJECTED`:
            return {
                ...state,
                theBuyerMeta: null,
                theBuyerMetaIsLoading: false,
                theBuyerMetaError: {
                    message: 'Database error',
                    err: payload
                }
            }; 
        default:
            return state;
    }
};

export default userMetaReducer;
