import React from 'react';
import styles from './BlogSectionTitle.module.scss';
import {NavLink} from 'react-router-dom';

const BlogSectionTitle = (props) => {
  const { tagTitle, title, linkTo, linkLabel, needMargin, tag } = props;
  let boxStyle = needMargin ? `${styles.SectionHead} ${styles.SectionMargin}` : styles.SectionHead;
  return(
    <div className={boxStyle}>
      <div className={styles.SectionTag}>
        <p className={styles.SectionTagText}>{tagTitle}</p>
      </div>
      <h2 className={styles.SectionHeadText}>{title}{tag ? ` : ${tag}` : ""}</h2>
      { linkTo &&
      <NavLink className={styles.MoreLink} to={linkTo}>
       {linkLabel}
      </NavLink>
      }
    </div>
  );
}

export default BlogSectionTitle;