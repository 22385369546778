import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import SendQuestion0 from '../../../assets/images/help/buying/buy.jpg';
import SendQuestion1 from '../../../assets/images/help/buying/buy_question/buy_question_1.jpg';
import SendQuestion2 from '../../../assets/images/help/buying/buy_question/buy_question_2.jpg';
import SendQuestion3 from '../../../assets/images/help/buying/buy_question/buy_question_3.jpg';
import SendQuestion4 from '../../../assets/images/help/buying/buy_question/buy_question_4.jpg';
import SendQuestion5 from '../../../assets/images/help/buying/buy_question/buy_question_5.jpg';


class SendQuestion extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービス提供者に質問する</span>
            </h3>
            <p className={styles.ArticleDesc}>
                サービスの購入を検討にする際に、サービス詳細ベージの情報を確認します。細かいサービスの内容や、特別な要望に答えてくれるかなど、サービス提供者（売り手）に質問がある場合は以下の手順で問い合わせをすることが出来ます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        申請したいサービスをタップしサービスの詳細ページに行きます。    
                    </li>
                    <img className={styles.ScreenShot} src={SendQuestion0} alt="サービス提供者に質問するステップ1画像" />
                    <li>
                        詳細ページで「問い合わせ」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={SendQuestion1} alt="サービス提供者に質問するステップ2画像" />
                    <li>
                        「自己紹介」が未登録の場合は、「アカウント情報を編集する」で入力してください。質問内容を「メッセージ」に入力し、「送信」ボタンをタップします。  
                    </li>
                    <img className={styles.ScreenShot} src={SendQuestion2} alt="サービス提供者に質問するステップ3画像" />
                    <li>
                        「メッセージを確認する」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={SendQuestion3} alt="サービス提供者に質問するステップ4画像" />
                    <li>
                        自動的に、「取引」＞「買い」＞「質問」に移動します。「質問中」と表示された取引をタップします。 
                    </li>
                    <img className={styles.ScreenShot} src={SendQuestion4} alt="サービス提供者に質問するステップ5画像" />
                    <li>
                        今後はこの画面でサービス提供者とコミュニケーションします。     
                    </li>
                    <img className={styles.ScreenShot} src={SendQuestion5} alt="サービス提供者に質問するステップ6画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SendQuestion);