import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class PayFee extends Component {
    render() {
        return(
            <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービス料の支払い</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムでは売り手よりサービスの提供が完了し（クラス受講の場合は、クラス参加受付後）、実費精算などの金額を調節して、請求書が送信されます。買い手は請求書を受け取り金額を確認したら、速やかにお支払いをお願いします。もし金額に疑問や、問題などがある場合は、「料金に問題がある」にて売り手の方に相談し問題を解決しましょう。</p>
            <p className={styles.HeaderMargin15}>*請求書が送信されてから、１０日以上「料金に問題がある」の報告がない場合は、自動的に請求金額を決済する場合がございます。</p>
            <p className={styles.HeaderMargin10}>*一度お支払い頂いた金額については、返金は致しかねますので十分金額をお確かめください。</p>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(PayFee);