import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';


class Fee extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>利用料金</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムはシステム利用料・入会金・会員費などは無料で提供しております。サービスを販売する場合も取引が成立した場合のみ手数料を頂きますが、出店サービスの掲載料などは無料となります。尚、タグジャムよりご登録の銀行口座に送金をする場合は送金金額に応じて手数料を頂きます。</p>
            <div className="Item">
                <dl className="Table">
                    <dt className={`${styles.FeeTitle} ${styles.FeeMiddle}`}>
                        入会費・会員費
                    </dt>
                    <dd className={`${styles.FeeContent} ${styles.Red} ${styles.FeeMiddle}`}>
                        <strong>￥０　無料</strong>
                    </dd>
                    <dt className={`${styles.FeeTitle} ${styles.FeeMiddle}`}>
                        サービス購入の手数料
                    </dt>
                    <dd className={`${styles.FeeContent} ${styles.Red} ${styles.FeeMiddle}`}>
                        <strong>￥０　無料</strong>
                    </dd>
                    <dt className={`${styles.FeeTitle} ${styles.FeeMiddle}`}>
                        サービス掲載料
                    </dt>
                    <dd className={`${styles.FeeContent} ${styles.Red} ${styles.FeeMiddle}`}>
                        <strong>￥０　無料</strong>
                    </dd>
                    <dt className={`${styles.FeeTitle} ${styles.FeeMiddle}`}>
                        サービス販売時の手数料
                    </dt>
                    <dd className={styles.FeeMiddle}>
                        <div>
                            <strong>取引金額の<span className={styles.Blue}>１5％</span></strong><br/>
                            {/* <p className="Caption" style={{display:'block'}}>（＊別途消費税がかかります）</p> */}
                        </div>
                    </dd>
                    <dt className={`${styles.FeeTitle} ${styles.FeeMiddle}`}>
                        販売代金の銀行振込手数料
                    </dt>
                    <dd className={styles.FeeMiddle} style={{flexDirection:"column", alignItems:"flex-start"}}>
                        <div>
                            <strong><span className={styles.Blue}>￥５００</span> ／　回</strong>
                            <p className="Caption" style={{display:'block'}}>（＊送金額が１万円未満の場合）</p>
                        </div>
                        <div style={{marginTop:10}}>
                            <strong><span className={styles.Blue}>￥３５０</span> ／　回</strong><br/>
                            <p className="Caption" style={{display:'block'}}>（＊送金額が１万円以上の場合）</p>
                        </div>
                    </dd>
                </dl>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(Fee);