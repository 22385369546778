import React, {Component} from 'react';
import styles from './Toast.module.scss';
import { connect } from 'react-redux';
import {
    initUiSuccessState
} from '../../../store/actions';

class Toast extends Component {

    getMessageStr = (message) => {
        const {appData, localCode} = this.props;
        const { LocalStrings } = appData;
        return message ? LocalStrings[localCode][message] : null
    }

    closeWin = () => {
        const {onInitUiSuccessState} = this.props; 
        const initState = {
            successWinOpen: false,
            successClosable: true,
            message:null 
        }
        onInitUiSuccessState(initState);
    }

    render() {
        const {
            appData, 
            uiSuccess
        } = this.props;
        const { successWinOpen, message } = uiSuccess;
        let attachStyles = [ styles.ToastModal, styles.Close];

        if(successWinOpen){
            attachStyles = [ styles.ToastModal, styles.Open ];
        }
        if(appData){
            return(
            <div className={attachStyles.join(' ')}>
                {message &&
                <div style={{display:'flex', flexDirection:"row", alignItems:"center"}}>
                    <i className={`fa fa-check-circle ${styles.Icon}`}  />
                    <p>{this.getMessageStr(message)}</p>
                </div> 
                }
            </div>
            );
        } else {
            return null;
        }     
    }

}

const mapStateToProps = ({
    ui:{ uiSuccess },
    local: { localCode },
    appData: {appData, appDataIsLoading, appDataError}
}) => ({
    uiSuccess,
    localCode,
    appData, appDataIsLoading, appDataError 
});

const mapDispatchToProps = dispatch => ({
    onInitUiSuccessState : (initState) => dispatch(initUiSuccessState(initState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);