import React from 'react';
import {NavLink} from 'react-router-dom';
import styles from './FeatureMain.module.scss';
import {trunicateString} from '../../../utils'

const FeatureMain = (props) => {
  const {id, imagePath, title, shortDescription} = props.item;
  return(
  <NavLink className={styles.PodBig} to={`/blog/Article/${id}`}>
  {/* BigPod */}
  <div className={styles.PodImageBig}>
    <img className={styles.PodImgBig} src={imagePath} alt={"注目の記事1"} />
  </div>
  <div className={styles.PodBigTextArea}>
    <h4 className={styles.PodBigTextHead}>{title}</h4>
    <p className={styles.PodBigText}>
      {trunicateString(43, shortDescription)}
      <span className={styles.Details}>
        詳しく
      </span>
    </p>
  </div>
</NavLink>
);
}

export default FeatureMain