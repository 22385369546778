import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class BannedItems extends Component {
    articleClickedHandler = (article) => {
        console.log(article);
        if(article === 'cancelpolicy'){
            this.props.history.push({
                pathname: `/${article}`
            });
        } else {
            this.props.history.push({
                pathname: `/help/${article}`
            });
        }
    }
    
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>出品禁止サービス</span>
            </h3>
            <h4 className={styles.HeaderMargin15}>法律・法令に違反しているサービス</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>必要資格・行政機関への届出なしに行うサービス。（詳しくは必要資格書類一覧参照<a href="/help" onClick={ e => { e.preventDefault(); this.articleClickedHandler('CertChart') } }>こちら</a>）</li>
                    <li>病気を治すなど身体に影響を与えることを前提としているサービス</li>
                    <li>具体的な病名や症状を聴聞して、対処法をアドバイスしているサービス</li>
                    <li>病気に効く薬を紹介しているサービス</li>
                    <li>特定の症状に効能を持つ食品やサプリを紹介しているサービス</li>
                    <li>人材の募集、派遣、斡旋を請け負うサービス</li>
                    <li>購入者に有利誤認を及ぼす恐れがあるサービス</li>
                    <ul>
                        <li>「期間限定」や「人数限定」を記載しているが、実際には限定されていないサービス</li>
                        <li>値下げ中である旨を記載しているが、実際には値下げを行っていないサービス</li>
                    </ul>
                    <li>特定の金融商品を勧めていたり、売買タイミングを指南しているサービス</li>
                    <li>投資判断に関するアドバイスを行うサービス</li>
                    <li>投資運用や資産運用を代行しているサービス</li>
                    <li>仮想通貨やICOに関するサービス</li>
                    <li>購入者に優良誤認を及ぼす恐れがあるサービス</li>
                    <ul>
                        <li>「必ず儲かる」や「勝率、利率〇〇%」という断定的な表現により、購入者に誤解を与える可能性があるサービス</li>
                        <li>「◯◯万円儲かった」など投資成果について具体的な金額を記載し、購入者に誤解を与える可能性があるサービス</li>
                        <li>「実績No.1」や「信頼度No.1」など裏付けのない順位を用いた表現をしているサービス</li>
                    </ul>
                </ul>
            </div>
            <h4 className={styles.HeaderMargin15}>公序良俗に反するサービス</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>成人向け、アダルト関連のサービス</li>
                    <li>性器、胸や乳首などが見えるまたは強調されている画像を掲載しているサービス</li>
                    <li>わいせつ又は下品とユーザーが解釈する可能性があるサービス</li>
                    <li>出会い系サービスや風俗又はそれらの利用を推奨しているサービス</li>
                    <li>児童ポルノに該当する恐れがあるサービス</li>
                    <li>犯罪をほう助または誘発する恐れがあるサービス</li>
                    <li>学校の課題（宿題、レポート等）を代行するサービス</li>
                    <li>第三者に呪い、復讐などで不幸をもたらすことを前提としているサービス</li>
                </ul>
            </div>
            <h4 className={styles.HeaderMargin15}>タグジャム外での取引を促しているサービス</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>メールアドレス、LINE IDなどのSNSでの外部連絡先を記載しているサービス</li>
                    <li>タグジャム外の連絡手段を用いてサービス提供を行うサービス</li>
                    <li>外部サイトへの誘導しているサービス</li>
                </ul>
            </div>
            <h4 className={styles.HeaderMargin15}>知的財産権、著作権等の侵害又は侵害を助長するサービス</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>商標を不正利用したサービス</li>
                    <li>第三者が著作権を有する著作物を許可なく複製・転用・不正利用したサービス</li>
                    <li>パブリシティ権、肖像権を侵害するサービス</li>
                    <li>商用利用が禁止されている素材を利用したサービス</li>
                    <li>無料で公開されているweb上の情報を、公開元の承諾を得ずに他者に提供するサービス</li>
                </ul>
            </div>
            <h4 className={styles.HeaderMargin15}>他社サービスの規約違反となるサービス</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>他社サービスのアカウントを販売や譲渡をしているサービス</li>
                    <li>口コミ（レビュー）の投稿を代行し、意図的に情報を操作することに協力するサービス</li>
                    <li>SNSなどの情報（フォロワー、いいねなど）を不当に操作するサービス</li>
                    <li>他社で禁止している無在庫販売や出品代行を指南しているサービス</li>
                    <li>SMS認証を代行しているサービス</li>
                    <li>その他規約違反が確認できるサービス</li>
                </ul>
            </div>
            <h4 className={styles.HeaderMargin15}>当社の利用規約上、禁止されているサービス</h4>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ul>
                    <li>外部サービスやメールマガジンへの登録、アプリのダウンロードを強要し利益を得ることを目的としたサービス</li>
                    <li>マルチ、ねずみ講、MLMなどへの勧誘が疑われるサービス</li>
                    <li>完全同一内容で複数出品されているサービス</li>
                    <li>適合しないカテゴリへ出品するサービス</li>
                    <li>返金保証を行っているサービス</li>
                    <li>オンラインカジノやブックメーカーなどの利用を推奨している又は利用方法を指南しているサービス</li>
                    <li>金融商品などの販売、使用を指南しているサービス</li>
                    <li>その他運営方針に反するサービスや当社の判断で不適当とみなすサービス</li>
                </ul>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BannedItems);