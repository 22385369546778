import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import Payment0 from '../../../assets/images/help/account/payment.png';
import Payment1 from '../../../assets/images/help/account/payment/payment_1.png';
import Payment2 from '../../../assets/images/help/account/payment/payment_2.png';
import SecurityCode from './SecurityCode/SecurityCode';

class Payment extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>支払い情報の事前登録</span>
            </h3>
            <p className={styles.ArticleDesc}>
                支払い情報を事前に登録しておくと、サービスの依頼時に入力する手間が省けます。<br/>
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                      メニューより、「アカウント」＞「支払い情報」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={Payment0} alt="支払い情報の事前登録ステップ1画像" />
                    <li>
                        クレジットカードの番号、カード名義人（大文字のローマ字で、姓と名の間はスペースを開ける）、有効期限を入力する。
                    </li>
                    <img className={styles.ScreenShot} src={Payment1} alt="支払い情報の事前登録ステップ2画像" />
                    <SecurityCode mode="payment" />
                    <li>
                      入力内容を確認して「保存」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={Payment2} alt="支払い情報の事前登録ステップ3_1画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(Payment);