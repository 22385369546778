import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import BlockUser1 from '../../../assets/images/help/buying/buy_block_user/buy_block_user_1.png';
import ReportDeal1 from '../../../assets/images/help/selling/sell_report_deal/sell_report_deal_1.png';
import ReportDeal2 from '../../../assets/images/help/selling/sell_report_deal/sell_report_deal_2.png';
import ReportDeal3 from '../../../assets/images/help/selling/sell_report_deal/sell_report_deal_3.png';
import ReportDeal4 from '../../../assets/images/help/selling/sell_report_deal/sell_report_deal_4.png';
import ReportService4 from '../../../assets/images/help/buying/buy_report_service/buy_report_service_4.png';
import ReportService6 from '../../../assets/images/help/buying/buy_report_service/buy_report_service_6.png';
class ReportDeal extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>取引の問題を報告する</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムでは、特定の取引で問題が生じた場合、以下の手順でカスタマーサービスに報告する事ができます。
            </p>
            <p className={`${styles.ArticleDesc} ${styles.HeaderMargin10}`}>
            ＊タグジャムの取引は原則として個人同士の同意により行うもので、カスタマーサービスでは全ての問題を解決できない事もありますのでご了承願います。
            だだし、タグジャム事務局が報告された内容が利用規約違反であると判断した場合には、該当会員のサービスの削除または、該当会員のアカウントの一時停止及び無効化を行う場合がございます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        「取引」画面で該当する取引をタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={BlockUser1} alt="取引の問題を報告するステップ1画像" />
                    <li>
                        「取引詳細」画面で「取引の問題の報告」ボタンをタップします。     
                    </li>
                    <img className={styles.ScreenShot} src={ReportDeal1} alt="取引の問題を報告するステップ2画像" />
                    <li>
                        「報告内容」を入力し、「取引の問題を報告する」ボタンをタップします。     
                    </li>
                    <img className={styles.ScreenShot} src={ReportDeal2} alt="取引の問題を報告するステップ3画像" />
                    <li>
                        送信済みの報告内容を確認する場合は「カスタマーサポートへ」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={ReportService4} alt="取引の問題を報告するステップ4画像" />
                    <li>
                        該当するケースをタップして内容、又は返信を確認出来ます。     
                    </li>
                    <img className={styles.ScreenShot} src={ReportDeal3} alt="取引の問題を報告するステップ5-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={ReportDeal4} alt="取引の問題を報告するステップ5-2画像" />
                    <li>
                        今後は、「アカウント」 > 「カスタマーサポート」にてタグジャムカスタマーサービスの返信を確認できます。    
                    </li>
                    <img className={styles.ScreenShot} src={ReportService6} alt="取引の問題を報告するステップ6画像" />
                </ol>
            </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(ReportDeal);