import React from 'react';

const closeBtn = props => {
    const btnStyle = {
        fontSize: props.fontSize ? props.fontSize : '16px',
        color: props.color ? props.color : '#44A7CB'
    }
    return(
        <i onClick={props.clicked}  
            className="fa fa-times-circle" 
            style={btnStyle} />
    );
}

export default closeBtn;