import {
    FETCH_GIGS,
    FETCH_THE_GIG,
} from '../actions/actionTypes';

const initState = {
    gigsIsLoading: false,
    gigs: null,
    gigsError: false,
    theGig: null,
    theGigIsLoading: false,
    theGigError: false,
};

const gigsReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${FETCH_GIGS}_PENDING`:{
            return {
                ...state,
                gigsIsLoading: true,
                gigsError: false
            };
        }
        case `${FETCH_GIGS}_FULFILLED`:{
            return {
                ...state,
                gigs: payload,
                gigsIsLoading: false,
                gigsError: false
            };
        }
        case `${FETCH_GIGS}_REJECTED`:{
            return {
                ...state,
                gigs: [],
                gigsIsLoading: false,
                gigsError: {
                    message: 'Database error',
                    err: payload
                }
            };
        }
        case `${FETCH_THE_GIG}_PENDING`:{
            return {
                ...state,
                theGigIsLoading: true,
                theGigError: false,
            }
        }
        case `${FETCH_THE_GIG}_FULFILLED`:{
            return {
                ...state,
                theGig:payload,
                theGigIsLoading: false,
                theGigError: false,
            }
        }
        case `${FETCH_THE_GIG}_REJECTED`:{
            return {
                ...state,
                theGig:null,
                theGigIsLoading: false,
                theGigError: true,
            }
        }
        default:
            return state;
    }
};

export default gigsReducer;
