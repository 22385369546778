import React, { Component } from 'react';
import styles from './ServiceItems.module.scss';
import TextStyle from '../../../constants/TextStyle.module.scss';
// import {
//     NumberStepper
// } from '../../components/UI';
import { 
    priceFormat 
} from '../../../utils';

class ServiceItems extends Component {
    state = {
        MainCat:null,
        SubCat:null
    }
    
    rederLateFeeTime = () => {
        const { theGig:{ feeInfo: {lateFeeDeadLine} }, localCode, appData:{LocalStrings} } = this.props;
        const time = new Date(lateFeeDeadLine).getHours();
        return `${time}${LocalStrings[localCode].AFTER_TIME}`;
    }

    renderChargeMethod = (chargeObj, value, Max) =>{
        const { localCode, theGig: {feeInfo}, appData:{LocalStrings} } = this.props
        let chargeStrings;
        const {CHARGE, MULTIPLE, MINUTES_COVERT} =chargeObj;
        if(!value){
            chargeStrings = " ";
        } else {
            if(CHARGE === 'ADD_TO_TOTAL'){
                if(Max){
                    chargeStrings = `+${priceFormat(value, localCode, LocalStrings)}${LocalStrings[localCode].MAX_LIMIT} (${LocalStrings[localCode].ADD_TO_TOTAL})`; 
                } else {
                    chargeStrings = `+${priceFormat(value, localCode, LocalStrings)}(${LocalStrings[localCode].ADD_TO_TOTAL})`; 
                }
                
            } else if(CHARGE === 'PER_RENT_UNIT') {
                let lateUnit = feeInfo.unit === 'DAY' ? 'HOUR' : 'DAY';
                chargeStrings = `+${priceFormat(value, localCode, LocalStrings)} / ${LocalStrings[localCode][lateUnit]}`;
            } else if(MINUTES_COVERT) {
                chargeStrings = `\u00D7 ${LocalStrings[localCode][CHARGE]}`;   
            } else if(MULTIPLE) {
                if(CHARGE==='HOURLY_PLUS')
                {
                    let MULTIPLE_Str = MULTIPLE==='PETS'? `${LocalStrings[localCode].ADDITIONAL}${LocalStrings[localCode].PET}` : `${LocalStrings[localCode].ADDITIONAL}${LocalStrings[localCode][MULTIPLE]}`; 
                    chargeStrings = `+${priceFormat(value, localCode, LocalStrings)}${LocalStrings[localCode].HOURLY} \u00D7 ${MULTIPLE_Str}`;
                } else {
                    chargeStrings = `+${priceFormat(value, localCode, LocalStrings)} / ${LocalStrings[localCode][CHARGE]} \u00D7 ${LocalStrings[localCode][MULTIPLE]}`;
                }
            } else {
                let unit = CHARGE === 'HOURLY_PLUS' ? "HOUR" : CHARGE;
                chargeStrings = `+${priceFormat(value, localCode, LocalStrings)} / ${LocalStrings[localCode][unit]}`
            }           
        }
        return chargeStrings;
    }

    renderQuantityInput = (selected, chargeObj, key) => {
        // eslint-disable-next-line no-unused-vars
        const { onChangeQuantity, localCode, mode, estimate, appData:{LocalStrings} } = this.props;
        let showQuantityInput = mode && mode==='estimateCart' ? true : false;
        // eslint-disable-next-line no-unused-vars
        const {CHARGE, MULTIPLE, MINUTES_COVERT} =chargeObj;
        if(selected && showQuantityInput && CHARGE!=="ADD_TO_TOTAL"){
            if(CHARGE==='HOURLY_PLUS' && !MULTIPLE) {
                return null;
            } else {
                // eslint-disable-next-line no-unused-vars 
                let unitLable;
                if(MULTIPLE){
                    unitLable = MULTIPLE === 'PERSONS' ? LocalStrings[localCode].PERSONS : LocalStrings[localCode].PET_UNIT;
                } else {
                    unitLable = CHARGE==='PLACE' || CHARGE==='LOAD' ? LocalStrings[localCode][CHARGE] : LocalStrings[localCode].QUANTITY_GENERAL;
                }
                
                if(estimate){
                    // eslint-disable-next-line no-unused-vars
                    const { numbers } = estimate;
                    return(
                    <div style={{width: "49%", marginTop:-10, marginBottom:15, display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                        {/* <NumberStepper
                            onChangeValue={val=> onChangeQuantity(key, val)}
                            initVal={numbers && numbers[key] ? numbers[key] : 1}
                            minVal={1}
                            maxVal={2}
                            interVal={1}
                            height={45}
                            round={true}
                            unit={unitLable}
                            errorMessage={LocalStrings[localCode]["undefined"]}
                            appData={appData}
                        /> */}
                    </div>
                    );   
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    serviceBtnPressHandler = (event, serviceType, key) => {
        if(event){
            event.preventDefault();
        }
        console.log("serviceBtnPressHandler pressed");
        // eslint-disable-next-line no-unused-vars
        const {  theGig: {MainCat, SubCat}, localCode, addItemToEstimate, estimate, appData:{LocalStrings}, appData:{ServiceData} }  = this.props
        const requiredBase = ServiceData[MainCat][SubCat].requiredBase;

        const isRequiredItem = (requiredBase && requiredBase.list.length > 0) ? requiredBase.list.filter(item => { return item === key }).length > 0 : false;
        if(isRequiredItem) {
            // eslint-disable-next-line no-unused-vars
            const alertText = requiredBase.alertText? requiredBase.alertText:"";
            // Alert.alert(
            // LocalStrings[localCode].SERVICES_SELECT_ALERT_TITLE, 
            // LocalStrings[localCode][key] + LocalStrings[localCode][alertText]
            // );
        } else if(key.includes('AFTER_6') ){
            // Alert.alert(
            //     LocalStrings[localCode].SERVICES_SELECT_ALERT_TITLE, 
            //     LocalStrings[localCode][key] + LocalStrings[localCode].AFTER_6_IS_AUTO_SELECT
            // );    
        } else {
            const val = estimate[serviceType] && estimate[serviceType][key] ? !estimate[serviceType][key] : true;
            console.log("not required Item pressed");
            addItemToEstimate(serviceType, key, val);
        }
    }

    convertToArray = (Data) => {
        let DataList = [];
        for(let key in Data){
            DataList.push(Data[key]);
        }
        return DataList;
    }

    render(){
        const { theGig: {
                    MainCat, 
                    SubCat, 
                    customBase, 
                    classPrepItems, 
                    baseService, 
                    optionableService, 
                    optionService, 
                    returnOptionService, 
                    actualCostService,
                    indicator:{VISITOR_TYPE}
                },
                localCode, estimate, mode,
                appData 
            } = this.props;
        const {Colors, LocalStrings, ServiceData } = appData;
        const actualCost = ServiceData[MainCat][SubCat].actualCost;
        let optionableServiceList = [];
        let isEstimate = mode && mode==='estimateCart' ? true : false;
        
        for(let key in optionableService){
            if(optionableService[key]){
                optionableServiceList.push({type:key, value:optionableService[key]}); 
            }    
        }

        let customBaseList = null;
        if(customBase){
            customBaseList = this.convertToArray(customBase);
        }
    
        let classPrepItemsList = null;
        if(classPrepItems){
            classPrepItemsList = this.convertToArray(classPrepItems);
        }

        const serviceItemData = ServiceData[MainCat][SubCat];
        const hasOptions = ( (optionService　&& serviceItemData.options) && (optionableServiceList.length > 0 || serviceItemData.options.list.length > 0));

        return(
            <div className={styles.ServiceBoxContainer}>
                { serviceItemData.customBase &&
                    <div className={styles.TitleContainer}>
                        <p className={TextStyle.BodyLargeText}>
                            {LocalStrings[localCode][serviceItemData.customBase.customBaseItemTitle]}
                        </p>
                        <div style={{marginTop:10}}>
                        {(customBase && serviceItemData.customBase.customBaseItemText) &&
                            <p className={TextStyle.BodyMediumText}>
                            {LocalStrings[localCode][serviceItemData.customBase.customBaseItemText]}
                            </p>    
                        }
                        </div>
                    </div>
                }
                { (customBaseList && customBaseList.length > 0) &&
                    <div className={styles.ServiceContainer} style={{justifyContent:"flex-start"}}>
                        {customBaseList.map( (item, i) => {
                            let backgroundColor = Colors.primaryButtonBGColor;
                            let forgroundColor = Colors.contentBGColor;
                            let borderW =  '2px';
                            return(
                            <div 
                                className={styles.BaseItem}
                                style={{width:null,backgroundColor:backgroundColor, border:`${borderW} solid ${forgroundColor}`}} 
                                key={i}
                            >   
                                <p 
                                    className={`${TextStyle.BodyTextSmall} ${TextStyle.Bold}`}
                                    style={{color:forgroundColor, paddingLeft:'10px', paddingRight:'10px'}}>
                                { item }
                                </p>
                            </div>
                            )
                        })}
                    </div>  
                } 
                {isEstimate===false && serviceItemData.classPrepItems &&
                    <div className={styles.TitleContainer}>
                        <p className={TextStyle.BodyLargeText}>
                            {LocalStrings[localCode][serviceItemData.classPrepItems.classPrepItemTitle]}
                        </p>
                        <div style={{marginTop:10}}>
                        {(classPrepItemsList && classPrepItemsList.length > 0) &&
                            <p className={TextStyle.BodyMediumText}>
                            {LocalStrings[localCode][serviceItemData.classPrepItems.classPrepItemText]}
                            </p>    
                        }
                        {(!classPrepItemsList || classPrepItemsList.length === 0) &&
                            <p className={TextStyle.BodyMediumText} style={{paddingBottom:'15px'}}>
                            {LocalStrings[localCode].NOTHING_SPECIAL}
                            </p>      
                        }    
                        </div>
                    </div>
                }
                { (isEstimate===false && classPrepItemsList && classPrepItemsList.length > 0) &&
                    <div className={styles.ServiceContainer}>
                        {classPrepItemsList.map( (item, i) => {
                            let backgroundColor = Colors.contentBGColor;//Colors.contentBGColor;
                            let forgroundColor = Colors.primaryButtonBGColor;//Colors.primaryButtonBGColor //Colors.primaryButtonBGColor;
                            let borderW = '2px';
                            return(
                            <div 
                                className={styles.BaseItem}
                                style={{backgroundColor:backgroundColor, border:`${borderW} solid ${forgroundColor}`}} 
                                key={i}
                            >   
                                <p className={`${TextStyle.BodyTextSmall} ${TextStyle.Bold}`} style={{color:forgroundColor, paddingRight:'5px'}}>
                                { item }
                                </p>
                            </div>
                            )
                        })}
                    </div>  
                } 
                { serviceItemData.base &&
                    <div className={styles.TitleContainer}>
                        <p className={TextStyle.BodyLargeText}>
                            {LocalStrings[localCode][serviceItemData.base.baseTitle]}
                        </p>
                        {/* <div style={{marginTop:10}}>
                            <p className={TextStyle.BodyMediumText}>
                                {LocalStrings[localCode][serviceItemData.base.baseText]}
                            </p>
                        </div> */}
                        <p className={`${TextStyle.BodyTextSmall} ${TextStyle.Bold}`} style={{color:Colors.primaryButtonBGColor, marginTop:'20px'}}>
                            {LocalStrings[localCode][serviceItemData.base.baseNote]}
                        </p>
                    </div>
                }
                { (serviceItemData.base && serviceItemData.base.list.length > 0 ) && 
                    <div className={styles.ServiceContainer}>
                        {serviceItemData.base.list.map( (item, i) => {
                            let backgroundColor = baseService[item] ? Colors.contentBGColor : Colors.tintColorBGColor;//Colors.tintColorBGColor;
                            let forgroundColor = baseService[item] ? Colors.primaryButtonBGColor : Colors.contentFontTintColor;//Colors.contentFontTintColor;
                            let borderW = baseService[item] ? '2px' : 0;
                            let disabled = baseService[item] ? false : true;
                            let selected = (estimate && estimate["baseService"] && estimate["baseService"][item]) ? estimate["baseService"][item] : false;
                            if(selected && disabled === false){
                                forgroundColor=Colors.primaryButtonTextColor;  
                                backgroundColor=Colors.primaryButtonBGColor;
                            }
                            return(
                            <div 
                                disabled={disabled}
                                //onClick={ (event) => this.serviceBtnPressHandler(event, "baseService", item) }
                                className={styles.BaseItem}
                                style={{position:"relative", backgroundColor:backgroundColor, border:`${borderW} solid ${forgroundColor}`}} 
                                key={i}
                            >   
                                <p className={`${TextStyle.BodyTextSmall} ${TextStyle.Bold}`} style={{color:forgroundColor, paddingRight:'5px'}}>
                                { LocalStrings[localCode][item]}
                                </p>
                                { disabled === false && (
                                    <div style={{position:'absolute', left:'8px'}}>
                                        <i className={"fa fa-check"} style={{fontSize:'15xp', color:selected? forgroundColor : backgroundColor}} />
                                    </div>
                                )}
                            </div>
                            )
                        })}
                    </div>  
                }
                { ( (optionService　&& serviceItemData.options) && (optionableServiceList.length > 0 || serviceItemData.options.list.length > 0)) &&
                    <div className={serviceItemData.base ? `${styles.TitleContainer} ${TextStyle.BorderTop}` : styles.TitleContainer}>
                        { serviceItemData.options.optionsTitle &&
                            <p className={TextStyle.BodyLargeText}>
                                {LocalStrings[localCode][serviceItemData.options.optionsTitle]}
                            </p>
                        }
                        { serviceItemData.options.optionsText &&
                            <div style={{marginTop:10}}>
                                <p className={TextStyle.BodyMediumText}>
                                    {LocalStrings[localCode][serviceItemData.options.optionsText]}
                                </p>
                            </div>
                        }
                        { serviceItemData.options.optionsNote &&
                            <p className={`${TextStyle.BodyTextSmall} ${TextStyle.Bold}`} style={{color:Colors.primaryBtnBorderColor, marginTop:'15px'}}>
                                {LocalStrings[localCode][serviceItemData.options.optionsNote]}
                            </p>
                        }
                    </div>
                }   
                { (optionableServiceList.length > 0 ) &&
                    <div className={styles.OptionContainer}>
                    {optionableServiceList.map( (optionableItem, i) => {
                        let backgroundColor = Colors.contentBGColor;
                        let forgroundColor = Colors.primaryButtonBGColor;
                        let selected = (estimate && estimate["optionableService"] && estimate["optionableService"][optionableItem.type]) ? estimate["optionableService"][optionableItem.type] : false;//this.state[optionableItem.type];
                        if(selected){
                            forgroundColor=Colors.primaryButtonTextColor;  
                            backgroundColor=Colors.primaryButtonBGColor;
                        }
                        return(
                        <div key={i} style={{width: "100%"}}>
                            <div style={{width: "100%", display:'flex', flexDirection:'row', alignItems:"center", justifyContent:'flex-start'}}>
                                <div 
                                    className={styles.BaseItem}
                                    style={{position:"relative", backgroundColor:backgroundColor, border:`2px solid ${forgroundColor}`}             }
                                    //onClick={ (event) => this.serviceBtnPressHandler(event, "optionableService", optionableItem.type) }
                                >
                                    <p className={`${TextStyle.BodyTextExSmall} ${TextStyle.Bold}`} style={{color:forgroundColor, paddingRight:'5px'}}>
                                    { LocalStrings[localCode][optionableItem.type]}
                                    </p>
                                    <div style={{position:'absolute', left:'8px'}}>
                                        <i className="fa fa-check" style={{color: selected ? forgroundColor : backgroundColor, fontSize:'15px'}} />
                                    </div>
                                </div>
                                <div className={styles.ChargeMethod}>
                                    <p className={TextStyle.BodyTextExSmall}>
                                    { this.renderChargeMethod( serviceItemData.optionable[optionableItem.type], optionableItem.value) }
                                    </p>
                                </div>
                            </div>
                            {this.renderQuantityInput(selected, serviceItemData.optionable[optionableItem.type], optionableItem.type)}
                        </div>
                        )
                    })}
                    </div>
                } 
                { (optionService && serviceItemData.options && serviceItemData.options.list.length > 0 ) &&
                    <div className={styles.OptionContainer}>
                    {serviceItemData.options.list.map( (item, i) => {
                        let backgroundColor = optionService[item.TYPE] ? Colors.contentBGColor : Colors.tintColorBGColor;
                        let forgroundColor = optionService[item.TYPE] ? Colors.primaryButtonBGColor : Colors.contentFontTintColor;
                        let borderW = optionService[item.TYPE] ? '2px' : 0;
                        let disabled = optionService[item.TYPE] ? false : true;
                        let selected = (estimate && estimate["optionService"] && estimate["optionService"][item.TYPE]) ? estimate["optionService"][item.TYPE] : false;//this.state[item.TYPE];
                        let serviceMinutes = (item.MINUTES_COVERT && optionService[item.TYPE]) ? optionService[item.TYPE] : item.MINUTES_COVERT
                        
                        if(selected && disabled === false){
                            forgroundColor=Colors.primaryButtonTextColor;  
                            backgroundColor=Colors.primaryButtonBGColor;
                        }
                        return(
                        <div key={i} style={{width: "100%"}}>
                            <div style={{width: "100%", display:'flex', flexDirection:'row', alignItems:"center", justifyContent:'flex-start'}} >
                                <div
                                    disabled={disabled}
                                    className={styles.BaseItem}
                                    style={{position:"relative", backgroundColor:backgroundColor, border:`${borderW} solid ${forgroundColor}`}}
                                    //onClick={ (event) => this.serviceBtnPressHandler(event, "optionService", item.TYPE) }  
                                >
                                    <div style={{textAlign:"center", width:"100%"}}>
                                        <p className={`${TextStyle.BodyTextExSmall} ${TextStyle.Bold}`} style={{color:forgroundColor, paddingRight:'5px'}}>
                                        { LocalStrings[localCode][item.TYPE]}
                                        </p>
                                        {item.MINUTES_COVERT && 
                                            <p className={`${TextStyle.BodyTextExSmall} ${TextStyle.Bold}`} style={{paddingTop:'2px', color:forgroundColor, paddingRight:'5px'}}>
                                            { `${serviceMinutes}${LocalStrings[localCode].ABOUT_MINUTE}` }
                                            </p>
                                        }
                                    </div>
                                    { disabled===false && (
                                        <div style={{position:'absolute', left:'8px'}}>
                                            <i className="fa fa-check" style={{fontSize:'15px', color: selected ? forgroundColor: backgroundColor}} />
                                        </div>
                                    )}
                                </div>
                                <div className={styles.ChargeMethod}>
                                    <p className={TextStyle.BodyTextExSmall}>
                                    { this.renderChargeMethod(item, optionService[item.TYPE]) }
                                    </p>
                                    {  item.NOTE &&
                                    <p className={TextStyle.BodyTextExExSmall} style={{color:Colors.redColor, marginTop:'3px'}} >
                                        {LocalStrings[localCode][item.NOTE]}
                                    </p>
                                    }
                                </div>
                            </div>
                            {this.renderQuantityInput(selected, item, item.TYPE)}
                        </div>
                        )
                    })}
                    </div>  
                }
                { isEstimate===false && serviceItemData.returnOptions && 
                    <div className={hasOptions ? `${styles.TitleContainer} ${TextStyle.BorderTop}`: styles.TitleContainer} style={{paddingTop:'15px'}}>
                        { serviceItemData.returnOptions.optionsTitle &&
                            <p className={TextStyle.BodyLargeText}>
                                {LocalStrings[localCode][serviceItemData.returnOptions.optionsTitle]}
                            </p>
                        }
                        { serviceItemData.returnOptions.optionsText &&
                            <div style={{marginTop:10}}>
                                <p className={TextStyle.BodyMediumText}>
                                    {LocalStrings[localCode][serviceItemData.returnOptions.optionsText]}
                                </p>
                            </div>
                        }
                        { serviceItemData.returnOptions.optionsNote &&
                            <p className={`${TextStyle.bodyTextExSmall} ${TextStyle.Bold}`} style={{color:Colors.primaryButtonBGColor, marginTop:'15px'}}>
                                {LocalStrings[localCode][serviceItemData.returnOptions.optionsNote]}
                            </p>
                        }
                    </div>
                }
                { isEstimate===false && serviceItemData.returnOptions && serviceItemData.returnOptions.list.length > 0 &&
                    <div className={styles.OptionContainer}>
                        {serviceItemData.returnOptions.list.map( (item, i) => {
                            let backgroundColor = returnOptionService[item.TYPE] ? Colors.contentBGColor : Colors.tintColorBGColor;
                            let forgroundColor = returnOptionService[item.TYPE] ? Colors.primaryButtonBGColor : Colors.contentFontTintColor;
                            let borderW = returnOptionService[item.TYPE] ? '2px' : 0;
                            return(
                            <div key={i} style={{width: "100%", display:"flex", flexDirection:'row', justifyContent:'center'}} >
                                <div className={styles.BaseItem} style={{backgroundColor:backgroundColor, border:`${borderW} solid ${forgroundColor}`}} >
                                    <div style={{textAlign:"center", width:"100%"}}>
                                        <p className={`${TextStyle.BodyTextExSmall} ${TextStyle.Bold}`} style={{color:forgroundColor, paddingRight:'5px'}}>
                                        { LocalStrings[localCode][item.TYPE]}
                                        </p>
                                        {item.CHARGE ==='PER_RENT_UNIT' && 
                                            <p className={`${TextStyle.BodyTextExSmall} ${TextStyle.Bold}`} style={{paddingTop:'2px', color:forgroundColor, paddingRight:'5px'}}>
                                            { `${this.rederLateFeeTime()}` }
                                            </p>
                                        }
                                    </div>
                                </div>
                                <div className={styles.ChargeMethod}>
                                    <p className={TextStyle.BodyTextExSmall}>
                                    { this.renderChargeMethod(item, returnOptionService[item.TYPE]) }
                                    </p>
                                </div>
                            </div>
                            )
                        })}
                    </div>   
                }
                { isEstimate===false && actualCost &&
                    <div className={`${styles.TitleContainer} ${TextStyle.BorderTop}`}>
                        <p className={`${TextStyle.BodyTextSmall} ${TextStyle.Bold}`} style={{color:Colors.primaryButtonBGColor, marginTop:'15px'}}>
                            {LocalStrings[localCode][actualCost.actualCostNote]}
                        </p>
                    </div>
                }
                {  (isEstimate===false && actualCost && actualCost.list.length > 0) && 
                    <div className={styles.ServiceContainer}>
                        {actualCost.list.map( (actualCostItem, i) => {
                            let backgroundColor = actualCostService[actualCostItem.TYPE]? Colors.primaryButtonBGColor : Colors.tintColorBGColor;
                            let forgroundColor = actualCostService[actualCostItem.TYPE]? Colors.primaryButtonTextColor : Colors.contentFontTintColor;
                            let Max = true;
                            if(VISITOR_TYPE && MainCat==='TEACH'){
                                Max = false;
                            }
                            return(
                            <div key={i} style={{width: "100%"}}>
                                <div style={{width: "100%", display:"flex", flexDirection:'row', alignItems:"center", justifyContent:'flex-start'}} >
                                    <div 
                                        key={LocalStrings[localCode][actualCostItem.TYPE]}
                                        className={styles.BaseItem}
                                        style={{backgroundColor:backgroundColor, borderWidth:0}}
                                    >
                                        <p className={`${TextStyle.BodyTextSmall} ${TextStyle.Bold}`} style={{color:forgroundColor, paddingRight:'5px'}}>
                                        { LocalStrings[localCode][actualCostItem.TYPE]}
                                        </p>
                                    </div>
                                    {actualCostService[actualCostItem.TYPE] && 
                                    <div className={styles.ChargeMethod}>
                                        <p className={TextStyle.BodyTextExSmall}>
                                        { this.renderChargeMethod(actualCostItem, actualCostService[actualCostItem.TYPE], Max) }
                                        </p>
                                        {  Max && actualCostItem.NOTE &&
                                        <p className={TextStyle.BodyTextExExSmall} style={{color:Colors.redColor, marginTop:'3px'}}>
                                            {LocalStrings[localCode][actualCostItem.NOTE]}
                                        </p>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>    
                            )    
                        })}
                    </div>  
                }
                {isEstimate===true && 
                <div className={styles.TitleContainer} style={{paddingTop:0}}>
                    <div style={{marginVertical:10}}>
                        <p className={TextStyle.BodyMediumText}>
                            {LocalStrings[localCode].AFTER_CALC_ESTIMATE_TEXT}
                        </p>
                    </div>
                </div> 
                }  
            </div>
        ) 
    }
    
}

export default ServiceItems;