import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import EvaluateBuyer1 from '../../../assets/images/help/selling/sell_rating/sell_rating_1.jpg';
import EvaluateBuyer2 from '../../../assets/images/help/selling/sell_rating/sell_rating_2.jpg';
import EvaluateBuyer3 from '../../../assets/images/help/selling/sell_rating/sell_rating_3.jpg';
import EvaluateBuyer4 from '../../../assets/images/help/selling/sell_rating/sell_rating_4.png';
import EvaluateBuyer5 from '../../../assets/images/help/selling/sell_rating/sell_rating_5.png';

class EvaluateBuyer extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>購入者を評価する</span>
            </h3>
            <p className={styles.ArticleDesc}>
                取引の最後に以下の手順で購入者を評価しましょう。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「売上」を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateBuyer1} alt="購入者を評価するステップ1画像" />
                    <li>
                        売り上げが表示されます。購入者を評価したい取引を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateBuyer2} alt="購入者を評価するステップ2画像" />
                    <li>
                        「購入者を評価する」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateBuyer3} alt="購入者を評価するステップ3画像" />
                    <li>
                        「評価」の欄でそれぞれの項目について５段階評価のボタンをタップしていきます。「コメント」欄には今回の依頼でよかった点などの感想、もし困った点などがあればここに記入しましょう。最後に「評価を投稿する」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateBuyer4} alt="購入者を評価するステップ4画像" />
                    <li>
                        「領収書へ戻る」ボタンで前画面に戻ることができます。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateBuyer5} alt="購入者を評価するステップ4画像" />   
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(EvaluateBuyer);