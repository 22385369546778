import React from 'react';
import styles from './UserInformation.module.scss';
import moment from 'moment';
import Review from '../Review/Review';
import {
    Avator
} from '../../../components/UI';

const getAvgResTime = (response) => {
    const {responceNumbers, totalResponceMinute} = response
    return Math.floor(totalResponceMinute/responceNumbers);  
}

const getDisplayTime = (minutes, localCode, LocalStrings) => {
    let timeStr = null;
    if( minutes < 60) {
        timeStr = `${moment.duration(minutes, "minutes").asMinutes()} ${LocalStrings[localCode].MINUTE}`;
    } else if ( minutes >= 60 && minutes < 1440) {
        // 1440 = 60min*24hrs
        let hours10 = Math.round(moment.duration(minutes, "minutes").asHours()*10);
        timeStr = `${hours10/10} ${LocalStrings[localCode].HOUR}`;
    } else {
        let days10 = Math.round(moment.duration(minutes, "minutes").asDays()*10);
        timeStr = `${days10/10} ${LocalStrings[localCode].DAY}`;
    }
    return timeStr;
}

const UserInformation = props => {
    const { accountInfo, theGig, localCode, onUserInfoPressed, onPressReviews, appData } = props;
    const { id, reviews, response} = theGig;
    const {Colors, LocalStrings} = appData;
    let avgResTime = 0;
    if(response){
        avgResTime = getAvgResTime(response);
    }
    
    const resTime = getDisplayTime(avgResTime, localCode, LocalStrings);

    return (
        <div className={styles.UserInformation}>
            <div 
                className={styles.AvatorContainer}
                onClick={(event) => {onUserInfoPressed(event)} }
            >
                <Avator size={55} avator={accountInfo.avator} alt="userInfo" />
                <p className={`${styles.BodyTextSmall} ${styles.bold}`} style={{ marginTop:'5px', color:Colors.primaryButtonBGColor}}>
                    {LocalStrings[localCode].DETAILS}
                </p>
            </div>
            <div className={styles.UserInfoBox} style={{ borderRight:`1px solid ${Colors.contentFontTintColor}`}}>
                <div className={styles.UserInfoDataHead}>
                    <p className={`${styles.BodyTextSmall}, ${styles.Bold}`}>
                        {accountInfo && accountInfo.displayName ? accountInfo.displayName : null}
                    </p>
                </div>
                {onPressReviews &&
                <div 
                    className={styles.UserInfoData }
                    style={{width:"80%"}}
                    onClick={(event) => {onPressReviews(event)} }
                >
                    <Review 
                        reviews={reviews} 
                        id={id}
                        localCode={localCode}
                        user={null}
                        fontSize={13}
                        appData={appData}
                    />
                </div>
                }
                {!onPressReviews &&
                <div 
                    className={styles.UserInfoData }
                    style={{width:"80%"}}
                    >
                    <Review 
                        reviews={reviews} 
                        id={id}
                        localCode={localCode}
                        user={null}
                        fontSize={13}
                        appData={appData}
                    />
                </div>
                }
            </div>
            <div className={styles.UserInfoBox}>
                <div className={styles.UserInfoDataHead}>
                    <p className={`${styles.BodyTextSmall}, ${styles.Bold}`}>
                        {LocalStrings[localCode].AVE_RES_TIME}
                    </p>
                </div>
                <div className={styles.UserInfoData}>
                    <p className={styles.BodyTextSmall}>
                        {avgResTime===0 ? `1 ${LocalStrings[localCode].HOUR}` : resTime }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default UserInformation;