import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux';
import BlogStyles from '../Blog.module.scss';
import styles from './HowToStart.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import { Spinner } from '../../../components/UI';
import BlogSectionTitle from '../../../components/Blog/BlogSectionTitle/BlogSectionTitle';
import FeatureRegular from '../../../components/Blog/FeatureRegular/FeatureRegular';
import SearchTags from '../../../components/Blog/SearchTags/SearchTags';
import FeatureVideo from '../../../components/Blog/FeatureVideo/FeatureVideo';
import Pagenation from '../../../components/Blog/Pagenation/Pagenation';
import { normalizedData, normalizedVideoList, calcRemainNum, handleSubCatPress } from '../../../utils';

class HowToStart extends Component {
    
    constructor(props){
      super(props);
      const {blog} = this.props;
      const itemNumPage = 9;
      const {videos, articles, howToStart} = blog;
      let theHowToStart = normalizedData(howToStart, articles);
      let howToVideos = normalizedVideoList(videos);
      let maxPage = Math.ceil(howToStart.length / itemNumPage);
      this.state = {
        howToStart:theHowToStart,
        howToVideos,  
        currentPage : 1,
        maxPage,
        itemNumPage: 9
      }
    }

    updateCurrentPage = (currentPage) => {
      this.setState( (prevState) => {
        return {
          ...prevState,
          currentPage
        }
      });
    }

    renderHowToStart = () => {
      const {howToStart, currentPage, maxPage, itemNumPage} = this.state;
      if(howToStart === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        let howToStartRemain = [];
        if(currentPage === maxPage){
          let remain = calcRemainNum(howToStart.length, 3);
          if(remain > 0) {
            for(let i=0; i < remain; i++){
              howToStartRemain.push(1);
            }  
          }
        }

        let currectPageData = howToStart.filter((article, index)=>{
          let prevPage = currentPage -1;
          let first = prevPage * itemNumPage;
          let last = currentPage * itemNumPage + 1;
          return (first < index+1 && index+1 < last);
        });

        return(
        <Aux>
          {currectPageData.map((subArticle, index) => {
              const {id} = subArticle;
              return(
              <div key={`howToStart_${id}`} className={styles.PodSmallRegularBox} >
                <FeatureRegular 
                  item={subArticle} 
                  index={index} />
              </div>
              );
            })
            }
            {currentPage === maxPage && 
            <Aux>
            {howToStartRemain.length > 0 && howToStartRemain.map((remain, index)=>{
              return(<div key={`remainHowToStart_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>
            }  
        </Aux>
        );
      }
    }

    renderHowToVideo = () => {
      const {howToVideos} = this.state;
      if(howToVideos === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        let howToMovieRemain = [];
        if(howToVideos.length < 3) {
          for(let i=0; i < 3 - howToVideos.length; i++){
            howToMovieRemain.push(1);
          }
        }
        return(
        <Aux>
          {howToVideos.map((subArticle, index) => {
              const {id} = subArticle;
              if(index < 3){
                return(
                  <div key={`howToMovies_${id}`} className={styles.PodSmallRegularBox}>
                    <FeatureVideo 
                      item={subArticle} />
                  </div>
                  );
              } else {
                return null;
              }
            })
            }
            <Aux>
            {howToMovieRemain.length > 0 && howToMovieRemain.map((remain, index)=>{
              return(<div key={`remainHowToVideo_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>  
        </Aux>
        );
      }
    }

    render(){
        const {location, localCode, appData} = this.props;
        const {currentPage, maxPage, howToStart} = this.state;
        let pathname = location.pathname;
        let title = 'タグジャムでのサービスの始め方';
        let description = 'タグジャムを使って個人サービスの始め方、SNSシェアで拡散機能、スケジュール管理、取引チャット機能、見積書、請求書機能、キャンセル管理など上手く活用してサービスを成功させよう。';
        
        return(
          <>
          <Helmet>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content={`タグジャム-${title}`} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={`タグジャム-${title}`} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" data-react-helmet="true" />
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={description}
              />
              <meta content="タグジャムの活用方法の記事,個人サービスで稼ぐ,始めるを応援,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className={BlogStyles.BlogPageContent}>
            {/* 始め方の記事 */}    
            <BlogSectionTitle
              tagTitle="始める"
              title="始めの一歩"
              needMargin={false}
            />
            <div className={styles.PodSmallRegularLayout}>
              {this.renderHowToStart()}
            </div>
            
            {maxPage && howToStart.length > 9 &&
            <Pagenation
              currentPage={currentPage}
              maxPage={maxPage}
              setCurrentPage={this.updateCurrentPage} 
            />
            }

            {/* 使い方動画 */}
            <BlogSectionTitle
              tagTitle="動画"
              title="使い方動画"
              linkTo="/blog/HowToVideos"
              linkLabel="もっと見る…"
              needMargin={true}
            />
            <div className={styles.PodSmallRegularLayout}>
              {this.renderHowToVideo()}
            </div>

            {/* タグで記事を探す */}
            <BlogSectionTitle
              tagTitle="タグ"
              title="タグで記事を探す"
              needMargin={true}
            />
            <div className={BlogStyles.SearchTagContainer}>
              <SearchTags
                appData={appData}
                localCode={localCode}
                onClick={(subCat) => {handleSubCatPress(subCat, this.props)} } 
              />
            </div>
        </div>
        </>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
  blog: { blog, blogIsLoading, blogError}
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError,
  blog, blogIsLoading, blogError
});

export default connect(mapStateToProps, null)(HowToStart);