import {
    FETCH_BANK,
    SET_BANK
} from '../actions/actionTypes';

const initState = {
    bank: null,
    bankIsLoading: false,
    bankError: false
};

const bankReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_BANK:
            return {
                ...state,
                bank: payload
            }
        case `${FETCH_BANK}_PENDING`:
            return {
                ...state,
                bankIsLoading: true,
                bankError: false,
            }
        case `${FETCH_BANK}_FULFILLED`:
            return {
                ...state,
                bank: payload,
                bankIsLoading: false,
                bankError: false,
            }
        case `${FETCH_BANK}_REJECTED`:
            return {
                ...state,
                bank: null,
                bankIsLoading: false,
                bankError: true,
            }
        default:
            return state;
    }
};

export default bankReducer;
