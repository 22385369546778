import React from 'react';
import styles from './CheckBox.module.css';

const CheckBox = (props) => {
    const { label, changed, checked, error } = props;
    return(
    <label className={styles.CheckBox}>{label}
        <input type="checkbox" onChange={changed} checked={checked} />
        <span className={`${styles.Checkmark} ${error ? styles.ErrorItem : null}`}></span>
        { error &&
        <div className={styles.Error}>
            {error}
        </div>
        }
    </label>
    );
};

export default CheckBox;