import React from 'react';
import styles from './FormInput.module.scss';

const FormInput = (props) => { 
    const { 
        label, 
        placeholder, 
        customStyle, 
        customInputStyle, 
        type,
        remainTextLeng,
        maxLength,
        onChange,
        onClear,
        onBlur,
        clearLabel, 
        value, 
        error, 
        valid, 
        touched,
        disabled, 
        rows, 
        cols 
    } = props;
    let inputStyles = [styles.InputBox];
    if(error && touched){
        inputStyles.push(styles.ErrorItem);
    }
    if(valid){
        inputStyles.push(styles.ValidItem);    
    }
    return(
        <div className={styles.FormInput} style={customStyle ? customStyle : null}>
            {label &&
                <label className={styles.Label}>
                    {label}
                </label>
            }
            <div style={{width:'100%', display:'flex', justifyContent:'flex-end', alignItems: type==='textarea'? "flex-end": "center"}}>
            { onClear && value && type!=='textarea' &&
            <i onClick={onClear} className={`fa fa-times ${styles.ClearIconText}`} />
            }
            { remainTextLeng && type==='textarea' &&
            <p className={styles.remainTextLabel}>{remainTextLeng}</p>
            }
            { onClear && value && type==='textarea' &&
            <p onClick={onClear} className={styles.ClearTextLabel}>{clearLabel}</p>
            }
            { type==='textarea' && 
                <textarea 
                    className={inputStyles.join(' ')}  
                    placeholder={placeholder}
                    value={value ? value : ""} 
                    onChange={onChange}
                    style={customInputStyle? customInputStyle : null}
                    rows={rows ? rows : 2}
                    cols={cols? cols : 26 }
                    disabled={disabled ? disabled : false}
                >
                </textarea>
            }
            { (type==='text'|| type==='password' || type === 'number' || type === 'email') &&
                <input
                    className={inputStyles.join(' ')} 
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value ? value : ""} 
                    type={type}
                    maxLength={maxLength}
                    style={customInputStyle? customInputStyle : null}
                    disabled={disabled ? disabled : false}
                />
            }
            </div>
            { (error && touched) &&
            <div className={styles.Error}>
            {error}
            </div>
            }
        </div>
    )
}

export default FormInput;