import React from 'react';
import styles from './CallToActions.module.scss';
import { Button } from '../../../components/UI'
const CallToActions = props => {
    const { 
        localCode, 
        appData:{LocalStrings},
        theGig,
        onServieAreaPress,
        onContactPress,
        onInterviewPress
    } = props;
    const {
        MainCat,  
        coveredArea,
        indicator: { DELIVERY_OK, VISIT_TYPE, VISITOR_TYPE }, 
    } = theGig;

    const certer = coveredArea.centerAddress;

    const renderDeliverlyBtn =() => {
        let iconName = "map-marker",
            disabled = false,
            btnLable = LocalStrings[localCode].COVERED_AREA_CONFIRM;

        if(MainCat==='RENT' || MainCat==='MAKE') {
            iconName = "truck";
            btnLable = LocalStrings[localCode].DELIVERY_AREA
            if(!DELIVERY_OK){
                disabled=true;
            }
        }

        if(MainCat==='HELP') {
            if(!VISIT_TYPE){
                disabled=true;
            }
        }

        if(MainCat==='TEACH') {
            if(VISITOR_TYPE){
                disabled=true;
            }
        }

        return(
        <Button
            onClick={onServieAreaPress}
            disabled={disabled}
            BtnType="Secoundary">
            <i 
                className={`fa fa-${iconName} ${styles.Icon}`}
                style={{marginRight:5}} />
            <p>{btnLable}</p>
        </Button>
        )
    }

    let intervewBtnDisable = (MainCat==='HELP' || (MainCat==='TEACH') ) 
        ? false : true;

    return(
        <div className={styles.CallToAction}>
            <div className={styles.ButtonRowContainer}>
                <div className={styles.AreaInfoContainer}>
                    <div className={styles.ServiceLocContainer}>
                        <p>{certer.city}</p>
                        <p>{certer.town}</p>
                    </div>
                    <div className={styles.CoveredAreaContainer}>
                        <p style={{fontSize:'20px', fontWeight:"bold", marginRight:5}}>
                            {coveredArea.radius ? coveredArea.radius : 0}
                        </p>
                        <div style={{marginBottom:4}}>
                            <p>{ LocalStrings[localCode].COVERED_RADIUS}</p> 
                        </div>
                    </div>
                </div>
                <div style={{ width:'50%', paddingLeft: 15}}>
                    {renderDeliverlyBtn()}
                </div>
            </div>
            <div className={styles.ButtonRowContainer}>
                <div style={{ width:'50%'}}>
                    <Button
                        BtnType="Secoundary"
                        onClick={onContactPress}
                    >
                        <i className={`fa fa-comments ${styles.Icon}`} style={{marginRight:5}} />
                        <p>{LocalStrings[localCode].CONTACT}</p>
                    </Button>     
                </div>
                <div style={{ width:'50%', paddingLeft: '15px'}}>
                    <Button
                        BtnType="Secoundary"
                        onClick={onInterviewPress}
                        disabled={intervewBtnDisable}
                    >
                        <div className={`icon-interview ${styles.Icon}`} />
                        <p style={{marginLeft:5}}>{LocalStrings[localCode].INTERVIEW_REQUEST}</p>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CallToActions;