import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class TransactionTermsForBuyer extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>売買取引・契約の規約</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムの取引を成立させるには以下に示す規約に同意する必要があります。</p>
            
            <h5 className={styles.HeaderMargin20}>サービス料支払いについて</h5>
            <p>サービス料金の支払いは、サービスが提供が提供され請求書が送信された後に速やかに行ってください。請求内容、金額について問題がある場合は「取引チャット」の「請求金額に問題あり」機能を通じでサービス提供者に連絡をして解決してくださ。</p>
            <h5 className={styles.HeaderMargin20}>キャンセルについて</h5>
            <p>サービスをキャンセルする場合はキャンセル通知をシステムにて送信してください。「助けます」「教えます」「貸します」の予約確定後のキャンセルはキャンセル回数にカウントされます。「作ります」のサービスの場合は出店者より作成開始のお知らせの送信後のキャンセルはキャンセル回数にカウントされます。</p>
            <p className={styles.HeaderMargin10}>「助けます」「教えます」のサービス開始時刻の４８時間前を過ぎるキャンセルはサービス料合計金額の２０％、２４時間前を過ぎるキャンセルはサービス料合計金額の５０％のキャンセル料がかかります。「貸します」のサービスはレンタル開始日の２日前を過ぎるキャンセルはサービス料合計金額の２０％、１日前を過ぎるキャンセルはサービス料合計金額の５０％のキャンセル料がかかります。「作ります」のサービスの場合は出店者より作成開始のお知らせの送信後のキャンセルは5０％のキャンセル料がかかります。</p>
            <h5 className={styles.HeaderMargin20}>当日受付について</h5>
            <p>「助けます」「教えます」「貸します」のサービス当日受付のオーダーのキャンセルはサービス料合計金額の５０％のキャンセル料金がかかりますのでお気をつけ下さい。</p>
            <h5 className={styles.HeaderMargin20}>実費精算について</h5>
            <p>サービス詳細ページに実費清算が記してある場合は、精算時にサービス料金の合計金額にサービス出品者が実費生産額（交通費、教材費、圏外発送非、追加作業費、又は追加材料費など）を加えた金額を請求致しますのでご注意下さい。</p>
            <h5 className={styles.HeaderMargin20}>レンタル品の返却時適用料金について</h5>
            <p>「貸します」のレンタル品返却の際にサービス出品者が故障、損傷、汚れ、又は、クリーニングが必要と判断した場合は別途返却時料金を適応する場合があります。返却時刻を超えての返却の場合は延滞料金がかかりますのでご注意下さい。</p>
            <h5 className={styles.HeaderMargin20}>レンタル品の保証金について</h5>
            <p>「貸します」サービスでの保証金はレンタル品の返却がなされなかった場合は返金出来ません。必ずレンタル品は返却して下さい。</p>
            <h5 className={styles.HeaderMargin20}>連絡先の交換について</h5>
            <p>出品者への連絡はタグジャムシステムを使用して行って下さい。出品者情報保護のためメールアドレスや電話番号を出店者に直接聞いたりしないで下さい。</p>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(TransactionTermsForBuyer);