import React from 'react';
import styles from './Step.module.css';
import PointRight from '../../../assets/images/pointer_right.svg';
import PointLeft from '../../../assets/images/pointer_left.svg';
import SpecialPointRight from '../../../assets/images/special_pointer_right.svg';
import SpecialPointLeft from '../../../assets/images/special_pointer_left.svg';
import TaguJamLogo from '../../../assets/images/tagujam_logo.svg';


const step = props => {

    let color = props.side==='left' 
                ? props.special ? '#f3cb86' : '#ffccb4'
                : props.special ? '#f3cb86' : '#b7e6f7';
    
    const ContentBorder = {border: `1px solid ${color}`};
    const HeadBgStyle = {backgroundColor: color};
    const ContentStyle = props.special 
            ? `${styles.StepContent} ${styles.BgSpecial}`
            : `${styles.StepContent} ${styles.BgWhite}` 

    const Text = props.children ? <p className={styles.StepText}>{props.children}</p> : null;

    let Content = (
        <div key={`${props.title}-content`} className={ContentStyle} style={ContentBorder}>
            <h4 className={styles.StepTitle}  style={HeadBgStyle}>{props.title}</h4>
            {Text}
        </div>
    );

    if(props.special){
        Content = (
        <div key={`${props.title}-content`} className={ContentStyle} style={ContentBorder}>
            <h4 className={styles.StepTitle}  style={HeadBgStyle}>
            <div className={styles.TaguJamLogo}>
                <img alt="tagujam logo" src={TaguJamLogo} style={{ width:'10px'}} />
            </div>
            {props.title}
            </h4>
            {Text}
        </div>
        );    
    }

    const PointerRight = (<img key={`${props.title}-PointRight`} className={styles.Pointer} src={PointRight} alt="右を指す"/>);
    const PointerLeft = (<img key={`${props.title}-PointLeft`} className={styles.Pointer} src={PointLeft} alt="左を指す"/>);
    const SpecialPointerRight = (<img key={`${props.title}-SpPointRight`} className={styles.Pointer} src={SpecialPointRight} alt="右を指す"/>);
    const SpecialPointerLeft = (<img key={`${props.title}-SpPointLeft`} className={styles.Pointer} src={SpecialPointLeft} alt="左を指す"/>);

    let StepContents = props.pointer === 'right' 
                        ? props.special ? [Content, SpecialPointerRight] : [Content, PointerRight] 
                        : props.special ? [SpecialPointerLeft, Content] : [PointerLeft, Content];

    let StepClass = props.side === 'left' 
                    ? styles.StepLeft
                    : styles.Step
    
    if(props.marginTop) {
        StepClass = StepClass +  ` ${styles.MarginTop15}`;
    }

    return(
    <div className={StepClass}>
        {StepContents} 
    </div>
    )
}

export default step;