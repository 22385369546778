import React from 'react';
import styles from './Certificates.module.scss';

const Certificates = props => {
    const { certificates, localCode, MainCat, SubCat, appData } = props;
    const {Colors, LocalStrings} = appData;
    const CertList = MainCat==='HELP' ? appData.CertItems[MainCat][SubCat] : appData.CertItems[MainCat];

    return (
        <div className={styles.Certificates} >
           {
            CertList.map( ( cert_id ) => {
                let iconName = cert_id.toLowerCase().replace(/_/g, "-");
                let fontColor = Colors.contentFontTintColor;
                let label = LocalStrings[localCode][cert_id];
                if(cert_id.indexOf('CERT_')!==-1){
                    iconName = cert_id.replace("CERT_", "").replace(/_/g, "-").toLowerCase();
                }
                if(cert_id==='CERT_ANIMAL'){
                    iconName='animal-cert';
                    label=LocalStrings[localCode].ANIMAL_CERT_SHORT;
                }
                if(cert_id==='REGISTRATION_1ST_CLASS_ANIMAL_HANDLING_BUSINESS' ){
                    iconName = 'gov-registered';
                    label = LocalStrings[localCode].ANIMAL_HANDLING;   
                }
                if(certificates){
                    if( certificates[cert_id] && certificates[cert_id] === 'VERIFIED' ){
                        fontColor = Colors.contentFontColor;
                    }
                    
                    if( (MainCat==='TEACH' || MainCat==='MAKE') &&  cert_id==="CERTIFIED" && !certificates["CERTIFIED"]) {
                        if(certificates["CERT_NURSE"] === 'VERIFIED' 
                            || certificates["CERT_CHILD_CARE"] === 'VERIFIED'
                            || certificates["CERT_HAIRDRESSER"] === 'VERIFIED'
                            || certificates["CERT_CARE_WORKER"] === 'VERIFIED' ){
                            fontColor = Colors.contentFontColor;     
                        }
                    }
                } 
                
                return(
                    <div key={cert_id} className={styles.BudgeContainer}>
                        <div className={`icon-${iconName}`} style={{fontSize: "30px", color:fontColor, paddingRight:'20px'}} />
                        <p className={styles.LabelText}style={{color:fontColor}}>
                            {label}
                        </p>
                    </div>
                )        
            })    
           } 
        </div>
    )
}

export default Certificates;