import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Privacy extends Component {
    render(){
        const {location} = this.props;
        let pathname = location.pathname;
        let title = '個人情報取り扱い規約 - プライバシーポリシー';
        let description = 'タグジャムプライバシー規約では、タグジャムで収集した個人情報をどのように取り扱うかが確認できます。';
        return(
        <>
          <Helmet>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content={`タグジャム-${title}`} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={`タグジャム-${title}`} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />  
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={description}
              />
              <meta content="タグジャムプライバシー,個人情報の扱い,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className="MainPadding">
            <h2 className="PageHeader">
                プライバシーポリシー
            </h2>
            <div className="Content">
                <div className="Item">
                    <p>タグジャム（以下、「当社」といいます。）は、本ウェブサイト及び当社のモバイル版アプリケーション（以下、「本ウェブサイト等」と言います。）で提供するサービス（以下,「本サービス」といいます。）における、お客様（サービス利用会員、サービス出品者、及び本ウェブサイト等の閲覧者を総称するものです）のお客様情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。本サービスのご利用をされるためには、本ポリシーを熟読のうえ、内容に同意していただく必要があります。同意いただけない場合は、本ウェブサイト等へのアクセス及び本サービスのご利用はお断りします。</p>
                </div>
                <div className="Item">
                    <h5>第１条　お客様情報について</h5>
                    <p>お客様情報とは、お客様個人を識別しうる情報を意味し、下記を含むものとします。</p>
                    <ul>
                        <li>お客様が登録もしくは変更の届出をしたか、または公開されている、お客様のお名前、ログインID、パスワード、ニックネーム、ご住所、電子メールアドレス、電話番号、会員に関する情報、クレジットカード番号その他のクレジットカードに関する情報、銀行口座に関する情報</li>
                        <li>お客様がご利用になった本サービスの内容、ご利用日時、ご利用回数などのご利用内容・ご利用履歴に関する情報</li>
                        <li>お客様のご利用に関連した取引記録や決済状況に関する情報</li>
                        <li>当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集する情報</li>
                    </ul>
                </div>
                <div className="Item">
                    <h5>第２条　お客さま情報の開示・訂正、利用の停止</h5>
                    <p>お客さまはご登録いただいたログインIDとパスワードで本ウェブサイト等にログインしていただき、会員登録時にご登録いただいたお客さまの情報をご確認・訂正いただくことができます。尚、お客様は当社Eメールにて事前連絡していただき、当社が設定する利用停止の手続きにより本サービスの利用停止が可能です。</p>
                </div>
                <div className="Item">
                    <h5>第３条　当社によるお客様情報の訂正について</h5>
                    <p>ご登録いただいたお客様情報のうち、市町村などの名称および郵便番号、金融機関の名称あるいはクレジットカードの有効期限など、ご請求を行う上で支障がある情報に変更があった場合には、当社が登録されているお客様情報を変更させていただく場合があります。</p>
                </div>
                <div className="Item">
                    <h5>第４条　お客様情報の取得・収集について</h5>
                    <p>当社は、以下の方法により、本ウェブサイト等をご利用になるお客様のお客様情報を取得・収集させていただくことがあります。</p>
                    <ul>
                        <li>本ウェブサイト等を通じてでお客様自身が入力されたお客様情報を、当社は取得・収集させていただきます。</li>
                        <li>お客様から当社に対し、電子メール、郵便、書面、電話等の手段によって、ご提供していただいたお客様情報を当社は取得・収集させていただきます。</li>
                        <li>お客様がご使用の電子メールソフトに電子メールの到着および開封の確認機能がある場合、当社からお客様にお送りした電子メールの到着および開封に関する情報を受け取らせていただき、記録させていただくことがございます。お客様がこのような情報を当社が受領することをご希望されない場合は、ご使用の電子メールソフトの確認機能を停止していただくか、確認機能のない電子メールソフトのご使用をお願いいたします。</li>
                        <li>お客様が本ウェブサイト等の本サービスをご利用された履歴等を収集させていただきます。これらの情報にはお客様が利用されるＵＲＬ、ブラウザや携帯電話の種類やIPアドレスなどの情報を含みます。</li>
                        <li>また、本ウェブサイト等では、ウェブサイト又はアプリケーションを改善する目的でGoogle Analyticsを利用し、それに伴い改善目的でお客様の情報を利用しています。Google Analyticsは、クッキーを利用してお客様の情報を収集します。Google Analyticsの利用規約及びプライバシーポリシーに関する説明については、Google Analyticsのサイトをご覧ください。</li>
                    </ul>
                </div>
                <div className="Item">
                    <h5>第５条　お客様情報の利用目的について</h5>
                    <p>当社は、お客様情報を取得・収集する際にお客様にお知らせした利用目的または以下の目的のために、お客様情報を利用させていただきます。これらの利用目的以外には、下記「第４条　お客様情報の第三者への預託、提供について」に記載する場合または事前にお客様に同意をいただいた場合を除き、利用致しません。なお、お客様は、お客様に対する情報提供について、会員登録時及び登録後に、情報提供を断ることができます。但し、本サービス運営上必要となる情報提供については、その情報提供を断ることができないこととします。</p>
                    <ul>
                        <li>本ウェブサイト等で安全かつ確実に取引をして頂くために 利用者に電子メール等で取引の状態や重要な事項を連絡させて頂くため</li>
                        <li>本取引のご購入確認をするため</li>
                        <li>電子メール配信サービスのお申し込みの確認やメールを配信するため</li>
                        <li>本サービスへの登録の確認、本人確認　又は本サービスを提供するため</li>
                        <li>本取引・本サービスのご請求、お支払いとその確認をするため</li>
                        <li>本ウェブサイト等運営上のトラブルの解決のため</li>
                        <li>本取引・本サービス、本ウェブサイト等などの内容を皆さまがよりご満足いただけるよう改良するため</li>
                        <li>本サービスの満足度を調査するため</li>
                        <li>個人情報を統計的に処理した情報を集約し調査結果として公表するため</li>
                        <li>皆さまのご利用状況を把握し、本サービスの改善や新サービスの開発に役立てるため</li>
                        <li>サービス利用会員へのイベント等の各種サービスのご案内をお届けするため</li>
                        <li>調査のご協力や各種イベントへのご参加をお願いしたり、その結果等をご報告するため</li>
                        <li>本取引、本サービス、本ウェブサイト等などの利用状況を把握し、当社をはじめお客様に有益と思われる企業/団体の情報をお届けするため</li>
                    </ul>
                </div>
                <div className="Item">
                    <h5>第６条　お客様情報の第三者への預託、提供について</h5>
                    <p>当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。尚お客様の本ウェブサイト等利用履歴や一部の公開用のお客様情報（ニックネーム、性別、自己紹介文等）は、本ウェブサイト等上で当社が定める期間、公開されます。</p>
                    <ul>
                        <li>業務委託先への預託・提供<br/>お客様情報の処理、お客様への情報提供、キャンペーン・アンケートの実施等を行うために、当社が信頼に足ると判断し個人情報の守秘義務契約を結んだ企業に、業務の全部または一部を委託する場合があり、この場合、当社の厳正な管理下のもと、業務を行うのに必要最小限の範囲で、当該業務委託先企業にお客様情報を預託、提供させていただくことがあります。</li>
                        <li>当社の協力会社・提携先への預託・提供<br/>本サービス提供のため、当社が信頼に足ると判断し個人情報の守秘義務契約を結んだ企業と協力関係・提携関係を結ぶ場合があり、この場合、当社の厳正な管理下のもと、本サービス提供に必要な最小限の範囲で、当該協力会社・提携先企業にお客様情報を預託、提供させていただくことがあります。</li>
                        <li>クレジットカード会社、その他金融機関に対する照会<br/>当社は、お客様が本ウェブサイト等で本サービスを利用された場合等に、代金の決済等を目的として、クレジットカードその他の決済用カードの番号およびお客様のお名前等をクレジットカード会社、その他金融機関に照会させていただくことがあります。</li>
                        <li>事業の譲渡に伴う事業売却先への提供<br/>当社は、事業として、合併や事業の売却をさせていただく場合があります。その場合、当社のお客様情報の取扱規定と同等の方針に従うことを条件に、事業売却先の第三者にお客様情報を提供させていただくことがあります。</li>
                        <li>法令等に基づく提供<br/>当社は、裁判所、行政機関、監督官庁その他の公的機関からお客様情報を提供するよう求められた場合、提供を拒否する合理的な理由がない場合には、お客様情報を提供させていただくことがあります。</li>
                        <li>第三者との紛争解決<br/>会員が第三者に迷惑をかけ、そのトラブルを解決するために、当社が開示を必要と判断した場合には、お客様情報を提供させていただくことがあります。 当社が提供する本サービスの利用者及び第三者の権利や利益等を守るために当社が情報開示が必要と判断した場合には、お客様情報を提供させていただくことがあります。</li>
                        <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
                        <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
                        <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                    </ul>
                </div>
                <div className="Item">
                    <h5>第７条　クッキー及びローカルストレージの使用について</h5>
                    <p>本ウェブサイト等は、お客様のコンピュータ及びモバイルデバイスの記憶装置に、「クッキー」又は「ローカルストレージ」と呼ばれるテキストデータを保存・利用させていただくことがございます。お客様は、「クッキー」を受け取る前にブラウザが警告を出す様に設定しておく事により、お客様の判断で「クッキー」を受け取る事を拒否できます。モバイルデバイスのローカルストレージのデータは消去することができます。ただし、本ウェブサイト等の機能または本サービスがご利用になれない場合があります。</p>
                </div>
                <div className="Item">
                    <h5>第８条　お客様情報の管理方法</h5>
                    <p>当社では、収集させていただいたお客様情報を、一般の利用者がアクセスできない環境下にある、ファイヤーウォールで保護されたサーバーにて安全に保管し、不正アクセス・紛失・破壊・改ざんまたは漏洩が生じないよう努めます。なお、当社では、当社が信頼に足ると判断し個人情報の守秘義務契約を結んだ企業に、個人情報の取り扱いを委託する場合がありますが、この場合にも、お客様情報は当社のお客様情報の取扱規定（プライバシーポリシー）のもとで保護されます。</p>
                </div>
                <div className="Item">
                    <h5>第９条　免責・注意事項について</h5>
                    <p>当社は、本ウェブサイト等のウェブページにリンクされている他の事業者または個人のウェブサイトにおけるお客様の個人情報等の保護について、責任を負うものではありません。お客様ご自身にて当該ウェブサイトの内容をよくご確認の上で、閲覧およびご利用をお願いいたします。</p>
                    <p>当社は、お客様の個人情報を不正なアクセスから守るため､データの保護が必要であると判断した場合、データ転送にSSL(Secure Socket Layer)という業界標準の暗号方式を使用しています。SSL非対応ブラウザによる本ウェブサイト等上での登録、申込もしくは注文、または電子メールもしくは郵便によりお客様情報をご提供いただく場合は、当社への情報到達過程にて、当社の管理外の第三者に閲覧、窃取される危険性が高まりますので、これらの危険性をご了解の上これらの手段をご利用ください。</p>
                    <p>万一、当社によるセキュリティ施策にもかかわらず、ハッカー等による不当な行為により、お客様および第三者に損害が生じた場合については、当社は責任を負いかねますのでご了承ください。 お客様ご本人を確認するための情報につきましては、お客様にて、紛失、忘失または第三者に知られることのないよう厳重な管理をお願いいたします。</p>
                </div>
                <div className="Item">
                    <h5>第１０条　海外からのアクセスについて</h5>
                    <p>本ウェブサイト等の利用、管理、運用方法および掲載内容が違法または不適切とされる国または地域からの本ウェブサイト等へのアクセスはご遠慮を願います。</p>
                </div>
                <div className="Item">
                    <h5>第１１条　プライバシーポリシーの変更</h5>
                    <p>当社は、必要と判断した場合には、いつでも本ポリシーを変更することができるものとします。なお、当社は、本ポリシーを変更した場合には、サービス利用会員に対して通知を行い、又は当社ウェブサイトに掲載するものとします。変更された本ポリシーの効力は、変更後の本ポリシーが当社ウェブサイトに掲載された時より生ずるものとします。</p>
                </div>
                <div className="Item">
                    <h5>第１２条　お客様情報の取扱規定の改善および最新のお客様情報の取扱規定の確認について</h5>
                    <p>当社はお客様情報の取扱規定（プライバシーポリシー）について、適宜その改善に努めていきます。最新のお客様情報の取扱規定は、当社のウェブサイトでご覧になれます。 お客様は、本ウェブサイト等にアクセスするか当社の本サービスをご利用になる前に、必ず最新のお客様情報の取扱規定をご確認ください。お客様が本ウェブサイト等にアクセスされるか当社の本サービスをご利用になられた場合は、最新のお客様情報の取扱規定の内容に同意されたものとみなします。</p>
                </div>
            </div>
          </div>
        </>
        );
    }
}

export default Privacy;