import React from 'react';
import styles from './SmartBanner.module.scss';
import {Button} from '..';
import {isIOS, isAndroid} from 'react-device-detect';

const StoreLabel = {
  ios: "App Store",
  android: "Google Play"
}

const StoreURL = {
  ios: "https://apps.apple.com/jp/app/id1479792883?mt=8",
  android: "https://play.google.com/store/apps/details?id=com.tagujammobile"
}

const onClickHandler = (event, platform) => {
  event.preventDefault();
  window.location.href =StoreURL[platform];
}

const getPlatform = () => {
  let platform = 'other';
  if (isIOS) {
    platform ='ios';  
  }
  if (isAndroid) {
    platform ='android';  
  }
  return platform;
}

const getStoreLabel = (freeLabel, platform) => {
  return `${freeLabel} -\n${StoreLabel[platform]}`;
}

const SmartBanner = (props) => {
  const {
    title,
    author,
    freeLabel,
    show,
    closeClick
  } = props;
  const platform = getPlatform();
  const description = getStoreLabel(freeLabel, platform);
  console.log('platform;',platform)
  if(platform!=='other' && show){
    return(
      <div className={styles.SmartBanner}>
        <div onClick={()=>{closeClick(false)}} className={styles.CloseContainer}>
          <i className="fa fa-times-circle" />
        </div>
        <div className={styles.Image} />
        <div className={styles.ContentContainer}>
          <div className={styles.Title}>{title}</div>
          <div className={styles.Description}>{author}</div>
          <div className={styles.Description}>{description}</div>
        </div>
        <div className={styles.BtnContainer}>
          <Button 
            disabled={false}
            loading={false}
            type='button'
            onClick={event => {onClickHandler(event, platform)}}
            BtnType='Primary'
          >
            {"開く"}
          </Button>
        </div>
      </div>
    );
  } else {
    return null
  }
  
}

export default SmartBanner;