import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SystemFlow.module.css';
import StepHead from './StepHead/StepHead';
import Step from './Step/Step';
import StepCenter from './StepCenter/StepCenter';
import BillCheck from '../../../assets/images/system_flow_bill_check.svg';
import BillCredit from '../../../assets/images/system_flow_bill_credit.svg';
import CreditCheck from '../../../assets/images/system_flow_credit_check.svg';
import CreditSchedule from '../../../assets/images/system_flow_credit_schedule.svg';
import EstimateCalender from '../../../assets/images/system_flow_estimate_calender.svg';
import Evaluation from '../../../assets/images/system_flow_evaluation.svg';
import IdCheck from '../../../assets/images/system_flow_id_check.svg';
import IdEmail from '../../../assets/images/system_flow_id_email.svg';
import PhoneForm from '../../../assets/images/system_flow_phone_form.svg';


const systemFlow = props => (
    <div className={styles.SystemFlow} >
        <div className={styles.DealFlowContents}>
            <div className={styles.FlowHead}>
                <div className={styles.FlowColum}>
                    <StepHead side="left" label="サービス提供者" role='provider' />
                </div>
                <div className={styles.FlowColum }>
                    <StepHead side="right" label="サービス購入者" role='customer' />
                </div>
            </div>    
        </div>
        <div className={styles.FlowRow} style={{justifyContent:'center'}}>
            <StepCenter
                title="会員登録"
                special={false}
            >
                <p className={styles.StepText}>メールアドレスで会員登録後に免許証など本人確認用の画像を提出</p>
                <div className={styles.StepImageBox}>
                    <img src={IdEmail} className={styles.StepCenterImage} alt="本人確認、Eメール" />
                </div>
            </StepCenter>
        </div>
        <div className={styles.FlowRow} style={{justifyContent:'center'}}>
            <StepCenter
                title="本人確認"
                special={true}
            >
                <p className={styles.StepText}>タグジャムが提出いただいた本人確認用の画像を確認し会員登録が完了します</p>
                <div className={styles.StepImageBox}>
                    <img src={IdCheck} className={styles.StepCenterImage} alt="本人確認、チェックマーク" />
                </div>
            </StepCenter>
        </div>
        <div className={styles.FlowRow} style={{justifyContent:'center'}}>
            <div className={styles.FlowColum}>
                <Step 
                    side="left"
                    title="サービス出品"
                    pointer="right">
                        <p className={styles.StepText}>シフト、サービス料金、提供エリア等を自分で決め、出店フォームに簡単入力で出品</p>
                        <div className={styles.StepImageBox}>
                            <img src={PhoneForm} className={styles.StepImage} alt="スマホ、入力フォーム" />
                        </div>
                </Step>
            </div>
            <div className={styles.FlowColum}>
                <Step 
                    side="right"
                    title="購入申請"
                    pointer="left"
                    marginTop={30}
                    >
                        <p className={styles.StepText}>気に入ったサービスがあれば、簡易見積もりで料金を確認後に購入・予約を申請</p>
                        <div className={styles.StepImageBox}>
                            <img src={EstimateCalender} className={styles.StepImage} alt="見積書、予約" />
                        </div>
                </Step>
            </div>
        </div>
        <div className={styles.FlowRow} style={{justifyContent:'center'}}>
            <StepCenter
                title="お支払いの審査"
                special={true}
            >
                <p className={styles.StepText}>買い手の方から登録頂いた支払い方法でのお支払いの審査を行います。審査通過後、売り手と買い手の取引が成立し予約が確定します</p>
                <div className={styles.StepImageBox}>
                    <img src={CreditSchedule} className={styles.StepCenterImage} alt="クレジットカード、予約確定" />
                </div>
            </StepCenter>
        </div>
        <div className={styles.FlowRow} style={{justifyContent:'center'}}>
            <div className={styles.FlowColum}>
                <Step 
                    side="left"
                    title="サービス提供"
                    pointer="right">
                        <p className={styles.StepText}>サービス提供、完了したらタグジャムに通知、請求書簡単作成・送信</p>
                        <div className={styles.StepImageBox}>
                            <img src={BillCheck} className={styles.StepImage} alt="サービス完了、請求書" />
                        </div>
                </Step>
            </div>
            <div className={styles.FlowColum}>
                <Step 
                    side="right"
                    title="支払い"
                    pointer="left"
                    marginTop={30}
                    >
                        <p className={styles.StepText}>サービスの完了を承諾し請求書の金額を最終確認して支払い</p>
                        <div className={styles.StepImageBox}>
                            <img src={BillCredit} className={styles.StepImage} alt="請求書チェック、クレジットカード" />
                        </div>
                </Step>
            </div>
        </div>
        <div className={styles.FlowRow} style={{justifyContent:'center'}}>
            <StepCenter
                title="お支払いの確定"
                special={true}
            >
                <p className={styles.StepText}>売り手よりサービス完了通知、買い手より請求金額の承諾の2点を確認後に、お支払いを決済します。</p>
                <div className={styles.StepImageBox}>
                    <img src={CreditCheck} className={styles.StepCenterImage} alt="クレジットカード決済確定" />
                </div>
            </StepCenter>
        </div>
        <div className={styles.FlowRow} style={{justifyContent:'center'}}>
            <StepCenter
                title="取引相手の評価"
                special={false}
            >
                <p className={styles.StepText}>売り手・買い手の良かった点、改善アイディアなどを評価に入力して今後のタグジャムコミュニティーに役立てよう</p>
                <div className={styles.StepImageBox}>
                    <img src={Evaluation} className={styles.StepCenterImage} alt="取引相手評価" />
                </div>
            </StepCenter>
        </div>
        <div className={styles.FlowRow} style={{paddingBottom:'0'}}>
            <div className={styles.Roles}>
                取引完了
            </div>
        </div>
        <div className={styles.FlowRow} style={{ backgroundImage:"none", padding:'30px 0px 0px 0px'}}>
            <div className={styles.ActionButtons}>
                <NavLink to="/dealflow" className={styles.ActionButton} exact={true}>
                    <i className="fa fa-recycle"/>
                    <label>ご利用の流れはこちら</label>
                    <span>&#10095;</span>
                </NavLink>
                <NavLink to="/help" className={styles.ActionButton} exact={true}>
                    <i className="fa fa-question-circle"/>
                    <label>使い方ガイドはこちら</label>
                    <span>&#10095;</span>
                </NavLink>
            </div>
        </div>
    </div>
);

export default systemFlow;