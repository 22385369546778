import React, {Component} from 'react';
import Aux from '../../../hoc/Aux/Aux';
import styles from './SafeSystem.module.css';
import Indicator from '../../../assets/images/indicator.svg';
import SecurityBuyer from '../../../assets/images/security_buyer.svg';
import SecuritySeller from '../../../assets/images/security_seller.svg';

class SafeSystem extends Component {
    state = {
        currentTab:0
    }

    tabClikedHandler = (tabNum) => {
        this.setState({
            currentTab: tabNum
        });
    }

    render(){
        const currentTab = this.state.currentTab;
        const show = {display: 'flex'};
        const hide = {display: 'none'};
        return(
        <Aux>
            <div className={styles.TabContainer}>
                <div className={currentTab === 0 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel} 
                    onClick={() => this.tabClikedHandler(0)}
                    >
                    <div className={styles.TabLabelText}>売り手の方へ</div>
                    <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                </div>
                <div className={styles.TabGap} />
                <div className={currentTab === 1 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                    onClick={() => this.tabClikedHandler(1)}
                    >
                    <div>買い手の方へ</div>
                    <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                </div>
                <div className={styles.TabGapRemain} />
            </div> 
            <div className={`${styles.SafeSystemArea} Fade`} style={currentTab === 0 ? show : hide}>
                <div className={styles.SafeSystemContainer}>
                <div className="Item" style={{textAlign:'left', flex:'3'}}>
                    <ul>
                        <li className={styles.ListText}>簡易見積もり機能によりサービス内容・料金を明確に表示して取引時のトラブルを防ぐ。</li>
                        <li className={styles.ListText}>取引成立時の買い手の支払いの審査と確保により、支払い不履行のリスクを最小限に。</li>
                        <li className={styles.ListText}>予約管理機能やチャットガイドにより取引をアシスト、円滑なやり取りで購入者からのクレームを最小限に。</li>
                        <li className={styles.ListText}>請求書機能で実費清算などの金額を入力するだけで請求書を簡単に作成。</li>
                        <li className={styles.ListText}>決済機能により買い手の支払いを安全に確保でき、売上も自動管理。</li>
                        <li className={styles.ListText}>システム独自の取引チャットを利用でき、メールアドレスなどを買い手へ開示する必要がない。</li>
                    </ul>
                </div>
                <img className={styles.SecurityImage} style={{flex:'1'}} src={SecuritySeller} alt="売り手の利点1" />
                </div>
            </div>
            <div className={`${styles.SafeSystemArea} Fade`} style={ currentTab === 1 ? show : hide }>
                <div className={styles.SafeSystemContainer}>
                <div className="Item" style={{textAlign:'left', flex:'3'}}>
                    <ul>
                        <li className={styles.ListText}>簡易見積もり機能で提供内容・金額が明確に事前確認出来る。</li>
                        <li className={styles.ListText}>質問機能でサービスの相談、不明な点の質問が取引前にできる。</li>
                        <li className={styles.ListText}>売り手は全て本人確認済み、また各種資格・届出書の有無も確認できる。</li>
                        <li className={styles.ListText}>面接申請で売り手と取引する前に会う事が出来る。</li>
                        <li className={styles.ListText}>システム独自の取引チャットを利用でき、メールアドレスなどを買い手へ開示する必要がない。</li>
                        <li className={styles.ListText}>独自システムによりサービス提供を確認後に納得する支払いが安全に行える。</li>
                    </ul>
                </div>
                <img className={styles.SecurityImage} style={{flex:'1'}} src={SecurityBuyer} alt="買い手の利点1" />
                </div>
            </div>
        </Aux>
        );    
    }
}



export default SafeSystem;