import React, {Component} from 'react';
import styles from './MemberCert.module.scss';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {
  Spinner
} from '../../components/UI';
import UserLevelNote from '../../components/UserLevelNote/UserLevelNote';
import {
  initUiErrorState,
  toggleErrorWinOpen,
  fetchTheUserMeta
} from '../../store/actions';
import ProfileCard from '../../components/UserProfile/ProfileCard/ProfileCard';

class MemberCert extends Component {

  state = {
    userLevelExpWinOpen:false,
    mode:null
  }
  compareOrder = (a , b) => {
    //sorting list by order assending order
    if(a.order < b.order) return -1;
    if(a.order > b.order) return 1;
    return 0;
  }

  getLevelList = () => {
    const { appData } = this.props;
    let list = [];
    for(let key in appData.UserLevels) {
        list.push({key, color:appData.UserLevels[key].color, order:appData.UserLevels[key].order});
    }
    return list.sort(this.compareOrder);   
  }

  toggleModalWindow = (key, mode) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [key]: !prevState[key],
        mode   
      };
    });
  }

  explainPress = (mode) => {
    console.log("explain user level pressed");
    this.toggleModalWindow("userLevelExpWinOpen", mode);
  }

  componentDidMount = async () => {
    const {
      onInitUiErrorState,
      onToggleErrorWinOpen,
      getTheMetaData,
      theUserMeta,
      match:{params} 
    } = this.props;
    const {userId} = params;
    try {
      if(!userId){
        throw new Error("there is no userId");
      }
      if(!theUserMeta || (theUserMeta && theUserMeta.id !==userId) ){
        console.log("userId found in navigation param");
        const theUserMeta = await getTheMetaData(userId);
        if(!theUserMeta){
          throw new Error("there is no such a user");
        }
      } else {
        console.log("theUserMeta id matches with userId");
      }
    } catch (error) {
      const initState = {
        errorWinOpen: false,
        closable: true,
        systemError: 'ERROR_OCCURED'    
      }
      onInitUiErrorState(initState);
      onToggleErrorWinOpen();
    }
  }

  render() {
    const { 
      appData, 
      localCode, 
      theUserMeta, theUserMetaIsLoading, theUserMetaError,
      location
    } = this.props;
    const { LocalStrings } = appData;
    const { mode, userLevelExpWinOpen } = this.state;
    const levelList = this.getLevelList();
    let content = null;
    if(theUserMetaIsLoading){
      content = <div className="SpinnerBox">
        <Spinner large />
      </div>
    } else if(theUserMetaError){
      content =  <p>{LocalStrings[localCode].ERROR_OCCURED}</p>;
    } else if(theUserMeta){
      content = <ProfileCard 
                  appData={appData} 
                  localCode={localCode} 
                  theUserMeta={theUserMeta} 
                  centerAddress={null}
                  explainPress={this.explainPress} />;
    }
    let pathname = location.pathname;
    let title = '会員登録証証明';
    let description = 'タグジャム会員登録証証明';
    return (
      <>
      <Helmet>
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
          <meta name="twitter:description" content={description} />
          <meta property="og:url" content={pathname} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
          <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
          <meta property="og:image:type" content="image/png" />
          <title>{title}</title>
          <meta
            name="description"
            content={description}
          />
          <meta content="会員登録証証明,タグジャム会員登録証証明,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
      </Helmet>
      <div className="MainPadding">
        <h2 className="PageHeader">
          {LocalStrings[localCode].TAGUJAM}
          {LocalStrings[localCode].MEMBERSHIP_CARD}   
        </h2>
        <div className={styles.Content} >
          <p>このページに表示された人物は、タグジャムの正規会員である事を証明します。</p>
          <div className={styles.CertContainer}>
            <div className={styles.UserProfileContainer}>
            {content}
            </div>
          </div>
        </div>
        <UserLevelNote
          localCode={localCode} 
          levelList={levelList}
          mode={mode}
          appData={appData}
          windowStatus={userLevelExpWinOpen}
          onClose={()=> { this.toggleModalWindow("userLevelExpWinOpen", mode) }} />
    </div>
    </>
    );
  }
}

const mapStateToProps = ({
  local: { localCode },
  auth: { authInfo },
  userMeta : { 
    theUserMeta, theUserMetaIsLoading, theUserMetaError
  },
  appData: { appData }
}) => ({
  localCode,
  authInfo,
  theUserMeta, theUserMetaIsLoading, theUserMetaError,
  appData,
});

const mapDispatchToProps = dispatch => ({
  onInitUiErrorState : (initState) => dispatch(initUiErrorState(initState)),
  onToggleErrorWinOpen : () => dispatch(toggleErrorWinOpen()),
  getTheMetaData: (userId, buyerId) => dispatch( fetchTheUserMeta(userId, buyerId) )
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberCert);
