import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import AccountInfo0 from '../../../assets/images/help/account/account_info.png'
import AccountInfo1 from '../../../assets/images/help/account/account_info/account_info_1.png'
import AccountInfo2 from '../../../assets/images/help/account/account_info/account_info_2.png'
import AccountInfo3 from '../../../assets/images/help/account/account_info/account_info_3.jpg'
import AccountInfo4 from '../../../assets/images/help/account/account_info/account_info_4.png'
import AccountInfo5 from '../../../assets/images/help/account/account_info/account_info_5.png'

class AccountInfo extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>アカウント情報</span>
            </h3>
            <p className={styles.ArticleDesc}>ここでは、ユーザー名、アバター、自己紹介（取引や出店時にあなたの情報として表示されます）を登録していきます。</p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「アカウント情報」を選びます。<br />
                    </li>
                    <img className={styles.ScreenShot} src={AccountInfo0} alt="アカウント情報登録ステップ1画像" />
                    <li>
                        ユーザー名、自己紹介を入力します。<br />
                    </li>
                    <img className={styles.ScreenShot} src={AccountInfo1} alt="アカウント情報登録ステップ2画像" />
                    <li>
                        アバターの変更をタップし「カメラで撮影」、「写真を選択する」を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={AccountInfo2} alt="アカウント情報登録ステップ3画像" />
                    <li>
                        画面上に撮影・選択した写真が表示されたら、位置を調節し「この写真を選択」を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={AccountInfo3} alt="アカウント情報登録ステップ4画像" />
                    <li>
                        画面下の「保存」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={AccountInfo4} alt="アカウント情報登録ステップ5画像" />
                    <li>
                        左上の「＜戻る」をタップしてアカウントのメニュー画面に戻り、ユーザー情報の登録へと進んでください。
                    </li>
                    <img className={styles.ScreenShot} src={AccountInfo5} alt="アカウント情報登録ステップ6画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(AccountInfo);