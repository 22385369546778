import React, {Component} from 'react';
import styles from './Slider.module.scss';

class Slider extends Component {
    constructor(props) {
        super(props);
        this.myRange = React.createRef();
    }

    handleChange=() => {
        const {onChange, step} = this.props;
        const val = parseInt(this.myRange.current.value) / step;
        onChange(val);
    }

    render(){
        const {min, max, minLabel, maxLabel, value} = this.props;
        return(
        <div style={{width:'100%', textAlign:'center'}} >
            <input 
                style={{width:'100%'}} 
                type="range" 
                onChange={this.handleChange} 
                min={min} 
                max={max} 
                value={value} 
                ref={this.myRange} />
            { (minLabel || maxLabel) 
            &&
            <div style={{ 
                display:"flex", 
                flexDirection:"row", 
                justifyContent:"space-between", 
                alignItems:"center" }}>
                { minLabel && 
                <p className={styles.Label}>-</p>
                }
                <p className={styles.CenterLabel}>Zoom</p>
                { maxLabel && 
                <p className={styles.Label}>+</p>
                }
            </div>
            }
        </div>
        );
    }
}

export default Slider;