import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import PurchaseHistory1 from '../../../assets/images/help/buying/buy_history/buy_history_1.jpg';
import PurchaseHistory2 from '../../../assets/images/help/buying/buy_history/buy_history_2.jpg';
import PurchaseHistory3 from '../../../assets/images/help/buying/buy_history/buy_history_3.png';

class PurchaseHistory extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>購入履歴を確認する</span>
            </h3>
            <p className={styles.ArticleDesc}>
                支払いが完了した取引は、購入履歴にて確認できます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「購入履歴」を選びます。        
                    </li>
                    <img className={styles.ScreenShot} src={PurchaseHistory1} alt="購入履歴を確認するステップ1画像" />
                    <li>
                        領収金額が確認できます。各取引の詳しい領収内容を見たい場合はその取引をタップします。           
                    </li>
                    <img className={styles.ScreenShot} src={PurchaseHistory2} alt="購入履歴を確認するステップ2画像" />
                    <li>
                        領収書が確認できます。     
                    </li>
                    <img className={styles.ScreenShot} src={PurchaseHistory3} alt="購入履歴を確認するステップ3画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(PurchaseHistory);