import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './ActionButtons.module.css';
import DownloadButtons from '../../DownloadBtns/DownloadBtns';

const actionButtons = props => (
    <div className={styles.ActionButtons}>
        <NavLink to="/dealflow" className={styles.ActionButton} exact={true}>
            <i className="fa fa-recycle"/>
            <label>ご利用の流れはこちら</label>
            <span>&#10095;</span>
        </NavLink>
        <NavLink to="/help" className={styles.ActionButton} exact={true}>
            <i className="fa fa-question-circle"/>
            <label>使い方ガイドはこちら</label>
            <span>&#10095;</span>
        </NavLink>
        <p>スマホですぐに「お手伝い」サービス出店！<br/>
            家事を楽々頼めて忙しいあなたをバックアップ！<br/>
            タグジャムは個人サービスの簡単フリマアプリです。</p>
        <h4>入会金・登録料は一切不要、気軽に利用できます｡</h4>
        <div className={styles.DownloadsActions}>
            <DownloadButtons />
        </div>
    </div>
);

export default actionButtons;