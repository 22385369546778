import {
  FETCH_BLOG,
  SET_BLOG,
} from './actionTypes';

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchBlogPending = () => ({
  type: `${FETCH_BLOG}_PENDING`
});

export const fetchBlogRejected = (err) => ({
  type: `${FETCH_BLOG}_REJECTED`,
  payload: err
});

export const fetchBlogFulFilled = (blog) => ({
  type: `${FETCH_BLOG}_FULFILLED`,
  payload: blog
});

export const fetchBlog = (id) => {
  return async dispatch => {
      dispatch(fetchBlogPending());
      try{
          //let token = await dispatch(authGetToken());
          let blog = await taguJamApi.fetchBlog(id);
          dispatch(fetchBlogFulFilled(blog))
          return blog;
      } catch(err){
          dispatch(fetchBlogRejected(err));
          throw err
      }
  }   
};

export const setBlog = (blog) => ({
  type: SET_BLOG,
  payload: blog
});
