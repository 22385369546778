import React, {Fragment} from 'react';
import styles from './CategoryList.module.scss';
import {
    CloseBtn,
    Backdrop
} from '../UI';



const CategoryList = (props) => {
    const { onClose, appData, localCode, windowStatus, onCategoryClicked} = props;
    const { LocalStrings, Colors, MainCatItems, SubCatItems} = appData;
    return(
    <Fragment>
    <Backdrop 
        show={windowStatus}
        clicked={onClose}/>
    <div className={windowStatus ? `${styles.CategoryListContainer} Fade ${styles.Open}` : `${styles.CategoryListContainer} Fade ${styles.Close}`}>
        <div className={styles.CloseBtnContainer}>
            <CloseBtn clicked={onClose} color={Colors.contentFontColor} fontSize={'1.5em'} />
        </div>
        <div style={{width:'100%', display:'flex', flexDirection:"column", justifyContent:"flex-start", alignItems:'center', padding:'5px'}}>
            <h2>{LocalStrings[localCode].CATEGORY_LISTING_S}</h2>
            <div className={styles.CatBoxContainer}>
                {MainCatItems.map((MainCat, index ) => {
                    const iconName = `icon-${MainCat.toLowerCase()}`;
                    return(
                    <div 
                        key={`${MainCat}_${index}_title`} 
                        className={styles.ManCatBoxItem}>
                        <div 
                            className={styles.MainCatTitle}
                            style={{backgroundColor:Colors[`mainMenuBgColor${MainCat}`]}}
                            onClick={()=>{ onCategoryClicked(MainCat, "ALL")} }
                            >
                            <p className={`${iconName} ${styles.CatIcon}`} style={{fontSize:'0.95rem'}} />
                            <h3 style={{color:Colors.white}}>
                                {LocalStrings[localCode][MainCat]}
                            </h3>
                            <i className={`fa fa-chevron-right ${styles.CatIcon}`} />
                        </div>
                        <div className={styles.SubCatBox}>
                            {SubCatItems[MainCat].map( (SubCat, i) => {
                                return(
                                <div 
                                    key={`${SubCat}_${i}_subtitle`} 
                                    onClick={()=>{ onCategoryClicked(MainCat, SubCat)} } 
                                    className={styles.SubCatTitle} 
                                    style={{borderRadius:(SubCatItems[MainCat].length-1 === index) ? '0 0 5px 5px' : '0'}}>
                                    <p className={styles.SubCatText}>{LocalStrings[localCode][SubCat]}</p>
                                    <i className={`fa fa-chevron-right ${styles.CatIcon}`} 
                                        style={{marginRight:'0', color:Colors.contentFontColor}} />
                                </div>
                                );
                            })}
                        </div>
                    </div>
                    );
                })}
            </div>
        </div>
    </div>                    
    </Fragment>
    );
}

export default CategoryList;