export default class AppDataApi {
    constructor(){
        //firebase path set up
        this.firebaseRoot = process.env.REACT_APP_FB_DATABASEURL;
    }

    convertToArrayInOder = (Data) => {
        let DataList = [];
        for(let i = 0; i < Data.length; i++){
            let key = i + "";
            DataList.push(Data[key]);    
        }
        return DataList;
    }
    
    convertNullInObject = (obj)=> {
        for (var key in obj)
        {
            if (typeof obj[key] == "object" && obj[key] !== null){
                this.convertNullInObject(obj[key]);    
            } else {
                //premative;
                if(obj[key]==='Null'){
                    obj[key] = null;
                }  
            }    
        }
    }
    
    normalizeData = (Data) => {
        let data = {};
        for(let key in Data){
            if(key === 'AccountMenuItems' || key === 'CertificateMenuItems') {
                data = {
                    ...data,
                    [key]: this.convertToArrayInOder(Data[key])
                }
            } else if(key === 'CertItems'){
                data = {
                    ...data,
                    [key]: {...Data[key]}
                }
            } else if(key === 'ActivityItems') {
                data = {
                    ...data,
                    [key]: {
                        "SELL_DEAL": {...Data[key]["0"]},
                        "BUY_DEAL": {...Data[key]["1"]},
                        "NOTICE": { ...Data[key]["2"]}
                    } 
                }
            } else if( key === 'commonInitData' || key=== 'InitServiceData' || key === 'ServiceData' ) {
                let newInitData = {...Data[key]};
                this.convertNullInObject(newInitData)
                data = {
                    ...data,
                    [key]: newInitData 
                }
            }
            else {
                data = {
                    ...data,
                    [key]: Data[key]
                }
            } 
        }
        return data;    
    }

    async fetchAppData() {
        try {
            let res = await fetch(`${this.firebaseRoot}/appData.json`);
            let data = await res.json();
            let appData = this.normalizeData(data);
            return appData;
        } catch(err) {
            console.log(err)
            throw err;
        }
    }

    async fetchBankData() {
        try {
            let res = await fetch(`${this.firebaseRoot}/bankData.json`);
            let data = await res.json();
            let bankData = this.normalizeData(data);
            return bankData;
        } catch(err) {
            console.log(err)
            throw err;
        }
    }

} 