import React, { Component } from 'react';
import styles from './MainCatMenu.module.scss';

class MainCatMenu extends Component {

    renderMenuItem = (menuItem) => {
        const { localCode, appData, MainCat } = this.props;
        const { LocalStrings } = appData;
        let isActive = ( MainCat === menuItem || MainCat ==='HOME' ) ? true : false; 
        
        const menuData = {
            HELP : {
                paddingLeft: '15px'
            },
            TEACH : {
                paddingLeft: '19px'
            },
            RENT : {
                paddingLeft: '18px'
            },
            MAKE : {
                paddingLeft: '19px'
            }
        }

        return (
            <div 
                key={`${menuItem}_menu`}
                className={isActive ? `${styles.MenuItemContainer} ${styles.Active} ${styles[menuItem]}` : styles.MenuItemContainer} 
                onClick={() => this.props.onSelected(menuItem)}
            >
                <div className={styles.MenuButtonTextContainer}>
                    <p className={styles.MenuText}>
                        {LocalStrings[localCode][menuItem]}
                    </p> 
                </div>
                <div className={styles.MenuIconContainer} style={{paddingLeft: menuData[menuItem].paddingLeft}}>
                    <div
                        className={`icon-${menuItem.toLowerCase()}`}
                        style={{fontSize: '25px'}}
                    />
                </div>
            </div>
        )
    }

    render(){
        const { appData } = this.props;
        const { MainCatItems } = appData;
        return(
        <div className={styles.MenuContainer}>
            {MainCatItems.map( item => {
                return this.renderMenuItem(item);
            })}
        </div>
        );
    }
}

export default MainCatMenu;