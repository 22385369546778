import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import RequestInterview0 from '../../../assets/images/help/buying/buy.jpg';
import RequestInterview1 from '../../../assets/images/help/buying/buy_interview/buy_interview_1.jpg';
import RequestInterview2 from '../../../assets/images/help/buying/buy_interview/buy_interview_2.jpg';
import RequestInterview3 from '../../../assets/images/help/buying/buy_interview/buy_interview_3.jpg';
import RequestInterview4 from '../../../assets/images/help/buying/buy_interview/buy_interview_4.jpg';
import RequestInterview5 from '../../../assets/images/help/buying/buy_interview/buy_interview_5.jpg';
import RequestInterview6 from '../../../assets/images/help/buying/buy_interview/buy_interview_6.jpg';
import RequestInterview7 from '../../../assets/images/help/buying/buy_interview/buy_interview_7.jpg';
import RequestInterview8 from '../../../assets/images/help/buying/buy_interview/buy_interview_8.jpg';
import RequestInterview9 from '../../../assets/images/help/buying/buy_interview/buy_interview_9.jpg';
import RequestInterview10 from '../../../assets/images/help/buying/buy_interview/buy_interview_10.jpg';
import RequestInterview11 from '../../../assets/images/help/buying/buy_interview/buy_interview_11.jpg';


class RequestInterview extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービス提供者に面接申請する</span>
            </h3>
            <p className={styles.ArticleDesc}>
            サービスの購入を検討する際に、サービス詳細ベージの情報から面接を申請することができます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        面接申請したいサービスをタップし、サービスの詳細ページに行きます。    
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview0} alt="サービス提供者に面接申請するステップ1画像" />
                    <li>
                        サービスの詳細ページの「面接申請」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview1} alt="サービス提供者に面接申請するステップ2画像" />
                    <li>
                        カレンダーより日にちを選択します。
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview2} alt="サービス提供者に面接申請するステップ3画像" />
                    <li>
                        「開始時刻」を確認します。希望の時間でない場合は「開始時刻」をタップし、次の画面で「開始時刻」を変更します。変更をリセットする場合は、「自動設定」でシステムが自動で設定した「開始時刻」に戻すことが出来ます。変更が終わったら「時間確定」ボタンをタップして前画面に戻ります。    
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview3} alt="サービス提供者に面接申請するステップ4-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestInterview4} alt="サービス提供者に面接申請するステップ4-2画像" />
                    <li>
                        「住所を選択」ボタンをタップして面接住所を選択します。登録された住所以外の場所を指定したい場合は、「新規住所をアドレス帳に登録」をタップし新しい住所を登録してください。     
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview5} alt="サービス提供者に面接申請するステップ5-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestInterview6} alt="サービス提供者に面接申請するステップ5-2画像" />
                    <li>
                    「自己紹介」が未登録の場合は「アカウント情報を編集する」で入力してください。挨拶などの「メッセージ」を入力し、「送信」ボタンをタップします。         
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview7} alt="サービス提供者に面接申請するステップ6画像" />
                    <li>
                        「メッセージを確認する」をタップします。         
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview8} alt="サービス提供者に面接申請するステップ7画像" />
                    <li>
                        自動的に「取引」＞「買い」＞「面接」に移動します。「面接申請中」と表示された取引をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview9} alt="サービス提供者に面接申請するステップ8画像" />
                    <li>
                        今後はこの画面でサービス提供者とコミュニケーションします。         
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview10} alt="サービス提供者に面接申請するステップ9画像" />
                    <li>
                    「面接申請」が受け付けられた場合は、この画面に以下のメッセージが表示されます。         
                    </li>
                    <img className={styles.ScreenShot} src={RequestInterview11} alt="サービス提供者に面接申請するステップ10画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(RequestInterview);