import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import SignUp1 from '../../../assets/images/help/sign_up/sign_up_1.jpg'
import SignUp2 from '../../../assets/images/help/sign_up/sign_up_2.png'
import SignUp3 from '../../../assets/images/help/sign_up/sign_up_3.png'
import SignUp4 from '../../../assets/images/help/sign_up/sign_up_4.png'

class BecomeMember extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>新規会員になる</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムでは、会員登録をされてない方でも掲載されているサービスの閲覧はできます。会員登録をしていただくと、サービスの購入・出店等の各種機能が使用できるようになります。会員登録は以下の手順で行ってください。</p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        タグジャムのホーム画面で　メニューから「アカウント」を選ぶ。<br />
                        <p className="Caption">＊すでに新規会員登録の画面が表示されている場合はこの手順は不要です。</p>
                    </li>
                    <img className={styles.ScreenShot} src={SignUp1} alt="メンバーになるステップ1画像" />
                    <li>
                        ログイン画面下の「アカウントをお持ちでない方」をタップする。<br />
                        <p className="Caption">＊すでに新規会員登録の画面が表示されている場合はこの手順は不要です。</p>
                    </li>
                    <img className={styles.ScreenShot} src={SignUp2} alt="メンバーになるステップ2画像" />
                    <li>
                        E-メールアドレス、パスワード、パスワード確認の３つを入力する。<br />
                        <p className="Caption">＊パスワードは６文字以上、英数字、または「〜！＠＃＄％＾＆＊（）＿＋」の文字が入力できます。</p>
                    </li>
                    <img className={styles.ScreenShot} src={SignUp3} alt="メンバーになるステップ3画像" />
                    <li>
                        「利用規約を読む」、「プライバシーポリシーを読む」をタップするとそれぞれの文章が表示されます。読み終わったら「利用規約とプライバシーポリシーに同意します」をチェックしてください。最後に「新規無料登録」をタップする。
                    </li>
                    <img className={styles.ScreenShot} src={SignUp4} alt="メンバーになるステップ4画像" />
                    <li>
                        登録が終わったら、アカウントの画面が表示されます。そのまま「アカウント情報」の登録へ進んでください。<br />
                    </li>
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BecomeMember);