import React from 'react';
import textStyle from '../../constants/TextStyle.module.scss';
import styles from './UserLevelNote.module.scss';
import {
    CloseBtn
} from '../UI';

const UserLevelNote = (props) => {
    const { onClose, appData, localCode, windowStatus, mode, levelList} = props;
    const { LocalStrings, Colors} = appData;
    return(
    <div className={windowStatus ? `${styles.UserLevelNoteContainer} Fade ${styles.Open}` : `${styles.UserLevelNoteContainer} Fade ${styles.Close}`}>
        <div className={styles.CloseBtnContainer}>
            <CloseBtn clicked={onClose} color={Colors.contentFontColor} fontSize={'1.5em'} />
        </div>
        <div style={{width:'100%', display:'flex', flexDirection:"column", justifyContent:"flex-start", alignItems:'center', padding:'5px'}}>
            <p className={textStyle.BodyMediumLText} style={{padding:'8px 0', alignSelf:"center"}}>
              { mode==='buy'? LocalStrings[localCode].BUYER_LEVEL : LocalStrings[localCode].SELLER_LEVEL}
            </p>
            {mode &&
            <div style={{width:"100%"}}>
            { levelList.map( (level, index) => {
                return(
                <div 
                    key={`${level.key}_${mode.toUpperCase()}_NOTE`}
                    className={index===0 ? "BorderTop BorderBottom" : "BorderBottom"} 
                    style={{display:'flex', flexDirection:'row', width:"100%", padding:'8px 0'}}>
                    <div style={{display:'flex', flexDirection: 'row', justifyContent:"center", alignItems:"center"}}>
                        <div style={{position:'relative', display:'flex',flexDirection: 'column', justifyContent:"center", alignItems:"center"}}>
                            <p className={`${textStyle.BodyText} ${textStyle.Bold}`} style={{position:'absolute'}}>
                                {LocalStrings[localCode][level.key]}
                            </p>
                            <i className="fa fa-certificate" style={{fontSize:'35px', color:Colors[level.color]}} />
                        </div>
                        <p className={`${textStyle.BodyText} ${textStyle.Bold}`} style={{marginLeft:'2px', marginRight:'10px'}}>
                            {LocalStrings[localCode].LEVEL_STR}
                        </p>
                    </div>
                    <p className={textStyle.BodyText} style={{flex:5, padding:'8px'}}>
                        {LocalStrings[localCode][`${level.key}_${mode.toUpperCase()}_NOTE`]}
                    </p>
                </div>
                )    
            })}
            </div>
            }
        </div>
    </div>                    
    );
}

export default UserLevelNote;