import {
    FETCH_ACCOUNT_INFO,
    SET_ACCOUNT_INFO
} from './actionTypes';

import { 
    uiStartLoading, 
    uiStopLoading,
    authGetToken 
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchAccountInfoPending = () => ({
    type: `${FETCH_ACCOUNT_INFO}_PENDING`
});

export const fetchAccountInfoRejected = (err) => ({
    type: `${FETCH_ACCOUNT_INFO}_REJECTED`,
    payload: err
});

export const fetchAccountInfoFulFilled = (accountInfo) => ({
    type: `${FETCH_ACCOUNT_INFO}_FULFILLED`,
    payload: accountInfo
});

export const fetchAccountInfo = (id) => {
    return async dispatch => {
        dispatch(fetchAccountInfoPending());
        try{
            let accountInfo = await taguJamApi.fetchAccountInfo(id);
            accountInfo = {
                id,
                ...accountInfo
            };
            dispatch(fetchAccountInfoFulFilled(accountInfo))
            return accountInfo;
        } catch(err){
            dispatch(fetchAccountInfoRejected(err));
            throw err
        }
    }   
};

export const setAccountInfo = (accountInfo) => ({
    type: SET_ACCOUNT_INFO,
    payload: accountInfo
});

export const saveAccountInfo = ( accountInfoData, id) => {
    return async dispatch => {
        dispatch(uiStartLoading(`accountInfo_${id}`));
        try{
            let token = await dispatch(authGetToken()); 
            let accountInfo = await taguJamApi.saveAccountInfo(accountInfoData, id, token);
            dispatch(setAccountInfo(accountInfo));
            dispatch(uiStopLoading(`accountInfo_${id}`));
            return accountInfo;  
        } catch(err){
            dispatch(uiStopLoading(`accountInfo_${id}`));
            console.log(err);
            throw err;
        }
    }
}