import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import Sell1 from '../../../assets/images/help/selling/sell/sell_help/sell_help_1.jpg';
import Sell2 from '../../../assets/images/help/selling/sell/sell_help/sell_help_2a.png';

import Sell3 from '../../../assets/images/help/selling/sell/sell_help/sell_help_2b.png';
import Sell3Teach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_2b.png';
import Sell3Rent from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_2b.png';
import Sell3Make from '../../../assets/images/help/selling/sell/sell_make/sell_make_2b.png';

import Sell4 from '../../../assets/images/help/selling/sell/sell_help/sell_help_3a.png';
import Sell5 from '../../../assets/images/help/selling/sell/sell_help/sell_help_3b.jpg';

import Sell6 from '../../../assets/images/help/selling/sell/sell_help/sell_help_4.jpg';
import Sell6Teach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_4.png';
import Sell6Rent from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_4.png';
import Sell6Make from '../../../assets/images/help/selling/sell/sell_make/sell_make_4.png';

import Sell7 from '../../../assets/images/help/selling/sell/sell_help/sell_help_5.png';
import Sell7Teach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_5.png';

import Sell8 from '../../../assets/images/help/selling/sell/sell_help/sell_help_6.png';
import Sell8aTeach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_6a.png';
import Sell8bTeach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_6b.png';
import Sell8aRent from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_6a.png';
import Sell8bRent from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_6b.png';
import Sell8aMake from '../../../assets/images/help/selling/sell/sell_make/sell_make_6a.png';
import Sell8bMake from '../../../assets/images/help/selling/sell/sell_make/sell_make_6b.png';

import Sell9 from '../../../assets/images/help/selling/sell/sell_help/sell_help_7a.jpg';
import Sell9Teach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_7.png';
import Sell9Make from '../../../assets/images/help/selling/sell/sell_make/sell_make_7a.png';

import Sell10 from '../../../assets/images/help/selling/sell/sell_help/sell_help_8a.png';
import Sell10RentMake from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_make_8a.png';

import Sell11 from '../../../assets/images/help/selling/sell/sell_help/sell_help_8b.png';

import Sell12 from '../../../assets/images/help/selling/sell/sell_help/sell_help_9.png';
import Sell12Teach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_9.png';
import Sell12Rent from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_9.png';
import Sell12Make from '../../../assets/images/help/selling/sell/sell_make/sell_make_9.png';

import Sell13 from '../../../assets/images/help/selling/sell/sell_help/sell_help_10a.png';
import Sell14 from '../../../assets/images/help/selling/sell/sell_help/sell_help_10b.png';
import Sell15 from '../../../assets/images/help/selling/sell/sell_help/sell_help_10c.png';
import Sell16 from '../../../assets/images/help/selling/sell/sell_help/sell_help_11a.png';
import Sell17 from '../../../assets/images/help/selling/sell/sell_help/sell_help_11b.png';

import Sell18 from '../../../assets/images/help/selling/sell/sell_help/sell_help_12.png';
import Sell18Teach from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_12.png';
import Sell18Rent from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_12.png';
import Sell18Make from '../../../assets/images/help/selling/sell/sell_make/sell_make_12.png';

import Sell19 from '../../../assets/images/help/selling/sell/sell_help/sell_help_13.png';
import Sell19RentMake from '../../../assets/images/help/selling/sell/sell_rent/sell_rent_make_13.png';

import Sell20 from '../../../assets/images/help/selling/sell/sell_help/sell_help_14.png';
import Sell20NonHELP from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_rent_make_14.png';

import Sell21 from '../../../assets/images/help/selling/sell/sell_help/sell_help_15.png';
import Sell21NonHELP from '../../../assets/images/help/selling/sell/sell_teach/sell_teach_rent_make_15.png';


class Sell extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    articleClickedHandler = (article) => {
        console.log(article);
        if(article === 'cancelpolicy'){
            this.props.history.push({
                pathname: `/${article}`
            });
        } else {
            this.props.history.push({
                pathname: `/help/${article}`
            });
        }
    }
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
            <span className={styles.ArticleTitle}>出品する（{this.getCategory()}）</span>
            </h3>
            <p className={styles.ArticleDesc}>
                タグジャムではあなたのサービスを出品フォームにて登録し、販売を始めることができます。サービスの価格は提供期間・時間は自分で決める事ができます。    
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「出品」をタップする。    
                    </li>
                    <img className={styles.ScreenShot} src={Sell1} alt="出品するステップ1画像" />

                    <li>
                        「項目を選ぶ」をタップし項目リストから自分のサービスに適した項目を選択します。         
                    </li>
                    <img className={styles.ScreenShot} src={Sell2} alt="出品するステップ2-1画像" />
                    { currentTab === 'HELP' && 
                        <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell3} alt="出品するステップ2-2画像" />
                    }
                    { currentTab === 'TEACH' && 
                        <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell3Teach} alt="出品するステップ2-2画像" />
                    }
                    { currentTab === 'RENT' && 
                        <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell3Rent} alt="出品するステップ2-2画像" />
                    }
                    { currentTab === 'MAKE' && 
                        <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell3Make} alt="出品するステップ2-2画像" />
                    }
                    
                    <li>
                        カメラボタンをタップし、「カメラで撮影する」、「写真を選択する」を選びます。なるべくサービスの内容がわかる写真を追加しましょう。サービス写真は４つまで追加することができます。少なくとも一つの写真が必要です。         
                    </li>
                    <img className={styles.ScreenShot} src={Sell4} alt="出品するステップ3-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell5} alt="出品するステップ3-2画像" />
                    
                    <li>
                        サービスの「タイトル」「詳細説明」を入力しましょう。アカウント情報の入力がお済みでない方は「自己紹介」も入力しましょう。         
                    </li>
                    { currentTab === 'HELP' && 
                        <img className={styles.ScreenShot} src={Sell6} alt="出品するステップ4画像" />
                    }
                    { currentTab === 'TEACH' && 
                       <img className={styles.ScreenShot} src={Sell6Teach} alt="出品するステップ4画像" />
                    }
                    { currentTab === 'RENT' && 
                        <img className={styles.ScreenShot} src={Sell6Rent} alt="出品するステップ4画像" />
                    }
                    { currentTab === 'MAKE' && 
                        <img className={styles.ScreenShot} src={Sell6Make} alt="出品するステップ4画像" />
                    }
                    
                    { (currentTab === 'HELP' || currentTab === 'TEACH') &&
                    <Aux>
                        <li>
                            サービス形態を選びます。出品の項目によって選択肢が違います。         
                        </li>
                        <p><strong>訪問型 </strong>: サービス利用者の指定場所に出張して提供するサービスの場合。</p>
                        <p><strong>来客型 </strong>: 出品者の指定場所に利用者の方に来てもらうサービスの場合。</p>
                        { currentTab === 'HELP' &&
                        <p><strong>来客型/訪問型 </strong>: 上記のいずれも提供可能なサービスの場合。</p>
                        }
                        { currentTab === 'HELP' &&
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={Sell7} alt="出品するステップ5画像" />
                        }
                        { currentTab === 'TEACH' &&
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={Sell7Teach} alt="出品するステップ5画像" />
                        }
                    </Aux>
                    }

                    { currentTab==='HELP' &&
                    <Aux>
                        <li>
                        基本料金のセクションでは「基本給」と「最短時間から受付」を入力します。タグジャムでの助けますの販売は全て時給換算されますので、希望の時給を「基本給」に入力しましょう。「最短時間から受付」には、サービス利用者が注文できる最短の時間を３０分単位で入力可能です。３０分の場合は0.5と入力しましょう。         
                        </li>
                        <img className={styles.ScreenShot} src={Sell8} alt="出品するステップ6画像" />
                    </Aux>
                    }

                    { currentTab==='TEACH' &&
                    <Aux>
                        <li>
                        基本料金では「基本給」を入力し「課金の単位」をタップします。単位を選択します。「クラス時間」と「定員」を入力します。         
                        </li>
                        <p className="Caption">＊「訪問型」のクラスでは、「人」（参加人数ごとに課金します）は選択できません。</p>
                        <p className="Caption">＊「個人レッスン」以外を選択した場合は、「定員」の入力が必要になります。</p>
                        <img className={styles.ScreenShot} src={Sell8aTeach} alt="出品するステップ6-1画像" />
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={Sell8bTeach} alt="出品するステップ6-2画像" />
                    </Aux>
                    }

                    { currentTab==='RENT' &&
                    <Aux>
                        <li>
                        基本料金では「レンタル料」を入力し「課金の単位」をタップします。単位を選択します。「保証金」と「返却時刻」を入力します。         
                        </li>
                        <p className="Caption">＊「保証金」とは、もしレンタル品が返却されなかったり完全に故障してしまった時に借主に保証してもらう金額です。現在のレンタル品の適切なリセール価格を入力しましょう。</p>
                        <p className="Caption">＊「返却時刻」とは、返却日に何時までに返却してもらいたいかの時間を入力してください。返却時刻より遅延しての返却には延滞料金を課金する事が出来ます。</p>
                        <img className={styles.ScreenShot} src={Sell8aRent} alt="出品するステップ6-1画像" />
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={Sell8bRent} alt="出品するステップ6-2画像" />
                    </Aux>
                    }

                    { currentTab==='MAKE' &&
                    <Aux>
                        <li>
                        基本料金では「制作料」を入力し「課金の単位」をタップします。単位を選択します。「何個から受付」と「完成日数」を入力します。         
                        </li>
                        <img className={styles.ScreenShot} src={Sell8aMake} alt="出品するステップ6-1画像" />
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={Sell8bMake} alt="出品するステップ6-2画像" />
                    </Aux>
                    }
                    
                    { currentTab==='HELP' &&
                    <Aux>
                    <li>
                        サービス内容では　基本料金で対応できるサービス項目を選択してください。対応できるサービス項目とは、追加料金を加えることなく対応できるサービスです。<br/>
                        <span className="Caption">＊ヘアケア・ネイル・リラクゼーション・エステの場合はここはスキップして8に進んでください</span>       
                    </li>
                    <img className={styles.ScreenShot} src={Sell9} alt="出品するステップ7画像" />
                    </Aux>
                    }

                    { currentTab==='TEACH' &&
                    <Aux>
                        <li>
                            クラスの参加者ご自身で準備してもらうものを入力して行きます。アイテムを追加する場合は、「＋追加する」ボタンをタップしましょう。        
                        </li>
                        <img className={styles.ScreenShot} src={Sell9Teach} alt="出品するステップ7画像" />
                    </Aux>
                    }

                    { currentTab==='MAKE' &&
                    <Aux>
                        <li>
                            基本料金に含まれているアイテムを入力しましょう。アイテムを追加する場合は、「＋追加する」ボタンをタップしましょう。        
                        </li>
                        <img className={styles.ScreenShot} src={Sell9Make} alt="出品するステップ7画像" />
                    </Aux>
                    }

                    { currentTab==='HELP' &&
                    <Aux>
                        <li>
                            オプションサービスで提供できるものを選択し、それぞれの料金を入力して行きます。合計に金額を加算、時給に金額を加算、追加数により金額を加算、などそれぞれのオプションで指定された加算方法で追加する金額を入力してください 
                            <p>＊オプション料金とは、基本時給に追加で料金をもらいたい場合の金額です。</p>
                            <p>＊ヘアケア・ネイル・リラクゼーション・エステの場合は次を参照。</p>        
                        </li>
                        <img className={styles.ScreenShot} src={Sell10} alt="出品するステップ8-1画像" />
                        <p className={styles.HeaderMargin10}>
                            <strong>ヘアケア・ネイル・リラクゼーション・エステのサービスの場合</strong><br/>
                            提供できるサービスを選択し、合計に金額を加算、時給に金額を加算、などそれぞれのオプションで指定された加算方法で追加する金額または施術時間を入力してください。施術時間は１５分単位で入力できます。以下のヘアカットの例では、時給を4000円と設定した場合は（4000円x45分/60分）＝3000円円の料金となります。
                        </p>
                        <img className={styles.ScreenShot} src={Sell11} alt="出品するステップ8-2画像" />
                    </Aux>
                    }

                    { (currentTab==='RENT' || currentTab==='MAKE') &&
                    <Aux>
                        <li>
                            オプションサービスで提供できるものを選択し、料金を入力して行きます。  
                        </li>
                        <img className={styles.ScreenShot} src={Sell10RentMake} alt="出品するステップ8画像" />
                    </Aux>
                    }

                    { currentTab==='HELP' &&
                    <Aux>
                    <li>
                        実費請求を設定します。サービス料金に加えて、費用が発生した場合に料金に加算したい項目を選択し、料金の上限の金額を入力してください。シニア付き添いサービスなどの場合、交通費、買い物代金、入場料、食事代などは建て替え出来る上限の金額を入力してください。
                    </li>
                    <img className={styles.ScreenShot} src={Sell12} alt="出品するステップ9画像" />
                    </Aux>
                    }

                    { currentTab==='TEACH' &&
                    <Aux>
                    <li>
                        実費請求を設定します。サービス料金に加えて、費用が発生した場合に料金に加算したい項目を選択し、金額を入力してください。
                    </li>
                    <p>＊「教材費」は「来客型」の場合は実際にかかった金額「訪問型」の場合は実費の上限金額</p>
                    <p>＊「訪問型」の場合のみ交通費の上限金額を入力してください。</p>
                    <img className={styles.ScreenShot} src={Sell12Teach} alt="出品するステップ9画像" />
                    </Aux>
                    }

                    { currentTab==='RENT' &&
                    <Aux>
                    <li>
                        レンタル品返却時適用料金を入力していきます。
                    </li>
                    <img className={styles.ScreenShot} src={Sell12Rent} alt="出品するステップ9画像" />
                    </Aux>
                    }

                    { currentTab==='MAKE' &&
                    <Aux>
                    <li>
                        実費請求を設定します。サービス料金に加えて、費用が発生した場合に料金に加算したい項目を選択し、それぞれの上限金額を入力してください。
                    </li>
                    <img className={styles.ScreenShot} src={Sell12Make} alt="出品するステップ9画像" />
                    </Aux>
                    }

                    <li>
                        「地域を設定する＞」をタップします。「サービス拠点」をタップし、住所を選択・登録します。    
                    </li>
                    <img className={styles.ScreenShot} src={Sell13} alt="出品するステップ10-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell14} alt="出品するステップ10-2画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell15} alt="出品するステップ10-3画像" />
                    
                    { (currentTab === "HELP" || currentTab==="TEACH") && 
                        <li>
                        「訪問型」のサービスの場合は「圏内」に　何Km圏内の人にサービスを提供できるかの数字を入力すると、その圏内の町名が出てきます。町名のリストを確認し提供できない町はチェックを外してください。リストのチェックが終わったら「戻る」ボタンを押し地域設定を確認します。<br/>
                         <span className="Caption">＊「訪問型」でないサービスはこのステップはスキップしてください。</span>
                        </li>
                    }
                    { (currentTab === "RENT" || currentTab==="MAKE") && 
                        <li>
                        「配達」のオプションを提供する場合は「圏内」に　何Km圏内の人にサービスを提供できるかの数字を入力すると、その圏内の町名が出てきます。町名のリストを確認し提供できない町はチェックを外してください。リストのチェックが終わったら「戻る」ボタンを押し地域設定を確認します。<br/>
                         <span className="Caption">＊「配達」のオプションを提供しないサービスはこのステップはスキップしてください。</span>
                        </li>
                    }
                    <img className={styles.ScreenShot} src={Sell16} alt="出品するステップ11-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={Sell17} alt="出品するステップ11-2画像" />
                    
                    <li>
                        注意事項では、サービス利用者に注文の際に理解・注意して頂きたい事柄などを入力して行きます。「定型文」が用意されているので、必要な項目のボタンを押してみましょう。定型文で入力された文の編集が必要であれば行いましょう。    
                    </li>
                    { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={Sell18} alt="出品するステップ13画像" />
                    }
                    { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={Sell18Teach} alt="出品するステップ13画像" />
                    }
                    { currentTab==='RENT' &&
                        <img className={styles.ScreenShot} src={Sell18Rent} alt="出品するステップ13画像" />
                    }
                    { currentTab==='MAKE' &&
                        <img className={styles.ScreenShot} src={Sell18Make} alt="出品するステップ13画像" />
                    }

                    { (currentTab==='HELP' || currentTab==='TEACH') &&
                    <Aux>
                        <li>
                        スケジュールを設定します。提供できる期間の「開始日」と「終了日」を選択し、当日の依頼を受け付けられる場合は、「当日受付可能」ボタンをタップします。この場合は、サービス開始時刻の何時間前までに注文を受けたいかを入力します。
                        </li>
                        <img className={styles.ScreenShot} src={Sell19} alt="出品するステップ13画像" />
                    </Aux>
                    }
                    { (currentTab==='RENT' || currentTab==='MAKE') &&
                    <Aux>
                        <li>
                        スケジュールを設定します。提供できる期間の「開始日」と「終了日」を選択し、当日の依頼を受け付けられる場合は、「当日受付可能」ボタンをタップします。この場合は、サービス開始時刻の何時間前までに注文を受けたいかを入力します。
                        </li>
                        <img className={styles.ScreenShot} src={Sell19RentMake} alt="出品するステップ13画像" />
                    </Aux>
                    }

                    { currentTab==='HELP' &&
                    <Aux>
                        <li>
                            最後に必要書類を確認してください。取引を始める前にこの書類の提出をして頂きます。確認したら「出店」ボタンを押します。
                        </li>
                        <img className={styles.ScreenShot} src={Sell20} alt="出品するステップ14画像" />
                        <p>＊必要書類の提出の手順は<a href="/help" onClick={ e => { e.preventDefault(); this.articleClickedHandler('SubmitDocs') } }>こちら</a></p>
                        <p>＊各種サービスの必要書類一覧表は<a href="/help" onClick={ e => { e.preventDefault(); this.articleClickedHandler('CertChart') } }>こちら</a></p>
                    </Aux>
                    }
                    { currentTab!=='HELP' &&
                    <Aux>
                        <li>
                            最後に入力内容を確認したら「出店」ボタンを押します。
                        </li>
                        <img className={styles.ScreenShot} src={Sell20NonHELP} alt="出品するステップ14画像" />
                    </Aux>
                    }
                    
                    { currentTab==='HELP' &&
                    <Aux>
                    <li>
                        以下の画面が出たら出店が完了です。「出店を確認する」で出店内容を確認できます。「書類の提出画面へ」のボタンが出ていた場合は、そのボタンをタップして必要書類の提出を行ってください。
                    </li>
                    <img className={styles.ScreenShot} src={Sell21} alt="出品するステップ14画像" />
                    </Aux>
                    }
                    { currentTab!=='HELP' &&
                    <Aux>
                    <li>
                        以下の画面が出たら出店が完了です。「出店を確認する」で出店内容を確認できます。
                    </li>
                    <img className={styles.ScreenShot} src={Sell21NonHELP} alt="出品するステップ14画像" />
                    </Aux>
                    }
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(Sell);