import React, {Component} from 'react';
import styles from './Hero.module.css';
import DownloadBtns from '../../DownloadBtns/DownloadBtns';
import Aux from '../../../hoc/Aux/Aux';

//const Positions = ['0%', '-100%', '-200%', '-300%', '-400%', '-500%'];

class Hero extends Component {

    state = {
        current: 0
    }

    updateSlide = (num) => {
      for (let i = 0; i < 6; i++) {
          let HeroName = `Hero${i+1}`;
          document.getElementById(`Hero${i}`).className = `${styles.HeroImage} ${styles[HeroName]} Hide`;
          document.getElementById(`dot${i}`).className = `${styles.SliderBtn}`;
      }
      let styleName = `Hero${num+1}`
      document.getElementById(`Hero${num}`).className = `${styles.HeroImage} ${styles[styleName]} Fade`;
      document.getElementById(`dot${num}`).className = `${styles.SliderBtn} ${styles.Active}`;   
  }

    changeSlideNum = (num) => {
        this.stopAnimation();
        this.updateSlide(num);
        this.setState({
            current: num
        });
    }

    arrowClickHandler = (event, direction) => {
        event.preventDefault();
        this.stopAnimation();
        if(direction === 'prev'){
            this.setState( prevState => {
                let currentNum = prevState.current === 0 ? 5 : prevState.current -1;
                this.updateSlide(currentNum);
                return {
                    current: currentNum
                }
            });
        } else {
            this.setState( prevState => {
                let currentNum = prevState.current === 5 ? 0 : prevState.current +1;
                this.updateSlide(currentNum);
                return {
                    current: currentNum
                }
            });
        }  
    }

    componentDidMount = () => {
        this.startAnimation();
    }

    componentWillUnmount = () => {
        this.stopAnimation();
    }

    stopAnimation = () => {
       console.log('stopAnimation called');
       if(this.IntVal) {
        clearInterval(this.IntVal);
        this.IntVal = null;     
       }
    }

    startAnimation = () => {
        this.IntVal = setInterval(() => {
            this.setState( prevState => {
                let currentNum = prevState.current < 5 ? prevState.current +1 : 0;
                this.updateSlide(currentNum);
                return {
                    current: currentNum
                }
            });   
        }, 4000);
    }

    render() {
        return(
            <Aux>
            <div className={styles.Slider}>
                <div id="ImageContainer" className={styles.ImageBox}>
                    <div id="Hero0" className={`${styles.HeroImage} ${styles.Hero1} Fade`}>
                        <div className={styles.HeroText}>
                            <h1 className={styles.TagLine}>
                              「手伝う」を売り買い<br />するフリマアプリ
                            </h1>
                            <h3 className={styles.SubTagLine}>
                                あなたの 「できる」に<br />タグを付け売ってみよう！
                            </h3>
                            <div className={styles.Downloads}>
                                <DownloadBtns mode="Hero" />
                            </div>
                        </div>
                    </div>
                    <div id="Hero1" className={`${styles.HeroImage} ${styles.Hero2} Hide`}>
                        <div className={styles.HeroText}>
                            <h1 className={styles.TagLine}>
                                今日来て欲しいを<br />「ポチッ」と依頼！
                            </h1>
                            <h3 className={styles.SubTagLine}>
                                急な子供の熱、保育が必要<br />即日サービスも依頼OK！
                            </h3>
                            <div className={styles.Downloads}>
                                <DownloadBtns mode="Hero" />
                            </div>
                        </div>
                    </div>
                    <div id="Hero2" className={`${styles.HeroImage} ${styles.Hero3} Hide`}>
                        <div className={styles.HeroText}>
                            <h1 className={styles.TagLine}>
                                スキマ時間で気軽に働く<br />自分流ワークスタイル
                            </h1>
                            <h3 className={styles.SubTagLine}>
                                好きな時間に、好きな場所<br />から自由に働ける！
                            </h3>
                            <div className={styles.Downloads}>
                                <DownloadBtns mode="Hero" />
                            </div>
                        </div>
                    </div>
                    <div id="Hero3" className={`${styles.HeroImage} ${styles.Hero4} Hide`}>
                        <div className={styles.HeroText}>
                            <h1 className={styles.TagLine}>
                                不安を一掃！「サクッ」<br />と依頼、簡単フリマ！
                            </h1>
                            <h3 className={styles.SubTagLine}>
                                日常の家事や介護など<br />「お手伝い」が頼める！
                            </h3>
                            <div className={styles.Downloads}>
                                <DownloadBtns mode="Hero" />
                            </div>
                        </div>
                    </div>
                    <div id="Hero4" className={`${styles.HeroImage} ${styles.Hero5} Hide`}>
                        <div className={styles.HeroText}>
                            <h1 className={styles.TagLine}>
                                自分のビジネス<br />ここから始めてみる？
                            </h1>
                            <h3 className={styles.SubTagLine}>
                                資金がなくても大丈夫<br />訪問美容などの夢にトライ
                            </h3>
                            <div className={styles.Downloads}>
                                <DownloadBtns mode="Hero"/>
                            </div>
                        </div>
                    </div>
                    <div id="Hero5" className={`${styles.HeroImage} ${styles.Hero6} Hide`}>
                        <div className={styles.HeroText}>
                            <h1 className={styles.TagLine}>
                                使ってない物「ポチッ」<br />と貸してみる？
                            </h1>
                            <h3 className={styles.SubTagLine}>
                                礼服、美顔器、旅行用品<br />など、色々貸しちゃえ！
                            </h3>
                            <div className={styles.Downloads}>
                                <DownloadBtns mode="Hero"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.BtnContainer}>
                    <div id="dot0" onClick={() => this.changeSlideNum(0) } className={`${styles.Active} ${styles.SliderBtn}`}></div>
                    <div id="dot1" onClick={() => this.changeSlideNum(1) } className={styles.SliderBtn}></div>
                    <div id="dot2" onClick={() => this.changeSlideNum(2) } className={styles.SliderBtn}></div>
                    <div id="dot3" onClick={() => this.changeSlideNum(3) } className={styles.SliderBtn}></div>
                    <div id="dot4" onClick={() => this.changeSlideNum(4) } className={styles.SliderBtn}></div>
                    <div id="dot5" onClick={() => this.changeSlideNum(5) } className={styles.SliderBtn}></div>
                </div>
                <div className={styles.ArrowBtnContainer}>
                    <a 
                        href="/"
                        className={styles.Prev} 
                        onClick={(event) => {event.preventDefault(); this.arrowClickHandler(event, "prev")} }>
                            &#10094;
                    </a>
                    <a 
                        href="/"
                        className={styles.Next} 
                        onClick={(event) => {event.preventDefault(); this.arrowClickHandler(event, "next")} }>
                            &#10095;
                        </a>
                </div>
            </div>
            <div className={styles.MobileOnly} style={{ width: '100%', textAlign:'center', padding: '15px 0 15px 0'}}>
                <div className={styles.DownloadContainer}>
                    <DownloadBtns />
                </div>
            </div>
            </Aux>   
        );
    }
}

export default Hero;