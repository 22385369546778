let Config = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASEURL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_SENDERID,
    appId: process.env.REACT_APP_FB_APPID
}

if(process.env.REACT_APP_TAGUJAM_ENV === 'prod'){
  Config = {
    ...Config,
    measurementId:process.env.REACT_APP_MEASUREMENT_ID
  }
}

const FirebaseConfig = Config;

export default FirebaseConfig;