import React from 'react';
import Aux from '../../../hoc/Aux/Aux';
import styles from './Pagenation.module.scss';

const Pagenation = (props) => {
  const {currentPage, maxPage, setCurrentPage} = props;
  
  const clickedPageNav = (direction) => {
    let pageNum = currentPage;
    if(direction === 'next'){
      pageNum++;
      if(pageNum > maxPage){
        pageNum = 1
      }    
    } else {
      pageNum--;
      if(pageNum < 1){
        pageNum = maxPage  
      }
    }
    setCurrentPage(pageNum);
  }

  let pageArray = []
  for(let i=0; i < maxPage; i++){
    pageArray.push(i+1);
  }

  return(
    <div className={styles.Pagenation}>
      <p className={styles.PageNavText} onClick={()=>{clickedPageNav('prev')}}>
        <i className="fa fa-backward" style={{marginRight:'5px'}}></i>前に
      </p>
      <Aux>
      {pageArray.map((num)=>{
        return(
          <div 
            key={`${num}_page`}
            onClick={()=>{setCurrentPage(num)}} 
            className={ num === currentPage ? styles.PageNavActive : styles.PageNav}>
              {num}
          </div>
        );
      })}
      </Aux>
      <p className={styles.PageNavText} onClick={()=>{clickedPageNav('next')}}>
        次に<i className="fa fa-forward" style={{marginLeft:'5px'}}></i>
      </p>
  </div>  
  );
}

export default Pagenation;
