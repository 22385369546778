import React from 'react';
import {
    priceFormat
} from '../../../utils';
import styles from './FeeInfo.module.scss';
import TextStyle from '../../../constants/TextStyle.module.scss';

const FeeInfo = props => {
    const { 
        MainCat, localCode, 
        feeInfo: { price, unit, length, lengthUnit, accepted, acceptedUnit, DEPOSIT, MAX_PERSON, MIN_PERSON, completionDay },
        appData 
    } = props
    const { Colors, LocalStrings } = appData;

    return(
        <div className={styles.FeeinfoContainer}>
            <div className={styles.TitleContainer}>
                <p className={TextStyle.BodyLargeText}>
                {LocalStrings[localCode].BASE_FEE}
                </p>
            </div>
            <div className={ (MainCat === 'TEACH' ||  MainCat ==='MAKE') ? `${styles.FeeContainer}` : `${styles.FeeContainer} ${TextStyle.BorderBottom}`}>
                <div style={{flex: MainCat === 'Rent'? 1 : 1.2, alignItems:'flex-start'}}>
                    <p className={TextStyle.BodyExLargeText}>
                        {priceFormat(price, localCode, LocalStrings)}
                        <span className={TextStyle.BodyTextExSmall} style={{fontWeight:'normal'}}>
                        { ` / ${LocalStrings[localCode][unit]}` }
                        </span>
                    </p>
                    { MainCat === 'RENT' && (
                    <p className={TextStyle.BodyTextExSmall}  style={{color:Colors.white}}>
                        {LocalStrings[localCode][`RETURN_POLISY_${unit}`]}
                    </p>
                    )
                    }
                </div>
                <div style={{flex: MainCat === 'Rent'? 1.7 : 1, alignItems:'flex-start'}}>
                { MainCat === 'RENT' && (
                        <div>
                            <p className={TextStyle.BodyExLargeText}>
                                {priceFormat(DEPOSIT, localCode, LocalStrings)}
                                <span className={TextStyle.BodyTextExSmall} style={{fontWeight:'normal'}}>
                                { ` ${LocalStrings[localCode]['DEPOSIT']}` }
                                </span>
                            </p>
                            <p className={TextStyle.BodyTextExSmall} style={{color:Colors.redColor}}>
                                {LocalStrings[localCode].DEPOSIT_RETURN_TEXT}
                            </p>
                        </div>
                    )
                }
                { MainCat !== 'RENT' && (
                        <p className={TextStyle.BodyExLargeText}>
                            {MainCat ==='TEACH' ? length : accepted }
                            <span className={TextStyle.BodyTextExSmall} style={{fontWeight:'normal'}}>
                                {MainCat ==='TEACH' ? ` ${LocalStrings[localCode][lengthUnit]}` : ` ${LocalStrings[localCode][acceptedUnit]}` }
                            </span>
                        </p>
                    )
                }
                </div>
            </div>
            { (MainCat === 'TEACH' && unit !== 'PRIVATE') && (
                <div className={styles.FeeContainer} style={{paddingTop:0}}>
                    { MIN_PERSON && (
                        <div style={{flex: MainCat === 'Rent'? 1 : 1.2, alignItems:'flex-start'}}>
                            <div style={{display:'flex', flexDirection:'row', alignItems:"center"}}>
                                <p className={TextStyle.BodyExLargeText}>
                                    {MIN_PERSON}
                                </p>
                                <p style={{marginLeft:'5px'}}>
                                    <span className={TextStyle.BodyTextExSmall}>{ `${LocalStrings[localCode]['MIN_CAP']} `}</span>
                                    <span className={TextStyle.BodyTextExSmall}>{ `${LocalStrings[localCode]['MIN_PERSON_L']} `}</span>
                                </p>
                            </div>
                        </div>
                    ) }
                    { MAX_PERSON && (
                    <div style={{flex: MainCat === 'Rent'? 1.7 : 1, alignItems:'flex-start'}}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:"center"}}>
                            <p className={TextStyle.BodyExLargeText}>
                                {MAX_PERSON}
                            </p>
                            <div style={{marginLeft:'5px'}}>
                                <p className={TextStyle.BodyTextExSmall}>{ `${LocalStrings[localCode]['CAPACITY']} `}</p>
                                <p className={TextStyle.BodyTextExSmall}>{ `${LocalStrings[localCode]['MAX_PERSON_L']} `}</p>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                )
            }
            { (MainCat === 'TEACH' && unit === 'PRIVATE') && (
                <div className={styles.FeeContainer} style={{paddingTop:'10px'}}>
                    <p className={TextStyle.BodyText} style={{fontWeight:'bold', color:Colors.primaryButtonBGColor}}>
                    {LocalStrings[localCode]['PRIVATE_LESSION_TEXT']}
                    </p>
                </div>
            )}
            { MainCat === 'MAKE' && (
                <div className={styles.FeeContainer} style={{paddingTop:0}}>
                    <p className={TextStyle.BodyExLargeText}>
                        {completionDay}
                        <span className={TextStyle.BodyTextExSmall} style={{fontWeight:'normal'}}>
                            {LocalStrings[localCode]['DAYS_COMPLETE']}
                        </span>
                        <span className={TextStyle.BodyTextExSmall} style={{fontWeight:'normal', color:Colors.redColor}}>
                        { `  ${LocalStrings[localCode]['COMPLETION_NOTE']}`}
                        </span>
                    </p>
                </div>
            )}
        </div>
    )
}

export default FeeInfo;