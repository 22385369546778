import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import './fontello.css';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import Help from './containers/Help/Help';
import DealFlow from './containers/DealFlow/DealFlow';
import Privacy from './containers/Privacy/Privacy';
import Terms from './containers/Terms/Terms';
import Sctl from './containers/Sctl/Sctl';
import CancelPolicy from './containers/CancelPolicy/CancelPolicy';
import About from './containers/About/About';
import Blog from './containers/Blog/Blog';
import Detail from './containers/Detail/Detail';
import Catalog from './containers/Catalog/Catalog';
import MemberCert from './containers/MemberCert/MemberCert';
import SecurityCode from './containers/Help/Article/SecurityCode/SecurityCode';
import { 
  fetchAppData,
  authAutoSignIn
} from './store/actions';

const asyncAccount = asyncComponent(() => {
  return import('./containers/Account/Account');
});

class App extends Component {

  componentDidMount() {
      const {onFetchAppData, onAutoSignIn} = this.props;

      onAutoSignIn()
      .then(tokenObj => {
      }).catch(error => {
      });
      
      onFetchAppData();
  }

  render(){
    const {authInfo:{token}} = this.props;
    let routes = <Switch>
        <Route path="/dealflow" component={DealFlow} />
        <Route path="/help" component={Help} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/securitycode" component={SecurityCode} />
        <Route path="/terms" component={Terms} />
        <Route path="/sctl" component={Sctl} />
        <Route path="/cancelpolicy" component={CancelPolicy} />
        <Route path="/about" component={About} />
        <Route path="/blog" component={Blog} />
        <Route path="/catalog/:MainCat/:SubCat" component={Catalog} />
        <Route path="/catalog/:MainCat" component={Catalog} />
        <Route path="/catalog" component={Catalog} />
        <Route path="/detail/:gigId" component={Detail} />
        <Route path="/gig/:MainCat/:gigId" component={Detail} />
        <Route path="/profile/:userId" component={MemberCert} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>;

    if(token){
      routes = <Switch>
        <Route path="/account" component={asyncAccount} />
        <Route path="/dealflow" component={DealFlow} />
        <Route path="/help" component={Help} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/securitycode" component={SecurityCode} />
        <Route path="/terms" component={Terms} />
        <Route path="/sctl" component={Sctl} />
        <Route path="/cancelpolicy" component={CancelPolicy} />
        <Route path="/about" component={About} />
        <Route path="/blog" component={Blog} />
        <Route path="/catalog/:MainCat/:SubCat" component={Catalog} />
        <Route path="/catalog/:MainCat" component={Catalog} />
        <Route path="/catalog" component={Catalog} />
        <Route path="/detail/:gigId" component={Detail} />
        <Route path="/gig/:MainCat/:gigId" component={Detail} />
        <Route path="/profile/:userId" component={MemberCert} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>;
    }  

    return (
      <div className="App">
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = ({ 
  appData: { appData, appDataIsLoading, appDataError },
  auth:{ authInfo }
}) => ({
  appData, appDataIsLoading, appDataError,
  authInfo
});

const mapDispatchToProps = dispatch => ({
  onFetchAppData : () => dispatch( fetchAppData() ),
  onAutoSignIn : () => dispatch(authAutoSignIn()),
}); 

export default connect(mapStateToProps, mapDispatchToProps)(App);
