import {
    UPDATE_CURRENT_HELP_TAB,
    UPDATE_CURRENT_HELP_MODE,
    UPDATE_CURRENT_HELP_TAB_AND_MODE,
    TOGGLE_HELP_MENU,
    SET_MAIN_MENU,
    SET_SUB_MENU,
    SET_MAIN_SUB_MENU,
    TOGGLE_AUTHWIN_OPEN,
    INIT_AUTH_UI_STATE,
    UI_START_LOADING,
    UI_STOP_LOADING,
    TOGGLE_ERRORWIN_OPEN,
    INIT_ERRORWIN_STATE,
    TOGGLE_SUCCESS_OPEN,
    INIT_SUCCESSWIN_STATE,
    SET_SMART_BANNER_SHOW
} from '../actions/actionTypes'

const initState = {
    Help : {
        currentTab:'HELP',
        currentMode:'SELL',
        showSideMenu: false
    },
    Cat: {
        MainCat: 'HOME',
        SubCat:'ALL'
    },
    Admin : {
        showAdminSideMenu: false
    },
    Auth: {
        authWinOpen: false,
        mode:"SIGNIN",
        closable: true
    },
    uiError: {
        errorWinOpen: false,
        closable: true,
        systemError:null    
    },
    uiSuccess: {
        successWinOpen: false,
        message:null    
    },
    isLoading:{},
    smartBannerShow:true
}

const reducer = (state=initState, action) => {
    const { type, payload} = action;
    switch (type){
        case INIT_AUTH_UI_STATE:
            return{
                ...state,
                Auth:{
                    ...state.Auth,
                    ...payload
                }   
            }
        case TOGGLE_AUTHWIN_OPEN:
            return{
                ...state,
                Auth:{
                    ...state.Auth,
                    authWinOpen: !state.Auth.authWinOpen
                }   
            }
        case UPDATE_CURRENT_HELP_TAB:
            return {
                ...state,
                Help: {
                    ...state.Help,
                    currentTab: payload
                }
            }
        case UPDATE_CURRENT_HELP_MODE:
            return {
                ...state,
                Help: {
                    ...state.Help,
                    currentMode: payload
                }
            }
        case UPDATE_CURRENT_HELP_TAB_AND_MODE:
            return {
                ...state,
                Help: {
                    ...state.Help,
                    currentMode: payload.mode,
                    currentTab: payload.tab
                }
            }
        case TOGGLE_HELP_MENU:
            return {
                ...state,
                Help: {
                    ...state.Help,
                    showSideMenu: payload
                }
            }
        case SET_MAIN_MENU: {
            return {
                ...state,
                Cat: {
                    MainCat: payload.mainMenuKey,
                    SubCat:'ALL'
                }
            }
        }
        case SET_SUB_MENU: {
            return {
                ...state,
                Cat: {
                    ...state.Cat,
                    SubCat: payload.subMenuKey
                }
            }
        }
        case SET_MAIN_SUB_MENU: {
            return{
                ...state,
                Cat: {
                    MainCat:payload.mainMenuKey,
                    SubCat:payload.subMenuKey 
                }  
            }
        }
        case UI_START_LOADING:
            return{
                ...state,
                isLoading:{
                    ...state.isLoading,
                    [payload]:true
                }
            }
        case UI_STOP_LOADING:
            return{
                ...state,
                isLoading:{
                    ...state.isLoading,
                    [payload]:false
                }
            }
        case INIT_ERRORWIN_STATE:
            return{
                ...state,
                uiError:{
                    ...state.uiError,
                    ...payload
                }   
            }
        case TOGGLE_ERRORWIN_OPEN:
            return{
                ...state,
                uiError:{
                    ...state.uiError,
                    errorWinOpen: !state.uiError.errorWinOpen
                }   
            }
        case TOGGLE_SUCCESS_OPEN:
            return{
                ...state,
                uiSuccess:{
                    ...state.uiSuccess,
                    successWinOpen: !state.uiSuccess.successWinOpen
                }   
            }
        case INIT_SUCCESSWIN_STATE:
            return{
                ...state,
                uiSuccess:{
                    ...state.uiSuccess,
                    ...payload
                }   
            }
        case SET_SMART_BANNER_SHOW:
            return {
              ...state,
              smartBannerShow: payload
            }
        default:
            return state
    }    
}

export default reducer;