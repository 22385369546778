import {
    FETCH_APP_DATA    
} from '../actions/actionTypes';

const initState = {
    appData: null,
    appDataIsLoading: false,
    appDataError: false
};

const appDataReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${FETCH_APP_DATA}_PENDING`:
            return {
                ...state,
                appDataIsLoading: true,
                appDataError: false,
            }
        case `${FETCH_APP_DATA}_FULFILLED`:
            return {
                ...state,
                appData: payload,
                appDataIsLoading: false,
                appDataError: false,
            }
        case `${FETCH_APP_DATA}_REJECTED`:
            return {
                ...state,
                appData: null,
                appDataIsLoading: false,
                appDataError: true,
            }
        default:
            return state;
    }
};

export default appDataReducer;
