import React from 'react';
import styles from './StepCenter.module.css';
import TaguJamLogo from '../../../../assets/images/tagujam_logo.svg';

const IconStyle = (role) => {
    if (role==='tagujam'){
        const style =  {
            backgroundColor: '#ffffff',
            width:'32px',
            height:'32px',
            borderRadius: '18px',
            padding:'auto',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            marginRight: '8px'
        }
        return (
        <div style={style}>
            <img alt="tagujam logo" src={TaguJamLogo} style={{ width:'17px'}} />
        </div>)
       
    } else {
        const style =  {
            color: role === 'provider' ? '#ffccb4' : '#b7e6f7', 
            fontSize:'18px',
            backgroundColor: '#ffffff',
            width:'32px',
            height:'32px',
            borderRadius: '18px'
        }
        return <div style={style}><i className="fa fa-user" /></div>
    }    
    
}

const stepCenter = props => {

    const borderClass = props.special 
            ? {
                border: '1px solid #f3cb86' 
            }
            : {
                border: '1px solid #D6D6D6'    
            };

    const headBG = props.special ? styles.BgSpecial : styles.BgLightGray;
    const providerIcon = IconStyle('provider');
    const customerIcon = IconStyle('customer');
    const taguJamIcon = IconStyle('tagujam');

    const Head = props.special 
        ? (<h4 className={`${styles.StepTitle} ${headBG}`} style={{justifyContent:'center'}}>
                {taguJamIcon}{props.title}
            </h4>)
        : (
        <h4 className={`${styles.StepTitle} ${headBG}`}>
            {providerIcon}{props.title}{customerIcon}
        </h4>
        )
    const Content = (
        <div key={`${props.title}-content`}>
            {Head}
            {props.children}
        </div>
    );

    return(
        <div className={styles.StepContent} style={borderClass}>
            {Content} 
        </div>
    )
}

export default stepCenter;