import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import BuyerCancel1 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_1.png';
import BuyerCancel2 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_2.png';
import BuyerCancel3 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_3.png';
import BuyerCancel4 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_4.png';
import BuyerCancel5 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_5.png';
import BuyerCancel6 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_6.png';
import BuyerCancel7 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_7.png';
import BuyerCancel8 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_8.png';
import BuyerCancel9 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_9.png';
import BuyerCancel10 from '../../../assets/images/help/buying/buy_cancel/buy_cancel_10.png';

class BuyerCancel extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービスの購入をキャンセル</span>
            </h3>
            <p className={styles.ArticleDesc}>
                キャンセルは基本的には出来ません。ただし、止むを得ない場合のみキャンセル出来ます。キャンセルのタイミングによりキャンセル料が発生致しますのでご理解ください。
            </p>
            <p>*キャンセル料の詳しい規定は<Link to="/cancelpolicy">こちら</Link>を参照して下さい。</p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerCancel1} alt="サービスの購入をキャンセルするステップ1画像" />
                    <li>
                        キャンセルしたい取引をタップします。     
                    </li>
                    <img className={styles.ScreenShot} src={BuyerCancel2} alt="サービスの購入をキャンセルするステップ2画像" />
                    <li>
                        取引詳細画面下の「止むを得ずキャンセルしなくてはいけない場合」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={BuyerCancel3} alt="サービスの購入をキャンセルするステップ3画像" />
                    <li>
                        キャンセル料金がかかる場合はこの画面が出てきます。「請求額を支払う」ボタンをタップします。処理中へと画面が切り替わります。
                    </li>
                    <p className="Caption">＊キャンセル料金がかからない場合は6の画面に進んでください。</p>
                    <p className="Caption">＊処理中は、アプリを閉じないようにお願いします。</p>
                    <p className="Caption" style={{paddingBottom:'5px'}}>＊一度お支払い頂いたキャンセル料の返金、又はキャンセルの取り消しは致しかねますので十分注意してください。</p>
                    <img className={styles.ScreenShot} src={BuyerCancel4} alt="サービスの購入をキャンセルするステップ4-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={BuyerCancel5} alt="サービスの購入をキャンセルするステップ4-2画像" />
                    <li>
                        処理が終わると以下の画面が表示されます。「キャンセルメッセージの送信へ」をタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerCancel6} alt="サービスの購入をキャンセルするステップ5画像" /> 
                    <li>
                        「取引キャンセルの理由」を選択してください。メッセージが自動入力されます。必要であればメッセージを編集してください。「取引キャンセル送信」ボタンをタップします。
                        キャンセル理由は必ず送信してください。送信しない場合は取引はキャンセルされてません。
                    </li>
                    <img className={styles.ScreenShot} src={BuyerCancel7} alt="サービスの購入をキャンセルするステップ6画像" /> 
                    <p style={{paddingBottom:'5px'}}>＊もし謝って「取引キャンセルの理由」を送信せずにアプリを閉じたり、他の画面に移動してしまった場合は、取引詳細画面下に「取引キャンセル」ボタンが表示され、ボタンをタップすると取引キャンセルの理由の送信が出来ます。</p>
                    <img className={styles.ScreenShot} src={BuyerCancel8} alt="サービスの購入をキャンセルするステップ6-1画像" /> 
                    <li>
                        「会話に戻る」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={BuyerCancel9} alt="サービスの購入をキャンセルするステップ7画像" /> 
                    <li>
                        キャンセルのメッセージを確認する。   
                    </li>
                    <p className="Caption" style={{paddingBottom:'5px'}}>＊キャンセル料金の支払いがある場合は支払い完了のメッセージも確認してください。</p>
                    <img className={styles.ScreenShot} src={BuyerCancel10} alt="サービスの購入をキャンセルするステップ8画像" />  
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BuyerCancel);