import React from 'react';
import styles from './RadioButton.module.scss';

const RadioButton = (props) => {
    const { label, changed, checked, error, checkmarkStyles, disabled} = props;
    return(
    <label className={styles.RadioButton}>{label}
        <input type="checkbox" onChange={changed} checked={checked} disabled={disabled? disabled: false} />
        <span className={`${styles.Checkmark} ${error ? styles.ErrorItem : null}`} style={checkmarkStyles}></span>
        { error &&
        <div className={styles.Error}>
            {error}
        </div>
        }
    </label>
    );
};

export default RadioButton;