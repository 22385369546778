import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class CancelPolicy extends Component {
    render(){
        const {location} = this.props;
        let pathname = location.pathname;
        let title = 'キャンセル料金の規約';
        let description = 'タグジャムキャンセル料金の規約についての説明です。止むを得ない理由の取引中のキャンセルを行う場合の料金や、規約をご確認頂けます。';
        return(
        <>
        <Helmet>
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={`タグジャム-${title}`} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
          <meta property="og:url" content={pathname} />
          <meta property="og:title" content={`タグジャム-${title}`} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png"/>
          <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
          <meta property="og:image:type" content="image/png" />
          <title>{title}</title>
          <meta
            name="description"
            content={description}
          />
          <meta content="タグジャムキャンセル料金の規約,キャンセル料金の規約,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
        </Helmet>
        <div className="MainPadding">
            <h2 className="PageHeader">
                キャンセル料金の規約
            </h2>
            <div className="Content">
                <div className="Item">
                    <p>サービス利用会員は、取引の契約成立後、止むを得ない理由の場合のみ出品者の合意の上で、商品購入のキャンセルができます。ただし、下記のキャンセル料金の設定に基づきキャンセル料が発生し、その支払いが必要です。</p>
                    <p>尚、一旦お支払いただきましたサービス料金及びキャンセル料金は返金いたしかねますので十分お気をつけください。</p>
                </div>
                <div className="Item">
                    <h5>「助けます」サービスのキャンセル：</h5>
                    <dl className="Table">
                        <dt>
                        予約確定後 <span className="Caption">(＊支払い情報登録後)</span><br />
                        サービス開始時刻の<br />
                        <strong>48時間前まで</strong>のキャンセル<br />
                        </dt>
                        <dd>
                        <strong>無料</strong><br />ただしシステムのキャンセル回数にカウントされます。
                        </dd>
                        <dt>
                        サービス開始時刻の<br/>
                        <strong>48時間以内</strong>のキャンセル
                        </dt>
                        <dd>
                        サービス料合計の<strong>20%</strong> / <strong>250円</strong>のいずれか高い方<br />
                        <span className="Caption">（＊実費清算料金は含まない）</span>
                        </dd>
                        <dt>
                        サービス開始時刻の<br/>
                        <strong>24時間以内</strong>のキャンセル
                        </dt>
                        <dd>
                        サービス料合計の<strong>50%</strong> / <strong>250円</strong>のいずれか高い方<br />
                        <span className="Caption">（＊実費清算料金は含まない）</span>
                        </dd>
                    </dl>
                </div>
                <div className="Item">
                    <h5>「教えます」サービスのキャンセル：</h5>
                    <dl className="Table">
                        <dt>
                        予約確定後 <span className="Caption">(＊支払い情報登録後)</span><br />
                        クラス開始時刻の<br />
                        <strong>48時間前まで</strong>のキャンセル<br />
                        </dt>
                        <dd>
                        <strong>無料</strong><br />ただしシステムのキャンセル回数にカウントされます。
                        </dd>
                        <dt>
                        クラス開始時刻の<br/>
                        <strong>48時間以内</strong>のキャンセル
                        </dt>
                        <dd>
                        受講料合計の<strong>20%</strong> / <strong>250円</strong>のいずれか高い方<br />
                        <span className="Caption">（＊実費清算料金は含まない）</span>
                        </dd>
                        <dt>
                        クラス開始時刻の<br/>
                        <strong>24時間以内</strong>のキャンセル
                        </dt>
                        <dd>
                        受講料合計の<strong>50%</strong> / <strong>250円</strong>のいずれか高い方<br />
                        <span className="Caption">（＊実費清算料金は含まない）</span>
                        </dd>
                    </dl>
                </div>
                <div className="Item">
                    <h5>「貸します」サービスのキャンセル：</h5>
                    <dl className="Table">
                        <dt>
                        予約確定後 <span className="Caption">(＊支払い情報登録後)</span><br />
                        レンタル開始日の<br />
                        <strong>2日前まで</strong>のキャンセル<br />
                        </dt>
                        <dd>
                        <strong>無料</strong><br />ただしシステムのキャンセル回数にカウントされます。
                        </dd>
                        <dt>
                        レンタル開始日の<br/>
                        <strong>2日前</strong>のキャンセル
                        </dt>
                        <dd>
                        レンタル料合計の<strong>20%</strong> / <strong>250円</strong>のいずれか高い方<br />
                        <span className="Caption">（＊配達料金、保証金は含まない）</span>
                        </dd>
                        <dt>
                        レンタル開始日の<br/>
                        <strong>１日前</strong>のキャンセル
                        </dt>
                        <dd>
                        レンタル料合計の<strong>50%</strong> / <strong>250円</strong>のいずれか高い方<br />
                        <span className="Caption">（＊配達料金、保証金は含まない）</span>
                        </dd>
                    </dl>
                </div>
                <div className="Item">
                    <h5>「作ります」サービスのキャンセル：</h5>
                    <dl className="Table">
                        <dt>
                        予約確定後 <span className="Caption">(＊支払い情報登録後)</span><br />
                        <strong>作成開始の通知前</strong>のキャンセル<br />
                        </dt>
                        <dd>
                        <strong>無料</strong><br />ただしシステムのキャンセル回数にカウントされます。
                        </dd>
                        <dt>
                        <strong>作成開始の通知後</strong>のキャンセル
                        </dt>
                        <dd>
                        サービス料合計の<strong>50%</strong> / <strong>250円</strong>のいずれか高い方<br />
                        <span className="Caption">（＊配達料金、実費清算料金は含まない）</span>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        </>
        );
    }
}

export default CancelPolicy;