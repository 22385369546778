import React from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from './Menu.module.scss';
import Orange_line from '../../../assets/images/orange_line.svg';
import Gray_line from '../../../assets/images/gray_line.svg';

const Menu = (props) => {
    const {sectionPath} = props;
    return(
      <ul className={styles.MenuContainer}>
        <NavLink to="/blog" className={styles.MenuItem}>
          <li className={sectionPath==='/blog' || sectionPath==='/blog/' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            注目の記事 
          </li>
          {sectionPath==='/blog' &&
            <img className={styles.MenuBorder} src={Orange_line} alt="menu border" />
          }
          {sectionPath!=='/blog' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink>
        <NavLink to="/blog/HowToStart" className={styles.MenuItem}>
          <li className={sectionPath==='/blog/HowToStart' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            始めの一歩 
          </li>
          {sectionPath==='/blog/HowToStart' &&
            <img className={styles.MenuBorder} src={Orange_line} alt="menu border" />
          }
          {sectionPath!=='/blog/HowToStart' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink>
        {/* <NavLink to="/blog/Examples" className={styles.MenuItem}>
          <li className={sectionPath==='/blog/Examples' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            活用例 
          </li>
          {sectionPath==='/blog/Examples' &&
            <img className={styles.MenuBorder} src={Orange_line} alt="menu border" />
          }
          {sectionPath!=='/blog/Examples' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink> */}
        {/* <NavLink to="/blog/Features" className={styles.MenuItem}>
          <li className={sectionPath==='/blog/Features' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            便利機能 
          </li>
          {sectionPath==='/blog/Features' &&
            <img className={styles.MenuBorder} src={Orange_line} alt="menu border" />
          }
          {sectionPath!=='/blog/Features' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink> */}
        <NavLink to="/blog/HowToVideos" className={styles.MenuItem}>
          <li className={sectionPath==='/blog/HowToVideos' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            使い方動画 
          </li>
          {sectionPath==='/blog/HowToVideos' &&
            <img className={styles.MenuBorder} src={Orange_line} alt="menu border" />
          }
          {sectionPath!=='/blog/HowToVideos' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink>
      </ul>
    );
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
  ui:{ smartBannerShow },
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError,
  smartBannerShow 
});

export default connect(mapStateToProps, null)(Menu);