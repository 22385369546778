import React, {Component} from 'react';
import { connect } from 'react-redux';
import styles from './DaySchedule.module.scss';
import TextStyle from '../../constants/TextStyle.module.scss';
import {
    getEventsOfTheShift,
    getClassEventsOfTheShift,
    getNoneClassEventsOfTheShift,
    getEventsOftheDay
} from './calenderUtils';

class DaySchedule extends Component {

    renderTimeLine = (TimeList) => {
        return(
            <div className={styles.Row} style={{position:'absolute', left:0, top:'30px'}}>
            { TimeList.map( time =>{
                return(
                    <div key={`${time}-timeline`} className={styles.TimeLineColum}>
                        <div className={styles.Timeline} />
                    </div> 
                )
            })}
            </div>
        )    
    }

    renderHourHead = (TimeList) => {
        return(
            <div className={styles.Row} style={{position:'absolute', left:0, top:0}}>
            {TimeList.map( time => {
                return(
                <div key={`${time}:00`} className={styles.TimeColum}>
                    <p className={styles.TimeText}>{`${time}:00`}</p>
                </div>     
                )
            })}
            </div>
        )  
    }

    renderReservedTime = (eventsOftheDay, shiftStart, shiftEnd) => {
        const { localCode, CalenderType, MAX_PERSON, appData, theGig } = this.props;
        const { Colors, LocalStrings} = appData;
        let reservedTimeMarkUp = null;
        let eventOfTheShift;
        let noneClassEventsOfTheShift = [];
        if(CalenderType==='SEAT'){
            eventOfTheShift = getClassEventsOfTheShift(
              eventsOftheDay,
              {
                start: shiftStart,
                end: shiftEnd,
              },
              theGig.user
            );
            noneClassEventsOfTheShift = getNoneClassEventsOfTheShift(
              eventsOftheDay,
              {
                start: shiftStart,
                end: shiftEnd,
              },
              theGig.user
            );
        } else {
            eventOfTheShift = getEventsOfTheShift(eventsOftheDay, {start: shiftStart, end: shiftEnd});
        }
    
        if(CalenderType==='SEAT'&& noneClassEventsOfTheShift.length === 0){
            const lengthMinute = (shiftEnd.getTime() - shiftStart.getTime())/60000; 
            const lengthWidth = (lengthMinute / 15 ) * 25;
            const leftPosition =0;
            const deductNum = (eventOfTheShift && eventOfTheShift.length) ? eventOfTheShift.length : 0;
            const remaining = MAX_PERSON - deductNum;
            reservedTimeMarkUp = (
                <div className={styles.Shift} style={{opacity: 0.75, top:'20px', height:'35px', left:`${leftPosition}px`, width:`${lengthWidth}px`, backgroundColor:'transparent', borderWidth:0}}>
                    <p className={styles.ShiftHourText} style={{paddingTop:'2px'}}>
                    {LocalStrings[localCode].REMAINING} 
                    <span className={`${styles.ShiftHourText} ${styles.RemainingText}`}>{remaining}</span>
                    {LocalStrings[localCode].SEAT}
                    </p>
                </div>
            )
        } else {
            let theEventOfTheShift =
            noneClassEventsOfTheShift.length > 0
              ? noneClassEventsOfTheShift
              : eventOfTheShift;
            reservedTimeMarkUp = theEventOfTheShift.map( (event, index) => {
                const lengthMinute = (event.end.getTime() - event.start.getTime())/60000;
                const lengthWidth = (lengthMinute / 15 ) * 25;
                const offSetMinute = (event.start.getTime() - shiftStart.getTime())/60000;
                const leftPosition = ((offSetMinute / 15 ) * 25)-2;
                return(
                    <div key={index} className={styles.Shift} style={{opacity: 0.75, top:'20px', height:'35px', left:`${leftPosition}px`, width:`${lengthWidth}px`, backgroundColor:Colors.contentFontTintColor, borderWidth:0}}>
                        <p className={styles.ShiftHourText} style={{paddingTop:'2px'}}>
                            {`${event.start.getHours()}:${ event.start.getMinutes()===0 ? "00" : event.start.getMinutes()}-${event.end.getHours()}:${ event.end.getMinutes()===0 ? "00" : event.end.getMinutes()}`}
                        </p>
                        { lengthWidth >= 100 && 
                        <p className={styles.ShiftHourText} style={{paddingTop:0}}>
                            {LocalStrings[localCode].RESERVED}
                        </p>
                        }
                    </div>
                )
            })
        }
        return reservedTimeMarkUp;
    }

    getSelDateObj = () => {
        const { estimate } = this.props;
        const selDateObj = (estimate && estimate.selectedDate) ? estimate.selectedDate : null;
        return selDateObj;
    }

    renderShift = (DayShiftList, TimeList) => {
        const { events, showTempEvent, appData} = this.props;
        const { Colors } = appData;
        const Day = this.getSelDateObj().start;
        const eventsOftheDay = getEventsOftheDay(Day, events, "NOT_RENT", showTempEvent);
        return DayShiftList.map( (shift, index) => {
            const lengthMinute = (shift.end.getTime() - shift.start.getTime())/60000;
            const lengthWidth = (lengthMinute / 15 ) * 25;
            const shiftSheetStart = new Date(shift.start.getFullYear(), shift.start.getMonth(), shift.start.getDate(), TimeList[0]);
            const offSetMinute = (shift.start.getTime() - shiftSheetStart.getTime())/60000;
            const leftPosition = ((offSetMinute / 15 ) * 25) + 50;
            const eventsOftheShift = getEventsOfTheShift(eventsOftheDay, shift);
            const borderColor = eventsOftheShift.length > 0 ? Colors.yellow : Colors.primaryButtonBGColor;
            return(
                <div 
                    key={index}
                    className={styles.Shift} 
                    style={{borderColor:borderColor, top:'45px', left:`${leftPosition}px`, width:`${lengthWidth}px`}}
                >
                    <p className={styles.ShiftHourText}>
                        {`${shift.start.getHours()}:${ shift.start.getMinutes()===0 ? "00" : shift.start.getMinutes()}-${shift.end.getHours()}:${ shift.end.getMinutes()===0 ? "00" : shift.end.getMinutes()}`}
                    </p>
                    {this.renderReservedTime(eventsOftheDay, shift.start, shift.end)}
                </div>
            )
        })
    }

    renderTimeSheet = () =>{
        const Day = this.getSelDateObj().start;
        const DayShift = this.getSelDateObj().DayShift;
        let start;
        let end;
        let DayShiftList = [];
        for (let key in DayShift) {
            let startDate = new Date(DayShift[key].start_hr);
            let endDate = new Date(DayShift[key].end_hr); 
            start = start ? start :  startDate.getHours()-1;
            end = endDate.getHours()+1;
            DayShiftList.push({
                start: new Date(Day.getFullYear(), Day.getMonth(), Day.getDate(), startDate.getHours(), startDate.getMinutes()),
                end: new Date(Day.getFullYear(), Day.getMonth(), Day.getDate(), endDate.getHours(), endDate.getMinutes())     
            })
        }
        const TimeList=[]
        while(start <= end){
            TimeList.push(start);
            start++
        }

        return(
            <div className={styles.TimeSheet}>
            { this.renderHourHead(TimeList) }
            { this.renderTimeLine(TimeList) }
            { this.renderShift(DayShiftList, TimeList)}
            </div>
        )
    }

    renderSelectedDateString = () => {
        const { localCode, appData:{ LocalStrings } } = this.props;
        const Day = this.getSelDateObj().start;
        const DayofWeek = [
            LocalStrings[localCode].SUN,
            LocalStrings[localCode].MON,
            LocalStrings[localCode].TUE,
            LocalStrings[localCode].WED,
            LocalStrings[localCode].THU,
            LocalStrings[localCode].FRI,
            LocalStrings[localCode].SAT
        ];
        return `${Day.getFullYear()}${LocalStrings[localCode].YEAR}${Day.getMonth()+1}${LocalStrings[localCode].MONTH}${Day.getDate()}${LocalStrings[localCode].DAY} (${DayofWeek[Day.getDay()]})` 
    }

    render(){
        const { localCode, CalenderType, appData } = this.props;
        const { Colors, LocalStrings} = appData;
        const backgroundColor = this.getSelDateObj().backgroundColor;
        return(
            <div className={styles.DaySchedule} style={{border:`2px solid ${backgroundColor}`}}>
                <div className={styles.DayContainer} style={{backgroundColor:backgroundColor}}>
                    <p style={{color:Colors.white, fontWeight:'bold'}}>
                    {this.renderSelectedDateString()}
                    </p>
                </div>
                <div style={{marginTop:'10px', padding:'0 8px'}}>
                    <p className={TextStyle.BodyMediumText}>
                        {LocalStrings[localCode].SELECT_SHIFT_TEXT}
                    </p>
                </div>
                <div style={{display:'flex', flexDirection:'row', padding:'0 8px', margin:'10px 0', alignItems:'center'}}>
                    <div className={styles.FreeZone} />
                    <p className={TextStyle.BodyTextExExSmall} style={{paddingLeft:'3px', paddingRight:'8px'}}>
                        { CalenderType === 'SEAT' && 
                           LocalStrings[localCode].SEAT_AVAILALBE
                        }
                        { CalenderType === 'DEFAULT' && 
                            LocalStrings[localCode].AVAILABLE
                        } 
                    </p>
                    <div className={styles.ParcialyFree} />
                    <p className={TextStyle.BodyTextExExSmall} style={{paddingLeft:'3px', paddingRight:'8px'}}>
                        { CalenderType === 'SEAT' && 
                            LocalStrings[localCode].SEAT_STILL_RAMAIN    
                        }
                        { CalenderType === 'DEFAULT' && 
                            LocalStrings[localCode].PART_AVAILABLE    
                        } 
                    </p>
                    <div className={styles.NotFree} />
                    <p className={TextStyle.BodyTextExExSmall} style={{paddingLeft:'3px', paddingRight:'8px'}}>
                        {LocalStrings[localCode].RESERVED}
                    </p>    
                </div>
                <div className={styles.ScrollContainer}>
                    {this.renderTimeSheet()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({
    estimate, 
    local:{localCode},
    appData:{appData} 
}) => ({
    estimate, 
    localCode,
    appData
});

export default connect(mapStateToProps, null)(DaySchedule);