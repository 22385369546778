import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
    toggleHelpMenu,
    updateHelpMode,
    updateHelpTab,
    updateHelpModeAndTab
} from '../../store/actions';
import styles from './Help.module.css';
import SideMenu from './SideMenu/SideMenu';
import SideMenuItems from './SideMenuItems/SideMenuItems';
import Article from './Article/Article';
import Aux from '../../hoc/Aux/Aux';
import {plainData} from '../../utils'

const SellerPages = [
    {url:"/help", title:'ヘルプガイドの使い方', description:'タグジャムのヘルプガイドの使い方'},
    {url:"/help/WhatTaguJam", title:'タグジャムとは？', description:'タグジャムでできる事、タグジャムコミュニティの説明'},
    {url:"/help/DealFlow", title:'取引の流れ', description:'タグジャムの基本的な取引の流れ'},
    {url:"/help/Fee", title:'利用料金', description:'タグジャムの利用料金'},
    {url:"/help/SafeEffort", title:'安全性への取組', description:'タグジャムが利用者の皆様に安全に取引をしていただくために取り組んでいる事'},
    {url:"/help/DealConfirm", title:'取引成立について', description:'タグジャムでの取引成立の説明'},
    {url:"/help/PayFee", title:'サービス料の支払い', description:'タグジャムでサービス料の支払いについての注意点'},
    {url:"/help/BasicManner", title:'タグジャムのマナー', description:'タグジャム使用上の基本マナー'},
    {url:"/help/BecomeMember", title:'新規会員になる', description:'タグジャムの新規会委員になる手順'},
    {url:"/help/AccountInfo", title:'アカウント情報', description:'タグジャムアカウント情報登録の手順'},
    {url:"/help/UserInfo", title:'ユーザー情報', description:'タグジャムユーザー情報登録の手順'},
    {url:"/help/EmailConfirmation1", title:'Eメールアドレスの確認', description:'タグジャムでEメールアドレスの確認する手順'},
    {url:"/help/AddressRegistration", title:'住所登録', description:'タグジャムで住所登録の手順'},
    {url:"/help/IdConfirmation1", title:'本人確認', description:'タグジャムで本人確認の手順'},    
    {url:"/help/IdConfirmation2", title:'本人確認', description:'タグジャムで本人確認の手順'},
    {url:"/help/EmailConfirmation2", title:'Eメールアドレスの確認', description:'タグジャムでEメールアドレスの確認する手順'},
    {url:"/help/RegisterBank", title:'銀行を登録する', description:'タグジャムで売り上げの振り込み銀行を登録する手順'},
    {url:"/help/Sell", title:'出品する', tabSelection:true, description:'タグジャムでお手伝いサービスの出品の手順'},
    {url:"/help/SubmitDocs", title:'必要書類を提出する', description:'タグジャムで必要書類を提出する手順'},
    {url:"/help/BannedBehaivors", title:'禁止行為', description:'タグジャムでの禁止行為'},
    {url:"/help/BannedItems", title:'出品禁止サービス', description:'タグジャムで出品禁止サービスもの'},
    {url:"/help/MannerForSeller", title:'出品時のマナー', description:'タグジャムでの出店時のマナー'},
    {url:"/help/AnswerQuestion", title:'サービス購入者の質問に返信する', description:'タグジャムでサービス購入者の質問に返信する手順'},
    {url:"/help/AcceptInterview", title:'面接の申請を受け付ける', description:'タグジャムで面接の申請を受け付ける手順'},
    {url:"/help/AcceptOrder", title:'申請を受け付ける', tabSelection:true, description:'タグジャムで購入申請を受け付ける手順'},
    {url:"/help/SellerSchedule", title:'スケジュール確認する(売り手)', description:'タグジャムで売り手がスケージュールを確認する手順'},
    {url:"/help/SellerRequestDetails", title:'依頼内容詳細の確認', tabSelection:true, description:'タグジャムで依頼内容詳細の確認する手順'},
    {url:"/help/SellerStartService", title:'サービスを提供をする', tabSelection:true, description:'タグジャムでサービスを提供をする手順'},
    {url:"/help/SellerFinishService", title:'サービスを完了する', tabSelection:true, description:'タグジャムでサービスを完了する手順'},
    {url:"/help/SendInvoice", title:'請求書の作成と送信', description:'タグジャムで請求書の作成と送信する手順'},
    {url:"/help/ResendInvoice", title:'支払い保留時の請求書の再送信', description:'タグジャムで支払い保留時の請求書の再送信する手順'},
    {url:"/help/CheckSales", title:'売り上げを確認する', description:'タグジャムで売り上げを確認する手順'},
    {url:"/help/SendMoney", title:'売上金を銀行へ振り込む', description:'タグジャムで売上金を銀行へ振り込む手順'},
    {url:"/help/EvaluateBuyer", title:'購入者を評価する', description:'タグジャムで購入者を評価する手順'},
    {url:"/help/SellerChangeRequest", title:'依頼内容の変更', tabSelection:true, description:'タグジャムで依頼内容の変更をする手順'},
    {url:"/help/SellerCancel", title:'サービス提供のキャンセル', description:'タグジャムでサービス提供のキャンセルをする手順'},
    {url:"/help/ReportService", title:'不適切なサービスを報告する', description:'タグジャムで不適切なサービスを報告する手順'},
    {url:"/help/ReportUser", title:'不適切な行為を報告する', description:'タグジャムで不適切な行為を報告する手順'},
    {url:"/help/BlockUser", title:'特定のユーザーをブロックする', description:'タグジャムで特定のユーザーをブロックする手順'},
    {url:"/help/ReportDeal", title:'取引の問題を報告する', description:'タグジャムで取引の問題を報告する手順'},
    {url:"/help/CustomerSupport", title:'カスタマーサポート', description:'タグジャムでカスタマーサポートに報告する手順'},
    {url:"/help/CertChart", title:'必要書類一覧', description:'タグジャムで資格が必要なサービスの一蘭及び各項目で承認可能な書類の種類'},
    {url:"/help/WhatCreditAuthorization", title:'支払い審査とは', description:'タグジャムで支払い審査とはの説明'},
    {url:"/help/WhatDeposit", title:'保証金とは', description:'タグジャムで保証金とはの説明'}
];

const BuyerPages = [
    {url:"/help", title:'ヘルプガイドの使い方', description:'タグジャムのヘルプガイドの使い方'},
    {url:"/help/WhatTaguJam", title:'タグジャムとは？', description:'タグジャムでできる事、タグジャムコミュニティの説明'},
    {url:"/help/DealFlow", title:'取引の流れ', description:'タグジャムの基本的な取引の流れ'},
    {url:"/help/Fee", title:'利用料金', description:'タグジャムの利用料金'},
    {url:"/help/SafeEffort", title:'安全性への取組', description:'タグジャムが利用者の皆様に安全に取引をしていただくために取り組んでいる事'},
    {url:"/help/DealConfirm", title:'取引成立について', description:'タグジャムでの取引成立の説明'},
    {url:"/help/PayFee", title:'サービス料の支払い', description:'タグジャムでサービス料の支払いについての注意点'},
    {url:"/help/BasicManner", title:'タグジャムのマナー', description:'タグジャム使用上の基本マナー'},
    {url:"/help/BecomeMember", title:'新規会員になる', description:'タグジャムの新規会委員になる手順'},
    {url:"/help/AccountInfo", title:'アカウント情報', description:'タグジャムアカウント情報登録の手順'},
    {url:"/help/UserInfo", title:'ユーザー情報', description:'タグジャムユーザー情報登録の手順'},
    {url:"/help/EmailConfirmation1", title:'Eメールアドレスの確認', description:'タグジャムでEメールアドレスの確認する手順'},
    {url:"/help/AddressRegistration", title:'住所登録', description:'タグジャムで住所登録の手順'},
    {url:"/help/IdConfirmation1", title:'本人確認', description:'タグジャムで本人確認の手順'},    
    {url:"/help/IdConfirmation3", title:'本人確認', description:'タグジャムで本人確認の手順'},
    {url:"/help/EmailConfirmation3", title:'Eメールアドレスの確認', description:'タグジャムでEメールアドレスの確認の手順'},
    {url:"/help/ServiceSubject", title:'サービス対象者の事前登録', description:'タグジャムでサービス対象者の事前登録の手順'},
    {url:"/help/ServiceAddress", title:'サービス住所の事前登録', description:'タグジャムでサービス住所の事前登録の手順'},
    {url:"/help/Payment", title:'支払い情報の事前登録', description:'タグジャムで支払い情報の事前登録'},
    {url:"/help/MannerForBuyer", title:'購入時のマナー', description:'タグジャムでの購入時のマナーについて'},
    {url:"/help/SendQuestion", title:'サービス提供者に質問する', description:'タグジャムでサービス提供者に質問する手順'},
    {url:"/help/RequestInterview", title:'サービス提供者に面接申請する', description:'タグジャムでサービス提供者に面接申請をする手順'},
    {url:"/help/RequestPurchase", title:'購入申請する', tabSelection:true, description:'タグジャムでサービスの購入申請をする手順'},
    {url:"/help/RegisterPayment", title:'支払い情報の登録をする', description:'タグジャムで支払い情報の登録をする手順'},
    {url:"/help/BuyerSchedule", title:'スケジュール確認する（買い手）', description:'タグジャムで買い手がスケジュール確認する手順'},
    {url:"/help/BuyerRequestDetails", title:'依頼内容詳細の入力', tabSelection:true, description:'タグジャムでサービスを購入し、依頼内容詳細の入力の手順'},
    {url:"/help/BuyerStartService", title:'サービスを提供開始', tabSelection:true, description:'タグジャムで提供者がサービスを提供開始した場合の確認の手順'},
    {url:"/help/BuyerFinishService", title:'サービスを提供完了', tabSelection:true, description:'タグジャムで提供者がサービスをサービスを提供完了した場合の確認の手順'},
    {url:"/help/CheckInvoicePay", title:'請求書の確認と支払い', description:'タグジャムでサービス料金を請求書で確認及び、支払いをする手順'},
    {url:"/help/PausePayment", title:'請求金額に問題がある場合', description:'タグジャムで請求金額に問題がある場合提供者に報告し、支払いを保留する手順'},
    {url:"/help/PurchaseHistory", title:'購入履歴を確認する', description:'タグジャムで購入履歴を確認する手順'},
    {url:"/help/EvaluateSeller", title:'提供者を評価する', description:'タグジャムで提供者を評価する手順'},
    {url:"/help/BuyerChangeRequest", title:'依頼内容の変更', tabSelection:true, description:'タグジャムで依頼内容を変更する手順'},
    {url:"/help/BuyerCancel", title:'サービスの購入をキャンセル', description:'タグジャムでサービスの購入をキャンセルする手順'},
    {url:"/help/TransactionTermsForBuyer", title:'売買取引・契約の規約', description:'タグジャムでサービス購入した場合の売買取引・契約の規約'},
    {url:"/help/ReportService", title:'不適切なサービスを報告する', description:'タグジャムで不適切なサービスを報告する手順'},
    {url:"/help/ReportUser", title:'不適切な行為を報告する', description:'タグジャムで不適切な行為を報告する手順'},
    {url:"/help/BlockUser", title:'特定のユーザーをブロックする', description:'タグジャムで特定のユーザーをブロックする手順'},
    {url:"/help/ReportDeal", title:'取引の問題を報告する', description:'タグジャムで取引の問題を報告する手順'},
    {url:"/help/CustomerSupport", title:'カスタマーサポート', description:'タグジャムでカスタマーサポートに報告する手順'},
    {url:"/help/CertChart", title:'必要書類一覧', description:'タグジャムで資格が必要なサービスの一蘭及び各項目で承認可能な書類の種類'},
    {url:"/help/WhatCreditAuthorization", title:'支払い審査とは', description:'タグジャムで支払い審査とはの説明'},
    {url:"/help/WhatDeposit", title:'保証金とは', description:'タグジャムで保証金とはの説明'}
]

class Help extends Component {

    getQueryKeyValues = () => {
      const {location} = this.props;
      let pairs = {
        mode:'sell',
        tab:'help' 
      };
      if(location.search){
        let query = location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split('=');
          let key =`${decodeURIComponent(pair[0])}`;
          let value = `${decodeURIComponent(pair[1])}`;
          if(key==='mode' || key==='tab'){
            let val = "";
            if(key==='mode'){
              val = (value === 'buy' || value === 'sell') ? value : 'sell';
            } else {
              val = (value === 'help' || value === 'teach' || value === 'rent' || value === 'make') ? value : 'help';
            }
            pairs = {
              ...pairs,
              [key]:val
            }
          }
        } 
      }
      return pairs;
    }

    componentDidMount = () => {
      console.log(this.props);
      const {onUpdateHelpModeAndTab, currentTab, currentMode, history, location} = this.props;
      let query = this.getQueryKeyValues();
      let path = location.pathname === '/help/' ? '/help' : location.pathname;
      history.replace(`${path}?mode=${query.mode}&tab=${query.tab}`);
      if(currentMode!==query.mode.toUpperCase() || currentTab!==query.tab.toUpperCase()){
        onUpdateHelpModeAndTab(query.mode.toUpperCase(), query.tab.toUpperCase());
      }
    }
    
    tabClikedHandler = (tabLabel) => {
        const {onUpdateHelpTab, currentMode, history, location} = this.props;
        history.replace(`${location.pathname}?mode=${currentMode.toLowerCase()}&tab=${tabLabel.toLowerCase()}`);
        onUpdateHelpTab(tabLabel);
        console.log(this.props);
    }
    selectorClikedHandler = (mode) => {
        const {onUpdateHelpMode, currentTab} = this.props;
        const Menu = mode === "BUY" ? BuyerPages : SellerPages;
        this.props.history.push({
            pathname: Menu[0].url,
            search:`?mode=${mode.toLowerCase()}&tab=${currentTab.toLowerCase()}`
        });
        onUpdateHelpMode(mode);
    }
    articleClickedHandler = (article) => {
        const {currentMode, currentTab} = this.props;
        console.log(article);
        this.props.history.push({
            pathname: `/help/${article}`,
            search:`?mode=${currentMode.toLowerCase()}&tab=${currentTab.toLowerCase()}`
        });
        this.sideMenuCloseHandler();
    }

    sideMenuCloseHandler = () => {
        const {onToggleHelpMenu} = this.props;
        const open = false;
        onToggleHelpMenu(open);
    }

    sideMenuToggleHandler = (event) => {
        const {onToggleHelpMenu, showSideMenu} = this.props;
        const open = !showSideMenu;
        onToggleHelpMenu(open);
    }

    disablePrev = () => {
        const pathname = this.props.history.location.pathname;
        return pathname === '/help/' || pathname === '/help';
    }

    disableNext = () => {
        const pathname = this.props.history.location.pathname;
        return pathname === '/help/WhatDeposit';
    }

    getArtcleIndex = () => {
      const pathname = this.props.history.location.pathname;
      const {currentMode} = this.props;
      const Menu = currentMode === "BUY" ? BuyerPages : SellerPages;
      const index = Menu.findIndex( page => {
          return page.url === pathname || page.url === `${pathname}/`;
      });
      return index;
    }

    clickedPrev = () => {
        const {currentMode, currentTab} = this.props;
        const Menu = currentMode === "BUY" ? BuyerPages : SellerPages;
        const index = this.getArtcleIndex();
        if(index > 0){
            this.props.history.push({
                pathname: Menu[index-1].url,
                search:`?mode=${currentMode.toLowerCase()}&tab=${currentTab.toLowerCase()}`
            });
        }
    }

    clickedNext = () => {
        const {currentMode, currentTab} = this.props;
        const Menu = currentMode === "BUY" ? BuyerPages : SellerPages;
        const index = this.getArtcleIndex();
        if(Menu.length > index+1 ){
            this.props.history.push({
                pathname: Menu[index+1].url,
                search:`?mode=${currentMode.toLowerCase()}&tab=${currentTab.toLowerCase()}`
            });
        }
    }

    isHeadless = () =>  {
        const query = new URLSearchParams(this.props.location.search);
        let headless = false;

        for (let param of query.entries()){
            // ['head', 'no']
            if(param[0] === 'head' && param[1] === 'no'){
                headless = true;
            } 
        }
        return headless;
    }

    getStructureData = (title) => {
      const {
          currentTab, currentMode,
          history
      } = this.props;
      let pathname = history.location.pathname;
      let search = history.location.search ? history.location.search : '';
      const structureData = {
        "@context" : "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type" : "ListItem",
          "position": 1,
          "name" : '使い方ガイド',
          "item" : `https://${process.env.REACT_APP_DEEPLINK_HOST}/help?mode=${currentMode.toLowerCase()}&tab=${currentTab.toLowerCase()}`
        }]
      }
      if(pathname !== '/help/' && pathname !== '/help' ){
        structureData.itemListElement.push({
          "@type" : "ListItem",
          "position": 2,
          "name" : `${title}`,
          "item" : `https://${process.env.REACT_APP_DEEPLINK_HOST}${pathname}${search}`
        });  
      }
      return structureData;
    }

    render(){
        const {localCode, appData:{LocalStrings}, currentTab, currentMode, showSideMenu, location} = this.props;
        let disablePrev = this.disablePrev();
        let disableNext = this.disableNext();
        const isHeadless = this.isHeadless();
        let articleMarginClass = isHeadless ? styles.NoMarginLeft : '';
        const Menu = currentMode === "BUY" ? BuyerPages : SellerPages;
        const index = this.getArtcleIndex();
        let postFix = '';
        let title = '';
        let description = '';
        if(index !==-1){
          postFix = Menu[index].tabSelection ? ` (${LocalStrings[localCode][currentTab]})` : '';
          title = Menu[index].title + postFix;
          description = Menu[index].description;
        }
        let pathname = location.pathname;
        return(
            <div className="MainPadding">
                <Helmet>
                  <script type="application/ld+json">
                  {plainData(this.getStructureData(title))}
                  </script>
                  <meta name="twitter:card" content="summary" />
                  <meta name="twitter:title" content={title} />
                  <meta name="twitter:description" content={description} />
                  <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
                  <meta property="og:url" content={pathname} />
                  <meta property="og:title" content={title} />
                  <meta property="og:description" content={description} />
                  <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
                  <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
                  <meta property="og:image:type" content="image/png" />
                  <title>{title}</title>
                  <meta
                    name="description"
                    content={description}
                  />
                  <meta content={`${title},サポート,タグジャムアプリの使い方,へプルガイド,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性`} name="keywords" />
                </Helmet>
                {!isHeadless && 
                <Aux>
                <h2 className="PageHeader">
                    使い方ガイド
                </h2>
                <div className={styles.BuySellSelector}>
                    <div className={currentMode === 'SELL' ? `${styles.SelecterLabel} ${styles.SelectActive}` : styles.SelecterLabel}
                        onClick={() => this.selectorClikedHandler('SELL')}>
                        <i className={`fa fa-caret-right ${styles.faicon}`}></i>
                        <div className={styles.SelecterLabelText}>売り手の方へ</div>
                    </div>
                    <div className={currentMode === 'BUY' ? `${styles.SelecterLabel} ${styles.SelectActive}` : styles.SelecterLabel}
                        onClick={() => this.selectorClikedHandler('BUY')}>
                        <i className={`fa fa-caret-right ${styles.faicon}`}></i>
                        <div className={styles.SelecterLabelText}>買い手の方へ</div>
                    </div> 
                </div>
                <div className={styles.TabContainer}>
                    <div className={currentTab === 'HELP' ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel} 
                        onClick={() => this.tabClikedHandler('HELP')}
                        >
                        <div className={`icon-help ${styles.CatIcon}`} />
                        <div className={styles.TabLabelText}>助けます</div>
                        <div className={currentTab === 'HELP' ? `${styles.TabBorder} ${styles.ActiveTab}` : styles.TabBorder}></div>
                    </div>
                    <div className={currentTab === 'TEACH' ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                        onClick={() => this.tabClikedHandler('TEACH')}
                        >
                        <div className={`icon-teach ${styles.CatIcon}`} />
                        <div>教えます</div>
                        <div className={currentTab === 'TEACH' ? `${styles.TabBorder} ${styles.ActiveTab}` : styles.TabBorder}></div>
                    </div>
                    <div className={currentTab === 'RENT' ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                        onClick={() => this.tabClikedHandler('RENT')}
                        >
                        <div className={`icon-rent ${styles.CatIcon}`} />
                        <div>貸します</div>
                        <div className={currentTab === 'RENT' ? `${styles.TabBorder} ${styles.ActiveTab}` : styles.TabBorder}></div>
                    </div>
                    <div className={currentTab === 'MAKE' ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                        onClick={() => this.tabClikedHandler('MAKE')}
                        >
                        <div className={`icon-make ${styles.CatIcon}`} />
                        <div>作ります</div>
                        <div className={currentTab === 'MAKE' ? `${styles.TabBorder} ${styles.ActiveTab}` : styles.TabBorder}></div>
                    </div>
                </div> 
                </Aux>
                }
                <div className={styles.HelpContent}>
                    {!isHeadless && 
                    <Aux>
                        <div className={styles.MobileOnly}>
                            <SideMenu
                                showSideMenu={showSideMenu} 
                                closed={this.sideMenuCloseHandler}
                                articleClickedHandler={this.articleClickedHandler}
                                currentMode={currentMode}
                                articleName={this.props.history.location.pathname}
                            />
                            <div className={styles.MenuToggleBtn}
                                onClick={this.sideMenuToggleHandler}>
                                <i className={`fa fa-caret-left ${styles.faiconVertical}`}></i>
                                <div className={styles.SelecterLabelText}>目次</div>
                            </div>
                        </div>
                        <div className={styles.DeskTopOnly}>
                            <SideMenuItems 
                                articleClickedHandler={this.articleClickedHandler}
                                currentMode={currentMode}
                                articleName={this.props.history.location.pathname}
                            />
                        </div>
                    </Aux>
                    }
                    <article className={`${styles.HelpArticle} ${articleMarginClass}`}>
                        <Article　/>
                        {!isHeadless && 
                        <div className={styles.PrevNextContainer}>
                            <p className={`${styles.Prev} ${disablePrev ? styles.Disable : null}`} onClick={this.clickedPrev}>前に</p>
                            <p className={`${styles.Next} ${disableNext ? styles.Disable : null}`} onClick={this.clickedNext}>次に</p>
                        </div>
                        }
                    </article>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode, showSideMenu } },
    local: {localCode},
    appData: {appData},
}) => ({
    currentTab, currentMode, showSideMenu,
    localCode, appData   
});

const mapDispatchToProps = dispatch => ({
    onToggleHelpMenu : (open) => dispatch(toggleHelpMenu(open)),
    onUpdateHelpMode : (mode) => dispatch(updateHelpMode(mode)),
    onUpdateHelpModeAndTab : (mode, tabLabel) => dispatch(updateHelpModeAndTab(mode, tabLabel)),
    onUpdateHelpTab : (tabLabel) => dispatch(updateHelpTab(tabLabel))
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);