import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import CheckSales1 from '../../../assets/images/help/selling/sell_sales/sell_sales_1.jpg';
import CheckSales2 from '../../../assets/images/help/selling/sell_sales/sell_sales_2.jpg';
import CheckSales3 from '../../../assets/images/help/selling/sell_sales/sell_sales_3.jpg';

class CheckSales extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>売り上げを確認する</span>
            </h3>
            <p className={styles.ArticleDesc}>
                サービス購入者の支払いが完了すると売り上げを確認できます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「売上」を選びます。     
                    </li>
                    <img className={styles.ScreenShot} src={CheckSales1} alt="売り上げを確認するステップ1画像" />
                    <li>
                        取引が表示されます。画面下部には、売上累計、送金可能額も確認できます。各取引の詳しい請求内容を見たい場合は確認したい取引をタップします。<br/>
                        <p className="Caption">＊売上金が銀行口座に送金可能になるまで、サービス購入者のお支払い確定から14日かかります。</p>
                    </li>
                    <img className={styles.ScreenShot} src={CheckSales2} alt="売り上げを確認するステップ2画像" />
                    <li>
                        購入者に送られた領収書が確認できます。      
                    </li>
                    <img className={styles.ScreenShot} src={CheckSales3} alt="売り上げを確認するステップ3画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(CheckSales);