import {
    DATE_SELECTED
} from '../actions/actionTypes';

const initState = {
    selectedDate: null,
};

const estimateReducer = (state=initState, action) => {
    const { type, payload } = action;
    switch(type){
        case DATE_SELECTED:{
            return{
                ...state,
                selectedDate: payload.SelDate,
                requestDateTime:null
            }
        }
        default:
            return state;
    }
}

export default estimateReducer;