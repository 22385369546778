import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import DealFlowImage from '../../../assets/images/help/deal_flow/deal_flow.svg';


class DealFlow extends Component {
    render() {
        return(
            <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>取引の流れ</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムの基本的な取引の流れは以下のように行われます。</p>
            <img className={styles.ImageWide} src={DealFlowImage} alt="売り手・買い手を選ぶ" />
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol>
                    <li>サービス利用者は見積もり、内容などを事前確認し、サービス提供者に購入申請をします。</li>
                    <li>サービス提供者は申請内容、スケジュールなどを確認して申請を受付けます。この段階で仮予約が作成されます。</li>
                    <li>サービス利用者は支払い方法の登録を行い、タグジャムはお支払いの審査を行います。</li>
                    <li>タグジャムは予約を確定して取引成立をサービス提供者・利用者に通知します。</li>
                    <li>サービス提供者は予定の日時にサービスを提供します。</li>
                    <li>サービス提供者は提供が終わるとサービス完了通知をタグジャムに送信します。</li>
                    <li>サービス利用者は請求書を受け取り、料金の支払いの旨をタグジャムに通知します。この通知により料金を支払うことを承諾した事になり、タグジャムは事前に登録していただいた支払い方法で決済をし支払いを預かります。</li>
                    <li>サービス提供者は決済の14日/30日後<span className={styles.WaringNote}>*1</span>にタグジャムに銀行振込申請をする事ができ、タグジャムは残金（売上相当額　ー（手数料15％）ー　振込手数料350円/500円<span className={styles.WaringNote}>*2</span>）を事前に登録して頂いた銀行に振込みをします。</li>
                </ol>
                <p className={styles.WaringNote}>*1. VISA、MASTERカードでの支払いの場合は14日、JCB, Diners Club, Discoverカードでの支払いの場合は30日後に振込可能になります。</p>
                <p className={styles.WaringNote}>*2. 送金額が１万円未満の場合500円、送金額が１万円以上の場合350円の送金手数料がかかります。</p>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(DealFlow);