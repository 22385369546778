import React from 'react';
import Aux from '../../../hoc/Aux/Aux';
import styles from './SideMenuItems.module.css';

const accordionClickedHandler = (e, sub, section) => {
    let activeClass = sub ? styles.SubActive : styles.Active;
    e.target.classList.toggle(activeClass);
    let panel = e.target.nextElementSibling;
    let parent = sub ? e.target.parentElement : null;
    if (panel.style.maxHeight) {
        if(parent){
            let parentH = parseInt(parent.style.maxHeight.replace("px", ""));
            parentH = parentH - parseInt(panel.style.maxHeight.replace("px", ""));
            parent.style.maxHeight = parentH + "px";
        }
        panel.style.maxHeight = null;
    } else {
        if(parent){
            let parentH = parseInt(parent.style.maxHeight.replace("px", ""));
            parentH = parentH + panel.scrollHeight;
            parent.style.maxHeight = parentH + "px";
        }
        panel.style.maxHeight = panel.scrollHeight + "px";
    }
}

const SideMenuItems = (props) => {
    const {articleClickedHandler, currentMode, articleName } = props;
    const ArticleId = articleName ? articleName.replace("/help/", "") : "";
    return(
    <aside className={styles.HelpMenu}>
        <h3 className={styles.SecTile}>初めての方へ</h3>
        <ul>
            <li className={ArticleId === 'WhatTaguJam' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('WhatTaguJam')} }>
                タグジャムとは？
            </li>
            <li className={ArticleId === 'DealFlow' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('DealFlow')} }>
                取引の流れ
            </li>
            <li className={ArticleId === 'Fee' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('Fee')} }>
                利用料金
            </li>
            <li className={ArticleId === 'SafeEffort' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('SafeEffort')} }>
                安全性への取組
            </li>
            <li className={ArticleId === 'DealConfirm' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('DealConfirm')} }>
                取引成立につて
            </li>
            <li className={ArticleId === 'PayFee' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('PayFee')} }>
                サービス料の支払い
            </li>
            <li className={`${styles.Last} ${ArticleId === 'BasicManner' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('BasicManner')} }>
                基本マナー
            </li>
        </ul>
        <h3 className={styles.SecTile}>始めてみよう</h3>
        <ul>
            <li className={ArticleId === 'BecomeMember' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('BecomeMember')} }>
                新規会員になる
            </li>
            <li className={ArticleId === 'AccountInfo' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('AccountInfo')} }>
                アカウント情報
            </li>
            <li className={ArticleId === 'UserInfo' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('UserInfo')} }>
                ユーザー情報
            </li>
            <li className={ArticleId === 'EmailConfirmation1' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('EmailConfirmation1')} }>
                Eメールアドレスの確認
            </li>
            <li className={ArticleId === 'AddressRegistration' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('AddressRegistration')} }>
                住所の登録
            </li>
            <li className={`${styles.Last} ${ArticleId === 'IdConfirmation1' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('IdConfirmation1')} }>
                本人確認
            </li>
        </ul>
        {currentMode==="SELL" &&
        <Aux>
        <h3 className={styles.SecTile}>サービス提供者の方へ</h3>
        <ul>
            <li onClick={(e) => { accordionClickedHandler(e, false, 'SELL-PREP') }} className={styles.Accordion}>準備</li>
            <ul className={styles.Panel}>
                <li className={ArticleId === 'IdConfirmation2' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('IdConfirmation2')} }>
                    本人確認
                </li>
                <li className={ArticleId === 'EmailConfirmation2' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('EmailConfirmation2')} }>
                    Eメールアドレスの確認
                </li>
                <li className={ArticleId === 'RegisterBank' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('RegisterBank')} }>
                    銀行の登録
                </li>
                <li className={ArticleId === 'Sell' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('Sell')} }>
                    出品する
                </li>
                <li className={ArticleId === 'SubmitDocs' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('SubmitDocs')} }>
                    必要書類を提出する
                </li>
                <li onClick={(e) => { accordionClickedHandler(e, true, 'SELL-RULES') }} className={styles.SubAccordion}>出品のルール</li>
                <div className={styles.SubPanel}>
                    <li className={ArticleId === 'BannedBehaivors' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('BannedBehaivors')} }>
                        禁止行為
                    </li>
                    <li className={ArticleId === 'BannedItems' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('BannedItems')} }>
                        出品禁止サービス
                    </li>
                    <li className={ArticleId === 'MannerForSeller' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('MannerForSeller')} }>
                        出品時のマナー
                    </li>
                </div>
            </ul>
            <li onClick={(e) => { accordionClickedHandler(e, false, 'SELL-DEAL') }} className={styles.Accordion}>取引を始める</li>
            <ul className={styles.Panel}>
                <li className={ArticleId === 'AnswerQuestion' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('AnswerQuestion')} }>
                    質問に返答する
                </li>
                <li className={ArticleId === 'AcceptInterview' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('AcceptInterview')} }>
                    面接申請を受け付ける
                </li>
                <li className={ArticleId === 'AcceptOrder' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('AcceptOrder')} }>
                    申請を受け付ける
                </li>
                <li className={ArticleId === 'SellerSchedule' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('SellerSchedule')} }>
                    スケジュールを確認する
                </li>
            </ul>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'SellerRequestDetails' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('SellerRequestDetails')} }>
                依頼内容詳細の確認
            </li>
            <li onClick={(e) => { accordionClickedHandler(e, false, 'SELL-SERVICE') }} className={styles.Accordion}>サービスを提供する</li>
            <ul className={styles.Panel}>
                <li className={ArticleId === 'SellerStartService' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('SellerStartService')} }>
                    サービス提供の開始
                </li>
                <li className={ArticleId === 'SellerFinishService' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('SellerFinishService')} }>
                    サービス提供の完了
                </li>
            </ul>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'SendInvoice' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('SendInvoice')} }>
                請求書の作成と送信
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'ResendInvoice' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('ResendInvoice')} }>
                支払い保留時の請求書の再送信
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'CheckSales' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('CheckSales')} }>
                売り上げを確認する
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'SendMoney' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('SendMoney')} }>
                売上金の振込依頼をする
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'EvaluateBuyer' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('EvaluateBuyer')} }>
                購入者を評価する
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'SellerChangeRequest' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('SellerChangeRequest')} }>
                依頼内容を変更する
            </li>
            <li className={`${styles.AccordionTitle} ${styles.Last} ${ArticleId === 'SellerCancel' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('SellerCancel')} }>
                キャンセルする
            </li>
        </ul>
        </Aux>
        }
        {currentMode==="BUY" &&
        <Aux>
        <h3 className={styles.SecTile}>サービス購入者の方へ</h3>
        <ul>
            <li onClick={(e) => { accordionClickedHandler(e, false, 'BUY-PREP') }} className={styles.Accordion}>準備</li>
            <ul className={styles.Panel}>
                <li className={ArticleId === 'IdConfirmation3' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('IdConfirmation3')} }>
                    本人確認
                </li>
                <li className={ArticleId === 'EmailConfirmation3' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('EmailConfirmation3')} }>
                    Eメールアドレスの確認
                </li>
                <li className={ArticleId === 'ServiceSubject' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('ServiceSubject')} }>
                    サービス対象者の事前登録
                </li>
                <li className={ArticleId === 'ServiceAddress' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('ServiceAddress')} }>
                    サービス住所の事前登録
                </li>
                <li className={ArticleId === 'Payment' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('Payment')} }>
                    支払い情報の事前登録
                </li>
                <li className={ArticleId === 'MannerForBuyer' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('MannerForBuyer')} }>
                    購入時のマナー
                </li>
            </ul>
            <li onClick={(e) => { accordionClickedHandler(e, false, 'BUY-DEAL') }} className={styles.Accordion}>取引を始める</li>
            <ul className={styles.Panel}>
                <li className={ArticleId === 'SendQuestion' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('SendQuestion')} }>
                    サービス提供者に質問する
                </li>
                <li className={ArticleId === 'RequestInterview' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('RequestInterview')} }>
                    サービス提供者に面接申請する
                </li>
                <li className={ArticleId === 'RequestPurchase' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('RequestPurchase')} }>
                    購入を申請する
                </li>
                <li className={ArticleId === 'RegisterPayment' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('RegisterPayment')} }>
                    支払い方法の登録
                </li>
                <li className={ArticleId === 'BuyerSchedule' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('BuyerSchedule')} }>
                    スケジュールを確認する
                </li>
            </ul>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'BuyerRequestDetails' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('BuyerRequestDetails')} }>
                依頼内容詳細の入力
            </li>
            <li onClick={(e) => { accordionClickedHandler(e, false, 'BUY-SERVICE') }} className={styles.Accordion}>サービス提供を受ける</li>
            <ul className={styles.Panel}>
                <li className={ArticleId === 'BuyerStartService' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('BuyerStartService')} }>
                    サービス提供の開始
                </li>
                <li className={ArticleId === 'BuyerFinishService' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('BuyerFinishService')} }>
                    サービス提供の完了
                </li>
            </ul>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'CheckInvoicePay' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('CheckInvoicePay')} }>
                請求書の確認と支払い
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'PausePayment' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('PausePayment')} }>
                請求金額に疑問がある場合
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'PurchaseHistory' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('PurchaseHistory')} }>
                購入履歴の確認
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'EvaluateSeller' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('EvaluateSeller')} }>
                提供者を評価する
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'BuyerChangeRequest' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('BuyerChangeRequest')} }>
                依頼内容を変更する
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'BuyerCancel' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('BuyerCancel')} }>
                キャンセルする
            </li>
            <li className={`${styles.AccordionTitle} ${styles.Last} ${ArticleId === 'TransactionTermsForBuyer' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('TransactionTermsForBuyer')} }>
                売買取引・契約の規約
            </li>
        </ul> 
        </Aux>
        } 
        <h3 className={styles.SecTile}>その他</h3>
        <ul> 
            <li onClick={(e) => { accordionClickedHandler(e, false, 'REPORT-PROBLEM') }} className={styles.Accordion}>問題を報告する</li>
            <ul className={`${styles.Panel} ${styles.Last}`}>
                <li className={ArticleId === 'ReportService' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('ReportService')} }>
                    不適切なサービスの報告
                </li>
                <li className={ArticleId === 'ReportUser' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('ReportUser')} }>
                    不適切なユーザーの報告
                </li>
                <li className={ArticleId === 'BlockUser' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('BlockUser')} }>
                    特定のユーザーをブロックする
                </li>
                <li className={ArticleId === 'ReportDeal' ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('ReportDeal')} }>
                    取引の問題を報告する
                </li>
            </ul>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'CustomerSupport' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('CustomerSupport')} }>
                カスタマーサポート
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'CertChart' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('CertChart')} }>
                必要書類一覧
            </li>
            <li className={`${styles.AccordionTitle} ${ArticleId === 'WhatCreditAuthorization' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('WhatCreditAuthorization')} }>
                支払い審査とは
            </li>
            <li className={`${styles.AccordionTitle} ${styles.Last} ${ArticleId === 'WhatDeposit' ? styles.MenuActive : ""}`} onClick={(e) =>{ articleClickedHandler('WhatDeposit')} }>
                保証金とは
            </li>
        </ul>  
    </aside>
    );
}

export default SideMenuItems;