import React from 'react';
import styles from './Avator.module.scss';
import UserIcon from '../../../assets/images/userIcon.svg';
import TaguJamLogo from '../../../assets/images/tagujam_logo.svg';

const Avator = (props) => {
    const { avator, size, alt, tagujam, bgLight } = props;
    let sizeStyle = { width: `${size}px`, height: `${size}px`, borderRadius:'50%'};
    if(avator) {
        return(
        <img src={avator} className={styles.Avator} style={sizeStyle} alt={alt} />
        );
    } else {
        return(
        <div className={bgLight ? `${styles.Avator} ${styles.LightBG}` : styles.Avator } style={sizeStyle}>
            <img src={tagujam ? TaguJamLogo : UserIcon} className={styles.UserIcon} alt={alt} />
        </div>
        );
    }
}

export default Avator;