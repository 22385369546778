import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class CertChart extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>必要書類一覧</span>
            </h3>
            <div className="Item">
                <p>タグジャムでは、会員登録の際に、全ての会員に本人確認が取れる書類・証明書の画像を提出して頂きます。</p>
                <p>尚、特定の項目への出店は以下の表に示す、必要資格・届出書の画像も提出もして頂きます。タグジャム事務局で確認が完了した時点でタグジャムでの取引を開始できます。</p>
            </div>
            <div className="Item">
                <h5>本人確認</h5>
                <dl className="Table">
                    <dt>
                        <strong>本人確認</strong>
                    </dt>
                    <dd>
                        マイナンバーカード、運転免許証、運転経歴書、健康保険証、パスポートのいずれか一つ
                    </dd>
                </dl>

                <h5 className={styles.HeaderMargin20}>必要資格・届出書一覧</h5>
                <dl className="Table">
                    <dt>
                        <strong>保育</strong><br/>
                        <strong>子供送迎</strong>
                    </dt>
                    <dd>
                        保育士、看護師、チャイルドマインダー、認定ベビーシッターのいずれかの資格、<br/>
                        及び自治体への届け出
                    </dd>
                    <dt>
                        <strong>シニア見守り</strong><br/>
                        <strong>シニア同行付き添い</strong>
                    </dt>
                    <dd>
                        介護福祉士、理学療法士、作業療法士、看護師、柔道整復師、医師<br/>
                        のいずれかの資格
                    </dd>
                    <dt>
                        <strong>ペットケア</strong>
                    </dt>
                    <dd>
                        愛玩動物飼養管理士、家庭犬訓練士、認定動物看護師、トリマー資格、認定ペットシッター資格、ペットシッター士資格、獣医師のいずれかの資格、<br/>
                        及び第一種動物取扱業登録証
                    </dd>
                    <dt>
                        <strong>ヘアケア</strong>
                    </dt>
                    <dd>
                        理容師、美容師のいずれかの資格、<br/>
                        及び保健所への届出
                    </dd>
                    <dt>
                        <strong>ネイル</strong><br/>
                        <strong>エステ</strong><br/>
                        <strong>リラクゼーション</strong>
                    </dt>
                    <dd>
                        ネイル・リラクゼーション・エステティシャンのコース修了証明書の提出が望ましい、又は職務経歴書などプロとして活動していた経験を証明する書類
                    </dd>
                </dl>
            </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(CertChart);