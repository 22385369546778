import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styles from './Blog.module.scss';
import Aux from '../../hoc/Aux/Aux';
import TaguJamLogo_white from '../../assets/images/tagujam_logo_white.svg';
import TaguJamTypeface_white from '../../assets/images/tagujam_typeface_white.svg';
import Menu from '../../components/Blog/Menu/Menu';
import BlogHome from './BlogHome/BlogHome';
import HowToStart from './HowToStart/HowToStart';
import Examples from './Examples/Examples';
import Features from './Features/Features';
import HowToVideos from './HowToVideos/HowToVideos';
import Article from './Article/Article';
import TaggedArticles from './TaggedArticles/TaggedArticles';
import {
  Spinner
} from '../../components/UI'
import {
  fetchBlog
} from '../../store/actions'

class About extends Component {

    componentDidMount() {
      const {
        onFetchBlog,
      } = this.props;
      onFetchBlog();
    }

    getImagePath = () => {
      let coverImagePath = null;
      const { 
        blog,
        location
      } = this.props;
      const {pathname} = location;
      let pathnameArray = pathname.split('/');
      if(blog && pathnameArray.length > 3 && pathnameArray[2]==='Article'){
        const {articles} = blog;
        const theArticle = articles[pathnameArray[3]] 
        ? articles[pathnameArray[3]]
        : null;
        coverImagePath = theArticle && theArticle.coverImagePath ? theArticle.coverImagePath : null;
      }
      return coverImagePath
    }

    render(){
      const { 
        appData,
        localCode,
        blog, blogIsLoading, blogError,
      } = this.props;
      const { LocalStrings, BlogMenuItems } = appData;
      
      let content = null;
      let coverImagePath = null;

      if(blogIsLoading){
        content = <div className="SpinnerBox">
          <Spinner large />
        </div>
      } else if(blogError){
        content = <p>{LocalStrings[localCode].DATA_LOADING_ERROR}</p>;
      } else {
        if(BlogMenuItems && blog){
          // /blog/Article/1626744025844
          coverImagePath = this.getImagePath();
          content = <Switch>
          <Route path="/blog" exact component={BlogHome} />
          <Route path="/blog/HowToStart" exact component={HowToStart} />
          <Route path="/blog/Examples" exact component={Examples} />
          <Route path="/blog/Features" exact component={Features} />
          <Route path="/blog/HowToVideos" exact component={HowToVideos} />
          <Route path="/blog/Article/:id" exact component={Article} />
          <Route path="/blog/taggedArticles/:tag" exact component={TaggedArticles} />
          <Redirect to="/blog" />
      </Switch>;
        } else {
          content = <p>現在掲載記事がありません。</p>  
        }
      }
      return(
      <Aux>
        <div className={styles.BlogHeader}>
          {coverImagePath== null && 
          <div className={styles.HeroImage}>
              <div className={styles.HeroText}>
                <div className={styles.BrandBox}>
                  <div className={styles.LogoWithTypeFace}>
                      <img className={styles.Logo} src={TaguJamLogo_white} alt="TaguJam タグジャム　ロゴ" />
                      <img className={styles.LogoTypeFace} src={TaguJamTypeface_white} alt="TaguJam タグジャム　タイプファエイス" />
                  </div>
                </div>
                <h1 className={styles.TagLine}>
                  あなたの「一歩踏み出す」<br />を応援します
                </h1>
              </div>
          </div>
          }
          { coverImagePath && 
          <div className={styles.HeroImage} style={{'backgroundImage': `url(${coverImagePath})`}} />
          }
          <Menu 
            sectionPath={this.props.history.location.pathname}
          />
        </div>
        <div className={styles.BlogMainContent}>
        {content}
        </div>
        <div className={styles.Banner}>
          <h1 className={styles.BannerText}>地域の「ありがとう」<br />を繋ぐコミュニティー</h1>
        </div>
      </Aux>
      );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
  blog: { blog, blogIsLoading, blogError }
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError,
  blog, blogIsLoading, blogError,
});

const mapDispatchToProps = dispatch => ({
  onFetchBlog : () => dispatch(fetchBlog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(About);