import {
    FETCH_CERTIFICATE,
    SET_CERTIFICATE,
    SET_THE_CERTIFICATE
} from '../actions/actionTypes';

const initState = {
    certificate: null,
    certificateIsLoading: false,
    certificateError: false
};

const certificateReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_CERTIFICATE:
            return {
                ...state,
                certificate: payload
            }
        case SET_THE_CERTIFICATE:
            return {
                ...state,
                certificate: {
                    ...state.certificate,
                    [payload.type]: payload.certificate
                }
            }
        case `${FETCH_CERTIFICATE}_PENDING`:
            return {
                ...state,
                certificateIsLoading: true,
                certificateError: false,
            }
        case `${FETCH_CERTIFICATE}_FULFILLED`:
            return {
                ...state,
                certificate: payload,
                certificateIsLoading: false,
                certificateError: false,
            }
        case `${FETCH_CERTIFICATE}_REJECTED`:
            return {
                ...state,
                certificate: null,
                certificateIsLoading: false,
                certificateError: true,
            }
        default:
            return state;
    }
};

export default certificateReducer;
