import {
    FETCH_THE_SALES,
} from './actionTypes';

import { 
    authGetToken
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchTheSalePending = () => ({
    type: `${FETCH_THE_SALES}_PENDING`
});

export const fetchTheSaleRejected = (err) => ({
    type: `${FETCH_THE_SALES}_REJECTED`,
    payload: err
});

export const fetchTheSaleCustomerFulFilled = (theSale) => ({
    type: `${FETCH_THE_SALES}_CUSTOMER_FULFILLED`,
    payload: theSale
});

export const fetchTheSaleProviderFulFilled = (theSale) => ({
    type: `${FETCH_THE_SALES}_PROVIDER_FULFILLED`,
    payload: theSale
});

export const fetchTheSale = (customerId, providerId) => {
    return async dispatch => {
        dispatch(fetchTheSalePending());
        try{
            let token = await dispatch(authGetToken()); 
            let theSale = await taguJamApi.fetchTheSale(customerId, providerId, token);
            if(customerId){
                dispatch(fetchTheSaleCustomerFulFilled(theSale))
            } else {
                dispatch(fetchTheSaleProviderFulFilled(theSale))
            }
            return theSale;
        } catch(err){
            dispatch(fetchTheSaleRejected(err));
            throw err
        }
    }   
};
