import React from 'react';
import styles from './SegmentControl.module.scss';

const SegmentControl = (props) => {

    const { data, TextStyle, selectedIndex, localCode, onChange, appData } = props;
    const { LocalStrings } = appData;

    const getBtnClass = (i) =>{
        if(i===0){
            return styles.SegLeftBtn;
        } else {
            if(i===data.length-1){
                return styles.SegRightBtn;
            } else {
                return styles.SegMiddleBtn;
            }
        }
    }

    const getMiddelBorder = (i, foreColor, backColor) => {
        if(i===0 || i===data.length-1){
            return null;
        } else {
            return {
                borderBottomColor:foreColor,
                borderTopColor:foreColor
            }
        }    
    }
    
    // eslint-disable-next-line no-unused-vars
    const btnPressed = (event, i) => {
        event.preventDefault();
        onChange(data[i].value);
    }

    return(
        <div style={{display:'flex', flexDirection:'row', flex:1}}>
        { data.map( (item, i) => {
            const selected = i === selectedIndex ? true : false;
            const foreColor = selected ? "#ffffff" : "#41BEEB";//Colors.primaryButtonTextColor : Colors.primaryButtonBGColor;
            const backColor = selected ? "#41BEEB" : "#ffffff"; //Colors.primaryButtonBGColor : Colors.white;
            const border = selected ? 0 : '1.5px';
            let content = (
                <div style={{display:'flex', flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                    <div className={`fa fa-${item.icon}`} style={{color:foreColor}} />
                    <p className={TextStyle.BodyText} style={{paddingLeft:5, color:foreColor}}>
                        {LocalStrings[localCode][item.label]}
                    </p>
                </div>
            );
            if(onChange){
                return (
                    <div
                        key={`segment-${i}`}
                        //onClick={(event) => {btnPressed(event, i)} }
                        className={getBtnClass(i)}
                        style={{
                            backgroundColor:backColor, 
                            border: `${border} solid ${foreColor}`, 
                            ...getMiddelBorder(i, foreColor, backColor) 
                        }}> 
                        {content}
                    </div>    
                )
            } else {
                return (
                <div
                    key={`segment-${i}`}
                    className={getBtnClass(i)}
                    style={{
                        backgroundColor:backColor, 
                        border: `${border} solid ${foreColor}`,
                        ...getMiddelBorder(i, foreColor, backColor)
                    }}> 
                    {content}
                </div>   
                )        
            }
            
        }) }
        </div>
    );   
}

export default SegmentControl;