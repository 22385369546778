import React from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import {isIOS, isAndroid} from 'react-device-detect';
import styles from './Menu.module.scss';
import Blue_line from '../../../assets/images/blue_line.svg';
import Gray_line from '../../../assets/images/gray_line.svg';

const getPlatform = () => {
  let platform = 'other';
  if (isIOS) {
    platform ='ios';  
  }
  if (isAndroid) {
    platform ='android';  
  }
  return platform;
}

const Menu = (props) => {
    const platform = getPlatform();
    const {sectionPath, smartBannerShow} = props;
    return(
      <ul className={platform!=='other' && smartBannerShow ? `${styles.MenuContainer} ${styles.MenuContainerWithBanner}` : styles.MenuContainer}>
        <NavLink to="/about" className={styles.MenuItem}>
          <li className={sectionPath==='/about' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            会社概要 
          </li>
          {sectionPath==='/about' &&
            <img className={styles.MenuBorder} src={Blue_line} alt="menu border" />
          }
          {sectionPath!=='/about' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink>
        <NavLink to="/about/mission" className={styles.MenuItem}>
          <li className={sectionPath==='/about/mission' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            私たちの思い 
          </li>
          {sectionPath==='/about/mission' &&
            <img className={styles.MenuBorder} src={Blue_line} alt="menu border" />
          }
          {sectionPath!=='/about/mission' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink>
        <NavLink to="/about/team" className={styles.MenuItem}>
          <li className={sectionPath==='/about/team' ? `${styles.MenuLink} ${styles.Active}` : styles.MenuLink}>
            運営チーム 
          </li>
          {sectionPath==='/about/team' &&
            <img className={styles.MenuBorder} src={Blue_line} alt="menu border" />
          }
          {sectionPath!=='/about/team' &&
            <img className={styles.MenuBorder} src={Gray_line} alt="menu border" />
          }
        </NavLink>
      </ul>
    );
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
  ui:{ smartBannerShow },
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError,
  smartBannerShow 
});

export default connect(mapStateToProps, null)(Menu);