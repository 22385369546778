import React from 'react';
import styles from './Toolbar.module.css';
import NavigationItems from '../NavigationItems';
import LogoWithTypeFace from '../../Brand/LogoWithTypeFace/LogoWithTypeFace';
import ToggleMenu from '../SideDrawer/ToggleMenu/ToggleMenu';

const toolbar = props => (
    <header className={styles.Toolbar}>
        <ToggleMenu clicked={props.drawerToggleClicked} />
        <div className={styles.Brand}><LogoWithTypeFace /></div>
        <nav className={styles.DesktopOnly}>
            <NavigationItems 
                onToggleAuthWinOpen={props.onToggleAuthWinOpen}
                onInitAuthUiState={props.onInitAuthUiState}
                onLogout={props.onLogout}
                uiAuth={props.uiAuth}
                authInfo={props.authInfo}
                appData={props.appData}
                localCode={props.localCode}  
                history={props.history}
                />   
        </nav>
    </header>
);

export default toolbar;