import React from 'react';
import SearchTagButton from '../../SearchTagButton/SearchTagButton';
import Aux from '../../../hoc/Aux/Aux';

const SearchTags = (props) => {
  const {appData, localCode, onClick} = props;
  return(
    <Aux>
      {appData && Object.keys(appData.SubCatItems).map( (MainCat, index) => {
        return(
        <Aux key={`${MainCat}_index`}>
        {appData.SubCatItems[MainCat].map( submenu => {
            if(submenu!=='OTHERS' && submenu!=='ALL'){
              return(
                <SearchTagButton 
                  SubCat={submenu}
                  selectedSubMenu={null}
                  localCode={localCode}
                  key={submenu}
                  round={true}
                  onClick={() => onClick(submenu) }
                  appData={appData}
                />
                );
            } else {
              return null;
            }
          })
        }
        </Aux> 
        );
      })}
    </Aux>
  );
}

export default SearchTags;