import {
    FETCH_PAYMENT,
    SET_PAYMENT
} from '../actions/actionTypes';

const initState = {
    payment: null,
    paymentIsLoading: false,
    paymentError: false
};

const paymentReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_PAYMENT:{
            return {
                ...state,
                payment: payload
            }
        } 
        case `${FETCH_PAYMENT}_PENDING`:{
            return {
                ...state,
                paymentIsLoading: true,
                paymentError: false,
            }
        }
        case `${FETCH_PAYMENT}_FULFILLED`:{
            return {
                ...state,
                payment: payload,
                paymentIsLoading: false,
                paymentError: false,
            }
        }
        case `${FETCH_PAYMENT}_REJECTED`:{
            return {
                ...state,
                payment: null,
                paymentIsLoading: false,
                paymentError: true,
            }
        }
        default:
            return state;
    }
};

export default paymentReducer;
