const stateKana = {
  北海道: 'ほっかいどう',
  青森県: 'あおもりけん',
  岩手県: 'いわてけん',
  宮城県: 'みやぎけん',
  秋田県: 'あきたけん',
  山形県: 'やまがたけん',
  福島県: 'ふくしまけん',
  茨城県: 'いばらきけん',
  栃木県: 'とちぎけん',
  群馬県: 'ぐんまけん',
  埼玉県: 'さいたまけん',
  千葉県: 'ちばけん',
  東京都: 'とうきょうと',
  神奈川県: 'かながわけん',
  新潟県: 'にいがたけん',
  富山県: 'とやまけん',
  石川県: 'いしかわけん',
  福井県: 'ふくいけん',
  山梨県: 'やまなしけん',
  長野県: 'ながのけん',
  岐阜県: 'ぎふけん',
  静岡県: 'しずおかけん',
  愛知県: 'あいちけん',
  三重県: 'みえけん',
  滋賀県: 'しがけん',
  京都府: 'きょうとふ',
  大阪府: 'おおさかふ',
  兵庫県: 'ひょうごけん',
  奈良県: 'ならけん',
  和歌山県: 'わやかまけん',
  鳥取県: 'とっとりけん',
  島根県: 'しまねけん',
  岡山県: 'おかやまけん',
  広島県: 'ひろしまけん',
  山口県: 'やまぐちけん',
  徳島県: 'とくしまけん',
  香川県: 'かがわけん',
  愛媛県: 'えひめけん',
  高知県: 'こうちけん',
  福岡県: 'ふくおかけん',
  佐賀県: 'さがけん',
  長崎県: 'ながさきけん',
  熊本県: 'くまもとけん',
  大分県: 'おおいたけん',
  宮崎県: 'みやざきけん',
  鹿児島県: 'かごしまけん',
  沖縄県: 'おきなわけん'
};

export default class GoogleMapApi {
    constructor(){
        this.heartrailsURL = process.env.REACT_APP_HEARTRAILS_URL;
    }
    
    getLangCode = (localCode) => {
        switch (localCode) {
            case 'ja_jp':
                return 'ja';
            case 'en_jp':
                return 'en';
            default :
                return 'ja';
        }
    }

    normalizeAddressData = (data, formatted_address) => {
        let tempAddress = {};
        data.map(item => {
            if(item.types.includes("sublocality_level_1")) {
                tempAddress["sublocality_level_1"] = item.long_name; 
            } else if(item.types.includes("sublocality_level_2")) {
                tempAddress["sublocality_level_2"] = item.long_name;         
            } else if(item.types.includes("locality")) {
                tempAddress["locality"] = item.long_name;     
            } else if(item.types.includes("administrative_area_level_1")) {
                tempAddress["administrative_area_level_1"] = item.long_name;    
            } else if(item.types.includes("administrative_area_level_2")) {
                tempAddress["administrative_area_level_2"] = item.long_name;    
            } else if(item.types.includes("country")) {
                tempAddress["country"] = item.long_name;
            } else if(item.types.includes("postal_code")){
                tempAddress["postal_code"] = item.long_name;
            }
            return true;
        });
        //city:[市、区、郡、村], town:[町, 区の場合+丁目], street1[丁目&番地], street2[建物、ビル]
        let address = {city:"", town:"", street1:"", street2:""};
        address["postal_code"] = tempAddress.postal_code;
        address["country"] = tempAddress.country;
        address["state"] = tempAddress.administrative_area_level_1;

        if(tempAddress.administrative_area_level_2){
            address["city"] = tempAddress.administrative_area_level_2 + tempAddress.locality;
        } else {
            address["city"] = tempAddress.locality;
        }

        if(tempAddress.sublocality_level_1){
            address["town"] = tempAddress.sublocality_level_1 + tempAddress.sublocality_level_2;
        } else {
            address["town"] = tempAddress.sublocality_level_2;    
        }

        let addressLineArray = formatted_address.split(" ");
        address["formatted_address"] = addressLineArray[1];
        const genericLine = `${address.state}${address.city}${address.town}`;
        address["street1"] = addressLineArray[1].replace(genericLine, "");
        if(addressLineArray[2]){
            address["street2"] = addressLineArray[2];
            address["formatted_address"] += ` ${addressLineArray[2]}`;
        }
        
        return address;
        // return null;
    }

    radians = (deg) => {
        return deg * Math.PI / 180;
    }

    getDistance = (lat1, lng1, lat2, lng2) => {
        return 6378.14 * Math.acos(Math.cos(this.radians(lat1))* 
         Math.cos(this.radians(lat2))*
         Math.cos(this.radians(lng2)-this.radians(lng1))+
         Math.sin(this.radians(lat1))*
         Math.sin(this.radians(lat2)));
    }

    withinArea = (address, latitude, longitude, radius) => {
        const { x, y } = address;
        let distance = this.getDistance(y, x, latitude, longitude);
        return distance <= radius ? true: false
    }

    async getTownListForArea (centerAddress, radius) {
        const { city, state, latitude, longitude } = centerAddress;
        try {
            let res = await fetch(`${this.heartrailsURL}/json?method=getTowns&city=${city}`);
            let data = await res.json();
            let addressList = data.response.location;
            let townList = [];
            addressList.map( address => {
                if(address.prefecture === state && this.withinArea(address, latitude, longitude, radius) ){
                    townList.push(address.town);
                }
                return true;
            });
            return townList; 
        } catch(err) {
            console.log(err)
            throw err;
        }    
    }

    async getAddressFromZip (zipcode, localCode) {
        let zip = zipcode.replace("-", "");
        try {
            let res = await fetch(`${this.heartrailsURL}/json?method=searchByPostal&postal=${zip}`);
            let data = await res.json();
            if(data && data.response && data.response.location){
                let address = data.response.location[0];
                const cleanAddress = {
                    state: address.prefecture,
                    state_kana: stateKana[address.prefecture],
                    city: address.city,
                    city_kana: address.city_kana,
                    town: address.town,
                    town_kana: address.town_kana,
                    latitude: address.y,
                    longitude: address.x
                };
                return cleanAddress;
            } else{
                return null;
            } 
        } catch(err) {
            console.log(err)
            throw err;
        }
    }
    
    async getAddress (coords, localCode) {
        const { latitude, longitude} = coords;
        try {
            let res = await fetch(`${this.heartrailsURL}/json?method=searchByGeoLocation&x=${longitude}&y=${latitude}`);
            let data = await res.json();
            let address = null;
            if(data.response.location){
                let address = data.response.location[0];
                const cleanAddress = {
                    state: address.prefecture,
                    state_kana: stateKana[address.prefecture],
                    city: address.city,
                    city_kana: address.city_kana,
                    town: address.town,
                    town_kana: address.town_kana,
                    latitude: address.y,
                    longitude: address.x
                };
                return cleanAddress;
            } else {
                return address 
            } 
        } catch(err) {
            console.log(err)
            throw err;
        }
    }
}


