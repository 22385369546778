import React, { Component } from 'react';
import styles from "./FeatruedList.module.scss";
import GigThumbnail from '../../GigThumbnail/GigThumbnail';
import Aux from '../../../hoc/Aux/Aux';


class FeaturedList extends Component {
    constructor(props) {
        super(props);
        this.Positions=['0px'];
        for(let i= 1; i < 10; i++){
            // width: 80px + left-margin: 15px * i:2
            this.Positions.push(`-${i * 95}px`);
        }
    }

    state = {
        HELP: {
            current: 0
        },
        TEACH: {
            current: 0
        },
        RENT: {
            current: 0
        },
        MAKE: {
            current: 0
        } 
    }

    renderGigThumbnailList(GigDataForMainCat) {
        const { localCode, onPressGig, appData} = this.props;
        return GigDataForMainCat.map( (GigItem, index) => {
            return(
                <GigThumbnail
                    key={GigItem.id}
                    localCode={localCode}
                    firstItem={index===0 ? true : false}
                    GigItem={GigItem}
                    onPressGig={onPressGig}
                    appData={appData}
                />
            );
        });
    }
    
    arrowClickHandler = (event, direction, MainCat) => {
        event.preventDefault();
        console.log(direction);
        if(direction === 'prev'){
            if(this.state[MainCat].current === 0 ){
                return;
            }
            this.setState( prevState => ({
                ...prevState,
                [MainCat]:{
                    ...prevState[MainCat],
                    current: prevState[MainCat].current-1
                }
            }),
            ()=> {
                this.updateDomPosition(this.state[MainCat].current, MainCat);
            });
        } else {
            if(this.state[MainCat].current === 9 ){
                return;
            }
            this.setState( prevState => ({
                ...prevState,
                [MainCat]:{
                    ...prevState[MainCat],
                    current: prevState[MainCat].current+1
                }
            }),
            ()=> {
                this.updateDomPosition(this.state[MainCat].current, MainCat);
            });
        }  
    }

    updateDomPosition = (num, MainCat) => {
        document.getElementById(`GigThumbnailView_${MainCat}`).style.left = this.Positions[num];
    }

    render(){
        const { MainCatItems, localCode, onCategoryPress, appData, GigDataList } = this.props;
        const { LocalStrings } = appData;
        return (
            MainCatItems.map( (MainCat, index)=>{
                const GigDataForMainCat = GigDataList.filter(gig => { return gig.MainCat === MainCat});
                if(GigDataForMainCat.length > 0){
                    return (
                    <Aux key={MainCat}>
                        <div className={styles.MainCatTitleContainer}>
                            <div style={{ flex: 2}} onClick={()=> onCategoryPress(MainCat)}>
                                <p className={styles.CatTitleText}>
                                    <span className={styles.CatTextBold}>
                                        {LocalStrings[localCode][MainCat]}
                                    </span> 
                                    {LocalStrings[localCode].NEW_ARRIVAL}
                                </p>
                            </div>
                            <div style={{ flex: 1, display:'flex', flexDirection:"column", alignItems:'flex-end'}}>
                                <div
                                    className={styles.SeeMoreButton}
                                    onClick={()=> onCategoryPress(MainCat)}>
                                    <p className={styles.SeeMoreText}>
                                        {LocalStrings[localCode].SEE_ALL}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.Slider}>
                            <div id={`GigThumbnailView_${MainCat}`} className={styles.GigThumbnailView}>
                                { this.renderGigThumbnailList(GigDataForMainCat)}     
                            </div>
                            { GigDataForMainCat.length > 3 &&
                            <Aux>
                            <div className={styles.Prev} onClick={(event) => {this.arrowClickHandler(event, "prev", MainCat)} }>&#10094;</div>
                            <div className={styles.Next} onClick={(event) => {this.arrowClickHandler(event, "next", MainCat)} }>&#10095;</div>
                            </Aux>
                            }
                        </div>
                    </Aux>
                    );  
                } else {
                    return null
                }
            })
        );
    }
}


export default FeaturedList;