import React from 'react';
import Aux from '../../../../hoc/Aux/Aux';
import styles from '../Article.module.css';

const BuyersManner = (props) => {
    return(
        <Aux>
            <h5 className={styles.HeaderMargin10}>サービス詳細ページの内容は購入前に十分理解しましょう</h5>
            <p>サービスの内容、基本料金、オプション料金、後日実費精算される金額、（＊レンタルの場合は保証金、返却時の適応料金等）詳細ページにてきちんと理解し、「簡易見積」機能で確認しましょう。特に注意事項などにきちんと目を通しておきましょう。</p>
            <h5 className={styles.HeaderMargin10}>サービスに質問・要望などは事前に伝えましょう</h5>
            <p>サービス詳細ページの内容や料金について不明な点、サービスを受けるに当たっての要望などある場合は「問い合わせ」機能を使い売り手に事前に質問し、確認しておきましょう。</p>
            <h5 className={styles.HeaderMargin10}>要望は無理のない範囲でお願いしましょう</h5>
            <p>追加のサービスやオプション、特別な要望がある場合は、出品者に対応可能かどうか、追加代金が必要かなどを確認しましょう。要望は出品者が同意できる内容になるようにしましょう。時間延長などは、次の予約などを考慮して行いましょう。</p>
            <h5 className={styles.HeaderMargin10}>サービスを受ける準備をしましょう</h5>
            <p>サービス詳細ページの注意事項（＊クラスの場合は準備するもの）、その他取引チャットなどで提供者から準備を頼まれた物などはサービスの予約時間が始まる前にきちんと準備しておきましょう。又、事前に提供にあたり提供者から質問された内容についてはきちんと返信しておきましょう。入り口の入り方や、鍵の開け方などの情報が必要な場合はきちんと事前連絡しておきましょう。</p>
            <h5 className={styles.HeaderMargin10}>レンタルサービスの購入</h5>
            <p>レンタル品を受け取る際は、問題がある場合はその場で提供者に伝えましょう。その場合は「受け取り」ボタンをタップしないようにしてください。レンタル品の取り扱いには十分に注意しましょう。傷や、故障、クリーニング代など返却の際に発見された場合はサービス提供者の方が返却時適用料金を請求する場合があります。その際は速やかに支払いましょう。レンタル品は必ず返却してください。返却が遅延した場合は、遅延金額を適用される場合があります。もし返却がなされない場合は、保証金の額を決済致しますので時間厳守でお願いします。</p>
            <h5 className={styles.HeaderMargin10}>キャンセルについて</h5>
            <p>タグジャムでは基本的に取引成立後のキャンセルはできません。ただし、止むを得ない場合はキャンセルの申請ができますが、必ずサービス提供者の同意の上で行ってください。キャンセル時期によってはキャンセル料が発生する場合がございますので、その場合はキャンセル料金の支払い完了にてキャンセルが確定致しますので、速やかに支払い願います。</p>
            <h5 className={styles.HeaderMargin10}>サービスの感想・評価の入力を行いましょう</h5>
            <p>コメント・評価は出品者の方のこれからのやりがいやサービス改善につながる大切なものです。5段階評価の一部（総合評価）やコメントは公開され、他のユーザーも見ることができます。なお、誹謗中傷や個人情報などを記載することがないようご注意ください。（タグジャムのルールに反する内容の場合は運営側で削除し、記載した方のご利用を制限いたします。）</p>
        </Aux>
    )
}

export default BuyersManner