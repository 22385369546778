import React, { Component } from 'react';
import Toolbar from '../../components/NavigationItems/Toolbar/Toolbar';
import SideDrawer from '../../components/NavigationItems/SideDrawer/SideDrawer';
import Footer from '../../components/Footer/Footer';
import Aux from '../Aux/Aux';
import {connect} from 'react-redux';
import {
    toggleAuthWinOpen,
    initAuthUiState,
    authLogOut,
    setSmartBannerShow
} from '../../store/actions'
import { withRouter } from 'react-router-dom';
import Auth from '../../components/Auth/Auth';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import {
    Toast,
    SmartBanner
} from '../../components/UI';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerCloseHandler = () => {
        this.setState({
            showSideDrawer: false
        })
    }

    drawerToggleHandler = (event) => {
        console.log("drawerToggle handler")
        this.setState( prevState => {
            return {
                showSideDrawer: !prevState.showSideDrawer
            }
        })
    }

    isHeadless = () =>  {
        const query = new URLSearchParams(this.props.location.search);
        let headless = false;

        for (let param of query.entries()){
            // ['head', 'no']
            if(param[0] === 'head' && param[1] === 'no'){
                headless = true;
            } 
        }
        return headless;
    }

    toggleAuthWinOpenHandler = () => {
        const {onToggleAuthWinOpen} = this.props;
        if(this.state.showSideDrawer){
            this.sideDrawerCloseHandler();
        }
        onToggleAuthWinOpen();
    }

    render() {
        const {
            uiAuth, 
            onInitAuthUiState,
            onLogout,
            authInfo,
            appData,
            localCode,
            history,
            smartBannerShow,
            onSetSmartBannerShow
        } = this.props;
        const isHeadLess = this.isHeadless();
        if(appData){
            const { LocalStrings } = appData;
            return(
            <Aux>
                { !isHeadLess &&
                <SmartBanner
                  title={LocalStrings[localCode].TAGUJAM}
                  author={LocalStrings[localCode].TAGUJAM}
                  freeLabel={LocalStrings[localCode].FREE}
                  closeClick={onSetSmartBannerShow}
                  show={smartBannerShow}
                />
                }
                { !isHeadLess &&
                <Aux>  
                    <Toolbar 
                        drawerToggleClicked={this.drawerToggleHandler }
                        onToggleAuthWinOpen={this.toggleAuthWinOpenHandler}
                        onLogout={onLogout}
                        onInitAuthUiState={onInitAuthUiState}
                        uiAuth={uiAuth}
                        authInfo={authInfo}
                        appData={appData}
                        localCode={localCode}
                        history={history}
                    />
                    <SideDrawer 
                        closed={this.sideDrawerCloseHandler} 
                        showSideDrawer={this.state.showSideDrawer}
                        onToggleAuthWinOpen={this.toggleAuthWinOpenHandler}
                        onLogout={onLogout}
                        onInitAuthUiState={onInitAuthUiState}
                        uiAuth={uiAuth}
                        authInfo={authInfo}
                        appData={appData}
                        localCode={localCode}
                        history={history}
                    />
                </Aux>
                }
                <main className="MainContent">
                    {this.props.children}
                </main> 
                { !isHeadLess &&
                <Footer />
                }
                <Auth />
                <ErrorModal />
                <Toast />
            </Aux>
            );  
        } else {
            return null;
        }
    }
}

const mapStateToProps = ({
    local: { localCode },
    appData: {appData},
    ui:{ Auth, uiError, smartBannerShow },
    auth:{ authInfo}
}) => ({
    localCode,
    appData,
    uiAuth: Auth,
    uiError,
    smartBannerShow,
    authInfo
});

const mapDispatchToProps = dispatch => ({
    onToggleAuthWinOpen : () => dispatch(toggleAuthWinOpen()),
    onInitAuthUiState : (initState) => dispatch(initAuthUiState(initState)),
    onLogout: (userId) => dispatch(authLogOut(userId)),
    onSetSmartBannerShow:(value) => dispatch(setSmartBannerShow(value))
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));