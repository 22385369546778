import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import TextStyle from '../../constants/TextStyle.module.scss';
import styles from './Detail.module.scss';
import Aux from '../../hoc/Aux/Aux';
import {
    Spinner,
    SegmentControl
} from '../../components/UI';
import {
    ImageSlides,
    CallToActions,
    UserInformation,
    Certificates,
    FeeInfo,
    ServiceItems,
    Remark
} from '../../components/Gigs/';
import Calender from '../../components/Calender/Calender';
import ConfirmCoveredArea from '../../components/ConfirmCoveredArea/ConfirmCoveredArea';
import CategoryList from '../../components/CategoryList/CategoryList';
import UserProfile from '../../components/UserProfile/UserProfile';
import ReviewItems from '../../components/ReviewItems/ReviewItems';
import DownloadApp from '../../components/DownloadApp/DownloadApp';
import {
    hasAfter6Option,
} from '../../components/Calender/calenderUtils'; 
import {
    hasMinuteConvert    
} from '../../utils/serviceItemUtil';
import {
    initUiErrorState,
    toggleErrorWinOpen,
    fetchTheGig,
    fetchTheUserMeta,
    setMainSubMenu,
    dateSelected
} from '../../store/actions'; 
import {plainData, getBusinessType} from '../../utils'

class Detail extends Component {
    state = {
        dataLoaded:false,
        checkAreaWinOpen:false,
        catListWinOpen:false,
        profileWinOpen:false,
        reviewWinOpen:false,
        downloadAppWinOpen:false,
        action:null
    }

    componentDidMount = async () => {
        const {
            getTheMetaData,
            onLoadTheGig,
            onInitUiErrorState,
            onToggleErrorWinOpen,
            onDateSelected,
            match:{params},
            history
        } = this.props;
        const MainCat = params.hasOwnProperty('MainCat') ? params.MainCat : null;
        const gigId = params.gigId;
        if(MainCat){
          history.replace(`/detail/${gigId}`);  
        } 
        try {
            const theGig = await onLoadTheGig(gigId);
            onDateSelected(null);
            // eslint-disable-next-line no-unused-vars
            const theUserMeta = await getTheMetaData(theGig.user); 
            this.setState(prevState =>{
                return {
                    ...prevState,
                    dataLoaded:true
                }
            })
        } catch (error) {
            const initState = {
                errorWinOpen: false,
                closable: true,
                systemError: 'ERROR_OCCURED'    
            }
            onInitUiErrorState(initState);
            onToggleErrorWinOpen();
        }
    }

    renderBudge =(indicator, localCode, MainCat, mode) => {
        const { appData } = this.props;
        const { LocalStrings } = appData;

        if(MainCat === 'TEACH' && MainCat === 'MAKE'){
            return null;
        }
        let budges = [],
        budgeColor = '#ffffff',
        budgeTextColor = '#ff0000';
        
        if( (MainCat === 'HELP' || MainCat==='TEACH') && indicator.AVAILABLE_NOW){
            budges.push( {label: LocalStrings[localCode].AVAILABLE_NOW, budgeColor, budgeTextColor}); 
        }

        if(MainCat==='HELP' || MainCat==='TEACH'){
            if(indicator.VISIT_TYPE){
                budges.push({label:LocalStrings[localCode].VISIT_TYPE, budgeColor, budgeTextColor});
            }
            if(indicator.VISITOR_TYPE){
                budges.push({label:LocalStrings[localCode].VISITOR_TYPE, budgeColor, budgeTextColor});
            }   
        } 

        if(MainCat === 'RENT'){
            if(indicator.LENDING){
                budgeColor = '#ffffff';
                budgeTextColor = 'rgb(63, 62, 62)';
                budges.push({label:LocalStrings[localCode].LENDING, budgeColor, budgeTextColor} ); 
            } 
            if(indicator.RENT_OK){
                budges.push({label:LocalStrings[localCode].RENT_OK, budgeColor, budgeTextColor}); 
            }
        }
        if( (MainCat==='RENT' || MainCat ==='MAKE') && indicator.DELIVERY_OK ){
            budges.push({label:LocalStrings[localCode].DELIVERY_OK, budgeColor, budgeTextColor});
        }
        
        const budgeStyle = mode==='bottomRight'
            ? {position: 'absolute', right:8, bottom:8} 
            : {position: 'absolute', left:8, top:8} 
        
        if(budges.length > 0){
            return (
                <div className={styles.IndiCatorContainer} style={budgeStyle}>
                    { budges.map( (budge, index) => {
                        return (
                        <div 
                            key={`${index}-${budge.label}`}
                            className={styles.BudgeBox} 
                            style={{ marginLeft: index===0 ? 0 : 5, backgroundColor:budge.budgeColor}}>
                            <p className={styles.IndicatorText} style={{color:budge.budgeTextColor}}>
                                {budge.label}
                            </p>
                        </div>
                        );
                    })}
                </div>  
            )
        } else {
            return null;
        }  
    }

    onUserInfoPressed = (event) => {
        if(event){
            event.preventDefault();
        }
        this.toggleWinOpen("profileWinOpen");
    }

    onPressReviews = (event)=> {
        // go to review page'
        if(event){
            event.preventDefault();
        }
        console.log( 'onPressReviews' );
        this.toggleWinOpen("reviewWinOpen");
    }

    addItemToEstimate = (event) => {
        if(event){
            event.preventDefault();
        }
        console.log( 'addItemToEstimate' )
    }

    getAfter6Option = () => {
        return hasAfter6Option(this.props.theGig);
    }

    onServieAreaPress = (event) => {
        if(event){
            event.preventDefault();
        }
        this.toggleWinOpen("checkAreaWinOpen");
    }

    onContactPress = (event) => {
        if(event){
            event.preventDefault();
        }
        this.toggleWinOpen("downloadAppWinOpen", "CONTACT");
    }

    onInterviewPress = (event) => {
        if(event){
            event.preventDefault();
        }
        this.toggleWinOpen("downloadAppWinOpen", "INTERVIEW_REQUEST");
    }

    goToCatalog = (event) => {
        if(event){
            event.preventDefault();
        }
        this.props.history.push({
            pathname:'/catalog'
        });
    }

    openCatList = (event) => {
        if(event){
            event.preventDefault();
        }
        this.toggleWinOpen("catListWinOpen");   
    }
    
    renderGig = () => {
        const {
            localCode,
            appData,
            theGig,
            theUserMeta
        } = this.props;
        const {LocalStrings} = appData;
        const {reviewWinOpen} = this.state;
        if(theGig){
            const { 
                MainCat, 
                SubCat, 
                title,
                description,
                gigImages,
                indicator,
            } = theGig;

            return(
            <Aux>
            <h3 className={`${styles.MainCat} ${styles[MainCat]}`}>
                <span onClick={this.goToCatalog} className={styles.TopNavText}>
                    <i className="fa fa-arrow-left" style={{paddingRight:'3px'}} />
                    タグ一覧
                </span>
                {LocalStrings[localCode][MainCat]}
                <span onClick={this.openCatList} className={styles.TopNavText}>
                    {LocalStrings[localCode].CATEGORY_LISTING_S}
                    <i className="fa fa-arrow-right" style={{paddingLeft:'3px'}} />
                </span> 
            </h3>
            <div className={styles.Card} style={{marginTop:0}}>
                <h3 className={styles.SubCat}>
                    {LocalStrings[localCode][SubCat]}
                </h3>
                <h4 style={{padding:'1rem 0.5rem'}}>{title}</h4>
                <div className={styles.ImageContainer}>
                    <ImageSlides images={gigImages} />
                    {this.renderBudge(indicator, localCode, MainCat, 'topLeft')}
                </div>
                <CallToActions
                    localCode={localCode}
                    appData={appData}
                    theGig={theGig}
                    onServieAreaPress={this.onServieAreaPress}
                    onContactPress={this.onContactPress}
                    onInterviewPress={this.onInterviewPress}
                />
                <UserInformation
                    localCode={localCode}
                    appData={appData}
                    onUserInfoPressed={this.onUserInfoPressed} 
                    accountInfo={theUserMeta.accountInfo}
                    theGig={theGig}
                    onPressReviews={this.onPressReviews} 
                />
                <Certificates 
                    MainCat={theGig.MainCat}
                    SubCat={theGig.SubCat}
                    certificates={theUserMeta.certificates}
                    localCode={localCode}
                    appData={appData}
                />
                
            </div>
            <div className={styles.Card}>
                <FeeInfo
                    MainCat={theGig.MainCat}
                    feeInfo={theGig.feeInfo}
                    localCode={localCode}
                    appData={appData}
                />
                <div style={{padding:'0px 15px 25px 15px'}}>
                    <p className={TextStyle.BodyMediumText}>{description}</p>
                </div>
            </div>
            <div className={styles.Card}>
                <ServiceItems 
                    theGig={theGig}
                    localCode={localCode}
                    addItemToEstimate={this.addItemToEstimate}
                    estimate={null}
                    appData={appData}
                />
            </div>
            { theGig.MainCat !== 'MAKE' &&
            <div className={styles.Card} style={{padding:'15px'}}>
                <p className={TextStyle.BodyLargeText} style={{marginBottom:'10px'}}>
                    {LocalStrings[localCode].SCHEDULE}
                </p>
                {/* <div style={{marginBottom:'10px'}}>
                    <p className={TextStyle.BodyMediumText}>
                        {LocalStrings[localCode].SELECT_DATE_TEXT}
                    </p>
                </div> */}
                <div style={{height:44, justifyContent:'center', marginBottom: theGig.indicator.AVAILABLE_NOW ? 0 : '20px'}}>
                    <SegmentControl
                        TextStyle={TextStyle}
                        data={[
                            {label:"SAMEDAY_OK", value:theGig.indicator.AVAILABLE_NOW, icon:"check"},
                            {label:"SAMEDAY_NG", value:!theGig.indicator.AVAILABLE_NOW, icon:"times"}
                        ]}
                        localCode={localCode}
                        selectedIndex={theGig.indicator.AVAILABLE_NOW === true ? 0 : 1}
                        appData={appData}
                    />
                </div>
                { theGig.indicator.AVAILABLE_NOW &&  
                <div style={{marginTop:10, marginBottom:20, alignItems:"center"}}>
                    <p className={TextStyle.BodyTextSmall}>
                        <span 
                            className={TextStyle.BodyMidiumLargeText}
                            style={{color:'#41BEEB', marginRight:'10px'}}>
                                {theGig.hoursBeforeOrder}
                        </span> 
                        {LocalStrings[localCode].NEED_ORDER_BEFORE}
                    </p>
                </div>
                }
                <Calender
                    theGig={theGig}
                    localCode={localCode}
                    events={theUserMeta.events}
                    gigEvents={theGig.gigEvents}
                    hasAfter6Option={this.getAfter6Option}
                    hasMinuteConvert={hasMinuteConvert(theGig, appData)}
                    DayScheduleNeed={true}
                />
            </div>
            }
            <div className={styles.Card}>
                <Remark
                    appData={appData}
                    localCode={localCode}
                    theGig={theGig}
                />
            </div>
            <ReviewItems
                reviewedGig={theGig}
                onClose={()=> {this.toggleWinOpen("reviewWinOpen")}}
                windowStatus={reviewWinOpen}
                onUserInfoPressed={this.onUserInfoPressed}
            />
            </Aux>
            )  
        } else {
            return null;
        }
    }

    toggleWinOpen = (key, action) => {
        if(key === 'profileWinOpen'){
            this.setState(prevState => {
                return{
                    ...prevState,
                    [key]:!prevState[key],
                    reviewWinOpen: false
                }
            })
        } else{
            this.setState(prevState => {
                return{
                    ...prevState,
                    [key]:!prevState[key],
                    action: action? action: prevState.action
                }
            })    
        }
    }

    handlePressCategory = async (MainCat, SubCat) => {
        const {onMainSubMenuSelected} = this.props;
        await onMainSubMenuSelected(MainCat, SubCat);
        this.props.history.push({
            pathname:SubCat!=='ALL' ? `/catalog/${MainCat.toLowerCase()}/${SubCat.toLowerCase()}` :`/catalog/${MainCat.toLowerCase()}`
        });
    }

    getStructureData = (theGig) => {
      const {theUserMeta} = this.props;
      const {ManCat, SubCat, id, description, coveredArea, gigImages} = theGig
      const {accountInfo:{displayName}} = theUserMeta
      const {centerAddress:{state, city}} = coveredArea;
      const businessType = getBusinessType(ManCat, SubCat);
      return {
        "@context": "http://www.schema.org",
        "@type": businessType==="" ? "LocalBusiness" : businessType,
        "name": `タグジャム-(${displayName})`,
        "url": `https://tagujam.com/details/${id}`,
        "image": gigImages[0].ImagePath,
        "description": description,
        "address": {
          "@type": "PostalAddress",
          "addressLocality": state,
          "addressRegion": city,
          "addressCountry": "japan"
        }
      }
    }

    render() {
        const {
            localCode,
            appData,
            theGig, theGigIsLoading, theGigError,
            theUserMeta, theUserMetaIsLoading, theUserMetaError,
            locationAddress,
            location
        } = this.props;
        const { LocalStrings } = appData;
        const {
            dataLoaded,
            checkAreaWinOpen,
            catListWinOpen,
            profileWinOpen,
            downloadAppWinOpen,
            action 
        } = this.state;
        let content = null;
        let keyword='助けて,助けます,教えて,教えます,貸して,貸します,作って,作ります,お手伝いサービス,地位域貢献,サービス出品';
        let words ='';
        let title = '';
        if( theGigIsLoading || theUserMetaIsLoading){
            content = <div className={styles.SpinnerBox}>
                <Spinner large />
            </div>
        } else if(theGigError || theUserMetaError){
            content = <p>{LocalStrings[localCode].ERROR_OCCURED}</p>;
        } else {
            if( LocalStrings && dataLoaded){
                content = this.renderGig();
            }
        }
        if(theGig){
          if(theGig.SubCat==='OTHERS'){
            words = appData.SubCatItems[theGig.MainCat].reduce((acc, subcat) => {
              let str = LocalStrings[localCode][subcat].replace('ｼﾆｱ同行付添', 'シニア同行付添').replace('ﾘﾗｸｾﾞｰｼｮﾝ','リラクゼーション');
              acc += `${str},`;
              return acc;
            }, '');
          } else {
            words = `${LocalStrings[localCode][theGig.MainCat]},${LocalStrings[localCode][theGig.SubCat]},${appData.Synonym[theGig.SubCat][localCode].join()},お手伝いサービス,地位域貢献,サービス出品`;
          }
          title = `サービス詳細 - ${LocalStrings[localCode][theGig.SubCat]}:${theGig.title}`;
        }
        let pathname = location.pathname;
        keyword= words!=='' ? `${words}お手伝いサービス,地位域貢献,サービス出品` : keyword;

        return(
        <Aux>
            <div className={styles.PageContent}>
                {content}
            </div>
            {theGig &&
            <Aux>
            <Helmet>
              {theUserMeta &&
              <script type="application/ld+json">
              {plainData(this.getStructureData(theGig))}
              </script>
              }
              <meta property="al:ios:url" content={`com.tagujam.mobile://gig/${theGig.MainCat}/${theGig.id}`} />
              <meta property="al:ios:app_store_id" content="1479792883" />
              <meta property="al:ios:app_name" content="タグジャム (TaguJam)" />
              <meta property="al:android:url" content={`com.tagujam.mobile://gig/${theGig.MainCat}/${theGig.id}`} />
              <meta property="al:android:app_name" content="タグジャム (TaguJam)" />
              <meta property="al:android:package" content="com.tagujammobile" />
              <meta property="al:web:url" content={`http://tagujam.com/detail/${theGig.id}`} />
              <meta name="twitter:card" content="app" />
              <meta name="twitter:app:name:iphone" content="タグジャム (TaguJam)" />
              <meta name="twitter:app:id:iphone" content="1479792883" />
              <meta name="twitter:app:url:iphone" content={`com.tagujam.mobile://gig/${theGig.MainCat}/${theGig.id}`} />
              <meta name="twitter:app:name:ipad" content="タグジャム (TaguJam)" />
              <meta name="twitter:app:id:ipad" content="1479792883" />
              <meta name="twitter:app:url:ipad" content={`com.tagujam.mobile://gig/${theGig.MainCat}/${theGig.id}`} />
              <meta name="twitter:app:name:googleplay" content="タグジャム (TaguJam)" />
              <meta name="twitter:app:id:googleplay" content="com.tagujammobile" />
              <meta name="twitter:app:url:googleplay" content={`com.tagujam.mobile://gig/${theGig.MainCat}/${theGig.id}`} />
              {/* <meta name="twitter:card" content="summary" /> */}
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={theGig.description} />
              <meta name="twitter:image" content={theGig.gigImages[0].ImagePath} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={theGig.description} />
              <meta property="og:image" content={theGig.gigImages[0].ImagePath} />
              <meta property="og:image:secure_url" content={theGig.gigImages[0].ImagePath} />
              <meta property="og:image:type" content="image/jpeg" />
              <meta property="og:url" content={pathname} />
              <title>{title}</title>
              <meta
                name="description"
                content={theGig.description}
              />
              <meta content={`${keyword}`} name="keywords" />
            </Helmet>
            <ConfirmCoveredArea
                localCode={localCode} 
                theGig={theGig}
                locationAddress={locationAddress}
                appData={appData}
                onClose={()=> {this.toggleWinOpen("checkAreaWinOpen")}}
                windowStatus={checkAreaWinOpen}
            />
            </Aux>
            }
            <CategoryList
                localCode={localCode} 
                appData={appData}
                onClose={()=> {this.toggleWinOpen("catListWinOpen")}}
                windowStatus={catListWinOpen}
                onCategoryClicked={this.handlePressCategory}
            />
            {theGig &&
            <UserProfile
                localCode={localCode} 
                appData={appData}
                onClose={()=> {this.toggleWinOpen("profileWinOpen")}}
                windowStatus={profileWinOpen}
                centerAddress={theGig.coveredArea.centerAddress}
                userId={theGig.user}
            /> 
            }
            <DownloadApp
                localCode={localCode} 
                appData={appData}
                onClose={()=> {this.toggleWinOpen("downloadAppWinOpen")}}
                windowStatus={downloadAppWinOpen}
                action={action} 
            />
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Auth, isLoading },
    local: {localCode},
    appData: {appData},
    userMeta: {theUserMeta, theUserMetaIsLoading, theUserMetaError},
    gigs: {theGig, theGigIsLoading, theGigError },
    userLocationAddress: {locationAddress},
}) => ({
    uiAuth: Auth,
    isLoading,
    localCode,
    appData,
    theGig, theGigIsLoading, theGigError,
    theUserMeta, theUserMetaIsLoading, theUserMetaError,
    locationAddress
});

const mapDispatchToProps = dispatch => ({
    onLoadTheGig: (id) => dispatch( fetchTheGig(id) ),
    getTheMetaData: (userId, buyerId) => dispatch( fetchTheUserMeta(userId, buyerId) ),
    onInitUiErrorState : (initState) => dispatch(initUiErrorState(initState)),
    onToggleErrorWinOpen : () => dispatch(toggleErrorWinOpen()),
    onMainSubMenuSelected : (mainKey, subKey) => dispatch( setMainSubMenu(mainKey, subKey)),
    onDateSelected : (selDate) => dispatch( dateSelected(selDate) )
});

export default connect(mapStateToProps, mapDispatchToProps )(Detail)