import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import SellerFinishService1 from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_help/sell_finish_service_help_1.jpg';
import SellerFinishService1Rent from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_rent/sell_finish_service_rent_1.jpg';
import SellerFinishService1Make from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_make/sell_finish_service_make_1.jpg';

import SellerFinishService2 from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_help/sell_finish_service_help_2.jpg';
import SellerFinishService2Rent from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_rent/sell_finish_service_rent_2.jpg';
import SellerFinishService2Make from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_make/sell_finish_service_make_2.jpg';

import SellerFinishService3 from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_help/sell_finish_service_help_3.jpg';
import SellerFinishService3Rent from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_rent/sell_finish_service_rent_3.jpg';
import SellerFinishService3Make from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_make/sell_finish_service_make_3.jpg';

import SellerFinishService4Make from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_make/sell_finish_service_make_4.jpg';

import SellerFinishService5Make from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_make/sell_finish_service_make_5.jpg';

import SellerFinishService6 from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_help/sell_finish_service_help_4.jpg';
import SellerFinishService6Rent from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_rent/sell_finish_service_rent_4.jpg';
import SellerFinishService6Make from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_make/sell_finish_service_make_6.jpg';
import SellerFinishService7Make from '../../../assets/images/help/selling/sell_finish_service/sell_finish_service_make/sell_finish_service_make_7.jpg';


class SellerFinishService extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービスを完了する（{this.getCategory()}）</span>
            </h3>
            {currentTab === 'HELP' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            サービスの提供を完了する場合は、以下の手順でサービス完了を行ってください。
            </p>
            </Aux>
            }
            { currentTab==='TEACH' && 
            <p className={styles.ArticleDesc}>
                「教えます」のサービスの場合は特に完了の手続きはありません。請求書の作成と送信の手順に進んでください。
            </p>
            }
            {currentTab === 'RENT' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            レンタル品をレンタル申請者（借り手）から受け取る場合は以下の手順でサービス完了を行ってください。
            </p>
            </Aux>
            }
            {currentTab === 'MAKE' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            作品の作成が終わり、作品を引き渡す場合は、以下の手順でサービス完了を行ってください。
            </p>
            </Aux>
            }
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    { currentTab!=='TEACH' && 
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「取引中」を選びます。
                    </li>
                    }
                    { currentTab==='HELP' && 
                        <img className={styles.ScreenShot} src={SellerFinishService1} alt="サービスを完了するステップ1画像" />
                    }
                    { currentTab==='RENT' && 
                        <img className={styles.ScreenShot} src={SellerFinishService1Rent} alt="サービスを完了するステップ1画像" />
                    }
                    { currentTab==='MAKE' && 
                        <img className={styles.ScreenShot} src={SellerFinishService1Make} alt="サービスを完了するステップ1画像" />
                    }
                    
                    {currentTab === 'HELP' && 
                    <Aux>
                        <li>
                        「サービスを開始しました」と表示された取引をタップします。       
                        </li>
                        <img className={styles.ScreenShot} src={SellerFinishService2} alt="サービスを完了するステップ2画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' && 
                    <Aux>
                        <li>
                        レンタル品を受け取り、サービスを完了する取引をタップします。      
                        </li>
                        <img className={styles.ScreenShot} src={SellerFinishService2Rent} alt="サービスを完了するステップ2画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' && 
                    <Aux>
                        <li>
                        「作成を開始しました」と表示された作品を引き渡す取引をタップします。   
                        </li>
                        <img className={styles.ScreenShot} src={SellerFinishService2Make} alt="サービスを完了するステップ2画像" />
                    </Aux>
                    }

                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                    「サービスを完了する」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService3} alt="サービスを完了するステップ3画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    レンタル品を受け取り、傷、汚れ、損傷などないか確認します。もし、返却時適応料金を課金する場合は、レンタル申請者（借り手）に伝えてください。確認が終わったら「レンタル品返却受け取り」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService3Rent} alt="サービスを完了するステップ3画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    「作成を完了する」ボタンをタップします。  
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService3Make} alt="サービスを完了するステップ3画像" />
                    </Aux>
                    }

                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    「作成が完了しました」のメッセージが送られたのを確認しましょう。配達のオプションを提供する場合は配達時間、配達オプションを提供しない場合は作品の引き渡し場所・時間を買い手の方と取引チャットにて話あいましょう。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService4Make} alt="サービスを完了するステップ4画像" />
                    </Aux>
                    }

                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    作品の引き渡しが完了したら、「作品の引き渡し完了」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService5Make} alt="サービスを完了するステップ5画像" />
                    </Aux>
                    }

                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                        サービス完了のメッセージが送られたのを確認しましょう。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService6} alt="サービスを完了するステップ4画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    返却受付完了のメッセージが送られたのを確認しましょう。
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService6Rent} alt="サービスを完了するステップ4画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    「作品引き渡し完了」のメッセージを確認します。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerFinishService6Make} alt="サービスを完了するステップ7画像" />
                    </Aux>
                    }

                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                        引き渡した作品に問題がある場合は、サービス購入者（買い手）の方が修正を依頼する場合があります。　作品に問題がなければ、サービス購入者（買い手）の画面で「作品受け取り完了」ボタンをタップしてもらいましょう。 
                        「作品が受け取られました」のメッセージが確認できます。     
                    </li>
                    <p>＊このステップは必ず行ってもらってください。次の「請求書を作成」ボタンが表示されません。 </p>
                    <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={SellerFinishService7Make} alt="サービスを完了するステップ7画像" />
                    </Aux>
                    }
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SellerFinishService);