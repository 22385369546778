import {
    FETCH_CERTIFICATE,
    SET_CERTIFICATE,
    SET_THE_CERTIFICATE
} from './actionTypes';

import { 
    uiStartLoading, 
    uiStopLoading,
    authGetToken,
    setCertStatusToMeta 
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchCertificatePending = () => ({
    type: `${FETCH_CERTIFICATE}_PENDING`
});

export const fetchCertificateRejected = (err) => ({
    type: `${FETCH_CERTIFICATE}_REJECTED`,
    payload: err
});

export const fetchCertificateFulFilled = (certificate) => ({
    type: `${FETCH_CERTIFICATE}_FULFILLED`,
    payload: certificate
});

export const fetchCertificate = (id) => {
    return async dispatch => {
        dispatch(fetchCertificatePending());
        try{
            let token = await dispatch(authGetToken());
            let certificate = await taguJamApi.fetchCertificate(id, token);
            dispatch(fetchCertificateFulFilled(certificate))
            return certificate;
        } catch(err){
            dispatch(fetchCertificateRejected(err));
            throw err
        }
    }   
};

export const fetchTheCertificate= (id, type) => {
    return async dispatch => {
        dispatch(uiStartLoading(`fetchTheCertificate_${id}`));
        try{
            let token = await dispatch(authGetToken());
            let certificate = await taguJamApi.fetchCertificate(id, token, type);
            dispatch(setTheCertificate(certificate, type));
            dispatch(uiStopLoading(`fetchTheCertificate_${id}`));
            return certificate;
        } catch(err){
            dispatch(uiStopLoading(`fetchTheCertificate_${id}`));
            console.log(err);
            throw err
        }
    }   
};

export const setTheCertificate = (certificate, type) => ({
    type: SET_THE_CERTIFICATE,
    payload: {certificate, type}
});

export const setCertificate = (certificate) => ({
    type: SET_CERTIFICATE,
    payload: certificate
});

export const saveCertificate = ( certificateData, id) => {
    return async dispatch => {
        dispatch(uiStartLoading(`saveCertificate_${id}`));
        try{
            let token = await dispatch(authGetToken()); 
            let certificate = await taguJamApi.saveCertificate(certificateData, id, token);
            let verifyStatus = certificate.verifyStatus;
            if(!certificateData.hasExpireDate && certificate.expireDate){
                certificate.expireDate = null;    
            }
            dispatch(setCertStatusToMeta(certificateData.certificateType.toLowerCase(), verifyStatus, id ));
            dispatch(setTheCertificate(certificate, certificateData.certificateType.toLowerCase() ));
            dispatch(uiStopLoading(`saveCertificate_${id}`));
            return certificate;  
        } catch(err){
            dispatch(uiStopLoading(`saveCertificate_${id}`));
            console.log(err);
            throw err;
        }
    }
}