import {
    FETCH_GIGS,
    FETCH_THE_GIG,
} from './actionTypes';

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchGigsPending = () => ({
    type: `${FETCH_GIGS}_PENDING`
});

export const fetchGigsRejected = (err) => ({
    type: `${FETCH_GIGS}_REJECTED`,
    payload: err
});

export const fetchGigsFulFilled = (gigs) => ({
    type: `${FETCH_GIGS}_FULFILLED`,
    payload: gigs
});

export const fetchGigs = () =>{
    return async dispatch => {
        dispatch(fetchGigsPending());
        try{
            let gigs = await taguJamApi.fetchGigs();
            //console.log("gigs", gigs);
            dispatch(fetchGigsFulFilled(gigs))
            return gigs;
        } catch(err){
            dispatch(fetchGigsRejected(err));
            throw err
        }
    }   
};

export const fetchTheGigPending = () => ({
    type: `${FETCH_THE_GIG}_PENDING`
});

export const fetchTheGigRejected = (err) => ({
    type: `${FETCH_THE_GIG}_REJECTED`,
    payload: err
});

export const fetchTheGigFulFilled = (theGig) => ({
    type: `${FETCH_THE_GIG}_FULFILLED`,
    payload: theGig
});

export const fetchTheGig = (userId) =>{
    return async dispatch => {
        dispatch(fetchTheGigPending());
        try{
            let theGig = await taguJamApi.fetchTheGig(userId);
            //console.log("theGig", theGig);
            dispatch(fetchTheGigFulFilled(theGig))
            return theGig;
        } catch(err){
            dispatch(fetchTheGigRejected(err));
            throw err
        }
    }   
};