import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import BuyerSchedule0 from '../../../assets/images/help/account/schedule.png';
import BuyerSchedule1 from '../../../assets/images/help/account/schedule/schedule_1.png';
import BuyerSchedule2 from '../../../assets/images/help/account/schedule/schedule_2_buy.png';
import BuyerSchedule3 from '../../../assets/images/help/account/schedule/schedule_3.png';
import BuyerSchedule4 from '../../../assets/images/help/account/schedule/schedule_4_buy.png';


class BuyerSchedule extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>スケジュール確認する（買い手）</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムでは、取引に関する、予定の管理は以下の手順で確認することができます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「スケジュール管理」を選びます。        
                    </li>
                    <img className={styles.ScreenShot} src={BuyerSchedule0} alt="スケジュール確認するステップ1画像" />
                    <li>
                        カレンダーには、予定がある日には、タグ（サービスの場合）又は吹き出し（面接の場合）アイコンが表示されます。仮予約の場合はアイコンはグレーで表示されます。予定を見たい日をタップします。   
                    </li>
                    <img className={styles.ScreenShot} src={BuyerSchedule1} alt="スケジュール確認するステップ2画像" />
                    <li>
                        予定の詳細が見れます。左の欄には移動時間も含めた予定時間が、右の欄には実際のサービスの予約時間が表示されます。
                        <p className="Caption">＊複数ある場合はスクロールしてください。</p>
                    </li>
                    <img className={styles.ScreenShot} src={BuyerSchedule2} alt="スケジュール確認するステップ3画像" />
                    <p className={styles.HeaderMargin10}>＊日付を選択し、「＋予定を作成」ボタンをタップする事により、タグジャムの予定だけでなく自分の予定もここで管理することができます。</p>
                    <img className={styles.ScreenShot} src={BuyerSchedule3} alt="スケジュール確認するステップ4画像" />
                    <p className={styles.HeaderMargin10}>「タイトル」、「開始時刻」「終了時刻」「移動時間」を入力してください。最後に「保存」ボタンをタップします。</p>
                    <img className={styles.ScreenShot} src={BuyerSchedule4} alt="スケジュール確認するステップ5画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(BuyerSchedule);