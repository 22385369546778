import moment from 'moment';

const compareDate = (a, b) => {
  let a_date = moment(a.createdDate).toDate();
  let b_date = moment(b.createdDate).toDate();
  if(a_date.getTime() < b_date.getTime()) return -1;
	if(a_date.getTime() > b_date.getTime()) return 1;
}

export const normalizedData = (data, articlesData) => {
  let cleanData = [];
  data.map(id => {
    if(articlesData[id]){
      cleanData.push({...articlesData[id], id});
    }
    return true;
  });
  return cleanData.sort(compareDate);
}

export const normalizedTaggedData = (tag, articlesData) => {
  let cleanData = [];
  Object.keys(articlesData).map((key)=>{
    const {MainCat, SubCat} = articlesData[key];
    const subCatIndex = SubCat.findIndex(item => {
      return item === tag;
    });
    if(MainCat===tag || subCatIndex > -1){
      cleanData.push({...articlesData[key]})  
    }
    return true;   
  })
  return cleanData.sort(compareDate);
}

export const calcRemainNum = (Len, rowItemNum) => {
  let remainNum =0;
  remainNum = rowItemNum - (Len - Math.floor(Len / rowItemNum) * rowItemNum);
  return remainNum;
}

export const normalizedVideoList = (data, includeAll) => {
  let cleanData = [];
  for(let key in data){
    const {howToVideo} = data[key];
    if(includeAll && includeAll===true){
      cleanData.push({...data[key]})
    } else {
      if(howToVideo===true){
        cleanData.push({...data[key]})  
      }
    }
  }
  return cleanData.sort(compareDate);
}

export const normalizedNoneHowToVideoList = (data) => {
  let cleanData = [];
  for(let key in data){
    const {howToVideo} = data[key];
    if(howToVideo===null){
      cleanData.push({...data[key]})
    }
  }
  return cleanData.sort(compareDate);
}


export const handleSubCatPress = (subCat, props)=> {
  const {history} = props;
  console.log(`${subCat} is clicked`);
  history.push({
    pathname:`/blog/taggedArticles/${subCat}`
  });
}