import {
    FETCH_TOS_ACCEPTANCE
} from '../actions/actionTypes';

const initState = {
    tosAcceptance: null,
    tosAcceptanceIsLoading: false,
    tosAcceptanceError: false
};

const tosAcceptanceReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${FETCH_TOS_ACCEPTANCE}_PENDING`:
            return {
                ...state,
                tosAcceptanceIsLoading: true,
                tosAcceptanceError: false,
            }
        case `${FETCH_TOS_ACCEPTANCE}_FULFILLED`:
            return {
                ...state,
                tosAcceptance: payload,
                tosAcceptanceIsLoading: false,
                tosAcceptanceError: false,
            }
        case `${FETCH_TOS_ACCEPTANCE}_REJECTED`:
            return {
                ...state,
                tosAcceptance: null,
                tosAcceptanceIsLoading: false,
                tosAcceptanceError: true,
            }
        default:
            return state;
    }
};

export default tosAcceptanceReducer;
