import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux';
import BlogStyles from '../Blog.module.scss';
import styles from './BlogHome.module.scss';
import Aux from '../../../hoc/Aux/Aux'

import BlogSectionTitle from '../../../components/Blog/BlogSectionTitle/BlogSectionTitle';
import FeatureMain from '../../../components/Blog/FeatureMain/FeatureMain';
import FeatureSmall from '../../../components/Blog/FeatureSmall/FeatureSmall';
import FeatureRegular from '../../../components/Blog/FeatureRegular/FeatureRegular';
import FeatureVideo from '../../../components/Blog/FeatureVideo/FeatureVideo';
import SearchTags from '../../../components/Blog/SearchTags/SearchTags';
import {normalizedData, normalizedVideoList, handleSubCatPress} from '../../../utils';
import { Spinner } from '../../../components/UI';


class BlogHome extends Component {

    constructor(props){
      super(props);
      const {blog} = this.props;
      const {videos, articles, howToStart, blogHome} = blog;
      let mainArticle = blogHome.mainArticle ? articles[blogHome.mainArticle] : null;
      let subArticles = blogHome.subArticles ? normalizedData(blogHome.subArticles, articles) : null;
      let theHowToStart = normalizedData(howToStart, articles);
      let howToVideos = normalizedVideoList(videos);
      this.state = {
        mainArticle,
        subArticles,
        howToStart: theHowToStart,
        howToVideos
      }
    }

    renderMain = () => {
      const {mainArticle} = this.state;
      if(mainArticle === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        return(
        <FeatureMain 
          item={mainArticle} 
        />
        );
      }  
    }

    renderFeature = () => {
      const {subArticles} = this.state;
      if(subArticles === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        return(
        <Aux>
          {subArticles.map((subArticle, index) => {
            const {id} = subArticle;
            return(
            <div key={`subArticle_${id}`} className={index < 2 ? styles.FeatureRightBox : `${styles.FeatureRightBox} ${styles.FeatureBottom}`}>
              <FeatureSmall 
                item={subArticle} 
                index={index} 
              />
            </div>
            );
            })
          } 
        </Aux>
        );
      }
    }

    renderHowToStart = () => {
      const {howToStart} = this.state;
      if(howToStart === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        let howToStartRemain = [];
        if(howToStart.length < 3) {
          for(let i=0; i < 3 - howToStart.length; i++){
            howToStartRemain.push(1);
          }  
        }
        return(
        <Aux>
          {howToStart.map((subArticle, index) => {
              const {id} = subArticle;
              if(index < 3){
                return(
                <div key={`howToStartArticle_${id}`} className={styles.PodSmallRegularBox} >
                  <FeatureRegular 
                    item={subArticle} 
                    index={index} />
                </div>
                );
              } else {
                return null;
              }
            })
            }
            <Aux>
            {howToStartRemain.length > 0 && howToStartRemain.map((remain, index)=>{
              return(<div key={`remainHowToStart_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>  
        </Aux>
        );
      }
    }

    renderHowToVideo = () => {
      const {howToVideos} = this.state;
      if(howToVideos === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        let howToMovieRemain = [];
        if(howToVideos.length < 3) {
          for(let i=0; i < 3 - howToVideos.length; i++){
            howToMovieRemain.push(1);
          }
        }
        return(
        <Aux>
          {howToVideos.map((subArticle, index) => {
              const {id} = subArticle;
              if(index < 3){
                return(
                  <div key={`HowToMovies_${id}`} className={styles.PodSmallRegularBox}>
                    <FeatureVideo 
                      item={subArticle} />
                  </div>
                  );
              } else {
                return null;
              }
            })
            }
            <Aux>
            {howToMovieRemain.length > 0 && howToMovieRemain.map((remain, index)=>{
              return(<div key={`remainHowToVideo_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>  
        </Aux>
        );
      }
    }

    render(){
        const {location, localCode, appData} = this.props;
        let pathname = location.pathname;
        let title = '注目の記事';
        let description = 'タグジャムを使って個人サービスの始め方、SNSシェアで拡散機能、スケジュール管理、取引チャット機能、見積書、請求書機能、キャンセル管理など上手く活用してサービスを成功させよう。';
        return(
          <>
          <Helmet>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content={`タグジャム-${title}`} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={`タグジャム-${title}`} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" data-react-helmet="true" />
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={description}
              />
              <meta content="タグジャムの活用方法の記事,個人サービスで稼ぐ,始めるを応援,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className={BlogStyles.BlogPageContent}>
            {/* 注目の記事 */}
            <BlogSectionTitle
              tagTitle="注目"
              title="注目の記事"
              needMargin={false}
            />
            <div className={styles.FeatureLayout}>
              <div className={styles.FeatureLeft}>
               {this.renderMain()}
              </div>
              <div className={styles.FeatureRight}>
                {this.renderFeature()} 
              </div>
            </div>
            
            {/* 始め方の記事 */}    
            <BlogSectionTitle
              tagTitle="始める"
              title="始めの一歩"
              linkTo="/blog/HowToStart"
              linkLabel="もっと見る…"
              needMargin={true}
            />
            <div className={styles.PodSmallRegularLayout}>
              {this.renderHowToStart()}
            </div>
            
            {/* 使い方動画 */}
            <BlogSectionTitle
              tagTitle="動画"
              title="使い方動画"
              linkTo="/blog/HowToMoviews"
              linkLabel="もっと見る…"
              needMargin={true}
            />
            <div className={styles.PodSmallRegularLayout}>
              {this.renderHowToVideo()}
            </div>

            {/* タグで記事を探す */}
            <BlogSectionTitle
              tagTitle="タグ"
              title="タグで記事を探す"
              needMargin={true}
            />
            <div className={BlogStyles.SearchTagContainer}>
            <SearchTags
              appData={appData}
              localCode={localCode}
              onClick={(subCat) => {handleSubCatPress(subCat, this.props)}}
            />
            </div>
        </div>
        </>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
  blog: { blog, blogIsLoading, blogError}
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError,
  blog, blogIsLoading, blogError
});

export default connect(mapStateToProps, null)(BlogHome);