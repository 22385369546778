import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import EvaluateSeller1 from '../../../assets/images/help/buying/buy_rating/buy_rating_1.png';
import EvaluateSeller2 from '../../../assets/images/help/buying/buy_rating/buy_rating_2.jpg';
import EvaluateSeller3 from '../../../assets/images/help/buying/buy_rating/buy_rating_3.jpg';
import EvaluateSeller4 from '../../../assets/images/help/buying/buy_rating/buy_rating_4.jpg';
import EvaluateSeller5 from '../../../assets/images/help/buying/buy_rating/buy_rating_5.jpg';

class EvaluateSeller extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>提供者を評価する</span>
            </h3>
            <p className={styles.ArticleDesc}>
                取引の最後に以下の手順で提供者を評価しましょう。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「購入履歴」選びます。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateSeller1} alt="提供者を評価するステップ1画像" />
                    <li>
                        サービス提供者を評価したい取引を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateSeller2} alt="提供者を評価するステップ2画像" />
                    <li>
                    　「出品者を評価する」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateSeller3} alt="提供者を評価するステップ3画像" />
                    <li>
                    　「評価」の欄でそれぞれの項目について５段階評価のボタンをタップしていきます。「コメント」欄にこのサービスを使ってみた感想もしくは、改善点などあればここに記入します。最後に「評価を投稿する」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateSeller4} alt="提供者を評価するステップ4画像" />
                    <li>
                        「領収書へ戻る」ボタンで前画面に戻ることができます。
                    </li>
                    <img className={styles.ScreenShot} src={EvaluateSeller5} alt="提供者を評価するステップ5画像" />   
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(EvaluateSeller);