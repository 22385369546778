import React from 'react';
import styles from './ProfileCard.module.scss';
import textStyle from '../../../constants/TextStyle.module.scss';
import { Avator } from '../../UI';
import {
  isCommunicationEnabled,
  getCommunication,
  getUserLevelStr,
  getUserLevel,
  isVerified,
  checkVerifiedMethod,
  getAddressLine
} from '../UserProfileUtils';

const ProfileCard = (props) => {
  const {  
      appData, 
      localCode, 
      theUserMeta, 
      centerAddress,
      explainPress
  } = props;
  const { LocalStrings, Colors} = appData;
  const modeList = ['sell', 'buy'];
  return(
  <div className={styles.Cotent}>
    <h3 style={{color:Colors.white, paddingBottom:'5px'}}>
      {LocalStrings[localCode].PROFILE}
    </h3>
    <div className={styles.AvatorBox}>
      <Avator avator={theUserMeta.accountInfo.avator} alt={LocalStrings[localCode].PROFILE} size={100} />
    </div>
    <div className={styles.UserNameBox}>
      <p className={textStyle.BodyMediumLText}>
        {theUserMeta.accountInfo.displayName}
      </p>
    </div>
    <div className={styles.Details}>
      <div style={ {display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", margin:'3px 0'} }>
        <i className="fa fa-envelope" style={{fontSize:'25px', color:isVerified('email', theUserMeta) ? Colors.contentFontColor : Colors.tintColorBGColor }}/>
        <p className={textStyle.BodyText} 
          style={{marginLeft:'5px', marginRight:'10px', color:isVerified('email', theUserMeta) ? Colors.contentFontColor : Colors.contentFontTintColor }}>
          {checkVerifiedMethod('email', localCode, appData, theUserMeta)}
        </p>
        <i className="fa fa-phone" style={{fontSize:'25px', color:isVerified('cell', theUserMeta) ? Colors.contentFontColor : Colors.tintColorBGColor }} />
        <p className={textStyle.BodyText} 
          style={{marginLeft:'5px', color:isVerified('cell', theUserMeta) ? Colors.contentFontColor : Colors.contentFontTintColor}}>
          {checkVerifiedMethod('cell', localCode, appData, theUserMeta)}
        </p>
      </div>
      {modeList.map( (mode, index) => {
        return(
        <div 
          key={`${mode}-${index}`} 
          style={ {display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", margin:'3px 0'} }>
          <p className={`${textStyle.BodyText} ${textStyle.Bold}`} style={{marginLeft:'5px', marginRight:'5px'}}>
              { mode==='buy'? LocalStrings[localCode].BUYER_LEVEL : LocalStrings[localCode].SELLER_LEVEL}
          </p>
          <div style={{display:"flex", flexDirection:"row", position:"relative", justifyContent:"center", alignItems:"center"}}>
            <p className={`${textStyle.BodyText} ${textStyle.Bold}`} style={{position:'absolute'}}>
                {getUserLevel(mode, localCode, appData, theUserMeta)}
            </p>
            <i className="fa fa-certificate" style={{fontSize:"35px", color:Colors[appData.UserLevels[getUserLevelStr(mode, theUserMeta)].color]}} />
          </div>
          <p className={`${textStyle.BodyText} ${textStyle.Bold}`} style={{marginLeft:'2px', marginRight:'20px'}}>
            {LocalStrings[localCode].LEVEL_STR}
          </p>
          <div
            style={{display:"flex", flexDirection:"row", alignItems:"center"}}
            onClick={() => {explainPress(mode)} }>
              <i className="fa fa-question-circle" style={{fontSize:'20px', color:Colors.primaryButtonBGColor}} />
              <p className={`${textStyle.BodyText} ${textStyle.Bold}`}style={{ marginLeft:'3px', color: Colors.primaryButtonBGColor}}>
                  {LocalStrings[localCode].EXPLAIN}
              </p>
          </div>
        </div>
        );
      })}
      { centerAddress && 
      <div className={`BorderTop ${styles.ContentRow}`} style={{marginBottom:0, paddingBottom:0}}>
          <p className={textStyle.BodyText}>{getAddressLine(centerAddress)}</p>    
      </div>
      }
      <div 
        className={`BorderBottom BorderTop ${styles.ContentRow}`}  
        style={{display:'flex', flexDirection:"column"}}>
        <p className={`${textStyle.BodyTextSmall} ${textStyle.Bold}`}>
          {`${LocalStrings[localCode].WHEN_DEAL}${LocalStrings[localCode].COMMUNICATION}`}
        </p> 
        <div style={ { display:'flex', flexDirection:"row", alignItems:"center", marginTop:'8px'} }>
          <i className="fa fa-comment" style={{fontSize:"20px", color: isCommunicationEnabled('CHAT', theUserMeta) ? Colors.contentFontColor : Colors.tintColorBGColor}} />
          <p className={textStyle.BodyTextExSmall} style={{marginLeft:'5px', marginRight:'15px', color:isCommunicationEnabled('CHAT', theUserMeta) ? Colors.contentFontColor : Colors.contentFontTintColor}}>
            {getCommunication('CHAT', localCode, appData, theUserMeta)}
          </p>
          <i className="fa fa-phone" style={{fontSize:"20px", color: isCommunicationEnabled('VOICE_CHAT', theUserMeta) ? Colors.contentFontColor : Colors.tintColorBGColor}} />
          <p className={textStyle.BodyTextExSmall} style={{marginLeft:'5px', marginRight:'15px', color:isCommunicationEnabled('VOICE_CHAT', theUserMeta) ? Colors.contentFontColor : Colors.contentFontTintColor}}>
            {getCommunication('VOICE_CHAT', localCode, appData, theUserMeta)}
          </p>
          <i className="fa fa-video-camera" style={{fontSize:"20px", color: isCommunicationEnabled('VIDEO_CHAT', theUserMeta) ? Colors.contentFontColor : Colors.tintColorBGColor}} />
          <p className={textStyle.BodyTextExSmall} style={{marginLeft:'5px', color:isCommunicationEnabled('VIDEO_CHAT', theUserMeta) ? Colors.contentFontColor : Colors.contentFontTintColor}}>
            {getCommunication('VIDEO_CHAT', localCode, appData, theUserMeta)}
          </p>
        </div>   
      </div>
      <div 
        className={styles.ContentRow} 
        style={{display:'flex', flexDirection:"column", marginTop:'8px', paddingTop:0}}>
        <p className={`${textStyle.BodyTextSmall} ${textStyle.Bold}`}>
          {LocalStrings[localCode].SELF_PR}
        </p> 
        <div style={{width:"100%", padding:'8px'}}>
          {!theUserMeta.accountInfo.selfPR &&
          <p className={textStyle.BodyText} style={{alignSelf:"center"}}>
            {LocalStrings[localCode].NOT_SELF_PR_FOUND}
          </p>
          }
          {theUserMeta.accountInfo.selfPR &&
          <p className={textStyle.BodyText}>
            {theUserMeta.accountInfo.selfPR}
          </p>
          }
        </div>
      </div>
    </div>
  </div>
  );   
}

export default ProfileCard;