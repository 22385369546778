import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styles from './Catalog.module.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import CatalogItem from '../../components/CatalogItem/CatalogItem';
import MainCatMenu from '../../components/MainCatMenu/MainCatMenu';
import SubCatButton from '../../components/SubCatButton/SubCatButton';
import {
    Spinner,
    NAMessage
} from '../../components/UI'
import {
    initUiErrorState,
    toggleErrorWinOpen,
    fetchGigs,
    fetchAllUserMeta,
    setMainMenu,
    setSubMenu,
    setMainSubMenu,
} from '../../store/actions';
import {plainData} from '../../utils'

const DESCRIPTION = {
  ALL:'「助けて」・「助けます」「教えて」・「教えます」「貸して」・「貸します」「作って」・「作ります」',
  HELP:'「助けて」・「助けます」',
  TEACH:'「教えて」・「教えます」',
  RENT:'「貸して」・「貸します」',
  MAKE:'「作って」・「作ります」'
}

class Catalog extends Component {

    componentDidMount = async () => {
        const {
            appData,
            onSetMainSubMenu,
            onLoadUserMeta,
            onLoadGigs,
            onInitUiErrorState,
            onToggleErrorWinOpen,
            history,
            match:{params},
        } = this.props;
        try {
            // eslint-disable-next-line no-unused-vars
            const MainList = ['HELP', 'TEACH', 'RENT', 'MAKE'];
            let MainCat = params.hasOwnProperty('MainCat') ? params.MainCat.toUpperCase() : 'HOME';
            let SubCat = params.hasOwnProperty('SubCat') ? params.SubCat.toUpperCase() : 'ALL';
            let replaceUrl;
            if( (MainCat!=='HOME' && MainList.indexOf(MainCat)===-1) || MainCat==='HOME') {
              MainCat='HOME';
              replaceUrl = '/catalog';
            }
            if( (MainCat!=='HOME' 
                && MainList.indexOf(MainCat)>-1 
                && appData.SubCatItems[MainCat].indexOf(SubCat)===-1)
                || SubCat==='ALL') {
              replaceUrl = MainCat==='HOME' ? '/catalog' : `/catalog/${MainCat.toLowerCase()}`;
              SubCat='ALL';
            }
            if(replaceUrl){
              history.replace(replaceUrl);
            }
            onSetMainSubMenu(MainCat, SubCat);
            const gigs = await onLoadGigs();
            // eslint-disable-next-line no-unused-vars
            const userMeta = await onLoadUserMeta(); 
            this.setState(prevState =>{
                return {
                    ...prevState,
                    dataLoaded:true
                }
            })
        } catch (error) {
            const initState = {
                errorWinOpen: false,
                closable: true,
                systemError: 'ERROR_OCCURED'    
            }
            onInitUiErrorState(initState);
            onToggleErrorWinOpen();
        }
    }

    handleGigPress = (gigId, MainCat) => {
        this.props.history.push({
            pathname: `/detail/${gigId}`
        });
    }

    mainMenuPresshander = MainCat =>{
        //this.props.onChangeText("");
        this.props.history.push({
          pathname: `/catalog/${MainCat.toLowerCase()}`
        });
        console.log(this.props);
        this.props.onMainMenuSelected(MainCat);
    }

    handleSubCatPress = (submenu)=> {
        //this.props.onChangeText("");
        const {MainCat} = this.props;
        let subMenu = submenu === 'ALL' ? '': `/${submenu.toLowerCase()}`;
        this.props.history.push({
          pathname: `/catalog/${MainCat.toLowerCase()}${subMenu}`
        });
        console.log(this.props);
        this.props.onSubMenuSelected(submenu);
    }

    hasCondition = (search) => {
        console.log("hasCondition");
        const {SearchMainCat, SearchSubCat, serviceItem, searchArea, searchPrice, searchDate, indicator} = search;
        let hasCondition = false;
        if(SearchMainCat || SearchSubCat || serviceItem || searchArea || searchDate || indicator){
            hasCondition = true;
        } else {
            let {priceMin, priceMax} = searchPrice;
            if( priceMin || priceMax ){
                hasCondition = true;
            } 
        }
        return hasCondition;
    }

    matchCondition = (gig, search) => {
        //console.log("gig", gig);
        const {SearchMainCat, SearchSubCat, serviceItem, searchArea, searchPrice, searchDate, indicator} = search;
        let fit=true;
        if(SearchMainCat){
            fit = fit && gig.MainCat === SearchMainCat;
        }
        if(fit===true && SearchSubCat){
            fit = fit && gig.SubCat === SearchSubCat;    
        }
        if(fit===true && serviceItem){
            if(serviceItem.baseService){
                for (let key in serviceItem.baseService){
                    let serviceFound = true;
                    if(serviceItem.baseService[key]){
                        if(!gig.baseService || (gig.baseService && !gig.baseService[key]) ){
                            if(!gig.optionableService || (gig.optionableService && !gig.optionableService[key])){
                                serviceFound = false;   
                            }  
                        }
                    }
                    fit = fit && serviceFound;
                    if(!fit){
                        break;
                    }
                }
            }
            if(fit===true && serviceItem.optionService){
                for (let key in serviceItem.optionService){
                    let serviceFound = true;
                    if(serviceItem.optionService[key]){
                        if(!gig.optionService || (gig.optionService && !gig.optionService[key])){
                            serviceFound = false;   
                        }    
                    }
                    fit = fit && serviceFound;
                    if(!fit){
                        break;
                    }
                }
            }    
        }
        if(fit===true && indicator){
            const {VISITOR_TYPE, VISIT_TYPE} = indicator;
            if(VISITOR_TYPE && VISIT_TYPE){
                if(!gig.indicator.VISIT_TYPE && !gig.indicator.VISITOR_TYPE){
                    fit = false;
                }    
            } else if(VISITOR_TYPE && !VISIT_TYPE){
                if(!gig.indicator.VISITOR_TYPE ){
                    fit = false;
                }    
            } else if(!VISITOR_TYPE && VISIT_TYPE){
                if(!gig.indicator.VISIT_TYPE ){
                    fit = false;
                }
            }   
        }
        if(fit===true && searchArea){
            const {VISIT_TYPE} = gig.indicator;
            let gigAddress = gig.coveredArea.centerAddress;
            if(searchArea.state && gigAddress.state !== searchArea.state){
                fit = false;
            }
            if(searchArea.city && gigAddress.city !== searchArea.city){
                fit = false;
            } 
            if(VISIT_TYPE){
                if(searchArea.town){
                    if(gig.coveredArea.towns.hasOwnProperty(searchArea.town) ){
                        if(gig.coveredArea.towns[searchArea.town]===false){
                            fit = false;    
                        }
                    } else {
                        fit = false;
                    };    
                }         
            }   
        }
        if(fit===true && searchPrice){
            let price =  parseFloat(gig.feeInfo.price);
            if(searchPrice.priceMin ){
                if( price < parseFloat(searchPrice.priceMin) ){
                    fit = false
                }
            }
            if(searchPrice.priceMax){
                if( price > parseFloat(searchPrice.priceMax) ){
                    fit = false
                }
            }
        }
        if(fit===true && searchDate){
            const { start_date, end_date } = gig.schedule.duration;
            if( moment(searchDate).isSameOrAfter(start_date, 'day') && moment(searchDate).isSameOrBefore(end_date, 'day') ){
                if(gig.MainCat === 'HELP' ||  gig.MainCat === 'TEACH' ){
                    let searchDateObj = moment(searchDate).toDate();
                    let theDay = parseInt(searchDateObj.getDay());
                    console.log("theDay",theDay);
                    if(gig.schedule.weekShift.length-1 >= theDay){
                        if(!gig.schedule.weekShift[theDay]) {
                            fit = false;
                        } else {
                            if( moment().isSame(searchDate, 'day') && gig.indicator.AVAILABLE_NOW===false ){
                                fit = false;    
                            }
                        }  
                    } else {
                        fit = false;
                    }
                    
                } else {
                    if( gig.MainCat === 'RENT' ){
                        if(gig.gigEvents){
                            for(let key in gig.gigEvents){
                                if(gig.gigEvents[key].bookTime && gig.gigEvents[key].Temp === false){
                                    const {start_hr, end_hr} = gig.gigEvents[key].bookTime; 
                                    if( moment(searchDate).isSameOrAfter(start_hr, 'day') && moment(searchDate).isSameOrBefore(end_hr, 'day') ){
                                        fit = false;
                                    }
                                }
                            }
                            if(fit===true && moment().isSame(searchDate, 'day') && gig.indicator.AVAILABLE_NOW===false ){
                                fit = false;
                            }
                        } else {
                            if(fit===true && moment().isSame(searchDate, 'day') && gig.indicator.AVAILABLE_NOW===false ){
                                fit = false;
                            }
                        }
                    }
                }
            } else {
                fit = false;
            }    
        }
        return fit;
    }

    isLocalMatch = (gig) => {
        const { locationAddress } = this.props;
        let match = true;
        if(locationAddress && locationAddress.state && locationAddress.city){
            if(gig.coveredArea && gig.coveredArea.centerAddress){
                const { city, state } = gig.coveredArea.centerAddress;
                match = locationAddress.state === state && locationAddress.city === city; 
            }
        }
        return match;    
    }

    filterGigs = (gigs) => {
        console.log("filterGigs");
        const {MainCat, SubCat, search, localCode, appData} = this.props;
        const {query} = search;
        let filteredGigs = [];
        let activeGigs = gigs.filter( gig => {
            return gig.active === true && gig.publish === true;
        });
        if(activeGigs.length > 0 ){
            if(this.hasCondition(search)){
                filteredGigs = activeGigs.filter( gig => {
                    return this.matchCondition(gig, search);
                });
            } else {
                if(query!=="" && MainCat==='HOME'){
                    // keyword search w/o specifiying MainCat/SubCat
                    filteredGigs = activeGigs.filter( gig => {
                        const RegEx = new RegExp('^'+ query);
                        //if SubCat === 'OTHERS' there is no synonym list
                        const synonym = appData.Synonym[gig.SubCat]
                                        ? appData.Synonym[gig.SubCat][localCode]
                                        : null;
                        const synonymList = synonym 
                                        ? synonym.filter( item => { return RegEx.test(item)} )
                                        : [];   
                        const keywordMatch = ( (gig.SubCat.includes(query) || synonymList.length > 0 ) 
                                                    || gig.title.includes(query) 
                                                    || gig.description.includes(query)　);
                        const localMatch = this.isLocalMatch(gig);
                        return ( keywordMatch && localMatch );
                    });
                } else {
                    // MainCat/SubCat or ManCat/SubCat has been pressed
                    filteredGigs = activeGigs.filter( gig => {
                        let keywordMatch = true;
                        if(MainCat!=='HOME'){
                            keywordMatch = (SubCat!=='ALL') 
                                ? gig.MainCat === MainCat && gig.SubCat === SubCat 
                                : gig.MainCat === MainCat;
                        }
                        const localMatch = this.isLocalMatch(gig);        
                        return ( keywordMatch && localMatch );
                    });
                }
            }
        }
        return filteredGigs;
    }

    getGigList = () => {
        const {gigs} = this.props;
        let list =[];
        for (let key in gigs){
            list.push({id:key, ...gigs[key]});
        }
        let filteredList = list;
        if(list.length > 0 ){
            filteredList = this.filterGigs(list);
        }
        return filteredList;
    }

    handleGoBackPress=() => {
        this.props.onSetMainSubMenu("HOME", "ALL");
    }

    handleGoSellPress=() => {
        console.log("handleGoSellPress");
    }

    renderCatalog = () => {
        const gigList = this.getGigList();
        const {localCode, userMeta, theBuyerMeta, appData} = this.props;
        const handleUpdateFavorite = () => {
            console.log("handleUpdateFavorite");
        }
        if(gigList.length > 0){
            return gigList.map( (item, index) => {
                const theUserMeta = userMeta[item.user];
                return(
                <CatalogItem
                    key={item.id}
                    itemWidth={170} 
                    item={item}
                    theUserMeta={theUserMeta}
                    theBuyerMeta={theBuyerMeta} 
                    index={index} 
                    localCode={localCode}
                    onPress={this.handleGigPress}
                    appData={appData}
                    handleUpdateFavorite={handleUpdateFavorite}
                  />
                );
            });
        } else {
            return(
                <NAMessage 
                    goBackOnPress={this.handleGoBackPress}
                    goSellOnPress={this.handleGoSellPress}
                    localCode={localCode}
                    type="SEARCH_ITEM_NOT_FOUND"
                    mode="Catalog"
                    appData={appData} 
                />
            );
        }
    }

    getStructureData = () => {
      const {
          localCode,
          appData, 
          MainCat, SubCat
      } = this.props;
      const {LocalStrings} = appData;
      const structureData = {
        "@context" : "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type" : "ListItem",
          "position": 1,
          "name" : 'サービスカタログ',
          "item" : `https://${process.env.REACT_APP_DEEPLINK_HOST}/catalog`
        }]
      }
      if(MainCat!=='HOME'){
        structureData.itemListElement.push({
          "@type" : "ListItem",
          "position": 2,
          "name" : `${LocalStrings[localCode][MainCat]}`,
          "item" : `https://${process.env.REACT_APP_DEEPLINK_HOST}/${MainCat.toLowerCase()}`
        });  
      }
      if(SubCat!=='ALL'){
        structureData.itemListElement.push({
          "@type" : "ListItem",
          "position": 3,
          "name" : `${LocalStrings[localCode][SubCat]}`,
          "item" : `https://${process.env.REACT_APP_DEEPLINK_HOST}/${MainCat.toLowerCase()}/${SubCat.toLowerCase()}`
        });  
      }
      return structureData;
    }

    render(){
        const {
            localCode,
            appData, 
            gigsIsLoading, gigs, gigsError,
            userMetaIsLoading, userMeta, userMetaError,
            MainCat, SubCat,
            location
        } = this.props;
        const {LocalStrings} = appData;

        let content = null;

        if( gigsIsLoading || userMetaIsLoading ){
            content = <div className="SpinnerBox">
                <Spinner large />
            </div>
        }

        if(userMeta && gigs){
            content = this.renderCatalog();
        }

        if(gigsError || userMetaError){
            content = <p>{LocalStrings[localCode].ERROR_OCCURED}</p>;
        }
        let pathname = location.pathname;
        let title='サービス一覧表';
        let keyword='助けて,助けます,教えて,教えます,貸して,貸します,作って,作ります,お手伝いサービス,地位域貢献,サービス出品';
        let description = DESCRIPTION.ALL;
        if(MainCat!=='HOME'){
          let words = appData.SubCatItems[MainCat].reduce((acc, subcat) => {
            if(subcat!=='OTHERS' && subcat!=='ALL'){
            let str = LocalStrings[localCode][subcat].replace('ｼﾆｱ同行付添', 'シニア同行付添').replace('ﾘﾗｸｾﾞｰｼｮﾝ','リラクゼーション');
            acc += `${str},`;
            }
            return acc;
          }, '');
          keyword = `${words}お手伝いサービス,地位域貢献,サービス出品`;
          description = DESCRIPTION[MainCat];
          title += ` - ${LocalStrings[localCode][MainCat]}`;
          if(SubCat!=='ALL' && SubCat!=='OTHERS'){
            keyword = `${LocalStrings[localCode][MainCat]},${LocalStrings[localCode][SubCat]},${appData.Synonym[SubCat][localCode].join()},お手伝いサービス,地位域貢献,サービス出品`;
            title += ` | ${LocalStrings[localCode][SubCat]}`;  
          }
        }
        description = `タグジャムサービスカタログでは、${description}の分野で出品されたサービスの一覧を閲覧、または出品可能な項目を確認できます。`;

        return(
        <>
        <Helmet>
            <script type="application/ld+json">
            {plainData(this.getStructureData())}
            </script>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`タグジャム-${title}`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:url" content={pathname} />
            <meta property="og:title" content={`タグジャム-${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:type" content="image/png" />
            <title>{title}</title>
            <meta
              name="description"
              content={description}
            />
            <meta content={`${keyword}`} name="keywords" />
        </Helmet>
        <div className={styles.Catalog}>
            <MainCatMenu 
                MainCat={MainCat}
                onSelected={this.mainMenuPresshander}
                localCode={this.props.localCode}
                appData={ appData }
            />
            { MainCat!=='HOME' &&
            <div className={styles.SubCatMenuBox}>
                <div className={styles.SubCatButtonContainer}>
                    {appData.SubCatItems[MainCat].map( submenu => {
                        return(
                            <SubCatButton 
                                SubCat={submenu}
                                selectedSubMenu={SubCat}
                                localCode={localCode}
                                key={submenu}
                                round={true}
                                onClick={() => this.handleSubCatPress(submenu) }
                                appData={appData}
                            />
                            );
                        })
                    }
                </div>
            </div>
            }
            <div className={styles.CatalogView}>
                {content}
            </div>
        </div>
        </>
        );    
    }
}


const mapStateToProps = ({
    ui:{ Auth, isLoading, Cat: {MainCat, SubCat} },
    local: {localCode},
    appData: {appData},
    userMeta: {userMetaIsLoading, userMeta, userMetaError},
    gigs: {gigsIsLoading, gigs, gigsError },
    search,
}) => ({
    uiAuth: Auth,
    isLoading,
    localCode,
    appData,
    gigsIsLoading, gigs, gigsError,
    userMetaIsLoading, userMeta, userMetaError,
    MainCat, SubCat,
    search,
    locationAddress:null
});

const mapDispatchToProps = dispatch => ({
    onLoadGigs: () => dispatch( fetchGigs() ),
    onLoadUserMeta: () => dispatch( fetchAllUserMeta() ),
    onInitUiErrorState : (initState) => dispatch(initUiErrorState(initState)),
    onToggleErrorWinOpen : () => dispatch(toggleErrorWinOpen()),
    onMainMenuSelected : (key) => dispatch( setMainMenu(key) ),
    onSubMenuSelected : (key) => dispatch( setSubMenu(key) ),
    onSetMainSubMenu : (Main, Sub) => dispatch( setMainSubMenu(Main, Sub) )
});


export default connect(mapStateToProps, mapDispatchToProps)(Catalog);