import React from 'react';
import styles from './SecurityCode.module.scss';
import VISA_MASTER_CVV from '../../../../assets/images/help/buying/cc_security_code/SC_Visa_Master.svg';
import AMEX_CVV from '../../../../assets/images/help/buying/cc_security_code/SC_AMEX.svg';


const SecurityCode = (props) => {
  const {mode} = props;
  const padddingStyle = {
    //padding: '10px',
    background: '#FFFFFF',
    marginTop: '0px'
  }
  return(
    <div className={styles.SecurityCode} style={!mode ?  padddingStyle : null}>
      <strong className={styles.Title}>セキュリティコードとは？</strong>
      <p>クレジットカードの裏面、または表面に表示された数字の事です。カードによって表示箇所が違います。</p>
      <div className={styles.CVVContent}>
        <div className={styles.CVVItem}>
          <img className={styles.ScreenShot} src={VISA_MASTER_CVV} alt="支払い情報の事前登録ステップ1画像" />  
          <p className="Caption">Visa, MasterCard, JCB, Diners&nbsp;Club</p>
        </div>
        <div className={styles.CVVItem}>
          <img className={styles.ScreenShot} src={AMEX_CVV} alt="支払い情報の事前登録ステップ1画像" />
          <p className="Caption">American Express</p>
        </div>
      </div>
    </div>
  );
}

export default SecurityCode;