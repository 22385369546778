import {
    FETCH_PAYMENT,
    SET_PAYMENT
} from './actionTypes';

import { 
    uiStartLoading, 
    uiStopLoading,
    authGetToken
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchPaymentPending = () => ({
    type: `${FETCH_PAYMENT}_PENDING`
});

export const fetchPaymentRejected = (err) => ({
    type: `${FETCH_PAYMENT}_REJECTED`,
    payload: err
});

export const fetchPaymentFulFilled = (payment) => ({
    type: `${FETCH_PAYMENT}_FULFILLED`,
    payload: payment
});

export const fetchPayment = (id) => {
    return async dispatch => {
        dispatch(fetchPaymentPending());
        try{
            let token = await dispatch(authGetToken()); 
            let payment = await taguJamApi.fetchPayment(id, token);
            dispatch(fetchPaymentFulFilled(payment))
            return payment;
        } catch(err){
            dispatch(fetchPaymentRejected(err));
            throw err
        }
    }   
};

export const setPayment = (payment) => ({
    type: SET_PAYMENT,
    payload: payment
});

export const savePayment = ( paymentData, tokenId, id, localCode) => {
    return async dispatch => {
        dispatch(uiStartLoading(`savePayment_${id}`));
        try{
            let token = await dispatch(authGetToken()); 
            let payment = await taguJamApi.savePayment(paymentData, tokenId, id, token, localCode);
            dispatch(setPayment(payment));
            dispatch(uiStopLoading(`savePayment_${id}`));
            return payment;  
        } catch(err){
            dispatch(uiStopLoading(`savePayment_${id}`));
            console.log(err);
            throw err;
        }
    }
}
