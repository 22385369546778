import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux';
import parse, {domToReact} from 'html-react-parser';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
} from "react-share";
import BlogStyles from '../Blog.module.scss';
import styles from './Article.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import { Spinner } from '../../../components/UI';
import FeatureRegular from '../../../components/Blog/FeatureRegular/FeatureRegular';
import FeatureVideo from '../../../components/Blog/FeatureVideo/FeatureVideo';
import SNSButton from '../../../components/Blog/SNSButton/SNSButton';
import DownloadBtns from '../../../components/DownloadBtns/DownloadBtns';
import BlogSectionTitle from '../../../components/Blog/BlogSectionTitle/BlogSectionTitle';
import SearchTags from '../../../components/Blog/SearchTags/SearchTags';
import Pagenation from '../../../components/Blog/Pagenation/Pagenation';
import {normalizedVideoList, handleSubCatPress} from '../../../utils';


class Article extends Component {

    constructor(props) {
      super(props);
      const {
        match,
        blog
      } = this.props;
      let id = match.params && match.params.id;
      let { articles, videos } = blog;
      let howToVideos = normalizedVideoList(videos);
      this.state = {
        currentIndex:0,
        article: articles[id],
        howToVideos
      }
    }

    handleTagPressed = (tagname)=> {
      console.log(`${tagname} is clicked`)
    }

    renderHowToVideo = () => {
      const {howToVideos} = this.state;
      if(howToVideos === null) {
        return (
          <div className="SpinnerBox">
            <Spinner large />
          </div>
        )
      } else {
        let howToMovieRemain = [];
        if(howToVideos.length < 3) {
          for(let i=0; i < 3 - howToVideos.length; i++){
            howToMovieRemain.push(1);
          }
        }
        return(
        <Aux>
          {howToVideos.map((subArticle, index) => {
              const {id} = subArticle;
              if(index < 3){
                return(
                  <div key={`howToMoviesArticle_${id}`} className={styles.PodSmallRegularBox}>
                    <FeatureVideo 
                      item={subArticle} />
                  </div>
                  );
              } else {
                return null;
              }
            })
            }
            <Aux>
            {howToMovieRemain.length > 0 && howToMovieRemain.map((remain, index)=>{
              return(<div key={`remainHowToVideo_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>  
        </Aux>
        );
      }
    }

    updateState = (articleId) => {
      console.log("articleId", articleId);
      const { blog, history } = this.props;
      history.push(`/blog/Article/${articleId}`);
      let { articles, videos } = blog;
      let { featureVideoId, howToVideoId, related } = articles[articleId];
      let relatedArticles = [];
      if(related){
        related.map((articleId) => {
          relatedArticles.push(articles[articleId])
          return true;
        })
      }
      let howToVideos = normalizedVideoList(videos);
      this.setState(prevState => {
        return {
          ...prevState,
          article: articles[articleId],
          featureVideo:featureVideoId ? videos[featureVideoId] : null,
          howToVideo:howToVideoId ? videos[howToVideoId] : null,
          howToVideos,
          relatedArticles
        }
      });
    }

    renderRelatedArticles = (relatedArticles) => {
      if(relatedArticles === null) {
        return null
      } else {
        let relatedArticlesRemain = [];
        if(relatedArticles.length < 3) {
          for(let i=0; i < 3 - relatedArticles.length; i++){
            relatedArticlesRemain.push(1);
          }  
        }
        return(
        <Aux>
          {relatedArticles.map((subArticle, index) => {
              const {id} = subArticle;
              if(index < 3){
                return(
                <div key={`relatedArticle_${id}`} className={styles.PodSmallRegularBox} >
                  <FeatureRegular 
                    item={subArticle} 
                    index={index} 
                    clicked={this.updateState}
                    />
                </div>
                );
              } else {
                return null;
              }
            })
            }
            <Aux>
            {relatedArticlesRemain.length > 0 && relatedArticlesRemain.map((remain, index)=>{
              return(<div key={`remainRelated_${index}`} className={styles.PodSmallRegularBox}/>);
            })
            }
            </Aux>  
        </Aux>
        );
      }
    }

    updateCurrentPage = (currentPage) => {
      this.setState( (prevState) => {
        return {
          ...prevState,
          currentIndex: currentPage-1
        };
      });
      window.scrollTo(0, 0);
    }

    getRelatedArticles = (relatedIds) => {
      const {blog:{articles}} = this.props;
      let relatedArticles = [];
      relatedIds.map((articleId) => {
        if(articles[articleId]){
          relatedArticles.push(articles[articleId])
        }
        return true;
      });
      return relatedArticles;
    }
    
    renderArticle = () => {
      const { appData, localCode, blog } = this.props;
      const {article, currentIndex} = this.state;
      const {LocalStrings} = appData;
      const options = {
        replace: ({name, children, attribs}) => {
          if (!name) {
            return;
          }
          if (name === 'p') {
            return <p className={styles.ArticleP}>{domToReact(children, options)}</p>;
          }
          if (name === 'ul') {
            return <ul className={styles.ArticleUl}>{domToReact(children, options)}</ul>;
          }
          if (name === 'li') {
            return <li className={styles.ArticleLi}>{domToReact(children, options)}</li>;
          }
          if (name === 'img') {
            return <img className={styles.ArticleImg} src={attribs.src} alt={attribs.alt ? attribs.alt : "イメージ"} />;
          }
        }
      };
      if(article===null){
        return(
        <div className="SpinnerBox">
          <Spinner large />
        </div> 
        )
      } else {
        let { videos } = blog;
        const {title, imagePath, id, MainCat, SubCat, body} = article;
        const {description, featureVideoId, howToVideoId, related} = body[currentIndex];
        let theFeatureVideoId = featureVideoId 
                              ? featureVideoId
                              : body[0].hasOwnProperty('featureVideoId') 
                                ? body[0].featureVideoId
                                : null;
        let featureVideo = theFeatureVideoId && videos[theFeatureVideoId] 
                            ? videos[theFeatureVideoId] 
                            : null;
        let theHowToVideoId = howToVideoId 
                              ? howToVideoId
                              : body[0].hasOwnProperty('howToVideoId') 
                                ? body[0].howToVideoId
                                : null;
        let howToVideo = theHowToVideoId && videos[theHowToVideoId] 
                            ? videos[theHowToVideoId] 
                            : null;
        let theRelatedArticlesIds = related 
                                  ? related
                                  : body[0].hasOwnProperty('related') 
                                    ? body[0].related
                                    : null;
        let relatedArticles = theRelatedArticlesIds 
                                ? this.getRelatedArticles(theRelatedArticlesIds)
                                : null;                     
        let SubCatStrings =[LocalStrings[localCode][MainCat]]; 
        SubCat.map((item)=>{
          SubCatStrings.push(LocalStrings[localCode][item]);
          return true;
        });
        const DriftEditorState = EditorState.createWithContent(convertFromRaw(JSON.parse(description)));
        const HTMLcode = draftToHtml(convertToRaw(DriftEditorState.getCurrentContent()));
        return(
        <Aux>
          <h1 className={styles.ArticleTitle}>
            {title}
          </h1>
          <div className={styles.BodyLayout}>
            <div className={styles.BodyLeftBox}>
              <div>{parse(HTMLcode, options)}</div>
              {body.length > 1 &&
                <Pagenation
                  currentPage={currentIndex+1}
                  maxPage={body.length}
                  setCurrentPage={this.updateCurrentPage} 
                />
              }
            </div>
            <div className={styles.BodyRightBox}>
              { featureVideo &&
              <FeatureVideo 
                item={featureVideo} />
              }
              { !featureVideo &&
              <div className={styles.PodImageSmall}>
                <img className={styles.PodImgSmall} src={imagePath} alt={title} />
              </div>
              }
            </div>
          </div>
          <div className={styles.BodyLayout}>
            <div className={styles.BodyLeftBox}>
              <div className={styles.SNSButtonGroup}>
                <FacebookShareButton
                  url={`https://${process.env.REACT_APP_DEEPLINK_HOST}/blog/Article/${id}`}
                  quote={title}
                  hashtags="タグジャム"
                  >
                  <SNSButton type='facebook-share'/>  
                </FacebookShareButton>
                <LineShareButton
                  url={`https://${process.env.REACT_APP_DEEPLINK_HOST}/blog/Article/${id}`}
                  title={title}
                  lineid="@037opbzs"
                  hashtags={[LocalStrings[localCode][MainCat], ...SubCatStrings ]}
                  >
                  <SNSButton type='line-send'/>  
                </LineShareButton>
                <TwitterShareButton 
                  url={`https://${process.env.REACT_APP_DEEPLINK_HOST}/blog/Article/${id}`}
                  title={title}
                  hashtags={[LocalStrings[localCode][MainCat], ...SubCatStrings ]}
                  >
                  <SNSButton type='twitter-tweet'/>  
                </TwitterShareButton>
              </div>
              <div className={styles.DownloadContainer}>
                <DownloadBtns mode='Blog'/>
              </div>
            </div>
            <div className={styles.BodyRightBox}>
              { howToVideo &&
                <FeatureVideo 
                  item={howToVideo} />
              }
              { !howToVideo &&
              <div className={styles.PodImageSmall}>
                <img className={styles.PodImgSmall} src="/tagujam_opg_1200x1200.png" alt={title} />
              </div>
              }
            </div>
          </div>

          {relatedArticles &&
          <BlogSectionTitle
              tagTitle="関連"
              title="関連する記事"
              needMargin={true}
            />
          }
          {relatedArticles &&
          <div className={styles.PodSmallRegularLayout}>
            {this.renderRelatedArticles(relatedArticles)}
          </div>
          }
          {/* 使い方動画 */}
          <BlogSectionTitle
              tagTitle="動画"
              title="使い方動画"
              linkTo="/blog/HowToVideos"
              linkLabel="もっと見る…"
              needMargin={true}
            />
          <div className={styles.PodSmallRegularLayout}>
            {this.renderHowToVideo()}
          </div>
          {/* タグで記事を探す */}
          <BlogSectionTitle
              tagTitle="タグ"
              title="タグで記事を探す"
              needMargin={true}
            />
          <div className={BlogStyles.SearchTagContainer}>
            <SearchTags
              appData={appData}
              localCode={localCode}
              onClick={(subCat) => {handleSubCatPress(subCat, this.props)}} 
            />
          </div>
        </Aux>
        );
      }
    }

    render(){
        const {location} = this.props;
        const {article} = this.state;
        const {title, shortDescription, imagePath, id} = article
        let pathname = location.pathname;
        
        return(
          <>
          <Helmet>
              <link rel="canonical" href={`https://49808367e06f.ngrok.io/blog/Article/${id}`} />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={`タグジャム-${title}`} />
              <meta name="twitter:description" content={shortDescription} />
              <meta name="twitter:image" content={imagePath} />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={`タグジャム-${title}`} />
              <meta property="og:description" content={shortDescription} />
              <meta property="og:image" content={imagePath} />
              <meta property="og:image:secure_url" content={imagePath} data-react-helmet="true" />
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={shortDescription}
              />
              <meta content="タグジャムの活用方法の記事,個人サービスで稼ぐ,始めるを応援,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className={BlogStyles.BlogPageContent}>
            {this.renderArticle()}
          </div>
        </>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
  blog: { blog, blogIsLoading, blogError}
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError,
  blog, blogIsLoading, blogError 
});

export default connect(mapStateToProps, null)(Article);