import {
    SET_LOCAL
} from '../actions/actionTypes';

const initState = {
    localCode: 'ja_jp'
};

const localReducer =  (state=initState, action) => {
    switch(action.type) {
        case SET_LOCAL : {
            return {
                ...state,
                localCode: action.localCode
            }
        }
        default:
            return state;
    }
};

export default localReducer;