const hiraToKanaHalf = (str, half) => {
    const kanaMap = {
        'ガ':'ｶﾞ', 'ギ':'ｷﾞ', 'グ':'ｸﾞ', 'ゲ':'ｹﾞ', 'ゴ':'ｺﾞ',
        'ザ':'ｻﾞ', 'ジ':'ｼﾞ', 'ズ':'ｽﾞ', 'ゼ':'ｾﾞ', 'ゾ':'ｿﾞ',
        'ダ':'ﾀﾞ', 'ヂ':'ﾁﾞ', 'ヅ':'ﾂﾞ', 'デ':'ﾃﾞ', 'ド':'ﾄﾞ',
        'バ':'ﾊﾞ', 'ビ':'ﾋﾞ', 'ブ':'ﾌﾞ', 'ベ':'ﾍﾞ', 'ボ':'ﾎﾞ',
        'パ':'ﾊﾟ', 'ピ':'ﾋﾟ', 'プ':'ﾌﾟ', 'ペ':'ﾍﾟ', 'ポ':'ﾎﾟ',
        'ヴ':'ｳﾞ', 'ヷ':'ﾜﾞ', 'ヺ':'ｦﾞ',
        'ア':'ｱ', 'イ':'ｲ', 'ウ':'ｳ', 'エ':'ｴ', 'オ':'ｵ',
        'カ':'ｶ', 'キ':'ｷ', 'ク':'ｸ', 'ケ':'ｹ', 'コ':'ｺ',
        'サ':'ｻ', 'シ':'ｼ', 'ス':'ｽ', 'セ':'ｾ', 'ソ':'ｿ',
        'タ':'ﾀ', 'チ':'ﾁ', 'ツ':'ﾂ', 'テ':'ﾃ', 'ト':'ﾄ',
        'ナ':'ﾅ', 'ニ':'ﾆ', 'ヌ':'ﾇ', 'ネ':'ﾈ', 'ノ':'ﾉ',
        'ハ':'ﾊ', 'ヒ':'ﾋ', 'フ':'ﾌ', 'ヘ':'ﾍ', 'ホ':'ﾎ',
        'マ':'ﾏ', 'ミ':'ﾐ', 'ム':'ﾑ', 'メ':'ﾒ', 'モ':'ﾓ',
        'ヤ':'ﾔ', 'ユ':'ﾕ', 'ヨ':'ﾖ',
        'ラ':'ﾗ', 'リ':'ﾘ', 'ル':'ﾙ', 'レ':'ﾚ', 'ロ':'ﾛ',
        'ワ':'ﾜ', 'ヲ':'ｦ', 'ン':'ﾝ',
        'ァ':'ｱ', 'ィ':'ｲ', 'ゥ':'ｳ', 'ェ':'ｴ', 'ォ':'ｵ',
        'ッ':'ﾂ', 'ャ':'ﾔ', 'ュ':'ﾕ', 'ョ':'ﾖ',
        '。':'｡', '、':'､', 'ー':'-', '「':'｢', '」':'｣', '・':'･'
    };
    // note: instead of 'ｰ' \uFF70, above will use HYPHEN-MINUS \u002D since bankData use it

    const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');

    str= str.replace(
        /[\u3041-\u3096]/g, 
        match => {
            var chr = match.charCodeAt(0) + 0x60;
            return String.fromCharCode(chr);
        }
    )
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜')
    .replace(/ゕ/g, 'ヵ').replace(/ゖ/g, 'ヶ');

    if (half) {
        str = str.replace(reg, function (match) {
            return kanaMap[match];
        })
        .replace(/゛/g, 'ﾞ')
        .replace(/゜/g, 'ﾟ');
    }

    return str;
}

export default hiraToKanaHalf;