import moment from 'moment';
import 'moment/locale/ja';

export const hasAfter6Option = (theGig) => {
    const {optionService, optionableService}  = theGig;
    let hasAfter6 = false;
    let key, serviceType;
    for(let keyName in optionService){
        if(keyName.includes('AFTER_6') && optionService[keyName]!==false){
            hasAfter6 = true;
            key = keyName;
            serviceType = "optionService";
            break;    
        }    
    }
    if(hasAfter6 === false){
        for(let keyName in optionableService){
            if(keyName.includes('AFTER_6') && optionableService[keyName]!==false){
                hasAfter6 = true;
                key = keyName;
                serviceType = "optionableService";
                break;    
            }    
        }
    }
    return { hasAfter6, key, serviceType };
}

export const getRoundHours = (duration) => {
    let roundMinutes = Math.round(duration/15) * 15 //round by 15 minutes and nearest number
    let hours = roundMinutes / 60; // converted to hours. 0.25, 0.5, 0.75, 1,...
    return hours;
}

export const getCalcAfter6 = (start, end) => {
    let start_hr = moment(start);
    let end_hr = moment(end);
    const startDateStr = start_hr.format("YYYY-MM-DD");
    const sixStart = moment( `${startDateStr} 18:00:00` );
    const duration = end_hr.diff(sixStart, 'minutes');
    const result = getRoundHours(duration) <= 0 ? 0 : getRoundHours(duration);
    return result;    
}

export const getOrderBaseSubTotal = (theOrder) => {
    let { numbers, baseService, customBase }  = theOrder;
    
    let times;
    if(numbers){
        times = numbers.orderHours ? numbers.orderHours : numbers.quantity;
    } else {
        times = 1;
    }
    
    let unitPrice = baseService ? parseInt(baseService.unitPrice) : parseInt(customBase.unitPrice);
    let total = unitPrice * parseFloat(times);
    return Math.round(total);
}

export const getOrderRentalBaseSubTotal = (theOrder) => {
    let { rentalBaseService }  = theOrder;
    let total = parseInt(rentalBaseService.RENT_FEE.unitPrice);
    return Math.round(total);
}

export const getOrderRentalResaleSubTotal = (theOrder) => {
    let { resaleServiceData }  = theOrder;
    let total = parseInt(resaleServiceData.RESALE_RENTAL_ITEM.unitPrice);
    return Math.round(total);
}

export const getOderRentalBaseDepositSubTotal = (theOrder) => {
    let { rentalBaseService, resaleServiceData }  = theOrder;
    let total = 0;
    if(resaleServiceData && Object.keys(resaleServiceData).length > 0){
        total = parseInt(resaleServiceData.RESALE_RENTAL_ITEM.unitPrice); 
    } else {
        total = parseInt(rentalBaseService.DEPOSIT.unitPrice);//+ parseInt(rentalBaseService.RENT_FEE.unitPrice)
    }   
    return Math.round(total);
}

export const getOrderTeachBaseSubTotal = (theOrder) => {
    let { teachBaseService }  = theOrder;
    let total = parseInt(teachBaseService.TUITION.unitPrice);
    return Math.round(total);
}

export const getOrderOptionSubTotal = (theOrder) => {
    let {numbers, optionService, requestDateTime} = theOrder;
    let total = 0;
    for(let key in optionService){
        if(optionService[key].selected){
            let { CHARGE, MULTIPLE, MINUTES_COVERT, unitPrice } = optionService[key];
            let subTotal=0;
            if(CHARGE==='ADD_TO_TOTAL'){
                subTotal += parseInt(unitPrice);
            } else if(MINUTES_COVERT) {
                let serveHr = getRoundHours(MINUTES_COVERT);
                subTotal += parseInt(unitPrice) * serveHr * parseInt(numbers[key]);
            } else if(CHARGE==='HOURLY_PLUS') {
                let after6Hrs = getCalcAfter6(requestDateTime.start, requestDateTime.end);
                let hours = key.startsWith('AFTER_6') 
                            ? numbers[key] 
                                ? numbers[key] 
                                : after6Hrs 
                            : numbers.orderHours;
                let total = parseInt(unitPrice) * parseFloat(hours);
                if(MULTIPLE){
                    total = total * parseInt(numbers[key]);
                }
                subTotal += Math.round(total);
            } else if(CHARGE==='PLACE' || CHARGE==='QUANTITY_NUMBER' ) {
                let total = (numbers && numbers[key]) ? parseInt(unitPrice) * parseFloat(numbers[key]) : 0;
                subTotal += Math.round(total);
            }    
            total += subTotal;
        }
    }
    return total;

}

export const getOrderActualCostSubTotal = (theOrder) => {
    let {actualCostService} = theOrder;
    let total = 0;
    for(let key in actualCostService){
        if(actualCostService[key].selected){
            let { unitPrice } = actualCostService[key];
            total += parseInt(unitPrice);
        }
    }
    return total;    
}

export const getOrderReturnOptionsSubTotal = (theOrder) => {
    let {returnOptionService, numbers} = theOrder;
    let total = 0;
    for(let key in returnOptionService){
        if(returnOptionService[key].selected){
            let { CHARGE, unitPrice } = returnOptionService[key];
            let subTotal=0;
            if(CHARGE==='ADD_TO_TOTAL'){
                subTotal += parseInt(unitPrice)   
            } else if(CHARGE==='PLACE' || CHARGE==="PER_RENT_UNIT" ) {
                let total = (numbers && numbers[key]) ? parseInt(unitPrice) * parseFloat(numbers[key]) : 0;
                subTotal += Math.round(total);
            } 
            total += subTotal;
        }
    }
    return total;        
}

const minuteConvertType = (optionService) => {
    let minuteConvertType = false;
    let unitPriceBase = 0;
    if(optionService && Object.keys(optionService).length > 0) {
        for (let key in optionService){
            let { MINUTES_COVERT, unitPrice } = optionService[key];
            if(MINUTES_COVERT){
                minuteConvertType = true;
                unitPriceBase = unitPrice;    
                break;    
            }
        }
    }
    return {minuteConvertType, unitPriceBase};
}

export const getOrderAllTotal = (theOrder, deposit) => {
    const { 
        customBase, 
        baseService,
        rentalBaseService,
        resaleServiceData,
        teachBaseService,  
        optionService, 
        actualCostService, 
        returnOptionService,
        cancelFee
    } = theOrder;
    let total = 0;
    if( (customBase && Object.keys(customBase).length > 0) || (baseService && Object.keys(baseService).length > 0) ){
        total += getOrderBaseSubTotal(theOrder);
    }
    if(rentalBaseService && Object.keys(rentalBaseService).length > 0){
        total += deposit 
        ? getOderRentalBaseDepositSubTotal(theOrder)
        : getOrderRentalBaseSubTotal(theOrder);
    }
    if(resaleServiceData && Object.keys(resaleServiceData).length > 0){
        total += getOderRentalBaseDepositSubTotal(theOrder);  
    }
    if(teachBaseService && Object.keys(teachBaseService).length > 0){
        total += getOrderTeachBaseSubTotal(theOrder);
    }
    if(optionService && Object.keys(optionService).length > 0){
        if( !rentalBaseService || (rentalBaseService && !deposit) ){
            total += getOrderOptionSubTotal(theOrder);
        }
        if(deposit){
            let minuteConvert = minuteConvertType(optionService);
            if(minuteConvert.minuteConvertType) {
                //MINUTES_COVERT case, add additinal 1.5 hours for deposit
                total += Math.round(minuteConvert.unitPriceBase * 1.5);  
            }
        } 
    }
    if(actualCostService && Object.keys(actualCostService).length > 0){
        total += getOrderActualCostSubTotal(theOrder);
    }
    if(returnOptionService && Object.keys(returnOptionService).length > 0){
        if(!deposit){
            total += getOrderReturnOptionsSubTotal(theOrder);
        }
    }
    if(cancelFee){
        total = total * (parseInt(cancelFee.cancelPersent) / 100 ); 
    }
    return total;     
}

export const getOrderHandlingFee = (afterTotal, handlingPersent) => {
    let persent = handlingPersent/100;
    return Math.round( afterTotal * persent);
}

export const getOrderAfterDiscountTotal = (allTotal, theOrder) => {
    const { discount: {selected, unitPrice} }= theOrder;
    let afterTotal = allTotal;
    if(selected){
        afterTotal -= parseInt(unitPrice);
    }
    return afterTotal    
}

export const getOrderProfit = (afterTotal, handlingFee) => {
    let profit = parseInt(afterTotal) - parseInt(handlingFee);
    return profit;
}

const getRequiredObj = (serviceItemData) => {
    let requiredObj = {};
    if(serviceItemData.requiredBase && serviceItemData.requiredBase.list){
        serviceItemData.requiredBase.list.map( item => {
            requiredObj = {
                ...requiredObj,
                [item]:true
            };
            return true;
        });    
    }
    return requiredObj;
}

const getOptionChargeObj = (serviceItemData, mode) => {
    let optionChargeObj = {};
    let baseObj = serviceItemData[mode];
    if(baseObj.list && baseObj.list.length >0 ){
        baseObj.list.map( optionItem => {
            let newObj = {...optionItem};
            delete newObj.TYPE;
            optionChargeObj = {
                ...optionChargeObj,
                [optionItem.TYPE] : newObj
            }
            return true;    
        })    
    }
    return optionChargeObj;
}

const isServiceSelected = (estimate, key) => {
    if(estimate.baseService && estimate.baseService[key]){
        return true;    
    } else if(estimate.optionableService && estimate.optionableService[key]){
        return true;    
    } else if(estimate.optionService && estimate.optionService[key]){
        return true;    
    } else {
        return false;
    }    
}

const processAfter6 = (requestDateTimeObj, theGig) => {
    let value = false;
    let keyname = null;
    if(theGig.MainCat === 'HELP'){
        const after6Obj = hasAfter6Option(theGig);
        const {hasAfter6, key} =  after6Obj;
        keyname = key;
        if( hasAfter6 ){
            if(requestDateTimeObj){
                const end = moment(requestDateTimeObj.end); 
                const endDateStr = end.format("YYYY-MM-DD");
                if(end.isAfter(`${endDateStr} 18:00:00`)){
                    value = true;
                }
            } 
        }
    }
    return value ? keyname : value;    
}

export const createOrder = (theGig, estimate, appData, cancelPersent, deposit, resale) => {
    const { ServiceData } = appData;
    const {MainCat, SubCat, baseService, customBase, optionableService, optionService, feeInfo, actualCostService, returnOptionService} = theGig;
    const {requestDateTime, numbers} = estimate;
    const serviceItemData = ServiceData[MainCat][SubCat]; 
    let orderData = {};
    let baseServiceData = {}; 
    let rentalBaseServiceData = {};
    let resaleServiceData = {};
    let teachBaseServiceData = {};
    let optionServiceData= {};
    let actualCostServiceData={};
    let returnOptionServiceData={};

    if(baseService){
        let requiredObj = getRequiredObj(serviceItemData);
        for(let key in baseService){
            if(baseService[key]){
                baseServiceData = {
                    ...baseServiceData,
                    [key]: {
                        selected:estimate.baseService && estimate.baseService[key] ? true : false,
                        required:requiredObj[key] ? true : false 
                    } 
                } 
            }      
        };
        baseServiceData = {
            ...baseServiceData,
            unitPrice: feeInfo.price,
            unit: feeInfo.unit
        };
        orderData = {
            ...orderData,
            baseService:baseServiceData
        } 
    }

    if(MainCat==='RENT'){
        if(resale){
            resaleServiceData = {
                RESALE_RENTAL_ITEM: {
                    selected:true,
                    required:true,
                    unitPrice: feeInfo.DEPOSIT  
                }
            };
            orderData = {
                ...orderData,
                resaleServiceData:resaleServiceData
            }
        } else {
            rentalBaseServiceData = {
                DEPOSIT: {
                    selected:true,
                    required:true,
                    unitPrice: feeInfo.DEPOSIT 
                },
                DEPOSIT_RETURN: {
                    selected:true,
                    required:true,
                    unitPrice: parseInt(feeInfo.DEPOSIT) * -1    
                },
                RENT_FEE:{
                    selected:true,
                    required:true,
                    unitPrice: feeInfo.price,
                    unit: feeInfo.unit//unit==='DAY' ? 'HOURS': 'DAY';    
                } 
            };
            rentalBaseServiceData = {
                ...rentalBaseServiceData,
                unit: feeInfo.unit
            };
            orderData = {
                ...orderData,
                rentalBaseService:rentalBaseServiceData
            }  
        }    
    }

    if(MainCat==='TEACH'){
        teachBaseServiceData = {
            TUITION:{
                selected:true,
                required:true,
                unitPrice: feeInfo.price,
                unit: feeInfo.lengthUnit,
                length: feeInfo.length    
            } 
        };
        teachBaseServiceData = {
            ...teachBaseServiceData,
            unit: feeInfo.lengthUnit
        };
        orderData = {
            ...orderData,
            teachBaseService:teachBaseServiceData
        }      
    }

    if(customBase){
        let customBaseData = {
            ...customBase,
            unitPrice: feeInfo.price,
            unit: feeInfo.unit
        }
        orderData = {
            ...orderData,
            customBase:customBaseData 
        };
    }

    if(!cancelPersent){
        if(optionableService){
            for(let key in optionableService){
                if(optionableService[key]){
                    optionServiceData = {
                        ...optionServiceData,
                        [key]: {
                            selected: estimate.optionableService && estimate.optionableService[key] ? true : false,
                            ...serviceItemData.optionable[key],
                            unitPrice: optionableService[key]
                        } 
                    } 
                }      
            }
        }
        
        if(optionService){
            let optionChargeObj = getOptionChargeObj(serviceItemData, 'options');
            for(let key in optionService){
                if(optionService[key]){
                    const {MINUTES_COVERT} = optionChargeObj[key];
                    optionServiceData = {
                        ...optionServiceData,
                        [key]: {
                            selected: estimate.optionService && estimate.optionService[key] ? true : false,
                            ...optionChargeObj[key],
                            unitPrice: optionService[key]
                        } 
                    }
                    if(MINUTES_COVERT){
                        optionServiceData = {
                            ...optionServiceData,
                            [key]: {
                                ...optionServiceData[key],
                                unitPrice: feeInfo.price,
                                MINUTES_COVERT:optionService[key]
                            } 
                        }    
                    } 
                }      
            }
        }
    
        if(Object.keys(optionServiceData).length > 0){
            orderData = {
                ...orderData,
                optionService:optionServiceData
            } 
        }
        
        if(actualCostService){
            for(let key in actualCostService){
                let selected = false;
                if(actualCostService[key]){
                    if(deposit){
                        if(key==='SHOPPING_FEE') {
                            if(isServiceSelected(estimate, "SHOPPING_ACCOMPANY") || isServiceSelected(estimate, "SHOPPING")){
                                selected = true;
                            }
                        } else if(key==='MEAL_FEE'){
                            selected = isServiceSelected(estimate, "DINE_OUT_ACCOMPANY")
                        } else if(key==='ADMISSION_FEE'){ 
                            selected = isServiceSelected(estimate, "ENTERTAINMENT_ACCOMPANY")
                        } else if(key==='FOOD_COST'){    
                            selected = isServiceSelected(estimate, "FOOD_SHOPPING")
                        } else if(key==='PET_FOOD_TREAT_FEE'){ 
                            if(isServiceSelected(estimate, "SNACK_CARE") || isServiceSelected(estimate, "MEAL_CARE")){
                                selected = true;
                            } 
                        } else {
                            /*
                            TRANSPORTATION_COST, 
                            EDU_MATERIAL_COST, 
                            ADDITIONAL_MATERIAL_FEE, 
                            ADDITIONAL_LABOR_FEE, 
                            OUTOF_AREA_SHIPPING_FEE
                            */
                            selected = true;
                        }
                    } else {
                        selected = true;    
                    }
                    actualCostServiceData = {
                        ...actualCostServiceData,
                        [key]: {
                            selected,
                            unitPrice: actualCostService[key]
                        } 
                    }
                }      
            }
            if(Object.keys(actualCostServiceData).length > 0){
                orderData = {
                    ...orderData,
                    actualCostService:actualCostServiceData
                } 
            }    
        }
    
        if(returnOptionService){
            if(!resale){
                let optionChargeObj = getOptionChargeObj(serviceItemData, 'returnOptions');;
                for(let key in returnOptionService){
                    if(returnOptionService[key]){
                        returnOptionServiceData = {
                            ...returnOptionServiceData,
                            [key]: {
                                selected: false,
                                ...optionChargeObj[key],
                                unitPrice: returnOptionService[key]
                            } 
                        }
                    }      
                }
                if(Object.keys(returnOptionServiceData).length > 0){
                    orderData = {
                        ...orderData,
                        returnOptionService:returnOptionServiceData
                    } 
                }  
            } 
        }
    }

    if(cancelPersent){
        orderData = {
            ...orderData,
            cancelFee:{cancelPersent: cancelPersent}
        }
    }

    if(requestDateTime && Object.keys(requestDateTime).length > 0){
        let newRequestDateTime = {...requestDateTime};
        let isAfter6 = false;
        if(deposit && MainCat==='HELP'){
            let minuteConvert = minuteConvertType(optionServiceData);
            let addHours = 2;
            if(minuteConvert.minuteConvertType){
                addHours = 1.5;
            }
            const endTime = moment(requestDateTime.end).add(addHours, 'hours').format();
            newRequestDateTime = {
                ...newRequestDateTime,
                end: endTime
            }
            isAfter6 = processAfter6(newRequestDateTime, theGig);
        }
        
        orderData = {
            ...orderData,
            requestDateTime: newRequestDateTime
        }

        if(isAfter6!==false){
            orderData = {
                ...orderData,
                optionService:{
                    ...optionServiceData,
                    [isAfter6]: {
                        ...optionServiceData[isAfter6],
                        selected: true
                    }
                }
            }
        }    
    }

    if(numbers && Object.keys(numbers).length > 0){
        let newNumbers = null;
        if(deposit && (MainCat==='HELP' || MainCat==='MAKE') ) {
            if(numbers.orderHours){
                let minuteConvert = minuteConvertType(optionServiceData);
                let addHours = 2;
                if(minuteConvert.minuteConvertType){
                    addHours = 1.5;
                }
                newNumbers = { ...numbers, orderHours: parseInt(numbers.orderHours) + addHours }  
            } else {
                newNumbers = { ...numbers, quantity : parseInt(numbers.quantity) };
            }
        }
        orderData = {
            ...orderData,
            numbers: newNumbers ? newNumbers : numbers
        }
    } else {
        orderData = {           
            ...orderData 
        }    
    }  

    const docId = deposit 
        ? `${estimate.id}-1-dpt`
        : `${estimate.id}-1`;
    
    orderData = {
        ...orderData,
        discount: { selected: false, unitPrice: 0},
        id: docId
    }

    return orderData;
}
