import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import PausePayment1 from '../../../assets/images/help/buying/buy_pause_payment/buy_pause_payment_1.jpg';
import PausePayment2 from '../../../assets/images/help/buying/buy_pause_payment/buy_pause_payment_2.jpg';
import PausePayment3 from '../../../assets/images/help/buying/buy_pause_payment/buy_pause_payment_3.jpg';
import PausePayment4 from '../../../assets/images/help/buying/buy_pause_payment/buy_pause_payment_4.jpg';
import PausePayment5 from '../../../assets/images/help/buying/buy_pause_payment/buy_pause_payment_5.jpg';
import PausePayment6 from '../../../assets/images/help/buying/buy_pause_payment/buy_pause_payment_6.jpg';
import PausePayment7 from '../../../assets/images/help/buying/buy_pause_payment/buy_pause_payment_7.jpg';


class PausePayment extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>請求金額に問題がある場合</span>
            </h3>
            <p className={styles.ArticleDesc}>
            請求金額を確認した際に、売り手が間違えている箇所や疑問がある場合は以下の手順で支払いの一時停止を行ってください。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。        
                    </li>
                    <img className={styles.ScreenShot} src={PausePayment1} alt="請求金額に問題がある場合ステップ1画像" />
                    <li>
                        「請求書が届いています」と表示されている取引をタップします。           
                    </li>
                    <img className={styles.ScreenShot} src={PausePayment2} alt="請求金額に問題がある場合ステップ2画像" />
                    <li>
                        「請求書を見る」をタップします。           
                    </li>
                    <img className={styles.ScreenShot} src={PausePayment3} alt="請求金額に問題がある場合ステップ3画像" />
                    <li>
                        請求内容を確認します。問題がある場合は「料金に問題がある」のボタンをタップしてください。＊サービス提供者が質問に返信し、再度請求書を送信するまで、支払いは一時保留になります。          
                    </li>
                    <img className={styles.ScreenShot} src={PausePayment4} alt="請求金額に問題がある場合ステップ4画像" />
                    <li>
                        「支払い保留の理由」を選択してください。メッセージが自動入力されます。メッセージを編集し請求金額の質問を入力して下さい。「支払い保留送信」ボタンをタップします。          
                    </li>
                    <img className={styles.ScreenShot} src={PausePayment5} alt="請求金額に問題がある場合ステップ5画像" />
                    <li>
                        「会話に戻る」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={PausePayment6} alt="請求金額に問題がある場合ステップ6画像" />
                    <li>
                        支払い保留のメッセージを確認します。    
                    </li>
                    <img className={styles.ScreenShot} src={PausePayment7} alt="請求金額に問題がある場合ステップ7画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(PausePayment);