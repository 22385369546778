import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import SellerStartService1 from '../../../assets/images/help/selling/sell_start_service/sell_start_service_help/sell_start_service_help_1.jpg';
import SellerStartService1Teach from '../../../assets/images/help/selling/sell_start_service/sell_start_service_teach/sell_start_service_teach_1.jpg';
import SellerStartService1Rent from '../../../assets/images/help/selling/sell_start_service/sell_start_service_rent/sell_start_service_rent_1.jpg';
import SellerStartService1Make from '../../../assets/images/help/selling/sell_start_service/sell_start_service_make/sell_start_service_make_1.jpg';

import SellerStartService2 from '../../../assets/images/help/selling/sell_start_service/sell_start_service_help/sell_start_service_help_2.jpg';
import SellerStartService2Teach from '../../../assets/images/help/selling/sell_start_service/sell_start_service_teach/sell_start_service_teach_2.jpg';
import SellerStartService2Rent from '../../../assets/images/help/selling/sell_start_service/sell_start_service_rent/sell_start_service_rent_2.jpg';
import SellerStartService2Make from '../../../assets/images/help/selling/sell_start_service/sell_start_service_make/sell_start_service_make_2.jpg';

import SellerStartService3 from '../../../assets/images/help/selling/sell_start_service/sell_start_service_help/sell_start_service_help_3.jpg';
import SellerStartService3Teach from '../../../assets/images/help/selling/sell_start_service/sell_start_service_teach/sell_start_service_teach_3.jpg';
import SellerStartService3Rent from '../../../assets/images/help/selling/sell_start_service/sell_start_service_rent/sell_start_service_rent_3.jpg';
import SellerStartService3Make from '../../../assets/images/help/selling/sell_start_service/sell_start_service_make/sell_start_service_make_3.jpg';

import SellerStartService4Teach from '../../../assets/images/help/selling/sell_start_service/sell_start_service_teach/sell_start_service_teach_4.jpg';

import SellerStartService5Teach from '../../../assets/images/help/selling/sell_start_service/sell_start_service_teach/sell_start_service_teach_5.jpg';

import SellerStartService6Teach from '../../../assets/images/help/selling/sell_start_service/sell_start_service_teach/sell_start_service_teach_6.jpg';
import SellerStartService6Rent from '../../../assets/images/help/selling/sell_start_service/sell_start_service_rent/sell_start_service_rent_4.jpg';

import SellerStartService7 from '../../../assets/images/help/selling/sell_start_service/sell_start_service_help/sell_start_service_help_4.jpg';
import SellerStartService7Teach from '../../../assets/images/help/selling/sell_start_service/sell_start_service_teach/sell_start_service_teach_7.jpg';
import SellerStartService7Rent from '../../../assets/images/help/selling/sell_start_service/sell_start_service_rent/sell_start_service_rent_5.jpg';
import SellerStartService7Make from '../../../assets/images/help/selling/sell_start_service/sell_start_service_make/sell_start_service_make_4.jpg';

class SellerStartService extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    articleClickedHandler = (article) => {
        console.log(article);
        if(article === 'cancelpolicy'){
            this.props.history.push({
                pathname: `/${article}`
            });
        } else {
            this.props.history.push({
                pathname: `/help/${article}`
            });
        }
    }
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービスを提供をする（{this.getCategory()}）</span>
            </h3>
            {currentTab === 'HELP' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            <strong>事前準備 </strong>: サービス開始前までに必要な道具などきちんと準備しておきましょう。買い手の方にサービスを提供するにあたって必要な情報の質問は全て済ませておきましょう。「訪問型」の場合は訪問先の場所や、行き方、交通費などをきちんと調べておきましょう。
            </p>
            <p className={styles.ArticleDesc}>
            確定された予約のサービスの開始日になり実際にサービスを提供を始める前には、以下の手順でサービス開始を行ってください。
            </p>
            </Aux>
            }
            {currentTab === 'TEACH' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            <strong>事前準備 </strong>: クラス開始前までに必要な教材などはきちんと準備しておきましょう。買い手の方にクラスを提供するにあたって必要な情報の質問は全て済ませておきましょう。「訪問型」の場合は訪問先の場所や、行き方、交通費などをきちんと調べておきましょう。
            </p>
            <p className={styles.ArticleDesc}>
            クラス予定時刻になり、実際にクラスを始める前には、以下の手順でクラスの受付を行ってください。
            </p>
            </Aux>
            }
            {currentTab === 'RENT' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            <strong>事前準備 </strong>: レンタル開始前までにレンタル品の点検・整備を行っておきましょう。借り手の方にレンタル品を提供するにあたって必要な情報の質問は全て済ませておきましょう。「配達」サービスを提供する場合は配達先の場所や、行き方、などをきちんと調べておきましょう。
            </p>
            <p className={styles.ArticleDesc}>
            確定されたレンタルの開始日になりレンタル提供を始める前には、以下の手順でサービス開始を行ってください。
            </p>
            </Aux>
            }
            {currentTab === 'MAKE' && 
            <Aux>
            <p className={styles.ArticleDesc}>
            <strong>事前準備 </strong>: 取引が成立したら取引チャットを使い買い手の方の作品を仕上げるのに必要な情報の質問を行いましょう。
            </p>
            <p className={styles.ArticleDesc}>
            必要な情報が集まり、作成を開始する場合は以下の手順で作成開始の手続きをしてください。
            </p>
            </Aux>
            }
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「取引中」を選びます。
                    </li>
                    { currentTab==='HELP' && 
                        <img className={styles.ScreenShot} src={SellerStartService1} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='TEACH' && 
                        <img className={styles.ScreenShot} src={SellerStartService1Teach} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='RENT' && 
                        <img className={styles.ScreenShot} src={SellerStartService1Rent} alt="サービスを提供をするステップ1画像" />
                    }
                    { currentTab==='MAKE' && 
                        <img className={styles.ScreenShot} src={SellerStartService1Make} alt="サービスを提供をするステップ1画像" />
                    }
                    
                    {currentTab === 'HELP' && 
                    <Aux>
                        <li>
                        サービスを開始する「予約が確定されました」と表示された取引をタップします。        
                        </li>
                        <img className={styles.ScreenShot} src={SellerStartService2} alt="サービスを提供をするステップ2画像" />
                    </Aux>
                    }
                    {currentTab === 'TEACH' && 
                    <Aux>
                        <li>
                        クラスの受付をしたい買い手の方の「予約が確定されました」が表示された取引をタップします。        
                        </li>
                        <img className={styles.ScreenShot} src={SellerStartService2Teach} alt="サービスを提供をするステップ2画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' && 
                    <Aux>
                        <li>
                        レンタルを開始する「予約が確定されました」と表示された取引をタップします。       
                        </li>
                        <img className={styles.ScreenShot} src={SellerStartService2Rent} alt="サービスを提供をするステップ2画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' && 
                    <Aux>
                        <li>
                        作成を開始する「予約が確定されました」と表示された取引をタップします。        
                        </li>
                        <img className={styles.ScreenShot} src={SellerStartService2Make} alt="サービスを提供をするステップ2画像" />
                    </Aux>
                    }

                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                    「サービスを開始する」ボタンをタップします。  
                    </li>
                    <p className="Caption">＊「サービスを開始する」ボタンは予約されたサービス開始時刻の１０分前に画面に出力されます。</p>
                    <img className={styles.ScreenShot} src={SellerStartService3} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }
                    {currentTab === 'TEACH' &&
                    <Aux>
                    <li>
                    「出席コードを読み取る」ボタンをタップします。   
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService3Teach} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    配達がある場合は、レンタル品を貸し手の指定した住所に届けます。レンタル申請者（借り手）がレンタル品を受け取ったら「レンタル品引き渡し完了」ボタンをタップします。
                    <p className="Caption">＊住所は見積もりにて確認できます。</p> 
                    <p className="Caption">＊「レンタル品引き渡し完了」ボタンは予約されたレンタル開始日に表示されます。</p>  
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService3Rent} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    「作成を開始する」ボタンをタップします。   
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService3Make} alt="サービスを提供をするステップ3画像" />
                    </Aux>
                    }

                    {currentTab === 'TEACH' &&
                    <Aux>
                    <li>
                    カメラへの許可を未だの場合は「許可」をタップします。すでに許可している場合にはこの手順は飛ばしてください。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService4Teach} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                    {currentTab === 'TEACH' &&
                    <Aux>
                    <li>
                    カメラが作動したら、クラス出席者クラスコードの表示画面をカメラの枠内に写し、出席コード（QR）を読み取ります。        
                    </li>
                    <p className="Caption">「訪問型」：代表者のQRコードのみを読み取ってください。</p>
                    <p className="Caption">「来客型」：参加者全員分のQRコードを読み取ってください。</p>
                    <img className={styles.ScreenShot} src={SellerStartService5Teach} alt="サービスを提供をするステップ5画像" />
                    </Aux>
                    }

                    {currentTab === 'TEACH' &&
                    <Aux>
                    <li>
                    出席コード（QR）が正しく読み取れた場合は以下のような画面が表示され出席の確認が取れます。「会話に戻る」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService6Teach} alt="サービスを提供をするステップ6画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    「レンタル品を渡しました」のメッセージが送られたのを確認しましょう。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService6Rent} alt="サービスを提供をするステップ5画像" />
                    </Aux>
                    }

                    {currentTab === 'HELP' &&
                    <Aux>
                    <li>
                        サービス開始のメッセージが送られたのを確認しましょう。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService7} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                    {currentTab === 'TEACH' &&
                    <Aux>
                    <li>
                    「クラスの参加を確認済み」と「請求書発送済み」の２つのメッセージが送られている事を確認します。        
                    </li>
                    <p className="Caption">＊「訪問型」は請求書の自動発送はされません。「請求書を作成」ボタンをタップして作成し送信してください。請求書発送の手順は<a href="/help" onClick={ e => { e.preventDefault(); this.articleClickedHandler('SendInvoice') } }>こちら</a></p>
                    <img className={styles.ScreenShot} src={SellerStartService7Teach} alt="サービスを提供をするステップ7画像" />
                    </Aux>
                    }
                    {currentTab === 'RENT' &&
                    <Aux>
                    <li>
                    レンタル申請者（借り手）と共に最初からあるダメージや損傷を確認しましょう。確認後に問題なければ、レンタル申請者に「レンタル品受け取り完了」のボタンをタップしてもらいましょう。その後「レンタル品受け取り」のメッセージが確認できます。
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService7Rent} alt="サービスを提供をするステップ5画像" />
                    </Aux>
                    }
                    {currentTab === 'MAKE' &&
                    <Aux>
                    <li>
                    「作成開始」のメッセージを確認します。        
                    </li>
                    <img className={styles.ScreenShot} src={SellerStartService7Make} alt="サービスを提供をするステップ4画像" />
                    </Aux>
                    }
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SellerStartService);