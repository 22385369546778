import React, { Component } from 'react';
import styles from './Calender.module.scss';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    dateSelected,
} from '../../store/actions';
import {
    hasAvailabeTime,
    getEventsOfTheShift,
    getEventList,
    getShiftList,
    getEventsOftheDay,
    renderCalenderType,
    getCalenderData,
    renderDayHead,
    renderRow,
    getClassEventsOfTheShift,
    getNoneClassEventsOfTheShift
} from './calenderUtils';
import DaySchedule from './DaySchedule';

class Calender extends Component {
    state = {
        calender: getCalenderData().monthData,
        calenderDateObj:getCalenderData().today
    }

    renderDateObj = (calenderDate) => {
        let CalenderData = getCalenderData(calenderDate);
        this.setState(prevState => {
            return {
                ...prevState,
                calender:CalenderData.monthData,
                calenderDateObj:CalenderData.today
            }
        });
    }

    isFullBooked = (eventsOftheDay, partiallyBooked, onShift, Day) => {
        const {
            theGig, 
            numbers,
            hasMinuteConvert
        } = this.props;
        const { 
          MainCat, 
          feeInfo: { accepted, acceptedUnit, lengthUnit, length, MAX_PERSON }, 
          indicator:{VISIT_TYPE, AVAILABLE_NOW }, 
          hoursBeforeOrder,
          user
      } = theGig;
        let acceptedMinutes;

        if(MainCat==='TEACH'){
            acceptedMinutes = lengthUnit === 'HOUR' ?  parseFloat(length) * 60 : parseFloat(length);
        } else {
            acceptedMinutes = acceptedUnit==='HOUR_ACCEPTED' ?  parseFloat(accepted) * 60 : parseFloat(accepted);
        }
        if(hasMinuteConvert){
            if(numbers && numbers["orderHours"]){
                let orderMins = parseFloat(numbers["orderHours"]) * 60;
                acceptedMinutes = acceptedMinutes > orderMins ? acceptedMinutes : orderMins;
            } 
        }
    
        if (MainCat === 'RENT') {
          if (partiallyBooked) {
            return true;
          } else {
            if (eventsOftheDay.length === 0) {
              if (moment().isSame(Day, 'day')) {
                if (AVAILABLE_NOW) {
                  let dateStr = moment().format('YYYY-MM-DD');
                  let minsBeforeOrder = parseFloat(hoursBeforeOrder) * 60;
                  let afterHour = moment(`${dateStr} 13:00:00`).subtract(
                    minsBeforeOrder,
                    'minutes'
                  );
                  if (moment().isBefore(afterHour)) {
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        } else {
          const shiftList = getShiftList(onShift, Day);
          let availTimeList = [];
          if (renderCalenderType(theGig) === 'SEAT') {
            shiftList.map((shift) => {
              let eventOfTheShift = getClassEventsOfTheShift(
                eventsOftheDay,
                shift,
                user
              );
              let noneClassEventsOfTheShift = getNoneClassEventsOfTheShift(
                eventsOftheDay,
                shift,
                user
              );
              if (noneClassEventsOfTheShift.length === 0) {
                if (eventOfTheShift.length < MAX_PERSON) {
                  if (moment().isSame(shift.start, 'day')) {
                    if (AVAILABLE_NOW) {
                      let minsBeforeOrder = parseFloat(hoursBeforeOrder) * 60;
                      let now = moment().add(minsBeforeOrder, 'minutes');
                      if (moment(shift.start).isAfter(now)) {
                        availTimeList.push([shift]);
                      }
                    }
                  } else {
                    availTimeList.push([shift]);
                  }
                }
              }
            });
          } else {
            shiftList.map((shift) => {
              let eventOfTheShift = getEventsOfTheShift(eventsOftheDay, shift);
              let availTime = hasAvailabeTime(
                eventOfTheShift,
                shift,
                acceptedMinutes,
                VISIT_TYPE,
                AVAILABLE_NOW,
                hoursBeforeOrder
              );
              if (availTime.length > 0) {
                availTimeList.push(availTime);
              }
            });
          }
          return availTimeList.length === 0;
        }
    }

    isPartiallyBooked = (eventsOftheDay, onShift, Day) => {
      const {theGig} = this.props;
      const {
        MainCat,
        feeInfo: {MAX_PERSON},
        user,
      } = theGig;
  
      if (MainCat === 'RENT') {
        if (eventsOftheDay.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        const shiftList = getShiftList(onShift, Day);
        let partiallyBooked = false;
        if (renderCalenderType(theGig) === 'SEAT') {
          shiftList.map((shift) => {
            let eventOfTheShift = getClassEventsOfTheShift(
              eventsOftheDay,
              shift,
              user
            );
            let noneClassEventsOfTheShift = getNoneClassEventsOfTheShift(
              eventsOftheDay,
              shift,
              user
            );
            if (noneClassEventsOfTheShift.length === 0) {
              if (
                eventOfTheShift.length > 0 &&
                eventOfTheShift.length < MAX_PERSON
              ) {
                partiallyBooked = true;
              }
            }
          });
        } else {
          shiftList.map((shift) => {
            let eventOfTheShift = getEventsOfTheShift(eventsOftheDay, shift);
            if (eventOfTheShift.length > 0) {
              partiallyBooked = true;
            }
          });
        }
        return partiallyBooked;
      }
    };

    isSelected = (Day) => {
        const { interview, selectedDate, interviewSelectedDate} = this.props;
        let isSelected = false;
        let selDateObj = interview && interview === true ? interviewSelectedDate : selectedDate;
        if(selDateObj) {
            if(selDateObj.end){
                isSelected = ( selDateObj.start <= Day.getTime() ) 
                        && ( Day.getTime() < selDateObj.end );    
            } else {
                isSelected = selDateObj.start.getTime() === Day.getTime()    
            }
        }
        return isSelected;    
    }

    dateStyleConfig = (Day) => {
        const { theGig, events, gigEvents, mode, appData } = this.props;
        const { MainCat, schedule: {duration, weekShift} } = theGig;
        const calenderType = renderCalenderType(theGig);
        const { Colors } = appData;
        const isDatePast = moment().isAfter(Day, 'day') ? true : false;
        const withinDuration = (moment(duration.start_date).isSameOrBefore(Day, 'day') && moment(duration.end_date).isSameOrAfter(Day, 'day')) ? true: false;
        const eventsOftheDay = MainCat === 'RENT' ? getEventsOftheDay(Day, gigEvents, MainCat) : getEventsOftheDay(Day, events, MainCat); //this.hasEvent(Day);
        const onShift =  (MainCat !== 'RENT' && MainCat !== 'MAKE') ? weekShift[Day.getDay()] : withinDuration;
        const isPartiallyBooked = onShift ? this.isPartiallyBooked(eventsOftheDay, onShift, Day) : false;
        const fullBooked = onShift? this.isFullBooked(eventsOftheDay, isPartiallyBooked, onShift, Day) : false;
        const isSelectedDate = mode==='read'? false : this.isSelected(Day);
        
        let dateStyle = {
            borderWidth:1,
            borderColor:Colors.tintColorBGColor,
            backgroundColor:'transparent',  
            iconColor: Colors.contentFontTintColor,
            iconName:null,
            disabled: false,
            showTime: false,
        }; 
        
        if(isDatePast){
            dateStyle.borderWidth = 0;
            dateStyle.backgroundColor = Colors.tintColorBGColor;
            dateStyle.disabled=true;
        } else {
            dateStyle.disabled=true;
            if(withinDuration && onShift){
                if(fullBooked){
                    dateStyle.iconName = "times";
                    dateStyle.iconColor = Colors.contentFontTintColor;
                    dateStyle.showTime = true;
                } else if(isPartiallyBooked || (onShift["1"] && calenderType !== 'SEAT') ) {
                    dateStyle.borderWidth = 1;
                    dateStyle.backgroundColor = isSelectedDate ? Colors.yellow: Colors.white;
                    dateStyle.borderColor = Colors.yellow;
                    dateStyle.iconColor = isSelectedDate ? Colors.white: Colors.yellow;
                    dateStyle.disabled=false;
                    dateStyle.iconName = "icon-triangle"; 
                    dateStyle.showTime = true;
                } else {
                    dateStyle.borderWidth = 1;
                    dateStyle.backgroundColor = isSelectedDate ? Colors.primaryButtonBGColor : Colors.white;
                    dateStyle.borderColor=Colors.primaryButtonBGColor;
                    dateStyle.iconColor = isSelectedDate ? Colors.white : Colors.primaryButtonBGColor;
                    dateStyle.disabled=false;
                    dateStyle.iconName = "circle-o";
                    dateStyle.showTime = true;
                }
            } 
        }
        return dateStyle;
    }

    renderTimeFormat = (date) => {
        return moment(date).format("H:mm");
    }

    handleDateBtnPressed = (selDate, backgroundColor) => {
        const { 
            theGig,
            selectedDate, localCode, gigEvents,
            onDateSelected,
            appData:{ LocalStrings }
        } = this.props;
        const { 
          MainCat,
          feeInfo: { unit },  
          schedule: { weekShift }
      } = theGig;
        const isSameDate= selectedDate && selectedDate.start.getTime() === selDate.getTime() ? true: false;
        let hasEvent = false;
        let end = null;
        if(MainCat === "RENT"){
            let days = unit === "WEEK"? 7 : 1;
            // 7day * 24hrs * 60min * 60 sec * 1000 ms
            const theReturnDate = new Date(selDate.getTime() + days*24*60*60*1000);
            const includeTempEvent = false;
            const eventList  = getEventList(gigEvents, includeTempEvent, MainCat);
            for (let i = 0; i < eventList.length; i++) {
                if(eventList[i].start && eventList[i].end){
                    if (selDate.getTime() <= eventList[i].start.getTime()
                        && eventList[i].start.getTime() < theReturnDate.getTime()){
                        hasEvent = true;
                        break;
                    }
                }
            } 
            if(!hasEvent){
                end = theReturnDate;
            }    
        } 

        if(hasEvent){
            let msg = LocalStrings[localCode].SELECT_DATE_RENT_ALERT;
            alert(
                //LocalStrings[localCode].DATE_SELECT,
                selDate.toLocaleDateString()+msg
            );    
        } else {
            const DayShift = MainCat === "RENT" ? null: weekShift[selDate.getDay().toString()];
            const selectedDateObj = {
                start:selDate, 
                end:end, 
                DayShift:DayShift,
                backgroundColor:backgroundColor
            }
            onDateSelected(isSameDate ? null : selectedDateObj);
            
            if(MainCat !== "RENT"){
                //show DaySchedule TODO
            }
        }
    }

    renderDayButton = (week) => {
        const { theGig, mode, appData } = this.props;
        const { schedule: {weekShift}, MainCat } = theGig;
        const { Colors } = appData;
        return(
            week.map(Day => {
                let id = Day.getFullYear()+ "-" + Day.getMonth() + "-" + Day.getDate();
                let today = new Date();
                let todayObj = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                let isDateToday = Day.getTime()===todayObj.getTime() ? true : false;
                let onShift = (MainCat !== 'RENT' && MainCat!=='MAKE') ? weekShift[Day.getDay()] : null;
                let isSelectedDate = mode==='read' && this.isSelected(Day);
                const dateStyleConfig = this.dateStyleConfig(Day);
                const dateBtnPress = ()=> { this.handleDateBtnPressed(Day, dateStyleConfig.borderColor) }
                return(
                <div key={id} style={{flex:1, flexDirection:"column", justifyContent:'center', alignItems:'center'}}>
                    <div 
                        style={{
                            display:'flex',
                            flexDirection:"column",
                            position:'relative',
                            justifyContent:'center',
                            alignItems:"center",
                            padding:'2px',
                            marginBottom:'5px', 
                            backgroundColor:'transparent',
                            width:'97%'
                        }}
                    >
                        {isDateToday && (
                            <div style={{position:'absolute', opacity:0.5}}>
                                <i className="fa fa-circle" style={{fontSize:'23px', color:Colors.pink}} />
                            </div>
                        )}
                        {isSelectedDate && (
                            <div style={{position:'absolute', opacity:0.5}}>
                                <i className="fa fa-circle" style={{fontSize:'23px', color:Colors.primaryButtonBGColor}} />
                            </div>
                        )}
                        <p style={{fontSize:12, fontWeight:"bold", color:Colors.contentFontColor}}>
                            {Day.getDate()}
                        </p>
                    </div>
                    <div 
                        style={{
                            marginTop:'2px',
                            borderRadius:'5px',
                            display:'flex',
                            flexDirection:'column',
                            backgroundColor:dateStyleConfig.backgroundColor,
                            border: `${dateStyleConfig.borderWidth}px solid ${dateStyleConfig.borderColor}`,
                            justifyContent:'center',
                            alignItems:'center',
                            height:'46px', 
                            width:'97%',
                            marginBottom:'5px'
                        }}
                        disabled={dateStyleConfig.disabled || mode==='read'}
                        onClick={dateBtnPress}
                    >   
                        { dateStyleConfig.showTime &&  
                            <div style={{position: 'relative', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                <div style={{position:'absolute', opacity:0.4}}>
                                { dateStyleConfig.iconName !== 'icon-triangle' && (
                                    <i className={`fa fa-${dateStyleConfig.iconName}`} style={{fontSize:'40px', color:dateStyleConfig.iconColor}} />
                                )}
                                { dateStyleConfig.iconName === 'icon-triangle' && (
                                    <div className="icon-triangle" style={{fontSize:'26px', paddingRight:'2px', color:dateStyleConfig.iconColor}} />
                                )}
                                </div>
                                { (MainCat !== 'RENT' && MainCat!== 'MAKE') &&
                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                                    <p style={{fontSize:11, color:"#000000"}}>
                                        { this.renderTimeFormat(onShift[0].start_hr)}
                                    </p>
                                    <p style={{fontSize:9, color:"#000000"}}>
                                        |
                                    </p>
                                    <p style={{fontSize:11, color:"#000000"}}>
                                        { onShift[1] ? this.renderTimeFormat(onShift[1].end_hr) : this.renderTimeFormat(onShift[0].end_hr)}
                                    </p>
                                </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                )
            })
        )
    }

    handleCalenderNavPress = (direct) => {
        const { calenderDateObj } = this.state;
        const monthMove = direct==='bk' ? calenderDateObj.getMonth()-1 : calenderDateObj.getMonth() + 1
        const newDate = new Date(calenderDateObj.getFullYear(), monthMove, 1);
        this.renderDateObj(newDate);
    }

    renderDaySchedule = () => {
        const { theGig, events, DayScheduleNeed, selectedDate } = this.props;
        const { MainCat, feeInfo: { MAX_PERSON, accepted, acceptedUnit } } = theGig;
        const CalenderType = renderCalenderType(theGig);
        const showDaySchedule = (MainCat!=='RENT' && DayScheduleNeed===true && selectedDate && selectedDate.start);
        return(
            <div
                className={`${styles.DaySchedule}`}
                style={{
                    maxHeight:showDaySchedule? '258px': '0px'
                }}
            >
            { showDaySchedule &&
            <DaySchedule　
                events={events}
                CalenderType={CalenderType}
                accepted={accepted}
                acceptedUnit={acceptedUnit}
                MAX_PERSON={MAX_PERSON}
                theGig={theGig}
                key={
                  selectedDate && selectedDate.start
                    ? moment(selectedDate.start).format('YYYY-MM-DD_HH:MM')
                    : null
                }
            />
            }
            </div>
        )
    }

    render(){
        const { localCode, theGig, appData } = this.props;
        const { MainCat } = theGig;
        const { Colors, LocalStrings} = appData;
        const { calenderDateObj } = this.state;
        const prevBtnDisabled = calenderDateObj.getMonth() === new Date().getMonth() ? true : false;
        return(
            <div>
                <div 
                    style={{
                        width:'100%', 
                        height:'40px',
                        display:'flex', 
                        flexDirection:'row', 
                        justifyContent:'space-between',
                        alignItems:'center',
                        backgroundColor:Colors.primaryButtonBGColor,
                        marginBottom:'8px',
                        borderRadius:'5px'
                    }}
                >
                    <div
                        disabled={prevBtnDisabled}
                        style={{paddingLeft:'10px', display:'flex', flexDirection:'column', justifyContent:'center', opacity: prevBtnDisabled ? 0.2: 1}}
                        onClick={() => this.handleCalenderNavPress('bk')}

                    >
                        <i className="fa fa-chevron-left" style={{fontSize:'18px', color:Colors.primaryButtonTextColor}} />
                    </div > 
                    <p style={{fontSize:'15px', fontWeight:'bold', color:Colors.primaryButtonTextColor}}>
                        {`${calenderDateObj.getFullYear()} ${LocalStrings[localCode].YEAR} ${calenderDateObj.getMonth()+1} ${LocalStrings[localCode].MON}`}
                    </p>
                    <div 
                        style={{paddingRight:'10px', display:'flex', flexDirection:'column', justifyContent:'center'}}
                        onClick={() => this.handleCalenderNavPress('fw')}
                    >
                        <i className="fa fa-chevron-right" style={{fontSize:'18px', color:Colors.primaryButtonTextColor}} />
                    </div>    
                </div>
                {this.renderDaySchedule()}
                {renderDayHead(localCode, appData)}
                {renderRow(this.state.calender, this.renderDayButton)}
                <div style={{ paddingBottom:'5px', width:'100%', display:'flex', flexDirection:'row', justifyContent:"flex-start", alignItems:"center"}}>
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row', alignItems:'center'}}>
                        <div style={{opacity:0.6, paddingRight:'2px'}}>
                            <i className="fa fa-circle-o" style={{fontSize:'17px', color:Colors.primaryButtonBGColor}} />
                        </div>
                        <p style={{fontSize:11, fontWeight:'bold', color:Colors.contentFontColor}}>
                        { renderCalenderType(theGig) === 'SEAT' && 
                           LocalStrings[localCode].SEAT_AVAILALBE
                        }
                        { renderCalenderType(theGig) === 'RENT' && 
                           LocalStrings[localCode].RENT_OK
                        }
                        { renderCalenderType(theGig) === 'DEFAULT' && 
                            LocalStrings[localCode].AVAILABLE
                        } 
                        </p>
                    </div>
                    { MainCat !=='RENT' &&
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row', alignItems:'center'}}>
                        <div style={{opacity:0.7, paddingLeft:'5px', paddingRight:'2px'}}>
                            <div className="icon-triangle" style={{fontSize:'14px', color:Colors.yellow  }} />
                        </div>
                        <p style={{fontSize:11, fontWeight:'bold', color:Colors.contentFontColor}}>
                            { renderCalenderType(theGig) === 'SEAT' && 
                                LocalStrings[localCode].SEAT_STILL_RAMAIN    
                            }
                            { renderCalenderType(theGig) === 'DEFAULT' && 
                                LocalStrings[localCode].PART_AVAILABLE    
                            } 
                        </p>
                    </div>
                    }
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'row', alignItems:'center'}}>
                        <div style={{opacity:0.7, paddingLeft:'5px', paddingRight:'2px'}}>
                            <i className="fa fa-times" style={{fontSize:'17px', color:Colors.contentFontTintColor}} />
                        </div>
                        <p style={{fontSize:11, fontWeight:'bold', color:Colors.contentFontColor}}>
                            { renderCalenderType(theGig) === 'SEAT' && 
                                LocalStrings[localCode].SEAT_SHORT    
                            }
                            { renderCalenderType(theGig) === 'RENT' && 
                                LocalStrings[localCode].LENDING_BOOKED    
                            }
                            { renderCalenderType(theGig) === 'DEFAULT' && 
                               LocalStrings[localCode].FULLY_BOOKED    
                            } 
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    local: { localCode }, 
    appData: {appData},
    estimate:{selectedDate}, 
}) => ({
    localCode,
    appData,
    selectedDate,
});

const mapDispatchToProps = dispatch => ({
    onDateSelected : (selDate) => dispatch( dateSelected(selDate) )
});

export default connect(mapStateToProps, mapDispatchToProps)(Calender);
