import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import ServiceSubject1 from '../../../assets/images/help/account/service_subject.png'
import ServiceSubject2 from '../../../assets/images/help/account/service_subject/service_subject_2.png'
import ServiceSubject3 from '../../../assets/images/help/account/service_subject/service_subject_3.png'
import ServiceSubject4 from '../../../assets/images/help/account/service_subject/service_subject_4.png'
import ServiceSubject5 from '../../../assets/images/help/account/service_subject/service_subject_5.png'

class ServiceSubject extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービス対象者の事前登録</span>
            </h3>
            <p className={styles.ArticleDesc}>
                保育、子供送迎、シニア見守り、シニア同行・付き添い、ヘアケア、エステ、ネイル、リラクゼーション、ペットケアなどのサービスを依頼予定の場合、サービスの対象者を事前に登録しておくと、サービスの依頼時に入力する手間が省けます。<br/>
                <span className="Caption">*上記以外のサービスを依頼予定の場合はこの手順は不要です。</span>
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「アカウント」＞「サービス対象者」を選びます。<br />
                    </li>
                    <img className={styles.ScreenShot} src={ServiceSubject1} alt="サービス対象者の事前登録ステップ1画像" />
                    <li>
                      「お子様情報」、「シニアの方の情報」、「ヘアケアお客様情報」、「ネイルお客様情報」、「エステお客様情報」、「ﾘﾗｸｾｰｼｮﾝお客様情報」のいずれかのボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={ServiceSubject2} alt="サービス対象者の事前登録ステップ3画像" />
                    <li>
                        質問項目を回答していきます。
                    </li>
                    <img className={styles.ScreenShot} src={ServiceSubject3} alt="サービス対象者の事前登録ステップ4画像" />
                    <li>
                        質問の回答は全て必須です。特にサービスを提供する方にお伝えする事がない場合は、”特になし”と入力して、全ての回答が完了したら「保存」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={ServiceSubject4} alt="サービス対象者の事前登録ステップ5画像" />
                    <li>
                        保存されたサービス対象者を確認して登録は完了です。
                    </li>
                    <img className={styles.ScreenShot} src={ServiceSubject5} alt="サービス対象者の事前登録ステップ6画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(ServiceSubject);