import {
    UPDATE_CURRENT_HELP_TAB,
    UPDATE_CURRENT_HELP_MODE,
    UPDATE_CURRENT_HELP_TAB_AND_MODE,
    TOGGLE_HELP_MENU,
    SET_MAIN_MENU,
    SET_SUB_MENU,
    SET_MAIN_SUB_MENU,
    TOGGLE_AUTHWIN_OPEN,
    INIT_AUTH_UI_STATE,
    UI_START_LOADING,
    UI_STOP_LOADING,
    TOGGLE_ERRORWIN_OPEN,
    INIT_ERRORWIN_STATE,
    TOGGLE_SUCCESS_OPEN,
    INIT_SUCCESSWIN_STATE,
    SET_SMART_BANNER_SHOW
} from './actionTypes'

export const updateHelpTab = (currentTab) =>({
    type: UPDATE_CURRENT_HELP_TAB,
    payload : currentTab
});

export const updateHelpMode = (mode) =>({
    type: UPDATE_CURRENT_HELP_MODE,
    payload : mode
});

export const updateHelpModeAndTab = (mode, tab) =>({
  type: UPDATE_CURRENT_HELP_TAB_AND_MODE,
  payload : {mode, tab} 
});

export const toggleHelpMenu = (open) =>({
    type: TOGGLE_HELP_MENU,
    payload: open
});

export const setMainMenu = (mainMenuKey) => ({
    type: SET_MAIN_MENU,
    payload: {mainMenuKey}
});

export const setMainSubMenu = (mainMenuKey, subMenuKey) => ({
    type: SET_MAIN_SUB_MENU,
    payload: { mainMenuKey, subMenuKey }
});

export const setSubMenu = subMenuKey => ({
    type: SET_SUB_MENU,
    payload: { subMenuKey }
});

export const toggleAuthWinOpen = () =>({
    type: TOGGLE_AUTHWIN_OPEN
});

export const initAuthUiState = (initState) =>({
    type: INIT_AUTH_UI_STATE,
    payload: initState
});

export const uiStartLoading =(id) =>{
    return {
        type: UI_START_LOADING,
        payload: id
    };
}

export const uiStopLoading =(id) =>{
    return {
        type: UI_STOP_LOADING,
        payload: id
    };
}

export const toggleErrorWinOpen = () =>({
    type: TOGGLE_ERRORWIN_OPEN
});

export const initUiErrorState = (initState) =>({
    type: INIT_ERRORWIN_STATE,
    payload: initState
});

export const toggleSuccessWinOpen = () =>({
    type: TOGGLE_SUCCESS_OPEN
});

export const initUiSuccessState = (initState) =>({
    type: INIT_SUCCESSWIN_STATE,
    payload: initState
});

export const setSmartBannerShow = (value) => ({
  type:SET_SMART_BANNER_SHOW,
  payload:value
});