import React, {Fragment} from 'react';
import {isIOS, isAndroid} from 'react-device-detect'
import textStyle from '../../constants/TextStyle.module.scss';
import styles from './DownloadApp.module.scss';
import AppStore from '../../assets/images/Download_App_Store.svg';
import GooglePlayStore from '../../assets/images/Download_Badge_JP.svg';
import {
    CloseBtn,
    Backdrop
} from '../UI';


const DownloadApp = (props) => {
    const { 
        onClose, 
        appData, 
        localCode, 
        windowStatus,
        action
    } = props;
    const { LocalStrings, Colors} = appData;
    const actonText = LocalStrings[localCode][action];
    const installText = LocalStrings[localCode].PLEASE_INSTALL_APP.replace("{ACTION}", actonText);
    return(
    <Fragment>
        <Backdrop 
            show={windowStatus}
            clicked={onClose}/>
        <div className={windowStatus ? `${styles.DownloadAppContainer} Fade ${styles.Open}` : `${styles.DownloadAppContainer} Fade ${styles.Close}`}>
            <div className={styles.CloseBtnContainer}>
                <CloseBtn clicked={onClose} color={Colors.contentFontColor} fontSize={'1.5em'} />
            </div>
            <div style={{display:"flex", flexDirection: 'column', padding:'10px,', backgroundColor: Colors.contentBGColor}}>
                <p className={textStyle.BodyText}>
                    {installText}
                </p>
                <div className={styles.DownloadBtnBox}>
                    { ((!isAndroid && !isIOS) || isIOS) && 
                    <a 
                        className={styles.BtnStyle} 
                        href="https://apps.apple.com/jp/app/%E3%82%BF%E3%82%B0%E3%82%B8%E3%83%A3%E3%83%A0-tagujam/id1479792883?mt=8"
                        target="blank">
                        <img className={styles.DownLoadBtn} src={AppStore} alt="App Store ダウンロード"/>
                    </a>
                    }
                    { ((!isAndroid && !isIOS) || isAndroid) && 
                    <a 
                        className={styles.BtnStyle} 
                        href="https://play.google.com/store/apps/details?id=com.tagujammobile"
                        target="blank">
                        <img className={styles.DownLoadBtn} src={GooglePlayStore} alt="Google Play Store ダウンロード"/>
                    </a>
                    }
                </div>
            </div>
        </div>              
    </Fragment>
    );
}


export default DownloadApp;
