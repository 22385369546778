import React from 'react';
import { 
    PriceTagSvg
} from '../UI';
import styles from './GigThumbnail.module.scss';
import { 
    priceFormat 
} from '../../utils';


const GigThumbnail = props => {
    const { localCode, small, publish, firstItem, onPressGig, GigItem : { id, MainCat, SubCat, gigImages, feeInfo}, appData } = props;
    const { Colors, LocalStrings} = appData;
    const height = small ? '58px' : '88px';

    const renderCapacity = (feeInfo, localCode, MainCat, styles, LocalStrings) => {
        const {MAX_PERSON, MIN_PERSON, unit} = feeInfo;
        if(MainCat!=='TEACH'){
            return null;
        } else {
            if(unit==='TIMES'){
                return MAX_PERSON ? (<span className={styles.MaxPerson}>{MAX_PERSON+LocalStrings[localCode]['MAX_PERSON']}</span>) : null;
            } else if(unit==='PERSON'){
                return MIN_PERSON ? (<span className={styles.MaxPerson}>{MIN_PERSON+LocalStrings[localCode]['MIN_PERSON']}</span>) : null;
            // } else if(unit=='PRIVATE'){
            //     return (<span style={styles.maxPerson}>{LocalStrings[localCode]['PRIVATE_LESSION']}</span>);    
            } else {
                return null;
            }
        }
    }
    
    return(
        <div 
            key={`${id}_Thumb`}
            onClick={() => onPressGig(id, MainCat)}
            className={styles.ThumbmailContainer}
            style={{height:height, marginLeft: firstItem ? 0: '15px'}}
        >
            <div 
                className={styles.GigImage}
                style={{
                    height:`${height}px`,
                    backgroundImage: `url(${gigImages[0].ImagePath})`
                }}>
                <div className={styles.SubCatBG} />
                <div className={styles.SubCatTitle}>
                    <p className={styles.SubCatText}>{LocalStrings[localCode][SubCat]}</p>
                </div>
                <div className={styles.PriceTagBG}>
                    <PriceTagSvg height="15" type={MainCat} borderRadius="0 0 5px 0" />
                </div>
                <div className={styles.Price}>
                    <p className={styles.PriceText}>
                        {priceFormat(feeInfo.price, localCode, LocalStrings)+"/"+LocalStrings[localCode][feeInfo.unit]}
                        {renderCapacity(feeInfo, localCode, MainCat, styles, LocalStrings)}
                    </p>
                </div>
                {publish === false && 
                <div className={styles.GigImage} style={{display:'flex', flexDirection:'column', height:`${height}px`, justifyContent:"center", alignItems:"center", opacity:0.8}}>
                    <i name="fa fa-pause-circle-o" style={{fontSize:'45px', color:Colors.white}} />
                </div>
                }
            </div>
        </div>
    );
}

export default GigThumbnail;

