import React from 'react';
import styles from './Spinner.module.scss';

const Spinner = (props) => {
    const { large } = props;
    let addStyles = null;
    if(large){
        addStyles = {
            width: '10em',
            height: '10em',
            borderWidth: '0.8em'
        }  
    }
    return (
        <div className={styles.Spinner} style={addStyles ? addStyles :null}>読み込み中...</div>
    );
}

export default Spinner