import React from 'react';
import TaguJamLogo from '../../../assets/images/tagujam_logo.svg';
import TaguJamLogo_white from '../../../assets/images/tagujam_logo_white.svg';

const logo = props => {
    const {width, height, mode} = props;
    const size = {
        width: width ? width : '35px',
        height: height ? height : null,
    }
    return(
    <img style={size} src={mode ? TaguJamLogo_white : TaguJamLogo} alt="TaguJam タグジャム　ロゴ" />
    );
    
} 
export default logo;
