import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import ServiceAddress0 from '../../../assets/images/help/account/address.png'
import ServiceAddress1 from '../../../assets/images/help/account/service_address/service_address_1.png'
import ServiceAddress2_1 from '../../../assets/images/help/account/service_address/service_address_2-1.png'
import ServiceAddress2_2 from '../../../assets/images/help/account/service_address/service_address_2-2.png'

class ServiceAddress extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービス住所の事前登録</span>
            </h3>
            <p className={styles.ArticleDesc}>
                保育、子供送迎、シニア見守り、シニア同行・付き添い、ペットケア、のお手伝いで送迎サービスを依頼予定の場合、事前に送迎先の情報を登録しておくと、サービスの依頼時に入力する手間が省けます。<br/>
                <span className="Caption">*上記以外のサービスを依頼予定の場合はこの手順は不要です。</span>
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                      メニューより、「アカウント」＞「住所」をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={ServiceAddress0} alt="サービス住所の事前登録ステップ1画像" />
                    <li>
                      「住所を追加」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={ServiceAddress1} alt="サービス住所の事前登録ステップ2画像" />
                    <li>
                    郵便番号を入力すると都道府県、市区郡村、町域が自動入力されます。丁目・番地を入力します。場所を特定するのに、ビル・建物・号も必要であれば入力します。
                    入り口の入り方など、サービス提供者にお伝えしたい情報があれば、備考欄を使って入力をします。<br />
                    入力内容を確認して「保存」ボタンをタップします。
                    </li>
                    <div className={styles.MultiImageBox}>
                    <img className={styles.ScreenShot} src={ServiceAddress2_1} alt="サービス住所の事前登録ステップ3_1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={ServiceAddress2_2} alt="サービス住所の登録ステップ3_2画像" />
                    </div>
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(ServiceAddress);