import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import AnswerQuestion1 from '../../../assets/images/help/selling/sell_question/sell_question_1.jpg';
import AnswerQuestion2 from '../../../assets/images/help/selling/sell_question/sell_question_2.jpg';
import AnswerQuestion3 from '../../../assets/images/help/selling/sell_question/sell_question_3.jpg';


class AnswerQuestion extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービス購入者の質問に返信する</span>
            </h3>
            <p className={styles.ArticleDesc}>
                サービス購入者の質問に返信する場合は、以下の手順で返信できます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「取引」＞「売り」＞「問合せ」を選びます。    
                    </li>
                    <img className={styles.ScreenShot} src={AnswerQuestion1} alt="質問に返信するステップ1画像" />
                    <li>
                        質問が来ている場合は以下のように表示されます。内容をみる場合は「質問」と表示された取引をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={AnswerQuestion2} alt="質問に返信するステップ1画像" />
                    <li>
                    メッセージを読み返信を入力します。「送信」ボタンをタップします。 
                    </li>
                    <img className={styles.ScreenShot} src={AnswerQuestion3} alt="質問に返信するステップ2画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(AnswerQuestion);