export const plainData = (data) => {
  return JSON.stringify(data);
};

export const getBusinessType = (Main, Sub) => {
  let SubCat = Sub;
  if(Sub==='COOKING' || Sub==='SPORTS' || Sub==='OTHERS' || Sub ==='TOY'){
    SubCat = `${Main}_${Sub}`
  }
  const businessType = {
    CHILD_CARE:"ChildCare",
    CHILD_PICK_UP:"ChildCare",
    SENIOR_ACCOMPANY:"",
    SENIOR_CARE:"",
    HELP_COOKING:"",
    INDOOR_HOUSEWORK:"",
    CLEANING:"",
    PET_CARE:"",
    HAIR_CARE:"HairSalon",
    NAIL:"NailSalon",
    RELAXATION:"HealthAndBeautyBusiness",
    ESTHETIC:"HealthAndBeautyBusiness",
    HANDY_HELP:"",
    HEALTH_BEAUTY:"",
    TEACH_COOKING:"",
    CULTURE:"",
    HANDMADE:"",
    TUTORE:"",
    LIFE_TIP:"",
    MUSIC:"",
    LANGUAGE:"",
    IT:"",
    PARENTING:"",
    TEACH_SPORTS:"",
    TEACH_OTHERS:"",
    CHILD_SUPPLIES:"",
    RENT_TOY:"",
    FASION:"",
    APPLIANCE:"",
    RENT_SPORTS:"",
    OUTDOOR:"",
    INSTRUMENT:"",
    TRAVEL:"",
    RENT_OTHERS:"",
    SMALL_ITEM:"",
    MAKE_TOY:"",
    JEWELRY:"",
    PET_ITEM:"",
    CLOTHES:"",
    KNITT:"",
    WEDDING:"",
    HOUSEHOLD_GOODS:"",
    MAKE_OTHERS:""
  }
  return businessType[SubCat];
}