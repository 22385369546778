import React from 'react';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import CloseBtn from '../../../components/UI/CloseBtn/CloseBtn';
import Backdrop from '../../../components/UI/Backdrop/Backdrop';
import SideMenuItems from '../SideMenuItems/SideMenuItems';
import styles from './SideMenu.module.css';

const SideMenu = (props) => {
    const { showSideMenu, closed, articleClickedHandler, currentMode, articleName } = props;
    let attachStyles = [ styles.SideMenu, styles.Close];
    
    if(props.showSideMenu){
        attachStyles = [ styles.SideMenu, styles.Open ];
    }

    return(
        <Aux>
            <Backdrop 
                show={showSideMenu}
                clicked={closed}
            />
            <div className={attachStyles.join(' ')}>
                <div style={{display:'flex', justifyContent:"space-between", alignItems:"center", marginBottom:'8px'}}>
                    <div style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
                        <Logo width={20} />
                        <span className={styles.MenuTitle}>タグジャムガイド目次</span>
                    </div> 
                    <div style={{marginLeft:'8px', marginRight:'8px',}}> 
                    <CloseBtn clicked={closed} fontSize={20} color="gray"/>
                    </div>
                </div>
                <SideMenuItems 
                    articleClickedHandler={articleClickedHandler}
                    currentMode={currentMode}
                    articleName={articleName}
                />
            </div>
        </Aux>
    );
}

export default SideMenu;