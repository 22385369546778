import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

class SafeEffort extends Component {
    render() {
        return(
            <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>安全性への取組</span>
            </h3>
            <p className={styles.ArticleDesc}>タグジャムはサービス利用者（売り手）または、サービス提供者（買い手）に快適かつ安全に取引をしていただくために以下の取組を行っています。</p>
            <h4 className={styles.HeaderMargin15}>本人確認</h4>
            <p>タグジャムでは、なりすましなどを防ぐために、全てのユーザーに免許証、保険証、マイナンバーカードなど本人が確認できる書類の画像の提出をお願いしています。画像の提出はタグジャムモバイルアプリの「アカウント」＞「必要書類」＞「本人確認」にてできます。本人確認が未提出の場合はタグジャムでの取引は開始できません。</p>
            <h4 className={styles.HeaderMargin15}>必要資格・届出書の確認</h4>
            <p>タグジャムでは、資格や、行政機関への届出書を必要とするサービスを販売する場合は、タグジャム事務局まで資格・届出書を提出していただく事を義務付けています。事務局の確認が終わるまで取引はできません。</p>
            <h4 className={styles.HeaderMargin15}>詳細ページ</h4>
            <p>サービスの詳細ページには基本料金、オプション料金、などの料金の情報、各種保有資格、届出書、スケジュール、購入の際の注意事項など表示しています。詳細ページの質問機能により売り手に直接、不明な点などを取引前に尋ねる事ができます。これにより取引のトラブルを防ぎます。</p>
            <h4 className={styles.HeaderMargin15}>簡易見積もり</h4>
            <p>簡易見積もり機能により、事前に購入するサービス料金を確認する事ができます。金額を事前に表示する事によって買い手の予算に合った購入の目安になり、請求金額のトラブルを防ぎます。
            <br/>
            ＊後日実費精算される物（例：交通費、材料費、入場料、買い物代金など）は実費精算アイテムとして見積書に記載されています。
            </p>
            <h4 className={styles.HeaderMargin15}>売り手への質問</h4>
            <p>サービスの内容や料金、注意事項、実費精算料金、など、詳細ページでわからない事があれば質問チャットを使って、売り手へ直接聞く事ができます。サービス内容、料金の誤解によるトラブルを防ぎます。</p>
            <h4 className={styles.HeaderMargin15}>売り手に面接申請</h4>
            <p>希望があれば、面接申請機能を使い売り手の方と面接をして、取引前に確認する事ができます。これによりサービスを提供する方への不安などが解消できます。</p>
            <h4 className={styles.HeaderMargin15}>買い手の支払い情報の登録・審査</h4>
            <p>売り手が取引申請の受付を行った時点で、買い手は支払い情報を登録して頂きます。タグジャムは取引毎にそのお支払い方法の審査を致します。登録いただいた支払い方法に問題がある場合は取引は成立しません。これにより、不正なカード利用（盗難、詐欺など）による利用料の決済トラブルを防ぎます。</p>
            <h4 className={styles.HeaderMargin15}>支払いの一時停止</h4>
            <p>買い手は売り手からの請求金額に疑問がある場合はその問題を売り手に報告する事ができます。問題を報告した場合は、利用料の決済が一時停止します。これにより、誤った金額の請求や、料金への疑問を解決し　売り手と買い手が納得した上で決済を行う事ができます。</p>
            <h4 className={styles.HeaderMargin15}>禁止事項の設定</h4>
            <p>健全なサービスの売り買いの妨げや、迷惑行為は利用規約により固く禁じられています。そのような行為を当局が発見、または利用者から通報をいただいた場合は、対象となる会員の利用の停止、または退会の措置を講じます。</p>
            <h4 className={styles.HeaderMargin15}>出店内容のモニタリング</h4>
            <p>タグジャムは不適切なサービスの出店は利用規約により固く禁じています。そのようなサービスを当局が発見、または利用者から通報をいただいた場合は、対象となる出店品の掲載停止、または削除の措置を講じます。</p>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SafeEffort);