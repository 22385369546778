import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
// import RegisterPayment0 from '../../../assets/images/help/buying/buy.jpg';
import RegisterPayment1 from '../../../assets/images/help/buying/buy_payment/buy_payment_1.jpg';
import RegisterPayment2 from '../../../assets/images/help/buying/buy_payment/buy_payment_2.jpg';
import RegisterPayment3 from '../../../assets/images/help/buying/buy_payment/buy_payment_3.jpg';
import RegisterPayment4 from '../../../assets/images/help/buying/buy_payment/buy_payment_4.jpg';
import RegisterPayment5 from '../../../assets/images/help/buying/buy_payment/buy_payment_5.jpg';
import RegisterPayment6 from '../../../assets/images/help/buying/buy_payment/buy_payment_6.jpg';
import RegisterPayment7 from '../../../assets/images/help/buying/buy_payment/buy_payment_7.jpg';

class RegisterPayment extends Component {
    articleClickedHandler = (article) => {
        console.log(article);
        if(article === 'cancelpolicy'){
            this.props.history.push({
                pathname: `/${article}`
            });
        } else {
            this.props.history.push({
                pathname: `/help/${article}`
            });
        }
    }
    
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>支払い情報の登録をする</span>
            </h3>
            <p className={styles.ArticleDesc}>
            サービス購入申請（買い手の購入・受講・レンタルの申請）がサービス提供者（売り手）より受け付けられると「支払い情報登録」へ進み、予約を確定させることができます。この登録が完了するまでは、予約は仮予約の状態ですので、速やかに「支払い情報登録」を済ませ、取引を成立させましょう。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                    取引の画面にいない場合は「取引」＞「買い」＞「取引中」を選びます。     
                    </li>
                    <img className={styles.ScreenShot} src={RegisterPayment1} alt="支払い情報の登録をするステップ1画像" />
                    <li>
                    「購入・受講・レンタル受付されました」をタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={RegisterPayment2} alt="支払い情報の登録をするステップ2画像" />
                    <li>
                    「支払い情報を登録する」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={RegisterPayment3} alt="支払い情報の登録をするステップ3画像" />
                    <li>
                    見積もり金額、支払い審査額、クレジットカード情報を確認、「取引規約を確認」をタップし規約を読んだら、「取引の規約に同意する」をチェックします。「このカードを支払いに使う」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={RegisterPayment4} alt="支払い情報の登録をするステップ4画像" />
                    <p>＊支払い審査額の詳しい情報は<a href="/help" onClick={ e => { e.preventDefault(); this.articleClickedHandler('Payment') } }>こちら</a></p>
                    <p>＊支払い情報登録後のキャンセルについては<a href="/help" onClick={ e => { e.preventDefault(); this.articleClickedHandler('cancelpolicy') } }>こちら</a></p>
                    <li>
                        お支払い審査中…が表示され、クレジットカードのお支払いの審査が行われます。         
                    </li>
                    <img className={styles.ScreenShot} src={RegisterPayment5} alt="支払い情報の登録をするステップ5画像" />     
                    <li>
                    「会話に戻る」ボタンをタップします。
                    </li>
                    <img className={styles.ScreenShot} src={RegisterPayment6} alt="支払い情報の登録をするステップ6画像" />
                    <li>
                    「予約が確定されました」のメッセージが送られているのを確認します。             
                    </li>
                    <p className="Caption" style={{paddingBottom:'5px'}}>＊「教えます」「貸します」の項目の場合は「詳細聞き取りフォームの入力」ボタンは表示されません。</p>
                    <img className={styles.ScreenShot} src={RegisterPayment7} alt="支払い情報の登録をするステップ7画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(RegisterPayment);