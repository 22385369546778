import React, {Fragment} from 'react';
import textStyle from '../../constants/TextStyle.module.scss';
import styles from './ConfirmCoveredArea.module.scss';
import {
    CloseBtn,
    Backdrop
} from '../UI';


const getTownList = (coveredArea) =>{
    const { towns, townList } = coveredArea;
    let list = [];
    townList.map( key => {
        list.push({ name: key, val: towns[key]});
        return true;
    })
    return list;
}

const getTitle = (theGig, localCode, LocalStrings) => {
    const { MainCat } = theGig;
    let title = LocalStrings[localCode].COVERED_AREA_TITLE;
    if(MainCat==='RENT' || MainCat==='MAKE') {
        title = LocalStrings[localCode].DELIVERY_AREA_TITLE;
    }
    return title;
}

const ConfirmCoveredArea = (props) => {
    const { onClose, appData, localCode, locationAddress, theGig, windowStatus} = props;
    const { LocalStrings, Colors } = appData;
    const { coveredArea } = theGig;
    let townList = [];
    let YourAreaIconName = "times"
    let YourAreaFontColor = Colors.contentFontTintColor;
    let isCoveredText = LocalStrings[localCode].NOT_COVERED;

    if(coveredArea.towns){
        townList = getTownList(coveredArea);
        console.log(townList);
        if(locationAddress && locationAddress.state === coveredArea.centerAddress.state && locationAddress.city === coveredArea.centerAddress.city){
            if(coveredArea.towns.hasOwnProperty(locationAddress.town) 
            && coveredArea.towns[locationAddress.town] === true){
                YourAreaIconName = "check-circle"
                YourAreaFontColor = Colors.primaryButtonBGColor;
                isCoveredText = LocalStrings[localCode].COVERED;
            }
        }
    }
    
    return(
    <Fragment>
    <Backdrop 
        show={windowStatus}
        clicked={onClose}/>
    <div className={windowStatus ? `${styles.ConfirmCoveredAreaContainer} Fade ${styles.Open}` : `${styles.ConfirmCoveredAreaContainer} Fade ${styles.Close}`}>
        <div className={styles.CloseBtnContainer}>
            <CloseBtn clicked={onClose} color={Colors.contentFontColor} fontSize={'1.5em'} />
        </div>
        <div style={{display:'flex', flexDirection:"column", alignItems:'center', padding:'0 5px'}}>
            <p className={`${textStyle.BodyMediumLText} ${textStyle.bold}`} style={{marginBottom:'20px'}}>
                {getTitle(theGig, localCode, LocalStrings)}
            </p>
            {coveredArea && 
            <div className="BorderBottom" style={{width:'100%', display:'flex', flexDirection:'row', paddingBottom:'10px'}}>
                {coveredArea.centerAddress &&
                <div className={[styles.ServiceLocContainer]}>
                    <p className={textStyle.BodyTextSmall}>
                        {coveredArea.centerAddress.city}
                    </p>
                    <p className={textStyle.BodyTextSmall}>
                        {coveredArea.centerAddress.town}
                    </p>
                </div>
                }
                { coveredArea.radius && 
                <div className={styles.CoveredAreaContainer}>
                    <p className={textStyle.BodyExLargeText}>
                        {coveredArea.radius ? coveredArea.radius : 0}
                    </p>
                    <div style={{marginBottom: '6px'}}>
                        <p className={styles.BodyTextExSmall}>
                        { LocalStrings[localCode].COVERED_RADIUS}
                        </p> 
                    </div>
                </div>
                }
            </div>
            }
            {coveredArea && locationAddress && 
            <div className="BorderBottom" style={{width:'100%', display:'flex', flexDirection:'row', paddingTop:'10px', paddingBottom:'10px'}}>
                {coveredArea.centerAddress &&
                <div className={styles.ServiceLocContainer} style={{justifyContent:'center'}}>
                    <p className={`${textStyle.BodyTextSmall} ${textStyle.Bold}`} style={{paddingBottom:5}}>
                        {LocalStrings[localCode].YOUR_AREA}
                    </p>
                    <p className={textStyle.BodyTextSmall}>
                        {locationAddress.city}
                    </p>
                    <p className={textStyle.BodyTextSmall}>
                        {locationAddress.town}
                    </p>
                </div>
                }
                <div className={styles.CoveredAreaContainer} style={{alignItems:'center'}}>
                    <i className={`fa fa-${YourAreaIconName}`} style={{fontSize:'25px', color:YourAreaFontColor}} />
                    <p className={textStyle.BodyLargeText} style={{paddingLeft:'8px', color:YourAreaFontColor}}>
                    { isCoveredText }
                    </p>
                </div>
            </div>
            }
            {townList.length > 0 &&
            <div className={styles.AreaTable}> 
                <div className={styles.RowContainer} style={{flexDirection:"column", paddingBottom:0}}>
                    <div className={styles.CityListContainer}>
                    {townList.map( item => {
                        let iconName = item.val ? "check-circle" : "times";
                        let fontColor = item.val ? Colors.primaryButtonBGColor : Colors.contentFontTintColor;  
                        return (
                        <div key={item.name} style={{width:'45%', height:44}}>
                            <div style={{display:'flex', flexDirection:'row', alignItems:"center"}}>
                                <i className={`fa fa-${iconName}`} style={{fontSize:'17px', color:fontColor}} />
                                <p className={textStyle.BodyText} style={{color:fontColor, paddingLeft:'5px'}}>
                                    {item.name}
                                </p>
                            </div>
                        </div>
                        )
                    })
                    } 
                    </div>
                </div>
            </div>
            }
        </div>
    </div>                    
    </Fragment>
    );
}

export default ConfirmCoveredArea;