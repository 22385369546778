import React from 'react'
import { 
    PriceTagSvg,
    Avator 
} from '../UI';
import styles from './CatalogItem.module.scss';
import { 
    trunicateString,
    priceFormat 
} from '../../utils';
import Review from '../../components/Gigs/Review/Review';

const CatalogItem = props => {
    const { itemWidth, item, theUserMeta, theBuyerMeta, localCode, onPress, appData, handleUpdateFavorite } = props;
    const { Colors,  LocalStrings} = appData;

    const renderCapacity = (feeInfo, localCode, MainCat) => {
        const {MAX_PERSON, MIN_PERSON, unit} = feeInfo;
        if(MainCat!=='TEACH'){
            return null;
        } else {
            if(unit==='TIMES'){
                return MAX_PERSON ? (<span className={styles.MaxPerson}>{MAX_PERSON+LocalStrings[localCode]['MAX_PERSON']}</span>) : null;
            } else if(unit==='PERSON'){
                return MIN_PERSON ? (<span className={styles.MaxPerson}>{MIN_PERSON+LocalStrings[localCode]['MIN_PERSON']}</span>) : null;    
            } else {
                return null;
            }
        }
    }

    const renderBudge =(indicator, localCode, MainCat, mode) => {
        if(MainCat === 'TEACH' && MainCat === 'MAKE'){
            return null;
        }
        let badge,
        budgeColor = Colors.white,
        budgeTextColor = Colors.redColor;

        if(mode==='bottomRight'){
            if(MainCat === 'HELP' && indicator.AVAILABLE_NOW){
                badge = LocalStrings[localCode].AVAILABLE_NOW; 
            }
            if(MainCat === 'RENT'){
                if(indicator.LENDING){
                    badge = LocalStrings[localCode].LENDING; 
                    budgeColor = Colors.white;
                    budgeTextColor = Colors.contentFontColor;
                } 
                if(indicator.RENT_OK){
                    badge = LocalStrings[localCode].RENT_OK; 
                }
            }
        } else {
            if( (MainCat==='RENT' || MainCat ==='MAKE') && indicator.DELIVERY_OK ){
                badge = LocalStrings[localCode].DELIVERY_OK;
            }
    
            if(MainCat==='HELP' || MainCat==='TEACH'){
                if(indicator.VISIT_TYPE){
                    badge = LocalStrings[localCode].VISIT_TYPE;
                }
                if(indicator.VISITOR_TYPE){
                    badge = LocalStrings[localCode].VISITOR_TYPE;
                }   
            }    
        }
        const budgeStyle = mode==='bottomRight'
            ? {backgroundColor:budgeColor} 
            : {backgroundColor:budgeColor, position:'relative', marginTop:'8px', padding:'2px', width:'40px'} 
        
        if(badge){
            return (
                <div className={styles.IndiCatorContainer} style={budgeStyle}>
                    <p className={styles.IndicatorText} style={{color:budgeTextColor}}>
                        {badge}
                    </p>
                </div>  
            )
        } else {
            return null;
        }  
    }

    const renderAddressLine = ( address ) => {
        return(
            <p className={styles.AddressText}>
            {`${address.state}/${address.city}/${address.town}`}
            </p>
        );
    }
    
    const trunicateTitle = (text) => {
        const {itemWidth} = props;
        const maxLen = (itemWidth==='150') ? 18 : 23;
        return trunicateString(maxLen, text);
    }

    const getNewFavoriteValue = (gigId) => {
        let value = false;
        if(!theBuyerMeta.favorite || !theBuyerMeta.favorite[gigId]){
            value = true;
        }
        return value;
    }

    const getCatIconStyleName = (MainCat) => {
        let styleName = `CatIcon${MainCat}`;
        return styles[styleName];
    }

    return(
        <div 
            key={item.id}
            onClick={()=> onPress(item.id, item.MainCat)} 
            className={styles.ItemContainer}
            >
            <div 
                className={styles.GigImageContainer} 
                style={{
                    backgroundImage: `url(${item.gigImages[0].ImagePath})`
                }}>
                <div className={styles.SubCatHeader}>
                    <p>
                        {LocalStrings[localCode][item.SubCat]}
                    </p>
                    {theBuyerMeta && 
                    <div className={styles.FavIconContainer} onClick={()=>{handleUpdateFavorite(item, getNewFavoriteValue(item.id))}}>    
                        { (theBuyerMeta.favorite && theBuyerMeta.favorite[item.id]) && 
                        <i className="fa fa-star" style={{fontSize:'18px', color:Colors.pink}} />
                        }
                        { (!theBuyerMeta.favorite || !theBuyerMeta.favorite[item.id]) && 
                        <i className="fa fa-star-o" style={{fontSize:'18px', color:Colors.contentFontColor}} />
                        }
                    </div>
                    }
                </div>
                {renderBudge(item.indicator, localCode, item.MainCat, 'topLeft')}
                <div className={styles.MainCatIconContainer}>
                    <div className={styles.MainCatIconCircle} style={{ backgroundColor:Colors[`mainMenuBgColor${item.MainCat}`] } }>
                        <div className={`icon-${item.MainCat.toLocaleLowerCase()} ${getCatIconStyleName(item.MainCat)}`} />
                    </div>
                </div>
                <div className={styles.PriceTagSvgContainer}>
                    <PriceTagSvg height="25" type={item.MainCat} borderRadius={0} />
                </div>
                <div className={styles.PriceTagContainer}>
                    <p className={styles.PriceText}>
                        {priceFormat(item.feeInfo.price, localCode, LocalStrings)+" / "+LocalStrings[localCode][item.feeInfo.unit]}
                        {renderCapacity(item.feeInfo, localCode, item.MainCat)}
                    </p>
                    {renderBudge(item.indicator, localCode, item.MainCat, 'bottomRight')}  
                </div>
            </div>
            <div className={styles.GigTitleContainer}>
                <div className={styles.GigTitleInnerContainer}>
                    <p className={styles.GigTitleText}>
                        {trunicateTitle(item.title)}
                    </p>
                </div>
            </div>
            <div className={styles.UserInfoContainer}>
                <div className={styles.AvatorContainer}>
                    <Avator avator={theUserMeta.accountInfo.avator} size={40} />
                </div> 
                <Review 
                    reviews={item.reviews}
                    id={item.id}
                    user={theUserMeta.accountInfo} 
                    localCode={localCode}
                    width={itemWidth-60}
                    fontSize={11}
                    appData={appData}
                />
            </div>
            <div className={styles.AddressLineContainer}>
                <i className="fa fa-map-marker" style={{fontSize:'13px', color:Colors.contentFontColor}} />
                { renderAddressLine(item.coveredArea.centerAddress) }
            </div>
        </div>
    )
}

export default CatalogItem;