import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import SellInvoice1 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_1.jpg';
import SellInvoice2 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_2.jpg';
import SellInvoice3 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_3.jpg';
import SellInvoice4 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_4.jpg';
import SellInvoice5HelpTeach from '../../../assets/images/help/selling/sell_invoice/sell_invoice_5.jpg';
import SellInvoice5Rent from '../../../assets/images/help/selling/sell_invoice/sell_invoice_rent.jpg';
import SellInvoice5Make from '../../../assets/images/help/selling/sell_invoice/sell_invoice_make.jpg';
import SellInvoice6 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_6.jpg';
import SellInvoice7 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_7.jpg';
import SellInvoice8 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_8.jpg';
import SellInvoice9 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_9.jpg';
import SellInvoice10 from '../../../assets/images/help/selling/sell_invoice/sell_invoice_10.jpg';

class SendInvoice extends Component {
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>請求書の作成と送信</span>
            </h3>
            <p className={styles.ArticleDesc}>
            サービスの提供が完了すると、以下の手順で請求書を自動作成しサービス購入者に送信できます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「取引中」を選びます。    
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice1} alt="請求書の作成と送信ステップ1画像" />
                    <li>
                        「サービスを完了しました」と表示された取引をタップします。
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice2} alt="請求書の作成と送信ステップ1画像" />
                    <li>
                        「請求書を作成」ボタンをタップします。  
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice3} alt="請求書の作成と送信ステップ2画像" />
                    <li>
                        サービス購入者が注文した項目が事前に選択されています。追加で行ったサービスや、時間の延長などがある場合は必要に応じて編集してください。
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice4} alt="請求書の作成と送信ステップ2画像" />
                    <li>
                        画面をスクロールして、実費精算などがある場合は、実際にかかった金額に訂正します。         
                    </li>
                    {(currentTab==='HELP' || currentTab==='TEACH') &&
                    <Aux>
                    <p className={styles.HeaderMargin10}>＊「助けます」「作ります」サービスの場合は各項目の金額を調節。実費精算項目がなければ以下の項目の選択を外してください。</p>
                    <img className={styles.ScreenShot} src={SellInvoice5HelpTeach} alt="請求書の作成と送信ステップ3(助けます・教えます)画像" />
                    </Aux>
                    }
                    {currentTab==='MAKE' &&
                    <Aux>
                    <p className={styles.HeaderMargin10}>＊「作ります」サービスの場合は、実費精算項目があれば選択してください</p>
                    <img className={styles.ScreenShot} src={SellInvoice5Make} alt="請求書の作成と送信ステップ3(作ります)画像" />
                    </Aux>
                    }
                    {currentTab==='RENT' &&
                    <Aux>
                    <p className={styles.HeaderMargin10}>＊「貸します」サービスの場合は、返却時適用料金があれば選択してください</p>
                    <img className={styles.ScreenShot} src={SellInvoice5Rent} alt="請求書の作成と送信ステップ3(貸します)画像" />
                    </Aux>
                    }
                    <li>
                        割引などを提供したい場合は割引にチェックをして金額を入力します。
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice6} alt="請求書の作成と送信ステップ4画像" />
                    <li>
                        請求金額合計を確認します。サービスの追加や時間の延長がなければ、「購入者も請求金額に同意しています」にチェックをし、「請求書送信へ」のボタンを押します。<br/>
                        <p className="Caption">＊追加や、時間の延長があり請求金額が見積もりと変わった場合は「請求書送信へ」を押す前に、チャットでサービス購入者の方に料金が追加になる件をお知らせ願います。お知らせ後に「購入者も請求金額に同意しています」にチェックをつけてください。</p>        
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice7} alt="請求書の作成と送信ステップ5画像" />
                    <li>
                        買い手の方へメッセージを入力し「請求書送信」ボタンをタップします。        
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice8} alt="請求書の作成と送信ステップ6画像" />
                    <li>
                        「会話に戻る」ボタンを押します。           
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice9} alt="請求書の作成と送信ステップ7画像" />
                    <li>
                        請求書送信のメッセージを確認します。           
                    </li>
                    <img className={styles.ScreenShot} src={SellInvoice10} alt="請求書の作成と送信ステップ7画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SendInvoice);