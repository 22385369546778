import React, {Component} from 'react';
import Aux from '../../../hoc/Aux/Aux';
import styles from './Merits.module.css';
import Indicator from '../../../assets/images/indicator.svg';
import SellerMerit1 from '../../../assets/images/seller_merit_1.svg';
import SellerMerit2 from '../../../assets/images/seller_merit_2.svg';
import SellerMerit3 from '../../../assets/images/seller_merit_3.svg';
import BuyerMerit1 from '../../../assets/images/buyer_merit_1.svg';
import BuyerMerit2 from '../../../assets/images/buyer_merit_2.svg';
import BuyerMerit3 from '../../../assets/images/buyer_merit_3.svg';

class Merits extends Component {
    state = {
        currentTab:0
    }


    tabClikedHandler = (tabNum) => {
        this.setState({
            currentTab: tabNum
        });
    }

    render(){
        const currentTab = this.state.currentTab;
        const show = {display: 'flex'};
        const hide = {display: 'none'};
        return(
        <Aux>
            <div className={styles.TabContainer}>
                <div className={currentTab === 0 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel} 
                    onClick={() => this.tabClikedHandler(0)}
                    >
                    <div className={styles.TabLabelText}>売り手の利点</div>
                    <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                </div>
                <div className={styles.TabGap} />
                <div className={currentTab === 1 ? `${styles.TabLabel} ${styles.Active}` : styles.TabLabel}
                    onClick={() => this.tabClikedHandler(1)}
                    >
                    <div>買い手の利点</div>
                    <img className={styles.TabIndicator} src={Indicator} alt="現在の洗濯中のタブ"/>
                </div>
                <div className={styles.TabGapRemain} />
            </div> 
            <div className={`${styles.MeritArea} Fade`} style={currentTab === 0 ? show : hide}>
                <div className={styles.MeritContainer}>
                    <h5>「できる」事から簡単出店</h5>
                    <img className={styles.MeritImage} src={SellerMerit1} alt="売り手の利点1" />
                    <p>特殊スキルなし•しばらく働いてない、でもOK!  多様な項目からできる事を選びガイドにそって簡単出店。</p>
                </div>
                <div className={styles.MeritContainer}>
                    <h5>あなた流の働き方改革</h5>
                    <img className={styles.MeritImage} src={SellerMerit2} alt="売り手の利点2" />
                    <p>自分の好きな時間・好きな場所から自由に働く。スキマ時間にあなた流の働き方改革をここから始める。</p>
                </div>  
                <div className={styles.MeritContainer}>
                    <h5>自分らしさ・充実を実感</h5>
                    <img className={styles.MeritImage} src={SellerMerit3} alt="売り手の利点3" />
                    <p>地域の「ありがとう」を体感！「何か始められた」を実感し、自分らしさを再び見つけよう。</p>
                </div>     
            </div>
            <div className={`${styles.MeritArea} Fade`} style={ currentTab === 1 ? show : hide }>
                <div className={styles.MeritContainer}>
                    <h5>身近な「普段使い」サービス</h5>
                    <img className={styles.MeritImage} src={BuyerMerit1} alt="買い手の利点1" />
                    <p>日常の家事・保育•介護などのサービス、趣味の教室や、礼服・家電のレンタルなど「サクッ」と簡単検索。</p>
                </div>
                <div className={styles.MeritContainer}>
                    <h5>スマホで簡単依頼・楽々取引</h5>
                    <img className={styles.MeritImage} src={BuyerMerit2} alt="買い手の利点2" />
                    <p>お子様が急に熱、高齢者が病気などの見守りサービスも即依頼！取引はガイドにそって楽々コミュニケーション。</p>
                </div>  
                <div className={styles.MeritContainer}>
                    <h5>安心サービス・納得の料金</h5>
                    <img className={styles.MeritImage} src={BuyerMerit3} alt="買い手の利点3" />
                    <p>サービス内容を売り手に直接相談して細かいニーズを伝え、価格を事前確認。納得いく内容と料金で安心依頼。</p>
                </div>     
            </div>
        </Aux>
        );    
    }
}



export default Merits;