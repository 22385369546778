import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import SendMoney1 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_1.jpg';
import SendMoney2 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_2.jpg';
import SendMoney3 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_3.jpg';
import SendMoney4 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_4.jpg';
import SendMoney5 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_5.jpg';
import SendMoney5_1 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_6.jpg';
import SendMoney5_2 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_7.jpg';
import SendMoney5_3 from '../../../assets/images/help/selling/sell_send_money/sell_send_money_8.jpg';

class SendMoney extends Component {
    render() {
        const {currentTab, currentMode} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>売上金を銀行へ振り込む</span>
            </h3>
            <p className={styles.ArticleDesc}>
            売上金を自分の銀行に送金する場合は、以下の手順で行ってください。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        メニューより「取引」＞「売り」＞「売上」を選び、画面下部の「送金可能額」の残高が振込手数料以上ある事を確認し「送金依頼」ボタンをタップします。
                        <p>＊振込手数料は送金額によって違います。詳しくは、<Link to={`/help/Fee?mode=${currentMode.toLowerCase()}&tab=${currentTab.toLowerCase()}`}>利用料金を参照</Link>して下さい。</p> 
                    </li>
                    <img className={styles.ScreenShot} src={SendMoney1} alt="売上金を銀行へ振り込むステップ1画像" />
                    <li>
                        状況が「確認中…」と表示されている場合は、タグジャム事務局での振込銀行の確認が取れてませんのでしばらくお待ちください。
                    </li>
                    <img className={styles.ScreenShot} src={SendMoney2} alt="売上金を銀行へ振り込むステップ2画像" />
                    <p>
                        振込銀行の確認が取れた場合は「使用可能」の文字が表示されます。「送金額」、「振込額」を確認し「売上を送金する」ボタンをタップします。
                    </p>
                    <img className={styles.ScreenShot} src={SendMoney3} alt="売上金を銀行へ振り込むステップ3画像" />
                    <li>
                        送金依頼の送信が完了すると「送金依頼済」と表示され、画面下部の「送金可能額」が０円になります。
                    </li>
                    <img className={styles.ScreenShot} src={SendMoney4} alt="売上金を銀行へ振り込むステップ4画像" />
                    <li>
                        その後送金状況を確認する場合は、メニューより「取引」＞「売り」＞「送金」を選びます。
                    </li>
                    <img className={styles.ScreenShot} src={SendMoney5} alt="売上金を銀行へ振り込むステップ5画像" />
                    <p><strong>送金依頼済</strong>：タグジャムへ送金依頼が送られた</p>
                    <img className={styles.ScreenShot} src={SendMoney5_1} alt="売上金を銀行へ振り込むステップ5-1画像" />
                    <p><strong>送金中</strong>：タグジャムが銀行へ送金依頼を送った。</p>
                    <img className={styles.ScreenShot} src={SendMoney5_2} alt="売上金を銀行へ振り込むステップ5-2画像" />
                    <p><strong>振込済み</strong>：銀行が売上金の振込を完了した。</p>
                    <img className={styles.ScreenShot} src={SendMoney5_3} alt="売上金を銀行へ振り込むステップ5-3画像" />
                    
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SendMoney);