import React from 'react';
import { NavLink } from 'react-router-dom'
import styles from './LogoWithTypeFace.module.css';
import TaguJamTypeface from '../../../assets/images/tagujam_typeface.svg';
import TaguJamTypeface_white from '../../../assets/images/tagujam_typeface_white.svg';
import Logo from '../Logo/Logo';

const logoWithTypeFace = props =>{
  const {width, mode, logoSize} = props;
  const size = {
      width: width ? `${width}px` : '135px'
  }
  return(
    <NavLink to="/"　className={styles.LogoWithTypeFace} style={size} exact={true}>
        <Logo width={logoSize ? logoSize : 28} mode={mode} />
        <img className={styles.LogoTypeFace} style={size} src={mode ? TaguJamTypeface_white : TaguJamTypeface } alt="TaguJam タグジャム　タイプファエイス" />
    </NavLink>
  );
} 
export default logoWithTypeFace;