import {
    FETCH_USER_META,
    FETCH_THE_USER_META,
    FETCH_THE_BUYER_META,
    ADD_USER_META_TO_LIST,
    SET_ACCOUNT_INFO_TO_META,
    SET_THE_PREFERENCE,
    SET_THE_MAIL_SUBSCRIPTION,
    SET_EMAIL_VERIFICATION_STATUS,
    SET_CERT_STATUS_TO_META,
    CHANGE_EMAIL_AND_RELATED
} from './actionTypes';
import { 
    uiStartLoading, 
    uiStopLoading,
    authGetToken
} from './index';

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchUserMetaPending = () => ({
    type: `${FETCH_USER_META}_PENDING`
});

export const fetchUserMetaRejected = (err) => ({
    type: `${FETCH_USER_META}_REJECTED`,
    payload: err
});

export const fetchUserMetaFulFilled = (userMeta) => ({
    type: `${FETCH_USER_META}_FULFILLED`,
    payload: userMeta
});

export const addUserMetaToList = (userMeta, id) => ({
    type: ADD_USER_META_TO_LIST,
    payload: {userMeta, id}
})

export const fetchAllUserMeta = () =>{
    return async dispatch => {
        dispatch(fetchUserMetaPending());
        try{
            let userMeta = await taguJamApi.fetchUserMeta();
            dispatch(fetchUserMetaFulFilled(userMeta))
            return userMeta;
        } catch(err){
            dispatch(fetchUserMetaRejected(err));
            throw err
        }
    }   
};

export const fetchTheBuyerMetaPending = () => ({
    type: `${FETCH_THE_BUYER_META}_PENDING`
});

export const fetchTheBuyerMetaRejected = (err) => ({
    type: `${FETCH_THE_BUYER_META}_REJECTED`,
    payload: err
});

export const fetchTheBuyerMetaFulFilled = (theUserMeta) => ({
    type: `${FETCH_THE_BUYER_META}_FULFILLED`,
    payload: theUserMeta
});


export const fetchTheUserMetaPending = () => ({
    type: `${FETCH_THE_USER_META}_PENDING`
});

export const fetchTheUserMetaRejected = (err) => ({
    type: `${FETCH_THE_USER_META}_REJECTED`,
    payload: err
});

export const fetchTheUserMetaFulFilled = (theUserMeta) => ({
    type: `${FETCH_THE_USER_META}_FULFILLED`,
    payload: theUserMeta
});

export const fetchTheUserMeta = (userId, buyerId) =>{
    return async dispatch => {
        let id = buyerId ? buyerId : userId;
        let pendingFunc = buyerId ? fetchTheBuyerMetaPending : fetchTheUserMetaPending;
        let fullFillFunc = buyerId ? fetchTheBuyerMetaFulFilled : fetchTheUserMetaFulFilled;
        let rejectFunc = buyerId ? fetchTheBuyerMetaRejected : fetchTheUserMetaFulFilled;
        dispatch(pendingFunc());
        try{
            let theUserMeta = await taguJamApi.fetchTheUserMeta(id);
            console.log("theUserMeta", theUserMeta);
            if(theUserMeta===null){
                theUserMeta = {id:id};
            } else {
                theUserMeta = {
                    ...theUserMeta,
                    id: id
                }
            }
            dispatch(fullFillFunc(theUserMeta))
            return theUserMeta;
        } catch(err){
            dispatch(rejectFunc(err));
            throw err
        }
    }   
};

export const setAccountInfoToMeta = (accountInfo) => ({
    type: SET_ACCOUNT_INFO_TO_META,
    payload:accountInfo 
});

export const updateOnline = (userId, value) => {
    return async dispatch => {
        dispatch(uiStartLoading('online'));
        try{
            let token = await dispatch(authGetToken()); 
            let online = await taguJamApi.updateOnlineStatus(userId, value, token);
            dispatch(uiStopLoading('online'));
            return online;  
        } catch(err){
            dispatch(uiStopLoading('online'));
            console.log(err);
            throw err;
        }
    }
}

export const setTheMailSubscription = (userId, key, value) => ({
    type: SET_THE_MAIL_SUBSCRIPTION,
    payload: {userId, key, value}
});

export const updateMailSubscriptionByKey = (userId, key, value) => {
    return async dispatch => {
        dispatch(uiStartLoading('subscription'));
        try{
            let token = await dispatch(authGetToken()); 
            let event = await taguJamApi.saveMailSubscriptionByKey(userId, key, value, token);
            dispatch(setTheMailSubscription(userId, key, value));
            dispatch(uiStopLoading());
            return event;  
        } catch(err){
            dispatch(uiStopLoading('subscription'));
            console.log(err);
            throw err;
        }
    }
}

export const setThePreference = (userId, key, value) => ({
    type: SET_THE_PREFERENCE,
    payload: {userId, key, value}
});

export const updatePreferenceByKey = (userId, key, value) => {
    return async dispatch => {
        dispatch(uiStartLoading('preference'));
        try{
            let token = await dispatch(authGetToken()); 
            let event = await taguJamApi.savePreferenceByKey(userId, key, value, token);
            dispatch(setThePreference(userId, key, value));
            dispatch(uiStopLoading('preference'));
            return event;  
        } catch(err){
            dispatch(uiStopLoading('preference'));
            console.log(err);
            throw err;
        }
    }
}

export const setEmailVerifyStatus = (userId) => ({
    type: SET_EMAIL_VERIFICATION_STATUS,
    payload: {userId}
});

export const setCertStatusToMeta = (certificateType, status, userId) => ({
    type: SET_CERT_STATUS_TO_META,
    payload: {certificateType, status, userId}
})

export const changeEmailAddress = (userId) => ({
    type: CHANGE_EMAIL_AND_RELATED,
    payload: {userId}
});