import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';

import RequestPurchase0 from '../../../assets/images/help/buying/buy.jpg';
import RequestPurchase0Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach.jpg';
import RequestPurchase0Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_1.jpg';
import RequestPurchase0Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_1.jpg';


import RequestPurchase1 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_1.jpg';
import RequestPurchase1Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_1.jpg';
import RequestPurchase1Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_2.jpg';
import RequestPurchase1Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_2.jpg';

import RequestPurchase2 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_2.png';
import RequestPurchase2Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_2.jpg';
import RequestPurchase2Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_3.jpg';
import RequestPurchase2Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_3.jpg';

import RequestPurchaseHelpExtra1 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_actual.png';
import RequestPurchaseTeachExtra1 from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_actual.png';
import RequestPurchaseRentExtra1 from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_4.png';
import RequestPurchaseMakeExtra1 from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_4.jpg';

import RequestPurchase3 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_3.png';
import RequestPurchase3Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_3.jpg';
import RequestPurchase3Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_5.png';

import RequestPurchase4 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_4.png';
import RequestPurchase4Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_4.jpg';
import RequestPurchase4Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_6.png';
import RequestPurchase4Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_5.jpg';


import RequestPurchase5 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_5.png';
import RequestPurchase5Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_5.jpg';
import RequestPurchase5Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_7.png';

import RequestPurchase6 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_6.png';
import RequestPurchase6Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_6.jpg';

import RequestPurchase7 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_7.png';

import RequestPurchase8 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_8.png';
import RequestPurchase8RentMake from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_make_1.png';

import RequestPurchase9 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_9.png';

import RequestPurchase10 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_10.png';

import RequestPurchaseMakeExtra2 from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_6.jpg';

import RequestPurchase11 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_11.png';
import RequestPurchase11Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_7.jpg';
import RequestPurchase11Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_9.png';
import RequestPurchase11Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_8.jpg';

import RequestPurchase12 from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_8.jpg';

import RequestPurchase13 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_13.png';
import RequestPurchase13Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_9.png';
import RequestPurchase13Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_10.png';
import RequestPurchase13Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_9.jpg';

import RequestPurchase14 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_14.png';
import RequestPurchase14Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_10.jpg';
import RequestPurchase14Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_11.png';
import RequestPurchase14Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_10.jpg';


import RequestPurchase15 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_15.jpg';
import RequestPurchase15Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_11.jpg';
import RequestPurchase15Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_12.png';
import RequestPurchase15Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_11.jpg';


import RequestPurchase16 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_16.jpg';
import RequestPurchase16Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_12.jpg';
import RequestPurchase16Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_13.png';
import RequestPurchase16Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_12.jpg';


import RequestPurchase17 from '../../../assets/images/help/buying/buy_req/buy_req_help/buy_req_help_17.jpg';
import RequestPurchase17Teach from '../../../assets/images/help/buying/buy_req/buy_req_teach/buy_req_teach_13.jpg';
import RequestPurchase17Rent from '../../../assets/images/help/buying/buy_req/buy_req_rent/buy_req_rent_14.png';
import RequestPurchase17Make from '../../../assets/images/help/buying/buy_req/buy_req_make/buy_req_make_13.jpg';




class RequestPurchase extends Component {
    getCategory = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'HELP':
                return '助けます';
            case 'TEACH':
                return '教えます';
            case 'RENT':
                return '貸します';
            case 'MAKE':
                return '作ります';
            default:
                return '助けます';
        }  
    }
    getPendingLabel = () => {
        const {currentTab} = this.props;  
        switch (currentTab) {
            case 'TEACH':
                return '受講';
            case 'RENT':
                return 'レンタル';
            default:
                return '購入';
        }  
    }
    render() {
        const {currentTab} = this.props;
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>購入申請する（{this.getCategory()}）</span>
            </h3>
            <p className={styles.ArticleDesc}>
            {this.getPendingLabel()}申請前に、サービスの詳細ページの内容を確認して、質問がある場合は出品者に聞いておきましょう。詳細ページの簡易見積もり確認後、{this.getPendingLabel()}申請できます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        申請したいサービスをタップし、サービスの詳細ページに行きます。    
                    </li>
                    { currentTab==='HELP' &&
                        <Aux>
                            <img className={styles.ScreenShot} src={RequestPurchase0} alt="購入申請するステップ1-1画像" />
                            <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestPurchase1} alt="購入申請するステップ1-2画像" />
                        </Aux>
                    }
                    { currentTab==='TEACH' &&
                        <Aux>
                            <img className={styles.ScreenShot} src={RequestPurchase0Teach} alt="購入申請するステップ1-1画像" />
                            <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestPurchase1Teach} alt="購入申請するステップ1-2画像" />
                        </Aux>
                    }
                    { currentTab==='RENT' &&
                        <Aux>
                            <img className={styles.ScreenShot} src={RequestPurchase0Rent} alt="購入申請するステップ1-1画像" />
                            <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestPurchase1Rent} alt="購入申請するステップ1-2画像" />
                        </Aux>
                    }
                    { currentTab==='MAKE' &&
                        <Aux>
                            <img className={styles.ScreenShot} src={RequestPurchase0Make} alt="購入申請するステップ1-1画像" />
                            <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestPurchase1Make} alt="購入申請するステップ1-2画像" />
                        </Aux>
                    }

                    { currentTab==='HELP' &&
                        <Aux>
                            <li>
                                詳細ページを「サービス内容」までスワイプし必要なサービスを選びます。        
                            </li>
                            <img className={styles.ScreenShot} src={RequestPurchase2} alt="購入申請するステップ2画像" />
                        </Aux>
                    }
                    { currentTab==='TEACH' &&
                        <Aux>
                            <li>
                                詳細ページを「準備してもらう物」までスワイプしクラスに参加するにあたり準備するものを確認します。       
                            </li>
                            <img className={styles.ScreenShot} src={RequestPurchase2Teach} alt="購入申請するステップ2画像" />
                        </Aux>
                    }
                    { currentTab==='RENT' &&
                        <Aux>
                        <li>
                        基本料金、保証金などを確認し、配達のオプションが選択可能であれば依頼することができます。
                        </li>
                        <p>＊保証金とは、万が一レンタル品の返却がなされない場合や、レンタル品が使用できなくなる故障などが起こった場合は、保証金の額をレンタル申請者（借り手）に支払ってもらう金額です。無事に返却されれば支払いの必要はありません。</p>
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={RequestPurchase2Rent} alt="購入申請するステップ3画像" />
                        </Aux>
                    }
                    { currentTab==='MAKE' &&
                        <Aux>
                            <li>
                                詳細ページをスワイプし基本料金に含まれるものや、配達などのオプションを必要であれば選択します。       
                            </li>
                            <img className={styles.ScreenShot} src={RequestPurchase2Make} alt="購入申請するステップ2画像" />
                        </Aux>
                    }

                    { (currentTab==='HELP' || currentTab==='TEACH') &&
                        <Aux>
                        <li>
                        詳細ページをさらにスワイプし、実費でかかる費用を確認します。
                        </li>
                        <p>＊実費でかかる費用とは最終請求書が作成される際に実際に費用が発生した場合に実費で請求金額に加わる料金です。</p>
                        {currentTab==='HELP' &&
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={RequestPurchaseHelpExtra1} alt="購入申請するステップ3画像" />
                        }
                        {currentTab==='TEACH' &&
                        <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={RequestPurchaseTeachExtra1} alt="購入申請するステップ3画像" />
                        }
                        </Aux>
                    }

                    { currentTab==='RENT' &&
                        <Aux>
                        <li>
                        詳細ページをさらにスワイプし、返却時適用料金を確認してください。
                        </li>
                        <p>＊返却時適用料金とは返却の際貸主が適応する場合がある料金です。</p>
                        <img className={styles.ScreenShot} src={RequestPurchaseRentExtra1} alt="購入申請するステップ3画像" />
                        </Aux>
                    }

                    { currentTab==='MAKE' &&
                        <Aux>
                        <li>
                        詳細ページをさらにスワイプし、料金が発生した時に適用される金額の上限もチェックします。
                        </li>
                        <p>＊基本料金に含まれている物材料や作業量では注文の作品を完成できない場合に適用される料金です。</p>
                        <img className={styles.ScreenShot} src={RequestPurchaseMakeExtra1} alt="購入申請するステップ3画像" />
                        </Aux>
                    }
                    
                    { currentTab!=='MAKE' &&
                    <Aux>
                        <li>
                            詳細ページをさらに「スケジュール」までスワイプし、希望の日にちを選択します。
                        </li>
                        { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={RequestPurchase3} alt="購入申請するステップ3画像" />
                        }
                        { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={RequestPurchase3Teach} alt="購入申請するステップ3画像" />
                        }
                        { currentTab==='RENT' &&
                        <img className={styles.ScreenShot} src={RequestPurchase3Rent} alt="購入申請するステップ3画像" />
                        }
                    </Aux>
                    }

                    <li>
                        詳細ページをさらに「注意事項」までスワイプして注意事項を読み、「簡易見積」ボタンをタップします。    
                    </li>
                    { currentTab==='HELP' &&
                    <img className={styles.ScreenShot} src={RequestPurchase4} alt="購入申請するステップ4画像" />
                    }
                    { currentTab==='TEACH' &&
                    <img className={styles.ScreenShot} src={RequestPurchase4Teach} alt="購入申請するステップ4画像" />
                    }
                    { currentTab==='RENT' &&
                    <img className={styles.ScreenShot} src={RequestPurchase4Rent} alt="購入申請するステップ4画像" />
                    }
                    { currentTab==='MAKE' &&
                    <img className={styles.ScreenShot} src={RequestPurchase4Make} alt="購入申請するステップ4画像" />
                    }
                    
                    { (currentTab==='HELP' || currentTab==='TEACH') &&
                    <li>
                        予約日程の「予約日」を確認し、間違っている場合は「日付変更」ボタンをタップし、日にちを選び直してください。         
                    </li>
                    }
                    { currentTab==='RENT' &&
                    <li>
                        予約日程を確認し、間違っている場合は「開始」をタップし、日にちを選び直してください。         
                    </li>
                    }
                    { currentTab==='HELP' &&
                    <img className={styles.ScreenShot} src={RequestPurchase5} alt="購入申請するステップ5画像" />
                    }
                    { currentTab==='TEACH' &&
                    <img className={styles.ScreenShot} src={RequestPurchase5Teach} alt="購入申請するステップ5画像" />
                    }
                    { currentTab==='RENT' &&
                    <img className={styles.ScreenShot} src={RequestPurchase5Rent} alt="購入申請するステップ5画像" />
                    }

                    { (currentTab==='HELP' || currentTab==='TEACH') &&
                    <Aux>
                        <li>
                        予約日程の「開始時刻」を確認し、希望の時間でない場合は「開始時刻」をタップし、「時刻設定」の画面で「開始時刻」を変更します。注文時間の変更は、「サービス時間」の「＋」、「ー」をタップして変更します。変更をリセットする場合は、「自動設定」でシステムが自動で設定した「開始時刻」「サービス時間」に戻すことが出来ます。変更が終わったら、「時間確定」ボタンをタップして前画面に戻ります。         
                        </li>
                        { currentTab==='HELP' &&
                        <img className={styles.ScreenShot} src={RequestPurchase6} alt="購入申請するステップ6-1画像" />
                        }
                        { currentTab==='TEACH' &&
                        <img className={styles.ScreenShot} src={RequestPurchase6Teach} alt="購入申請するステップ6-1画像" />
                        }
                        <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestPurchase7} alt="購入申請するステップ6-2画像" />
                        { currentTab==='HELP' &&
                        <Aux>
                        <p>＊最短受付時間より短く注文する事は出来ません。</p>
                        <p>＊提供シフト時間を超えて、開始時刻や、注文時間を変更した場合はエラーとなります。</p>
                        <p>＊「訪問型」のサービスの場合は最低３０分の移動時間も考慮され、提供シフト時間を超えている場合はエラーとなります。</p>
                        </Aux>
                        }
                        { currentTab==='TEACH' &&
                        <Aux>
                        <p>＊教えますのサービスはサービス時間の変更は出来ません。</p>
                        <p>＊クラス提供シフト時間外の開始時刻へ変更した場合はエラーとなります。</p>
                        <p>＊「訪問型」のクラスサービスの場合は最低３０分の移動時間も考慮され、提供シフト時間を超えている場合はエラーとなります</p>
                        </Aux>
                        }
                    </Aux>
                    }
                    
                    { ( currentTab==='HELP' || currentTab==='TEACH' ) &&
                    <li>
                    「訪問型」のサービスの場合は「住所を選択＞」ボタンをタップし、訪問してもらう住所を設定します。登録済みの住所と違う場所を指定したい時は、「新規住所をアドレス帳に登録＞」をタップし登録を行ってください。             
                    </li>
                    }
                    { ( currentTab==='RENT' || currentTab==='MAKE' ) &&
                    <li>
                    「配達」オプションがある場合は「住所を選択＞」ボタンをタップし、訪問してもらう住所を設定します。登録済みの住所と違う場所を指定したい時は、「新規住所をアドレス帳に登録＞」をタップし登録を行ってください。             
                    </li>
                    }
                    { ( currentTab==='HELP' || currentTab==='TEACH' ) &&
                    <img className={styles.ScreenShot} src={RequestPurchase8} alt="購入申請するステップ7-1画像" />
                    }
                    { ( currentTab==='RENT' || currentTab==='MAKE' ) &&
                    <img className={styles.ScreenShot} src={RequestPurchase8RentMake} alt="購入申請するステップ7-1画像" />
                    }
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={RequestPurchase9} alt="購入申請するステップ7-2画像" />
                    <p className={styles.HeaderMargin10}>＊住所の登録がない場合は、「依頼先郵便番号」を入力し番地を入力し「住所を登録する＞」ボタンをタップして登録しましょう。</p>
                    <img className={`${styles.ScreenShot} ${styles.HeaderMargin10}`} src={RequestPurchase10} alt="購入申請するステップ7-3画像" />
                    

                    { currentTab==='MAKE' &&
                    <Aux>
                        <li>
                        注文件数・個数を「＋」、「ー」で調節します。オプションが必要であれば選択します。
                        </li>
                        <img className={styles.ScreenShot} src={RequestPurchaseMakeExtra2} alt="購入申請するステップ8画像" />
                    </Aux>
                    }

                    { currentTab==='HELP' &&
                    <Aux>
                        <li>
                            「サービス内容」でご希望のサービスが選択されている事を確認してください。
                            <p className="Caption">＊訪問型のサービスの場合は訪問先住所も確認してください。</p>
                        </li>
                        <img className={styles.ScreenShot} src={RequestPurchase11} alt="購入申請するステップ8画像" />
                    </Aux>
                    }
                    { currentTab==='TEACH' &&
                    <Aux>
                        <li>
                        ご希望のクラスの時間が選択されている事を再度確認してください。
                        <p className="Caption">＊訪問型のクラスの場合は訪問先住所も確認してください。</p>
                        </li>
                        <img className={styles.ScreenShot} src={RequestPurchase11Teach} alt="購入申請するステップ8画像" />
                    </Aux>
                    }
                    { currentTab==='RENT' &&
                    <Aux>
                        <li>
                        申請の内容を再度確認してください。
                        <p className="Caption">＊配達オプションを選択の場合は住所も確認してください。</p>
                        </li>
                        <img className={styles.ScreenShot} src={RequestPurchase11Rent} alt="購入申請するステップ8画像" />
                    </Aux>
                    }
                    { currentTab==='MAKE' &&
                    <Aux>
                        <li>
                        申請の内容を再度確認してください。
                        <p className="Caption">＊配達オプションを選択の場合は住所も確認してください。</p>
                        </li>
                        <img className={styles.ScreenShot} src={RequestPurchase11Make} alt="購入申請するステップ8画像" />
                    </Aux>
                    }
                    
                    <li>
                        「見積もり計算」をタップします。             
                    </li>
                    <img className={styles.ScreenShot} src={RequestPurchase12} alt="購入申請するステップ9画像" />
                    
                    { ( currentTab==='HELP' || currentTab==='TEACH' ) &&
                    <li>
                    必要なサービス項目、合計金額、予約日時、住所（*訪問型の場合）を確認し、「{this.getPendingLabel()}申請」ボタンをタップする。         
                    </li>
                    }
                    { ( currentTab==='RENT' || currentTab==='MAKE' ) &&
                    <li>
                    必要なサービス項目、合計金額、{currentTab!=='MAKE' && '予約日時、' }住所（*配達の場合）を確認し、「{this.getPendingLabel()}申請」ボタンをタップする。         
                    </li>
                    }
                    { currentTab==='TEACH' && 
                        <p>＊「来客型」のクラスの住所は売り手の方が「受講申請」の受付が済み次第見積もりに表示されます。</p>
                    }
                    { currentTab==='HELP' &&
                    <img className={styles.ScreenShot} src={RequestPurchase13} alt="購入申請するステップ10画像" />
                    }
                    { currentTab==='TEACH' &&
                    <img className={styles.ScreenShot} src={RequestPurchase13Teach} alt="購入申請するステップ10画像" />
                    }
                    { currentTab==='RENT' &&
                    <img className={styles.ScreenShot} src={RequestPurchase13Rent} alt="購入申請するステップ10画像" />
                    }
                    { currentTab==='MAKE' &&
                    <img className={styles.ScreenShot} src={RequestPurchase13Make} alt="購入申請するステップ10画像" />
                    }

                    <li>
                        「自己紹介」に自分の事を紹介しておきましょう。「メッセージ」の欄に、{this.getPendingLabel()}についての質問もしくは、要望があれば入力しておきましょう。{currentTab!=='MAKE' && '日時と、' }金額を確認して「{this.getPendingLabel()}申請送信」ボタンをタップします。         
                    </li>
                    { currentTab==='HELP' &&
                    <img className={styles.ScreenShot} src={RequestPurchase14} alt="購入申請するステップ10画像" />
                    }
                    { currentTab==='TEACH' &&
                    <img className={styles.ScreenShot} src={RequestPurchase14Teach} alt="購入申請するステップ10画像" />
                    }
                    { currentTab==='RENT' &&
                    <img className={styles.ScreenShot} src={RequestPurchase14Rent} alt="購入申請するステップ10画像" />
                    }
                    { currentTab==='MAKE' &&
                    <img className={styles.ScreenShot} src={RequestPurchase14Make} alt="購入申請するステップ10画像" />
                    }

                    <li>
                        「メッセージを確認する」ボタンをタップします。
                    </li>
                    { currentTab==='HELP' &&
                    <img className={styles.ScreenShot} src={RequestPurchase15} alt="購入申請するステップ11画像" />
                    }
                    { currentTab==='TEACH' &&
                    <img className={styles.ScreenShot} src={RequestPurchase15Teach} alt="購入申請するステップ11画像" />
                    }
                    { currentTab==='RENT' &&
                    <img className={styles.ScreenShot} src={RequestPurchase15Rent} alt="購入申請するステップ11画像" />
                    }
                    { currentTab==='MAKE' &&
                    <img className={styles.ScreenShot} src={RequestPurchase15Make} alt="購入申請するステップ11画像" />
                    }

                    <li>
                        自動的に「取引」＞「買い」＞「取引中」に移動します。申請中の取引をタップします。         
                    </li>
                    { currentTab==='HELP' &&
                    <img className={styles.ScreenShot} src={RequestPurchase16} alt="購入申請するステップ12画像" />
                    }
                    { currentTab==='TEACH' &&
                    <img className={styles.ScreenShot} src={RequestPurchase16Teach} alt="購入申請するステップ12画像" />
                    }
                    { currentTab==='RENT' &&
                    <img className={styles.ScreenShot} src={RequestPurchase16Rent} alt="購入申請するステップ12画像" />
                    }
                    { currentTab==='MAKE' &&
                    <img className={styles.ScreenShot} src={RequestPurchase16Make} alt="購入申請するステップ12画像" />
                    }

                    <li>
                        チャット画面が現れます。今後はサービス提供者(売り手)とのコミュニケーションはこのチャットを使います。         
                    </li>
                    { currentTab==='HELP' &&
                    <img className={styles.ScreenShot} src={RequestPurchase17} alt="購入申請するステップ13画像" />
                    }
                    { currentTab==='TEACH' &&
                    <img className={styles.ScreenShot} src={RequestPurchase17Teach} alt="購入申請するステップ13画像" />
                    }
                    { currentTab==='RENT' &&
                    <img className={styles.ScreenShot} src={RequestPurchase17Rent} alt="購入申請するステップ13画像" />
                    }
                    { currentTab==='MAKE' &&
                    <img className={styles.ScreenShot} src={RequestPurchase17Make} alt="購入申請するステップ13画像" />
                    }

                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(RequestPurchase);