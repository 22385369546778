import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import SellerCancel1 from '../../../assets/images/help/selling/sell_cancel/sell_cancel_1.jpg';
import SellerCancel2 from '../../../assets/images/help/selling/sell_cancel/sell_cancel_2.jpg';
import SellerCancel3 from '../../../assets/images/help/selling/sell_cancel/sell_cancel_3.jpg';
import SellerCancel4 from '../../../assets/images/help/selling/sell_cancel/sell_cancel_4.jpg';
import SellerCancel5 from '../../../assets/images/help/selling/sell_cancel/sell_cancel_5.jpg';
import SellerCancel6 from '../../../assets/images/help/selling/sell_cancel/sell_cancel_6.jpg';
import SellerCancel7 from '../../../assets/images/help/selling/sell_cancel/sell_cancel_7.jpg';

class SellerCancel extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>サービス提供のキャンセル</span>
            </h3>
            <p className={styles.ArticleDesc}>
                キャンセルは基本的には出来ません。ただし、止むを得ない場合のみキャンセル出来ます。
            </p>
            <p className={styles.Red}>＊キャンセルをする場合は必ずサービス購入者（買い手）の同意を得て行ってください。</p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        取引の画面にいない場合は「取引」＞「売り」＞「取引中」を選びます。     
                    </li>
                    <img className={styles.ScreenShot} src={SellerCancel1} alt="サービス提供のキャンセルのステップ1画像" />
                    <li>
                        キャンセルしたい取引をタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={SellerCancel2} alt="サービス提供のキャンセルのステップ2画像" />
                    <p>
                        キャンセルする前に必ず買い手の方に連絡し、了解をとりましょう。    
                    </p>
                    <img className={styles.ScreenShot} src={SellerCancel3} alt="サービス提供のキャンセルのステップ3画像" />
                    <li>
                        画面下部の「止むを得ずキャンセルしなくてはならない」をタップします。
                    <p className={`${styles.Red} Caption`}>＊キャンセルの取り消しは致しかねますので十分注意してください。</p>    
                    </li>
                    <img className={styles.ScreenShot} src={SellerCancel4} alt="サービス提供のキャンセルのステップ4画像" />
                    <li>
                        「キャンセルの理由」を選択するとメッセージが自動入力されます。編集が必要であればお願いします。「購入者もキャンセルに同意しています」にチェックをつけてください。最後の「取引キャンセル送信」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={SellerCancel5} alt="サービス提供のキャンセルのステップ5画像" />
                    
                    <li>
                        「会話に戻る」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={SellerCancel6} alt="サービス提供のキャンセルのステップ6画像" />
                    <li>
                        キャンセルのメッセージを確認する   
                    </li>
                    <img className={styles.ScreenShot} src={SellerCancel7} alt="サービス提供のキャンセルのステップ7画像" />
                </ol>
            </div>
        </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(SellerCancel);