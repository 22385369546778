import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styles from './Home.module.scss';
import Hero from '../../components/HomePage/Hero/Hero';
import Header from '../../components/HomePage/Header/Header';
import CatDescriptions from '../../components/HomePage/CatDescriptions/CatDescriptions';
import Merits from '../../components/HomePage/Merits/Merits';
import ActionButtons from '../../components/HomePage/ActionButtons/ActionButtons';
import SafeSystem from '../../components/HomePage/SafeSystem/SafeSystem';
import SystemFlow from '../../components/HomePage/SystemFlow/SystemFlow';
import FeaturedList from '../../components/HomePage/FeaturedList/FeaturedList';
import {
    Spinner,
} from '../../components/UI'
import {
    initUiErrorState,
    toggleErrorWinOpen,
    fetchGigs,
    fetchAllUserMeta,
    setMainMenu,
    setSubMenu,
    setMainSubMenu,
    getLocationAddress
} from '../../store/actions';
import {plainData} from '../../utils'

class Home extends Component {

    getUserLocation = () => {
        const {locationAddress} = this.props;
        if(!locationAddress || (locationAddress && !locationAddress.state))
        {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.getAddress, this.handleGeoLocationError);
            } else { 
                console.log("This browser doesn't support Geolocation")
            }   
        } 
    }

    getAddress = (position) => {
        const { localCode, onGetLocationAddress } = this.props;
        const { coords } = position;
        onGetLocationAddress(coords, localCode);
    }

    handleGeoLocationError = (error) => {
        switch(error.code) {
            case error.PERMISSION_DENIED:
                console.log("User denied the request.")
                break;
            case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.")
                break;
            case error.TIMEOUT:
                console.log("The request timed out.")
                break;
            case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.")
                break;
            default:
                console.log("Something went wrong")
                break;
          }
    }

    componentDidMount = async () => {
        const {
            onLoadGigs,
            onInitUiErrorState,
            onToggleErrorWinOpen
        } = this.props;
        try {
            this.getUserLocation();
            // eslint-disable-next-line no-unused-vars
            const gigs = await onLoadGigs();
            this.setState(prevState =>{
                return {
                    ...prevState,
                    dataLoaded:true
                }
            })
        } catch (error) {
            const initState = {
                errorWinOpen: false,
                closable: true,
                systemError: 'ERROR_OCCURED'    
            }
            onInitUiErrorState(initState);
            onToggleErrorWinOpen();
        }
    }

    handleGigPress = (gigId, MainCat) => {
        console.log("handleGigPress");
        this.props.history.push({
            pathname: `/detail/${gigId}`
        });
    }

    getGigList = () => {
        const {gigs} = this.props;
        let list =[];
        for (let key in gigs){
            list.push({id:key, ...gigs[key]});
        }
        return list;
    }

    handleOnSelected= (mainMenuKey) => {
        this.props.onMainMenuSelected(mainMenuKey);
        this.props.history.push({
            pathname: `/catalog/${mainMenuKey.toLowerCase()}`
        });
    }

    renderFeatureList = () => {
        const { localCode, appData } = this.props;
        const { MainCatItems } = appData;
        const gigList = this.getGigList();
        let activeGigs = gigList.filter( gig => {
            return gig.active === true && gig.publish === true;
        });
        return(
            <FeaturedList 
                MainCatItems={MainCatItems} 
                localCode={localCode}
                GigDataList={activeGigs}
                onCategoryPress={this.handleOnSelected}
                onPressGig={this.handleGigPress}
                appData={appData}
            />
        );
    }

    getStructureData = () => {
      return {
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "タグジャム-TaguJam",
        "url": "https://tagujam.com",
        "logo": "https://tagujam.com/static/media/tagujam_logo.40e70b4d.svg",
        "image": "https://tagujam.com/static/media/tagujam_logo.40e70b4d.svg",
        "description": "個人の「手伝う」を売り買いするフリマ - TaguJam (タグジャム)"
      }
    }
    render(){
        const {
            localCode,
            appData, 
            gigsIsLoading, gigs, gigsError,
            location
        } = this.props;
        const {LocalStrings} = appData;

        let content = null;

        if( gigsIsLoading ){
            content = <div className='SpinnerBox'>
                <Spinner large />
            </div>
        }

        if(gigs){
            content = this.renderFeatureList();
        }

        if(gigsError){
            content = <p>{LocalStrings[localCode].ERROR_OCCURED}</p>;
        }
        let pathname = location.pathname;
        let title = "個人の「手伝う」を売り買いするフリマ - タグジャム (TaguJam)";
        let description = "タグジャムは誰もが参加できる個人の「手伝う」を売り買いするフリマアプリです。自分ができるお手伝に値札「タグ」をつけて簡単に出品できます。地域のみんなのありがとうを体験しよう！";

        return(
            <>
            <Helmet>
              <script type="application/ld+json">
                {plainData(this.getStructureData())}
              </script>
              <meta name="twitter:card" content="summary" data-react-helmet="true" />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:url" content={pathname} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
              <meta property="og:image:type" content="image/png" />
              <title>{title}</title>
              <meta
                name="description"
                content={description}
              />
              <meta content="助けます,助けて,教えます,教えて,貸します,貸して,作ります,作って,便利屋,地方のサービスクラシファイド,地域の掲示板,売り買いの手伝い,お手伝い,助っ人,お手伝いで繋がる,地域貢献,困ってる人を助ける,個人サービスのフリマ" name="keywords" />
            </Helmet>
            <div className={styles.HomeContent}>
                <Hero />
                <div className={styles.SectionContainer}>
                    <Header /> 
                </div>
                <div className={styles.SectionContainer}>
                    <CatDescriptions /> 
                </div>
                <div className={styles.SectionContainer}>
                    <Merits />
                    <ActionButtons />
                </div>
                <div className={styles.SectionHead} >
                    <h1>注目のタグ</h1>
                </div>
                <div className={styles.FeaturedListContainer}>
                    {content}
                </div>
                <div className={styles.SectionHead} >
                    <h1>安全取引•明朗会計</h1>
                </div>
                <div className={styles.SectionContainer} style={{marginTop:'15px'}}>
                    <SafeSystem />
                </div>
                <div className={styles.SectionHead} >
                    <h1>タグジャムの仕組み</h1>
                </div>
                <div className={styles.SectionContainer} style={{marginTop:'20px'}}>
                    <SystemFlow />
                </div>
            </div>
          </>
        );
    }
}

const mapStateToProps = ({
    ui:{ Auth, isLoading, Cat: {MainCat, SubCat} },
    local: {localCode},
    appData: {appData},
    gigs: {gigsIsLoading, gigs, gigsError },
    search,
    userLocationAddress: {locationAddress},
}) => ({
    uiAuth: Auth,
    isLoading,
    localCode,
    appData,
    gigsIsLoading, gigs, gigsError,
    MainCat, SubCat,
    search,
    locationAddress
});

const mapDispatchToProps = dispatch => ({
    onLoadGigs: () => dispatch( fetchGigs() ),
    onLoadUserMeta: () => dispatch( fetchAllUserMeta() ),
    onInitUiErrorState : (initState) => dispatch(initUiErrorState(initState)),
    onToggleErrorWinOpen : () => dispatch(toggleErrorWinOpen()),
    onMainMenuSelected : (key) => dispatch( setMainMenu(key) ),
    onSubMenuSelected : (key) => dispatch( setSubMenu(key) ),
    onSetMainSubMenu : (Main, Sub) => dispatch( setMainSubMenu(Main, Sub) ),
    onGetLocationAddress: (coords, localCode) => dispatch( getLocationAddress(coords, localCode) ),
});


export default connect(mapStateToProps, mapDispatchToProps)(Home);