import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './Article.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Logo from '../../../components/Brand/Logo/Logo';
import CustomerSupport1 from '../../../assets/images/help/buying/buy_customer_support/buy_customer_support_1.png';
import CustomerSupport2 from '../../../assets/images/help/buying/buy_customer_support/buy_customer_support_2.png';
import CustomerSupport3 from '../../../assets/images/help/buying/buy_customer_support/buy_customer_support_3.png';
import CustomerSupport4 from '../../../assets/images/help/buying/buy_customer_support/buy_customer_support_4.png';
import ReportService4 from '../../../assets/images/help/buying/buy_report_service/buy_report_service_4.png';
import ReportService6 from '../../../assets/images/help/buying/buy_report_service/buy_report_service_6.png';

class CustomerSupport extends Component {
    render() {
        return(
        <Aux>
            <h3 className={styles.ArticleHead}>
                <Logo width={20} />
                <span className={styles.ArticleTitle}>カスタマーサポート</span>
            </h3>
            <p className={styles.ArticleDesc}>
            タグジャムでは、使い方の質問や、各種機能でお困りの場合、以下の手順でカスタマーサポートケースを作成する事ができます。
            </p>
            <div className={`Item ${styles.HeaderMargin10}`}>
                <ol className={styles.HowToStep}>
                    <li>
                        「アカウント」 > 「カスタマーサポート」をタップする       
                    </li>
                    <img className={styles.ScreenShot} src={ReportService6} alt="カスタマーサポートケースを作成するステップ1画像" /> 
                    <li>
                        画面下の「サポートケースの作成」ボタンをタップします。     
                    </li>
                    <img className={styles.ScreenShot} src={CustomerSupport1} alt="カスタマーサポートケースを作成するステップ2画像" />
                    <li>
                        サポートが必要な理由を選択し、「お困り事・質問・問題」を入力し、「お困り・サポートが必要を報告する」ボタンをタップします。   
                    </li>
                    <img className={styles.ScreenShot} src={CustomerSupport2} alt="カスタマーサポートケースを作成するステップ3画像" />
                    <li>
                        送信済みの報告内容を確認する場合は「カスタマーサポートへ」ボタンをタップします。    
                    </li>
                    <img className={styles.ScreenShot} src={ReportService4} alt="カスタマーサポートケースを作成するステップ4画像" />
                    <li>
                        該当するケースをタップして内容、又は返信を確認出来ます。     
                    </li>
                    <img className={styles.ScreenShot} src={CustomerSupport3} alt="カスタマーサポートケースを作成するステップ5-1画像" />
                    <img className={`${styles.ScreenShot} ${styles.ScreenShotRight}`} src={CustomerSupport4} alt="カスタマーサポートケースを作成するステップ5-2画像" />
                </ol>
            </div>
            </Aux>
        );
    }
}

const mapStateToProps = ({
    ui:{ Help:{ currentTab, currentMode } }
}) => ({
    currentTab, currentMode    
});

export default connect(mapStateToProps, null)(CustomerSupport);