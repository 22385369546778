import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {connect} from 'react-redux';
import AboutStyles from '../About.module.scss';
import styles from './Mission.module.scss';
import Blue_line from '../../../assets/images/blue_line.svg';

class Mission extends Component {
    render(){
      const {location} = this.props;
      let pathname = location.pathname;
      let title = '私たちの思い';
      let description = '誰かの助けになりたい、でもどうして良いか分からない誰かの助けが欲しい、でもどこに頼むか分からない。タグジャムはそんなあなたに「一歩踏み出す」キッカケを与える会社です。';
        return(
          <>
          <Helmet>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`タグジャム-${title}`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:url" content={pathname} />
            <meta property="og:title" content={`タグジャム-${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://tagujam.com/tagujam_opg_1200x1200.png" />
            <meta property="og:image:secure_url" content="https://tagujam.com/tagujam_opg_1200x1200.png" data-react-helmet="true" />
            <meta property="og:image:type" content="image/png" />
            <title>{title}</title>
            <meta
              name="description"
              content={description}
            />
            <meta content="タグジャムミッション,一歩踏み出す,頑張る人,地位域貢献,助け合い,お手伝いネットワーク,ゆるい働き方,売り買いのお手伝い,地方活性" name="keywords" />
          </Helmet>
          <div className={AboutStyles.AboutPageContent} >
            <h2 className={AboutStyles.SectionHead}>私たちの思い</h2>
            <div className={styles.Section}>
              <div className={styles.SectionTitleBox}>
                <div className={`${styles.SectionTitleBG} ${styles.TagBG}`} />
                <div className={styles.SectionTitle}>
                  <p className={styles.SectionTitleText}>
                    ミッション 
                  </p>
                  <img className={styles.SectionBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                    地域を応援し続ける 
                  </p>
                </div>
              </div>
              <p className={styles.Description}>
              誰かの助けになりたい、でもどうして良いか分からない。誰かの助けが欲しい、でもどこに頼むか分からない。そんな地域の皆さんに「一歩踏み出す」キッカケを与え、応援し続ける事が、<strong>タグジャム</strong>の使命と考えています。
              </p>
            </div>
            <div className={styles.Section}>
              <div className={styles.SectionTitleBox}>
                <div className={`${styles.SectionTitleBG} ${styles.HeartBG}`} />
                <div className={styles.SectionTitle}>
                  <p className={styles.SectionTitleText}>
                    ビジョン 
                  </p>
                  <img className={styles.SectionBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                    全ての地域を助け合いで豊かに 
                  </p>
                </div>
              </div>
              <p className={styles.Description}>
                <strong>タグジャム</strong>は、特定の都市部や年齢層だけが恩恵を受けるものでなく、全ての地域そして全ての年代の方々が、お互いに助け合う事でもっと豊かな社会になる事を願っています。様々な地域、様々な年代の方々、助け合いを提供する方も依頼する方も、お互いが安心してポジティブな気持ちを循環できるコミュニティー形成を目指しています。
              </p>
            </div>
            <div className={styles.Section}>
              <div className={styles.SectionTitleBox}>
                <div className={`${styles.SectionTitleBG} ${styles.FlowerBG}`} />
                <div className={styles.SectionTitle}>
                  <p className={styles.SectionTitleText}>
                    依頼者の方へ 
                  </p>
                  <img className={styles.SectionBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                    時には頼る事で自由になれる 
                  </p>
                </div>
              </div>
              <p className={styles.Description}>
                <strong>タグジャム</strong>は、頑張る人に寄り添うサービスを目指しています。家事、育児、介護、日常の困り事を「自分でやらなきゃ」と日々奮闘している方に、時には「誰かに頼る」事で自由になれると言う考えを広めたいと思っています。また、公的サービスや、業者では行き届かない、地域の小さな「お手伝い」のニーズに寄り添える人々の輪を広げたいと考えています。育児、スマホ、趣味、文化など、得意な方から楽しく教われる、旅行用品、礼服、楽器などを借りれる、ゼッケン、雑巾、通園バッグなどの作成を得意な人から作ってもらうなど、ちょっとした「お手伝い」が提供できるコミュニティーを作ります。
              </p>
            </div>
            <div className={styles.Section}>
              <div className={styles.SectionTitleBox}>
                <div className={`${styles.SectionTitleBG} ${styles.CloverBG}`} />
                <div className={styles.SectionTitle}>
                  <p className={styles.SectionTitleText}>
                    提供者の方へ 
                  </p>
                  <img className={styles.SectionBorder} src={Blue_line} alt="menu border" />
                  <p className={styles.Statement}>
                    誰でも誰かの役に立てる 
                  </p>
                </div>
              </div>
              <p className={styles.Description}>
                <strong>タグジャム</strong>では、様々な方が出来る事から自分のペースで地域に貢献できる仕組みを提供したいと思っています。保育、看護、介護、理・美容の有資格者またはエステ・ネイルの経験者で開業資金はなくても直接自分のサービスをお客様に届けたい方、資格はないけど、家事、お掃除など、お手伝いをしたい方、時間はないけど使っていない物をレンタルできる方、自分の特技やスキルを教えられる方、何かを作って欲しいなどのお手伝いができる方など、誰でも誰かの助けになれて、地域の「ありがとう」を体験できるプラットフォームの提供を目指しています。
              </p>
            </div>
          </div>
        </>
        );
    }
}

const mapStateToProps = ({
  local: { localCode },
  appData: {appData, appDataIsLoading, appDataError},
}) => ({
  localCode,
  appData, appDataIsLoading, appDataError, 
});

export default connect(mapStateToProps, null)(Mission);