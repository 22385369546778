import {
    TRY_AUTH_START,
    TRY_AUTH_END,
    SET_AUTH,
    SET_AUTH_ERROR,
    SET_AUTH_SUCCESS
} from './actionTypes';

import {
    toggleAuthWinOpen,
    updateOnline,
    addUserMetaToList,
    changeEmailAddress,
    resetEmailVerification
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';
import FirbaseApi from '../../constants/FirebaseApi';

const taguJamApi = new TaguJamApi();

let authTimer = null;

const tryAuthStart = () => {
    return {
        type: TRY_AUTH_START
    }
};

const tryAuthEnd = () => {
    return {
        type: TRY_AUTH_END
    }
};

const setAuth = (data) => {
    return {
        type: SET_AUTH,
        payload: data
    }
}

export const setAuthError = (errorMsg) => ({
    type: SET_AUTH_ERROR,
    payload: errorMsg
});

export const setAuthSuccess = (successMsg) => ({
    type: SET_AUTH_SUCCESS,
    payload: successMsg
});

export const authGetToken = (needUserId) => {
    return async (dispatch, getState) => {
        try {
            console.log("authGetToken called");
            let token = localStorage.getItem("token");
            let email = localStorage.getItem("email");  
            let expirationDate = new Date(localStorage.getItem("expirationDate"));
            let userId = localStorage.getItem("userId");

            if(!token) {
                throw new Error("Token not found in storage");
            } else {
                if(expirationDate <= new Date()){
                    throw new Error("Token has expired");
                } else {
                    const expiresIn = (expirationDate.getTime() - new Date().getTime()) / 1000;
                    dispatch(storeAuthToken(token, email, expiresIn, userId));
                    dispatch(setAuthTimeOut( expiresIn, userId) );
                    return needUserId 
                        ? {token, userId} 
                        : token;       
                }
            } 
        } catch (err) {
            console.log("there is no token in localStrage or expired token");
            throw err;
        }
    }
}

export const authAutoSignIn = () => {
    return async (dispatch, getState) => {
        try{
            let needUserId = true;
            let tokenObj = await dispatch(authGetToken(needUserId));
            console.log("authAutoSignIn scusses");
            return tokenObj;
        } catch(err) {
            dispatch(authClearStorage());
            FirbaseApi.signOut();
            authTimer=null;
            throw err;      
        }
    }
}

export const authLogOut = (userId, timeout) => {
    return async (dispatch, getState) => {
        try{
            if(!timeout){
                // eslint-disable-next-line no-unused-vars
                let offLine = await dispatch(updateOnline(userId, false));
            }
            dispatch(authClearStorage());
            FirbaseApi.signOut();
            authTimer=null;
            // dispatch(removeFbListeners()) 
            return true;
        } catch(err) {
            authTimer=null;
            console.log("authLogOut error");
            throw err;
        }
    }
}

export const tryAuth = (authData, mode) => {
    return async (dispatch, getState) => {
        dispatch(tryAuthStart());
        try{
            let data = await taguJamApi.tryAuth(authData, mode);
            if(!data.idToken){
                let error = "UNKNOWN_ERROR";
                if(data.error && data.error.message){
                    error = data.error.message    
                }
                throw new Error(error);
            } else {
                if(mode==='SIGNUP'){
                    let userMetaData = {
                        id: data.localId,
                        preference:{
                            showDealingOnly:true,
                            showActiveInterviewOnly:true,
                            showActiveQuestionOnly:true     
                        },
                        mailSubscription:{
                            campaignMail:false,
                            dealPending:false,
                            systemNotification:false     
                        },
                        fcmTokens: {
                            [authData.platform]:'webSignIn'    
                        },
                        tos_acceptance: {
                            date: authData.today,
                            ip: authData.ipAddress
                        }
                    };
                    dispatch(addUserMetaToList(userMetaData, data.localId));
                }
                dispatch(setAuthTimeOut(data.expiresIn, data.localId));
                setStorageData(data.idToken, data.email, data.expiresIn, data.localId);
                dispatch(storeAuthToken(data.idToken, data.email, data.expiresIn, data.localId));
                const {email, password} = authData;
                // eslint-disable-next-line no-unused-vars
                let result = await FirbaseApi.authUser(email, password);
                dispatch(updateOnline(data.localId, true));
                // dispatch(removeFbListeners()), dispatch(addFbListeners(data.localId));
                dispatch(tryAuthEnd());
                return {token : data.idToken, userId: data.localId };
            }
        } catch(err){
            dispatch(setAuthError("ERROR_OCCURED"))
            dispatch(tryAuthEnd());
            throw err
        }
    }   
};

const setAuthTimeOut = (expirationTime, userId) => {
    return dispatch => {
        if(!authTimer){
            authTimer = () => {
                let timeout = true;
                dispatch(setAuthError("SESSION_TIME_OUT"));
                dispatch(authLogOut(userId, timeout))
                .then(logout => {
                    dispatch(toggleAuthWinOpen());
                })
                .catch(error => {console.log("logout error")});
            };
            setTimeout( authTimer, expirationTime * 1000);
        }
    }  
}

const setStorageData = (idToken, email, expiresIn, userId) => {
    const expirationDate = new Date( new Date().getTime() + expiresIn * 1000 );
    localStorage.setItem("token", idToken);
    localStorage.setItem("expirationDate", expirationDate);
    localStorage.setItem("userId", userId);
    localStorage.setItem("email", email);
}

const storeAuthToken = (idToken, email, expiresIn, userId ) => {
    return dispatch => {
        const expirationDate = new Date( new Date().getTime() + expiresIn * 1000 );
        const authInfo = {
            token : idToken,
            expirationDate,
            userId,
            email
        };
        dispatch(setAuth(authInfo));
    }
}

const authClearStorage = () => {
    return dispatch => {
        localStorage.removeItem("token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("userId");
        localStorage.removeItem("email");
        const authInfo = {
            token : null,
            expirationDate : null,
            userId : null,
            email : null
        };;
        dispatch(setAuth(authInfo));
    }
};

export const sendResetPasswordMsg = (email, localCode) => {
    return async dispatch => {
        dispatch(tryAuthStart());
        try{ 
            let theSendRestMsgResult = await taguJamApi.sendResetPasswordMsg(email, localCode);
            dispatch(tryAuthEnd()); 
            return theSendRestMsgResult;
        } catch(err){
            dispatch(tryAuthEnd());
            throw err
        }
    }    
}

export const updatePassword = (newPassword, authData) => {
    return async dispatch => {
        dispatch(tryAuthStart());
        try{ 
            let data = await taguJamApi.tryAuth(authData, 'SIGNIN');
            if(!data.idToken){
                let error = "UNKNOWN_ERROR";
                if(data.error && data.error.message){
                    error = data.error.message    
                }
                throw new Error(error);
            } else {
                let updatePasswordResult = await taguJamApi.updatePassword(newPassword, data.idToken);
                dispatch(setAuthTimeOut(updatePasswordResult.expiresIn, updatePasswordResult.localId));
                setStorageData(updatePasswordResult.idToken, updatePasswordResult.email, updatePasswordResult.expiresIn, updatePasswordResult.localId);
                dispatch(storeAuthToken(updatePasswordResult.idToken, updatePasswordResult.email, updatePasswordResult.expiresIn, updatePasswordResult.localId));
                dispatch(tryAuthEnd()); 
                return updatePasswordResult; 
            }
        } catch(err){
            dispatch(tryAuthEnd());
            throw err
        }
    }    
}

export const updateEmail = (newEmail, authData, userId) => {
    return async dispatch => {
        dispatch(tryAuthStart());
        try{ 
            let data = await taguJamApi.tryAuth(authData, 'SIGNIN');
            if(!data.idToken){
                let error = "UNKNOWN_ERROR";
                if(data.error && data.error.message){
                    error = data.error.message    
                }
                throw new Error(error);
            } else {
                let updateEmailResult = await taguJamApi.updateEmail(newEmail, userId, data.idToken);
                dispatch(setAuthTimeOut(updateEmailResult.expiresIn, updateEmailResult.localId));
                setStorageData(updateEmailResult.idToken, updateEmailResult.email, updateEmailResult.expiresIn, updateEmailResult.localId);
                dispatch(storeAuthToken(updateEmailResult.idToken, updateEmailResult.email, updateEmailResult.expiresIn, updateEmailResult.localId));
                dispatch(changeEmailAddress(userId));
                dispatch(resetEmailVerification())
                dispatch(tryAuthEnd());
                return updateEmailResult;
            }
        } catch(err){
            dispatch(tryAuthEnd());
            throw err
        }
    }    
}